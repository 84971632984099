import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import './DeleteBadge.css';

interface DeleteBadgeInterface {
  onClick: () => void;
  children: React.ReactNode;
}

const DeleteBadge = (props: DeleteBadgeInterface) => (
  <div className={'delete-badge-container'}>
    <span className={'delete-badge-button'} onClick={props.onClick}>
      <ClearIcon fontSize={'small'} />
    </span>
    {props.children}
  </div>
);

export default DeleteBadge;
