import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getPlacesListSuccess,
  getPlacesListFailure,
  postPlacesSuccess,
  postPlacesFailure,
  putPlacesSuccess,
  putPlacesFailure,
  putPlacesPositionSuccess,
  putPlacesPositionFailure,
  getPlaceSuccess,
  getPlaceFailure,
  getPlacesTranslationSuccess,
  getPlacesTranslationFailure,
  postPlacesTranslationSuccess,
  postPlacesTranslationFailure,
  putPlacesTranslationSuccess,
  putPlacesTranslationFailure,
  deletePlacesSuccess,
  deletePlacesFailure,
} from '../actions';
import { placesConstants } from '../constants';
import {
  getPlacesByProjectApi,
  postPlacesByProjectApi,
  putPlacesApi,
  putPlacesPositionApi,
  getPlaceApi,
  getTranslationPlacesSelfApi,
  postTranslationPlacesSelfApi,
  putTranslationPlacesSelfApi,
  deletePlacesSelfApi,
} from '../api';

export function* fetchGetPlacesByProject(action: any): any {
  try {
    const placesList = yield call(getPlacesByProjectApi, {
      idProject: action.idProject,
    });
    yield put(getPlacesListSuccess(placesList));
  } catch (error) {
    yield put(getPlacesListFailure(error));
  }
}

export function* fetchPostPlacesByProject(action: any): any {
  try {
    const newPlace = yield call(postPlacesByProjectApi, {
      idProject: action.idProject,
      newPlace: action.place,
    });
    yield put(postPlacesSuccess(newPlace));
  } catch (error) {
    yield put(postPlacesFailure(error));
  }
}

export function* fetchPutPlaces(action: any): any {
  try {
    const updatedPlace = yield call(putPlacesApi, {
      id: action.placeId,
      updatedPlace: action.place,
    });
    yield put(putPlacesSuccess(updatedPlace));
  } catch (error) {
    yield put(putPlacesFailure(error));
  }
}

export function* fetchPutPlacesPosition(action: any): any {
  try {
    const updatedPlacePosition = yield call(putPlacesPositionApi, {
      id: action.place.id,
      index: action.place.position,
    });
    yield put(putPlacesPositionSuccess(updatedPlacePosition));
  } catch (error) {
    yield put(putPlacesPositionFailure(error));
  }
}

export function* fetchGetPlace(action: any): any {
  try {
    const placeDetails = yield call(getPlaceApi, {
      idPlace: action.id,
    });
    yield put(getPlaceSuccess(placeDetails));
  } catch (error) {
    yield put(getPlaceFailure(error));
  }
}

export function* fetchGetTranslationPlacesSelf(id: any): any {
  try {
    const translationPlaceSelf = yield call(getTranslationPlacesSelfApi, id);
    yield put(getPlacesTranslationSuccess(translationPlaceSelf));
  } catch (error) {
    yield put(getPlacesTranslationFailure(error));
  }
}

export function* fetchPostTranslationPlacesSelf(action: any): any {
  try {
    const translationPlace = yield call(postTranslationPlacesSelfApi, {
      id: action.placeId,
      translationPlace: action.translationPlace,
    });
    yield put(postPlacesTranslationSuccess(translationPlace));
  } catch (error) {
    yield put(postPlacesTranslationFailure(error));
  }
}

export function* fetchPutTranslationPlacesSelf(action: any): any {
  try {
    const updatedTranslationPlace = yield call(putTranslationPlacesSelfApi, {
      id: action.placeId,
      updatedTranslationPlace: action.translationPlace,
    });
    yield put(putPlacesTranslationSuccess(updatedTranslationPlace));
  } catch (error) {
    yield put(putPlacesTranslationFailure(error));
  }
}

export function* fetchDeletePlacesSelf(action: any): any {
  try {
    const oldPlace = yield call(deletePlacesSelfApi, action.id);
    yield put(deletePlacesSuccess(oldPlace));
  } catch (error) {
    yield put(deletePlacesFailure(error));
  }
}

export function* getPlacesByProject() {
  yield takeLatest(
    placesConstants.GET_PLACES_LIST_REQUEST,
    fetchGetPlacesByProject
  );
}

export function* postPlacesByProject() {
  yield takeLatest(
    placesConstants.POST_PLACES_REQUEST,
    fetchPostPlacesByProject
  );
}

export function* putPlaces() {
  yield takeLatest(placesConstants.PUT_PLACES_REQUEST, fetchPutPlaces);
}

export function* putPlacesPosition() {
  yield takeLatest(
    placesConstants.PUT_PLACES_POSITION_REQUEST,
    fetchPutPlacesPosition
  );
}

export function* getPlace() {
  yield takeLatest(placesConstants.GET_PLACE_REQUEST, fetchGetPlace);
}

export function* getTranslationPlacesSelf() {
  yield takeLatest(
    placesConstants.GET_PLACES_TRANSLATION_REQUEST,
    fetchGetTranslationPlacesSelf
  );
}

export function* postTranslationPlacesSelf() {
  yield takeLatest(
    placesConstants.POST_PLACES_TRANSLATION_REQUEST,
    fetchPostTranslationPlacesSelf
  );
}

export function* putTranslationPlacesSelf() {
  yield takeLatest(
    placesConstants.PUT_PLACES_TRANSLATION_REQUEST,
    fetchPutTranslationPlacesSelf
  );
}

export function* deletePlacesSelf() {
  yield takeLatest(
    placesConstants.DELETE_PLACES_REQUEST,
    fetchDeletePlacesSelf
  );
}

export default function* placesSaga() {
  yield all([
    fork(getPlacesByProject),
    fork(postPlacesByProject),
    fork(putPlaces),
    fork(putPlacesPosition),
    fork(getPlace),
    fork(getTranslationPlacesSelf),
    fork(postTranslationPlacesSelf),
    fork(putTranslationPlacesSelf),
    fork(deletePlacesSelf),
  ]);
}
