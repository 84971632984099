export default class PlaceModel {
  acronym: null | string;
  attendeeLimit: number;
  external_id: null | number;
  floorNumber: null | number;
  id: number;
  name: string;
  position: number;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.acronym = data.acronym;
    this.attendeeLimit = data.attendeeLimit;
    this.external_id = data.external_id;
    this.floorNumber = data.floorNumber;
    this.position = data.position;
  }
}
