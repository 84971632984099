import React, { useState } from 'react';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { formatDateTime, formatDate } from '../../services/formatDate';
import { NavLink } from 'react-router-dom';
import Translator from '../../services/translator';
import { pagesConstant } from '../../Constants/Constant';
import SessionModel from '../../Models/SessionModel';

const LinkToSession = (props: { session: SessionModel }) => {
  const sessionId = props.session.id;
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <>
      <NavLink
        className="linkSession"
        to={pagesConstant.sessions.edit + sessionId}
        id={'PopoverLink-' + sessionId}
        onMouseEnter={() => setPopoverOpen(true)}
        onMouseLeave={() => setPopoverOpen(false)}
      >
        {props.session.title
          ? props.session.title
          : Translator.trans('sessions.name.id', {
              id: sessionId,
            })}
      </NavLink>

      <UncontrolledPopover
        placement="auto"
        isOpen={popoverOpen}
        target={'PopoverLink-' + sessionId}
        className="popover-container"
      >
        <PopoverBody>
          {props.session.timeToBeConfirmed ? (
            <div>
              <h2>{Translator.trans('sessions.title.timeToBeConfirmed')} :</h2>
              <p>{formatDate(props.session.liveStartDateTimeUTC)}</p>
            </div>
          ) : (
            <>
              <div>
                <h2>{Translator.trans('sessions.title.start')} :</h2>
                <p>{formatDateTime(props.session.liveStartDateTimeUTC)}</p>
              </div>
              <div>
                <h2>{Translator.trans('sessions.title.end')} :</h2>
                <p>{formatDateTime(props.session.liveEndDateTimeUTC)}</p>
              </div>
            </>
          )}
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default LinkToSession;
