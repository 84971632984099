import ImageModel from './ImageModel';
import ContentModel from './ContentModel';

export default class NewsModel extends ContentModel {
  picture: ImageModel | null;

  constructor(data: any) {
    super(data);
    this.picture = data.picture ? new ImageModel(data.picture) : null;
  }
}
