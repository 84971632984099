import React from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const FilterClear = ({
  show,
  handleReset,
}: {
  show: boolean;
  handleReset: () => void;
}) =>
  show ? (
    <IconButton onClick={handleReset} className={'p-0 m-0'}>
      <Close />
    </IconButton>
  ) : (
    <IconButton />
  );

export default FilterClear;
