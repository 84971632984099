import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SortingState,
  PagingState,
  CustomPaging,
  FilteringState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  getPlacesListRequest,
  getSessionsListRequest,
  getProgramsListRequest,
  getPerformersListRequest,
  clearSession,
} from '../../../Redux/actions';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import Translator from '../../../services/translator';
import ColumnCell from '../../../components/Table/Columns/ColumnCell';
import SessionsCell from '../../../components/Table/Cells/Sessions/SessionsCell';
import { Loading } from '@emisys/audience-sdk-ui-react';
import NoData from '../../../components/Table/NoData/NoData';
import FilterSessionCell from '../../../components/Table/Cells/Sessions/FiltersCell';
import { currentProject } from '../../../Global/currentProject';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import {
  hideSortingButtons,
  rowPerTablePage,
} from '../../../components/Table/Constants/Constant';
import TableContainer from '../../../components/Table/TableContainer/TableContainer';
import SortLabel from '../../../components/Table/Sorting/SortLabel';
import PagingTable from '../../../components/Table/Paging/PagingTable';
import {
  columnsSessions,
  columnType,
  tableColumnExtensionsSessions,
} from '../../../components/Table/Columns/ShowColumns';
import { storeType } from '../../../index';
import SessionModel from '../../../Models/SessionModel';

const SessionsTable = () => {
  const dispatch = useDispatch();
  const localStorageSessions = localStorage.getItem('paramsSessions');

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [rows, setRows] = useState([]);
  const [objectCount, setObjectCount] = useState(0);
  const [newColumnsTickets, setNewColumnsTickets] = useState<any>();

  const [isPlaceReady, setIsPlaceReady] = useState(false);
  const [isProgramReady, setIsProgramReady] = useState(false);
  const [isPerformersReady, setIsPerformersReady] = useState(false);

  const programsList = useSelector(
    (state: storeType) => state.programs.programsList
  );
  const newSessionPerformers = useSelector(
    (state: storeType) => state.sessions.newSessionPerformers
  );
  const oldSessionSelf = useSelector(
    (state: storeType) => state.sessions.oldSessionSelf
  );
  const newSessionPlaces = useSelector(
    (state: storeType) => state.sessions.newSessionPlaces
  );
  const sessionsList = useSelector(
    (state: storeType) => state.sessions.sessionsList
  );
  const performersList = useSelector(
    (state: storeType) => state.performers.performersList
  );
  const placesList = useSelector((state: storeType) => state.places.placesList);

  const timeout: any = useRef(null);
  let params: any = useMemo(
    () => ({
      accepted: 1,
      limit: currentPageSize,
      page: currentPage + 1,
      order: 'id:DESC',
    }),
    [currentPage, currentPageSize]
  );

  const getSessionsList = useCallback(
    (params: any) => {
      dispatch(getSessionsListRequest(currentProject.id, params));
    },
    [dispatch]
  );

  const getSessionInfos = useCallback(
    (params: any) => {
      getSessionsList(params);
      dispatch(getProgramsListRequest(currentProject.id));
      dispatch(getPlacesListRequest(currentProject.id));
      dispatch(
        getPerformersListRequest(currentProject.id, { order: 'name:ASC' })
      );
    },
    [dispatch, getSessionsList]
  );

  useEffect(() => {
    if (!localStorageSessions) {
      getSessionInfos(params);
      localStorage.setItem('paramsSessions', JSON.stringify(params));
    }
  }, [getSessionInfos, localStorageSessions, params]);

  useEffect(() => {
    if (localStorageSessions) {
      const newParams = JSON.parse(localStorageSessions);
      setCurrentPage(newParams.page - 1);
      setCurrentPageSize(newParams.limit);
      getSessionInfos(newParams);
    }
  }, [getSessionInfos, localStorageSessions]);

  useEffect(() => {
    if (sessionsList) {
      setLoading(sessionsList.loading);
      if (checkReduxResponse(sessionsList, 'sessions')) {
        let newRows = sessionsList.sessions.map((session: SessionModel) => ({
          id: session.id,
          title: session.title,
          performer: session.sessionPerformers,
          place: session.sessionPlaces,
          liveStartDateTimeUTC: session.liveStartDateTimeUTC,
          liveEndDateTimeUTC: session.liveEndDateTimeUTC,
          timeToBeConfirmed: session.timeToBeConfirmed,
          program: session.program?.name,
          session: session,
        }));

        setObjectCount(sessionsList.meta.object_count);
        setRows(newRows);
      }
    }
  }, [sessionsList]);

  useEffect(() => {
    if (placesList) {
      setLoading(placesList.loading);
      if (checkReduxResponse(placesList, 'places')) {
        setIsPlaceReady(true);
        columnsSessions.map((data: columnType) => {
          if (data.name === 'place') {
            data.places = placesList.places;
          }
        });

        setNewColumnsTickets(columnsSessions);
      }
    }
  }, [placesList]);

  useEffect(() => {
    if (programsList) {
      setLoading(programsList.loading);
      if (checkReduxResponse(programsList, 'programs')) {
        setIsProgramReady(true);
        columnsSessions.map((data: columnType) => {
          if (data.name === 'program') {
            data.programs = programsList.programs;
          }
        });

        setNewColumnsTickets(columnsSessions);
      }
    }
  }, [programsList]);

  useEffect(() => {
    if (performersList) {
      setLoading(performersList.loading);
      if (checkReduxResponse(performersList, 'performers')) {
        setIsPerformersReady(true);
        columnsSessions.map((data: columnType) => {
          if (data.name === 'performer') {
            data.performers = performersList.performers;
          }
        });

        setNewColumnsTickets(columnsSessions);
      }
    }
  }, [performersList]);

  useEffect(() => {
    if (oldSessionSelf) {
      if (checkReduxResponse(oldSessionSelf, 'session')) {
        NotificationManager.success(
          Translator.trans('sessions.message.success.delete')
        );
        getSessionsList(params);
      }

      dispatch(clearSession());
    }
  }, [dispatch, getSessionsList, oldSessionSelf, params]);

  useEffect(() => {
    if (newSessionPerformers) {
      if (checkReduxResponse(newSessionPerformers, 'sessionPerformers')) {
        getSessionsList(params);
      }
    }
  }, [dispatch, getSessionsList, newSessionPerformers, params]);

  useEffect(() => {
    if (newSessionPlaces) {
      if (checkReduxResponse(newSessionPlaces, 'sessionPlaces')) {
        dispatch(getProgramsListRequest(currentProject.id));
      }
    }
  }, [dispatch, newSessionPlaces]);

  const handleCurrentPage = (page: number) => {
    setCurrentPage(page);
    params.page = page + 1;
    localStorage.setItem('paramsSessions', JSON.stringify(params));
  };

  const handlePageSize = (pageSize: number) => {
    setCurrentPageSize(pageSize);
    params.limit = pageSize;
    params.page = 1;
    localStorage.setItem('paramsSessions', JSON.stringify(params));
  };

  const onFilter = (filter: any) => {
    setFilters(filter);
    filter.forEach((data: any) => {
      if (data.value && data.value !== '' && data.value !== 'all') {
        if (data.columnName === 'program') {
          params[data.columnName] = {};
          params[data.columnName].operation = 'eq';
          params[data.columnName].value = data.value;
        } else if (
          data.columnName === 'liveStartDateTimeUTC' ||
          data.columnName === 'liveEndDateTimeUTC'
        ) {
          params[data.columnName] = [];
          params[data.columnName].push({
            operation: 'gt',
            value: data.values.dateStart,
          });
          params[data.columnName].push({
            operation: 'lt',
            value: data.values.dateEnd,
          });
        } else if (data.columnName === 'performer') {
          params['sessionPerformers.performer.id'] = {};
          params['sessionPerformers.performer.id'].operation = 'contain';
          params['sessionPerformers.performer.id'].value = data.value;
        } else if (data.columnName === 'place') {
          params['sessionPlaces.place.id'] = {};
          params['sessionPlaces.place.id'].operation = 'contain';
          params['sessionPlaces.place.id'].value = data.value;
        } else {
          params[data.columnName] = {};
          params[data.columnName].operation = 'contain';
          params[data.columnName].value = data.value;
        }
      } else {
        if (data.columnName === 'performer') {
          delete params['sessionPerformers.performer.id'];
        } else if (data.columnName === 'place') {
          delete params['sessionPlaces.place.id'];
        } else {
          delete params[data.columnName];
        }
      }
    });

    localStorage.setItem('paramsSessions', JSON.stringify(params));
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      getSessionsList(params);
    }, 200);
  };

  const setSorting = (sort: any) => {
    sort.forEach((data: any) => {
      params.limit = currentPageSize;
      params.page = 1;
      params.order = data.columnName + ':' + data.direction.toUpperCase();
    });

    handleCurrentPage(0);

    getSessionsList(params);

    localStorage.setItem(
      'paramsSessions',
      JSON.stringify({
        accepted: 1,
        limit: currentPageSize,
        page: currentPage + 1,
        order: 'id:DESC',
      })
    );
  };

  return (
    <div>
      {isPlaceReady && isProgramReady && isPerformersReady && (
        <Grid rows={rows} columns={newColumnsTickets}>
          <PagingState
            pageSize={currentPageSize}
            currentPage={currentPage}
            onCurrentPageChange={handleCurrentPage}
            onPageSizeChange={handlePageSize}
          />
          <SortingState
            onSortingChange={setSorting}
            columnExtensions={hideSortingButtons}
          />
          <FilteringState onFiltersChange={onFilter} filters={filters} />
          <Table
            columnExtensions={tableColumnExtensionsSessions}
            cellComponent={SessionsCell}
            tableComponent={TableContainer}
            noDataCellComponent={NoData}
          />
          <CustomPaging totalCount={objectCount} />
          <TableHeaderRow
            showSortingControls
            sortLabelComponent={SortLabel}
            cellComponent={ColumnCell}
          />
          <TableFilterRow cellComponent={FilterSessionCell} />
          <PagingPanel
            pageSizes={rowPerTablePage}
            containerComponent={PagingTable}
          />
        </Grid>
      )}
      {loading && <Loading />}
    </div>
  );
};

export default SessionsTable;
