export const contentArticlesConstants = {
  GET_CONTENT_ARTICLES_REQUEST: 'GET_CONTENT_ARTICLES_REQUEST',
  GET_CONTENT_ARTICLES_SUCCESS: 'GET_CONTENT_ARTICLES_SUCCESS',
  GET_CONTENT_ARTICLES_FAILURE: 'GET_CONTENT_ARTICLES_FAILURE',

  PUT_CONTENT_ARTICLES_REQUEST: 'PUT_CONTENT_ARTICLES_REQUEST',
  PUT_CONTENT_ARTICLES_SUCCESS: 'PUT_CONTENT_ARTICLES_SUCCESS',
  PUT_CONTENT_ARTICLES_FAILURE: 'PUT_CONTENT_ARTICLES_FAILURE',

  PUT_CONTENT_ARTICLES_POSITION_REQUEST:
    'PUT_CONTENT_ARTICLES_POSITION_REQUEST',
  PUT_CONTENT_ARTICLES_POSITION_SUCCESS:
    'PUT_CONTENT_ARTICLES_POSITION_SUCCESS',
  PUT_CONTENT_ARTICLES_POSITION_FAILURE:
    'PUT_CONTENT_ARTICLES_POSITION_FAILURE',

  POST_CONTENT_ARTICLES_REQUEST: 'POST_CONTENT_ARTICLES_REQUEST',
  POST_CONTENT_ARTICLES_SUCCESS: 'POST_CONTENT_ARTICLES_SUCCESS',
  POST_CONTENT_ARTICLES_FAILURE: 'POST_CONTENT_ARTICLES_FAILURE',

  DELETE_CONTENT_ARTICLES_REQUEST: 'DELETE_CONTENT_ARTICLES_REQUEST',
  DELETE_CONTENT_ARTICLES_SUCCESS: 'DELETE_CONTENT_ARTICLES_SUCCESS',
  DELETE_CONTENT_ARTICLES_FAILURE: 'DELETE_CONTENT_ARTICLES_FAILURE',

  GET_CONTENT_ARTICLE_REQUEST: 'GET_CONTENT_ARTICLE_REQUEST',
  GET_CONTENT_ARTICLE_SUCCESS: 'GET_CONTENT_ARTICLE_SUCCESS',
  GET_CONTENT_ARTICLE_FAILURE: 'GET_CONTENT_ARTICLE_FAILURE',

  GET_CONTENT_ARTICLE_TRANSLATION_REQUEST:
    'GET_CONTENT_ARTICLE_TRANSLATION_REQUEST',
  GET_CONTENT_ARTICLE_TRANSLATION_SUCCESS:
    'GET_CONTENT_ARTICLE_TRANSLATION_SUCCESS',
  GET_CONTENT_ARTICLE_TRANSLATION_FAILURE:
    'GET_CONTENT_ARTICLE_TRANSLATION_FAILURE',

  POST_CONTENT_ARTICLE_TRANSLATION_REQUEST:
    'POST_CONTENT_ARTICLE_TRANSLATION_REQUEST',
  POST_CONTENT_ARTICLE_TRANSLATION_SUCCESS:
    'POST_CONTENT_ARTICLE_TRANSLATION_SUCCESS',
  POST_CONTENT_ARTICLE_TRANSLATION_FAILURE:
    'POST_CONTENT_ARTICLE_TRANSLATION_FAILURE',

  PUT_CONTENT_ARTICLE_TRANSLATION_REQUEST:
    'PUT_CONTENT_ARTICLE_TRANSLATION_REQUEST',
  PUT_CONTENT_ARTICLE_TRANSLATION_SUCCESS:
    'PUT_CONTENT_ARTICLE_TRANSLATION_SUCCESS',
  PUT_CONTENT_ARTICLE_TRANSLATION_FAILURE:
    'PUT_CONTENT_ARTICLE_TRANSLATION_FAILURE',

  CLEAR_CONTENT_ARTICLES: 'CLEAR_CONTENT_ARTICLES',
};
