import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getContentNewsListSuccess,
  getContentNewsListFailure,
  postContentNewsSuccess,
  postContentNewsFailure,
  putContentNewsSuccess,
  putContentNewsFailure,
  putContentNewsPositionSuccess,
  putContentNewsPositionFailure,
  getContentNewsSuccess,
  getContentNewsFailure,
  deleteContentNewsSuccess,
  deleteContentNewsFailure,
  getContentNewsTranslationSuccess,
  getContentNewsTranslationFailure,
  postContentNewsTranslationSuccess,
  postContentNewsTranslationFailure,
  putContentNewsTranslationSuccess,
  putContentNewsTranslationFailure,
} from '../actions';
import { contentNewsConstants } from '../constants';
import {
  getContentNewsByProjectApi,
  postContentNewsByProjectApi,
  putContentNewsApi,
  putContentNewsPositionApi,
  getContentNewsApi,
  deleteContentNewsApi,
  getTranslationContentNewsApi,
  postTranslationContentNewsSelfApi,
  putTranslationContentNewsSelfApi,
} from '../api';

export function* fetchGetContentNewsByProject(action: any): any {
  try {
    const contentNews = yield call(getContentNewsByProjectApi, {
      idProject: action.idProject,
    });
    yield put(getContentNewsListSuccess(contentNews));
  } catch (error) {
    yield put(getContentNewsListFailure(error));
  }
}

export function* fetchPostContentNewsByProject(action: any): any {
  try {
    const newContentNews = yield call(postContentNewsByProjectApi, {
      idProject: action.idProject,
      newContentNews: action.newContentNews,
    });
    yield put(postContentNewsSuccess(newContentNews));
  } catch (error) {
    yield put(postContentNewsFailure(error));
  }
}

export function* fetchPutContentNews(action: any): any {
  try {
    const updatedContentNews = yield call(putContentNewsApi, {
      updatedContentNews: action.updatedContentNews,
    });
    yield put(putContentNewsSuccess(updatedContentNews));
  } catch (error) {
    yield put(putContentNewsFailure(error));
  }
}

export function* fetchPutContentNewsPosition(action: any): any {
  try {
    const updatedContentNewsPosition = yield call(putContentNewsPositionApi, {
      id: action.updatedContentNewsPosition.id,
      index: action.updatedContentNewsPosition.position,
    });
    yield put(putContentNewsPositionSuccess(updatedContentNewsPosition));
  } catch (error) {
    yield put(putContentNewsPositionFailure(error));
  }
}

export function* fetchGetContentNews(action: any): any {
  try {
    const contentNewsDetails = yield call(getContentNewsApi, {
      idContentNews: action.idContentNews,
    });
    yield put(getContentNewsSuccess(contentNewsDetails));
  } catch (error) {
    yield put(getContentNewsFailure(error));
  }
}

export function* fetchDeleteContentNews(action: any): any {
  try {
    const deletedContentNews = yield call(deleteContentNewsApi, {
      idContentNews: action.idContentNews,
    });
    yield put(deleteContentNewsSuccess(deletedContentNews));
  } catch (error) {
    yield put(deleteContentNewsFailure(error));
  }
}

export function* fetchGetTranslationContentNews(action: any): any {
  try {
    const translationContentNewsList = yield call(
      getTranslationContentNewsApi,
      { id: action.idContentNews }
    );
    yield put(getContentNewsTranslationSuccess(translationContentNewsList));
  } catch (error) {
    yield put(getContentNewsTranslationFailure(error));
  }
}

export function* fetchPostTranslationContentNewsSelf(action: any): any {
  try {
    const translationContentNews = yield call(
      postTranslationContentNewsSelfApi,
      {
        id: action.newsId,
        translationContentNews: action.translationContentNews,
      }
    );
    yield put(postContentNewsTranslationSuccess(translationContentNews));
  } catch (error) {
    yield put(postContentNewsTranslationFailure(error));
  }
}

export function* fetchPutTranslationContentNewsSelf(action: any): any {
  try {
    const updatedTranslationContentNews = yield call(
      putTranslationContentNewsSelfApi,
      {
        id: action.newsId,
        updatedTranslationContentNews: action.updatedTranslationContentNews,
      }
    );
    yield put(putContentNewsTranslationSuccess(updatedTranslationContentNews));
  } catch (error) {
    yield put(putContentNewsTranslationFailure(error));
  }
}

export function* getContentNewsByProject() {
  yield takeLatest(
    contentNewsConstants.GET_CONTENT_NEWS_LIST_REQUEST,
    fetchGetContentNewsByProject
  );
}

export function* postContentNewsByProject() {
  yield takeLatest(
    contentNewsConstants.POST_CONTENT_NEWS_REQUEST,
    fetchPostContentNewsByProject
  );
}

export function* putContentNews() {
  yield takeLatest(
    contentNewsConstants.PUT_CONTENT_NEWS_REQUEST,
    fetchPutContentNews
  );
}

export function* putContentNewsPosition() {
  yield takeLatest(
    contentNewsConstants.PUT_CONTENT_NEWS_POSITION_REQUEST,
    fetchPutContentNewsPosition
  );
}

export function* getContentNews() {
  yield takeLatest(
    contentNewsConstants.GET_CONTENT_NEWS_REQUEST,
    fetchGetContentNews
  );
}

export function* deleteContentNews() {
  yield takeLatest(
    contentNewsConstants.DELETE_CONTENT_NEWS_REQUEST,
    fetchDeleteContentNews
  );
}

export function* getTranslationContentNews() {
  yield takeLatest(
    contentNewsConstants.GET_CONTENT_NEWS_TRANSLATION_REQUEST,
    fetchGetTranslationContentNews
  );
}

export function* postTranslationContentNewsSelf() {
  yield takeLatest(
    contentNewsConstants.POST_CONTENT_NEWS_TRANSLATION_REQUEST,
    fetchPostTranslationContentNewsSelf
  );
}

export function* putTranslationContentNewsSelf() {
  yield takeLatest(
    contentNewsConstants.PUT_CONTENT_NEWS_TRANSLATION_REQUEST,
    fetchPutTranslationContentNewsSelf
  );
}

export default function* contentNewsSaga() {
  yield all([
    fork(getContentNewsByProject),
    fork(postContentNewsByProject),
    fork(putContentNews),
    fork(putContentNewsPosition),
    fork(getContentNews),
    fork(deleteContentNews),
    fork(getTranslationContentNews),
    fork(postTranslationContentNewsSelf),
    fork(putTranslationContentNewsSelf),
  ]);
}
