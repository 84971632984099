import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PersonalContentSide from './PersonalContentSide';
import PersonalContent from './PersonalContent';
import { NotificationManager } from '../../components/Notification/NotificationManager';
import PersonIcon from '@mui/icons-material/Person';
import { checkReduxResponse } from '../../services/checkReduxResponse';
import { HeaderBanner } from '@emisys/audience-sdk-ui-react';
import Translator from '../../services/translator';
import { storeType } from '../../index';
import './index.css';

const PersonalAccount = () => {
  const userUpdate = useSelector((state: storeType) => state.user.userUpdate);

  useEffect(() => {
    if (userUpdate) {
      if (checkReduxResponse(userUpdate, 'user')) {
        NotificationManager.success(
          Translator.trans('account.personal.message.success.updated')
        );
      }

      if (userUpdate.error) {
        NotificationManager.error(userUpdate.error.message);
      }
    }
  }, [userUpdate]);

  return (
    <div className="app-wrapper">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('appModule.myProfile')}
          icon={<PersonIcon />}
        />
      </div>
      <div className="col-12">
        <div className="row jr-card p-0">
          <div className="col-12 col-lg-3 asideContent">
            <PersonalContentSide />
          </div>
          <div className="col-12 col-lg-9 infoContent">
            <PersonalContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalAccount;
