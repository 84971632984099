import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBrandingRequest,
  putBrandingRequest,
  postUploadPictureRequest,
  clearBrandingByProject,
} from '../../Redux/actions';
import {
  HeaderBanner,
  UnderlineTitle,
  DragAndDrop,
  Loading,
} from '@emisys/audience-sdk-ui-react';
import { NotificationManager } from '../../components/Notification/NotificationManager';
import Translator from '../../services/translator';
import { currentProject } from '../../Global/currentProject';
import { checkReduxResponse } from '../../services/checkReduxResponse';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteBadge from '../../components/DeleteBadge/DeleteBadge';
import ButtonsSaveModal from '../../components/Buttons/ButtonsSaveModal';
import { storeType } from '../../index';
import BrandingModel from '../../Models/BrandingModel';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import './Branding.css';

type imageType = {
  id: number | null;
  original: string;
};

const Branding = () => {
  const dispatch = useDispatch();
  const [colorPrimary, setColorPrimary] = useState('');
  const [colorSecondary, setColorSecondary] = useState('');
  const [colorBackground, setColorBackground] = useState('');
  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingBranding, setLoadingBranding] = useState(false);

  const [pictureLogo, setPictureLogo] = useState<imageType | null>(null);
  const [pictureMap, setPictureMap] = useState<imageType | null>(null);
  const uploadedPicture = useRef('');

  const brandingList = useSelector(
    (state: storeType) => state.branding.brandingList
  );
  const updatedBrandingList = useSelector(
    (state: storeType) => state.branding.updatedBrandingList
  );
  const newUploadPicture = useSelector(
    (state: storeType) => state.uploads.newUploadPicture
  );

  useEffect(() => {
    if (brandingList) {
      setLoadingBranding(brandingList.loading);
      if (checkReduxResponse(brandingList, 'branding')) {
        setBrandingContent(brandingList.branding);
      }
    } else {
      dispatch(getBrandingRequest(currentProject.id));
    }
  }, [brandingList, dispatch]);

  function setBrandingContent(branding: BrandingModel) {
    setColorPrimary(branding.colorPrimary);
    setColorSecondary(branding.colorSecondary);
    setColorBackground(branding.colorBackground);

    if (branding.pictureLogo) {
      setPictureLogo({
        id: branding.pictureLogo.id,
        original: branding.pictureLogo.original,
      });
    }
    if (branding.pictureMap) {
      setPictureMap({
        id: branding.pictureMap.id,
        original: branding.pictureMap.original,
      });
    }
  }

  useEffect(() => {
    if (updatedBrandingList) {
      setLoadingSend(updatedBrandingList.loading);
      if (checkReduxResponse(updatedBrandingList, 'updatedBranding')) {
        setBrandingContent(updatedBrandingList.updatedBranding);
        NotificationManager.success(
          Translator.trans('branding.message.success.updated')
        );
      }
      if (updatedBrandingList.error) {
        NotificationManager.error(Translator.trans('appModule.message.error'));
      }

      dispatch(clearBrandingByProject());
    }
  }, [updatedBrandingList, dispatch]);

  useEffect(() => {
    if (checkReduxResponse(newUploadPicture, 'uploadPicture')) {
      switch (uploadedPicture.current) {
        case 'pictureLogoTemp':
          setPictureLogo(newUploadPicture.uploadPicture);
          break;
        case 'pictureMapTemp':
          setPictureMap(newUploadPicture.uploadPicture);
          break;
      }
    }
  }, [newUploadPicture]);

  function deletePicture(name: string) {
    if (name === 'deleteLogo') {
      setPictureLogo(null);
    }
    if (name === 'deleteMap') {
      setPictureMap(null);
    }
  }

  function handleSubmit() {
    dispatch(
      putBrandingRequest(currentProject.id, {
        colorPrimary: colorPrimary,
        colorSecondary: colorSecondary,
        pictureLogo: pictureLogo?.id ? pictureLogo.id : null,
        pictureMap: pictureMap?.id ? pictureMap.id : null,
      })
    );
  }

  function onSelectFile(image: any) {
    uploadedPicture.current = image.name;
    if (uploadedPicture.current === 'pictureLogoTemp') {
      setPictureLogo({
        id: null,
        original: image.src,
      });
    }
    if (uploadedPicture.current === 'pictureMapTemp') {
      setPictureMap({
        id: null,
        original: image.src,
      });
    }
    dispatch(
      postUploadPictureRequest({
        type: 'PictureCommon',
        project_id: currentProject.id,
        data: image.src,
      })
    );
  }

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('branding.title')}
          icon={<CopyrightIcon />}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <UnderlineTitle
              title={Translator.trans('branding.subtitle.picture')}
            />
            <div className="row mb-4">
              <div className="col-12 col-lg-4">
                <div className="text-center">
                  <h2>{Translator.trans('branding.title.logo')}</h2>
                  {pictureLogo?.original ? (
                    <DeleteBadge onClick={() => deletePicture('deleteLogo')}>
                      <img
                        src={pictureLogo.original}
                        className="logoImage"
                        alt="logo"
                      />
                    </DeleteBadge>
                  ) : (
                    <DragAndDrop
                      name={'pictureLogoTemp'}
                      labelText={Translator.trans('appModule.drag.and.drop')}
                      onChange={onSelectFile}
                    />
                  )}
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="text-center">
                  <h2>{Translator.trans('branding.title.map')}</h2>
                  {pictureMap?.original ? (
                    <DeleteBadge onClick={() => deletePicture('deleteMap')}>
                      <img
                        src={pictureMap.original}
                        className="mapImage"
                        alt="logo"
                      />
                    </DeleteBadge>
                  ) : (
                    <DragAndDrop
                      name={'pictureMapTemp'}
                      labelText={Translator.trans('appModule.drag.and.drop')}
                      onChange={onSelectFile}
                    />
                  )}
                </div>
              </div>
            </div>

            <UnderlineTitle
              title={Translator.trans('branding.subtitle.color')}
            />
            <div className="row mb-4">
              <div className="col-6 col-md-auto">
                <ColorPicker
                  label={Translator.trans('branding.primary.color')}
                  color={colorPrimary}
                  changeColor={setColorPrimary}
                />
              </div>
              <div className="col-6 col-md-auto">
                <ColorPicker
                  label={Translator.trans('branding.secondary.color')}
                  color={colorSecondary}
                  changeColor={setColorSecondary}
                />
              </div>
              <div className="col-6 col-md-auto">
                <ColorPicker
                  label={Translator.trans('branding.background.color')}
                  color={colorBackground}
                  changeColor={setColorBackground}
                />
              </div>
            </div>

            <ButtonsSaveModal
              onSubmit={handleSubmit}
              isLoading={loadingSend}
              hideCancel
            />
          </div>
          {loadingBranding && <Loading />}
        </div>
      </div>
    </div>
  );
};

export default Branding;
