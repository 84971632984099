import React, { useEffect, useState } from 'react';
import { MainTextField } from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';
import { capitalizeFirstLetter } from '../../../services/capitalizeFirstLetter';

const PlacesCreationTranslations = (props: {
  language: string;
  newPlace?: string;
  handleChangeTranslation: (
    name: string,
    newValue: string,
    language: string
  ) => void;
}) => {
  const [name, setName] = useState('');

  useEffect(() => {
    if (props.newPlace) {
      setName(capitalizeFirstLetter(props.newPlace));
    }
  }, [props.newPlace]);

  const handleChangeTranslation = (name: string) => (event: any) => {
    const newValue = capitalizeFirstLetter(event.target.value);
    setName(newValue);
    props.handleChangeTranslation(name, newValue, props.language);
  };

  return (
    <div className="row">
      <div className="col-12 col-md-4 mt-3">
        <h4>{Translator.trans('sessions.title.place')}</h4>
      </div>
      <div className="col-12 col-md-8 mt-4">
        <MainTextField
          value={name}
          onChange={handleChangeTranslation('name')}
        />
      </div>
    </div>
  );
};
export default PlacesCreationTranslations;
