import { performersConstants } from '../constants';
import PerformerModel from '../../Models/PerformerModel';

const initialState: any = {
  performersList: null,
  newPerformer: null,
  performerDetails: null,
  updatedPerformer: null,
  deletedPerformer: null,
  translationPerformerList: null,
  translationPerformer: null,
  updatedTranslationPerformer: null,
};

let listPerformers: any[] = [];
const performers = (state = initialState, action: any) => {
  switch (action.type) {
    case performersConstants.GET_PERFORMERS_LIST_REQUEST: {
      return {
        ...state,
        performersList: {
          performers: listPerformers,
          error: false,
          loading: true,
        },
      };
    }

    case performersConstants.GET_PERFORMERS_LIST_SUCCESS: {
      listPerformers = action.performers.data.map(
        (performers: any) => new PerformerModel(performers)
      );

      return {
        ...state,
        performersList: {
          performers: listPerformers,
          meta: action.performers.meta,
          error: false,
          loading: false,
        },
      };
    }

    case performersConstants.GET_PERFORMERS_LIST_FAILURE: {
      return {
        ...state,
        performersList: { performers: null, error: true, loading: false },
      };
    }

    case performersConstants.POST_PERFORMER_REQUEST: {
      return {
        ...state,
        newPerformer: { performer: null, error: false, loading: true },
      };
    }

    case performersConstants.POST_PERFORMER_SUCCESS: {
      return {
        ...state,
        newPerformer: {
          performer: new PerformerModel(action.performer.data),
          error: false,
          loading: false,
        },
      };
    }

    case performersConstants.POST_PERFORMER_FAILURE: {
      return {
        ...state,
        newPerformer: { performer: null, error: true, loading: false },
      };
    }

    case performersConstants.GET_PERFORMER_REQUEST: {
      return {
        ...state,
        performerDetails: { performer: null, error: false, loading: true },
      };
    }

    case performersConstants.GET_PERFORMER_SUCCESS: {
      return {
        ...state,
        performerDetails: {
          performer: new PerformerModel(action.performerDetails.data),
          error: false,
          loading: false,
        },
      };
    }

    case performersConstants.GET_PERFORMER_FAILURE: {
      return {
        ...state,
        performerDetails: { performer: null, error: true, loading: false },
      };
    }

    case performersConstants.PUT_PERFORMER_REQUEST: {
      return {
        ...state,
        updatedPerformer: { performer: null, error: false, loading: true },
      };
    }

    case performersConstants.PUT_PERFORMER_SUCCESS: {
      return {
        ...state,
        updatedPerformer: {
          performer: action.updatedPerformer.data,
          error: false,
          loading: false,
        },
      };
    }

    case performersConstants.PUT_PERFORMER_FAILURE: {
      return {
        ...state,
        updatedPerformer: { performer: null, error: true, loading: false },
      };
    }

    case performersConstants.DELETE_PERFORMER_REQUEST: {
      return {
        ...state,
        deletedPerformer: { performer: null, error: false, loading: true },
      };
    }

    case performersConstants.DELETE_PERFORMER_SUCCESS: {
      return {
        ...state,
        deletedPerformer: {
          performer: true,
          error: false,
          loading: false,
        },
      };
    }

    case performersConstants.DELETE_PERFORMER_FAILURE: {
      return {
        ...state,
        deletedPerformer: { performer: null, error: true, loading: false },
      };
    }

    case performersConstants.GET_PERFORMER_TRANSLATIONS_REQUEST: {
      return {
        ...state,
        translationPerformerList: {
          performerList: null,
          error: false,
          loading: true,
        },
      };
    }

    case performersConstants.GET_PERFORMER_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        translationPerformerList: {
          performerList: action.translationPerformerList.data,
          error: false,
          loading: false,
        },
      };
    }

    case performersConstants.GET_PERFORMER_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        translationPerformerList: {
          performerList: null,
          error: true,
          loading: false,
        },
      };
    }

    case performersConstants.POST_PERFORMER_TRANSLATIONS_REQUEST: {
      return {
        ...state,
        translationPerformer: { performer: null, error: false, loading: true },
      };
    }

    case performersConstants.POST_PERFORMER_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        translationPerformer: {
          performer: action.translationPerformer.data,
          error: false,
          loading: false,
        },
      };
    }

    case performersConstants.POST_PERFORMER_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        translationPerformer: { performer: null, error: true, loading: false },
      };
    }

    case performersConstants.PUT_PERFORMER_TRANSLATIONS_REQUEST: {
      return {
        ...state,
        updatedTranslationPerformer: {
          performer: null,
          error: false,
          loading: true,
        },
      };
    }

    case performersConstants.PUT_PERFORMER_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        updatedTranslationPerformer: {
          performer: action.updatedTranslationPerformer.data,
          error: false,
          loading: false,
        },
      };
    }

    case performersConstants.PUT_PERFORMER_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        updatedTranslationPerformer: {
          performer: null,
          error: true,
          loading: false,
        },
      };
    }

    case performersConstants.CLEAR_PERFORMER: {
      return {
        ...state,
        newPerformer: null,
        performerDetails: null,
        updatedPerformer: null,
        deletedPerformer: null,
        translationPerformerList: null,
        translationPerformer: null,
        updatedTranslationPerformer: null,
      };
    }

    default:
      return state;
  }
};

export default performers;
