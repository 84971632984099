import Translator from '../services/translator';

export default class ErrorModel {
  message: string;
  formErrors: any | null;

  constructor(data: any) {
    this.message =
      data?.meta?.errors[0].message ??
      Translator.trans('appModule.message.error');
    this.formErrors =
      data?.meta?.errors[0]?.form_validations_failed?.errors ?? null;
  }
}
