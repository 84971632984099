import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const SortableRow = (props: any) => {
  const TableRow = SortableElement(Table.Row);
  return (
    <TableRow index={props.rows.indexOf(props.props.row)} {...props.props} />
  );
};

export default SortableRow;
