import { programsConstants } from '../constants';

export const getProgramsListRequest = (idProject: number | string) => ({
  type: programsConstants.GET_PROGRAMS_LIST_REQUEST,
  idProject,
});

export const getProgramsListSuccess = (programs: any) => ({
  type: programsConstants.GET_PROGRAMS_LIST_SUCCESS,
  programs,
});

export const getProgramsListFailure = (error: any) => ({
  type: programsConstants.GET_PROGRAMS_LIST_FAILURE,
  error,
});

export const getProgramSessionsRequest = (id: number | string) => ({
  type: programsConstants.GET_PROGRAM_SESSIONS_REQUEST,
  id,
});

export const getProgramSessionsSuccess = (sessions: any) => ({
  type: programsConstants.GET_PROGRAM_SESSIONS_SUCCESS,
  sessions,
});

export const getProgramSessionsFailure = (error: any) => ({
  type: programsConstants.GET_PROGRAM_SESSIONS_FAILURE,
  error,
});

export const postProgramSessionsRequest = (
  idProgram: number | string,
  newSession: any
) => ({
  type: programsConstants.POST_PROGRAM_SESSIONS_REQUEST,
  newSession,
  idProgram,
});

export const postProgramSessionsSuccess = (newSession: any) => ({
  type: programsConstants.POST_PROGRAM_SESSIONS_SUCCESS,
  newSession,
});

export const postProgramSessionsFailure = (error: any) => ({
  type: programsConstants.POST_PROGRAM_SESSIONS_FAILURE,
  error,
});

export const clearProgram = () => ({
  type: programsConstants.CLEAR_PROGRAM,
});
