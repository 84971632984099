import { projectConstants } from '../constants';

export const getProjectDetailsRequest = (id: number | string) => ({
  type: projectConstants.GET_PROJECT_DETAILS_REQUEST,
  idProject: id,
});

export const getProjectDetailsSuccess = (projectDetails: any) => ({
  type: projectConstants.GET_PROJECT_DETAILS_SUCCESS,
  projectDetails,
});

export const getProjectDetailsFailure = (error: any) => ({
  type: projectConstants.GET_PROJECT_DETAILS_FAILURE,
  error,
});

export const getProjectsSelfRequest = () => ({
  type: projectConstants.GET_PROJECT_REQUEST,
});

export const getProjectsSelfSuccess = (projects: any) => ({
  type: projectConstants.GET_PROJECT_SUCCESS,
  projects,
});

export const getProjectsSelfFailure = (error: any) => ({
  type: projectConstants.GET_PROJECT_FAILURE,
  error,
});

export const putProjectDetailsRequest = (
  idProject: number | string,
  projectDetails: any
) => ({
  type: projectConstants.PUT_PROJECT_DETAILS_REQUEST,
  idProject,
  projectDetails,
});

export const putProjectDetailsSuccess = (updatedProjectDetails: any) => ({
  type: projectConstants.PUT_PROJECT_DETAILS_SUCCESS,
  updatedProjectDetails,
});

export const putProjectDetailsFailure = (error: any) => ({
  type: projectConstants.PUT_PROJECT_DETAILS_FAILURE,
  error,
});

export const getProjectCostRequest = (idProject: number | string) => ({
  type: projectConstants.GET_PROJECT_COST_REQUEST,
  idProject,
});

export const getProjectCostSuccess = (projectCost: any) => ({
  type: projectConstants.GET_PROJECT_COST_SUCCESS,
  projectCost,
});

export const getProjectCostFailure = (error: any) => ({
  type: projectConstants.GET_PROJECT_COST_FAILURE,
  error,
});

export const archiveProjectRequest = (idProject: number | string) => ({
  type: projectConstants.ARCHIVE_PROJECT_REQUEST,
  idProject,
});

export const archiveProjectSuccess = (response: any) => ({
  type: projectConstants.ARCHIVE_PROJECT_SUCCESS,
  response,
});

export const archiveProjectFailure = (error: any) => ({
  type: projectConstants.ARCHIVE_PROJECT_FAILURE,
  error,
});

export const clearProjectRequest = () => ({
  type: projectConstants.CLEAR_PROJECT,
});
