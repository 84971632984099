import React, { useCallback } from 'react';
import { putContentArticlesRequest } from '../../../../Redux/actions';
import { useDispatch } from 'react-redux';
import ButtonsArticles from '../../../Buttons/ButtonsArticles';
import { SwitchComponent } from '@emisys/audience-sdk-ui-react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { columnNameAction, columnNameDrag } from '../../Constants/Constant';
import DragHandle from '../../Sortable/DragHandle/DragHandle';

const ArticleCell = (props: Table.DataCellProps) => {
  const dispatch = useDispatch();
  let content = props.value;

  const putContentArticles = useCallback(
    (newArticle: any) => {
      dispatch(putContentArticlesRequest(newArticle));
    },
    [dispatch]
  );

  const handleChangeIsActive = (event: any) => {
    const newArticle = { ...props.row.article };
    newArticle.isActive = event.target.checked;
    putContentArticles(newArticle);
  };

  if (props.column.name === columnNameDrag) {
    return (
      <Table.Cell {...props} style={{ width: 20 }}>
        <DragHandle />
      </Table.Cell>
    );
  }

  if (props.column.name === 'isActive') {
    content = (
      <SwitchComponent
        onHandleChange={handleChangeIsActive}
        checked={props.row.isActive}
      />
    );
  }

  if (props.column.name === columnNameAction) {
    content = <ButtonsArticles id={props.row.id} />;
  }

  return <Table.Cell {...props}>{content}</Table.Cell>;
};

export default ArticleCell;
