import { getUrl, get, update } from '../../services';

export async function getAppMobileByProjectApi(params: any) {
  return await get(getUrl('projects/' + params.idProject + '/appmobile'));
}

export async function putAppMobileByProjectApi(params: any) {
  return await update(
    getUrl('projects/' + params.idProject + '/appmobile'),
    params.newAppMobile
  );
}
