import React, { useEffect, useRef, useState } from 'react';
import { Modal } from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';
import { currentProject } from '../../../Global/currentProject';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import PlacesCreationTranslations from './PlacesCreationTranslations';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import { useDispatch, useSelector } from 'react-redux';
import { storeType } from '../../../index';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { capitalizeFirstLetter } from '../../../services/capitalizeFirstLetter';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import {
  postPlacesRequest,
  postPlacesTranslationRequest,
} from '../../../Redux/actions';

const ModalPlacesCreation = (props: {
  newPlace?: string;
  open: boolean;
  close: () => void;
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [loadingSend, setLoadingSend] = useState(false);

  const translationPlace: {
    current: any[];
  } = useRef([]);

  const newPlace = useSelector((state: storeType) => state.places.newPlace);

  useEffect(() => {
    if (currentProject.allowedLanguages) {
      currentProject.allowedLanguages.forEach((element) => {
        translationPlace.current.push({
          locale: element,
          name: '',
        });
      });
    }
  }, []);

  useEffect(() => {
    if (props.newPlace && currentProject.allowedLanguages) {
      currentProject.allowedLanguages.forEach((data) => {
        handleChangeTranslation(
          'name',
          capitalizeFirstLetter(props.newPlace),
          data
        );
      });
    }
  }, [props.newPlace]);

  useEffect(() => {
    if (newPlace) {
      setLoadingSend(newPlace.loading);
      if (checkReduxResponse(newPlace, 'place')) {
        dispatch(
          postPlacesTranslationRequest(newPlace.place.id, {
            translations: translationPlace.current,
          })
        );
        props.close();
      }
    }
  }, [dispatch, newPlace, props]);

  const handleChangeTranslation = (
    name: string,
    value: string,
    locale: string
  ) => {
    if (currentProject.defaultLanguage === locale) {
      setName(value);
    }
    let objIndex = translationPlace.current.findIndex(
      (item: any) => item.locale === locale
    );
    if (objIndex > -1) {
      translationPlace.current[objIndex][name] = value;
    }
  };

  const handleSubmit = () => {
    if (!name) {
      NotificationManager.warning(Translator.trans('message.title.missing'));
    } else {
      dispatch(
        postPlacesRequest(currentProject.id, {
          name: name,
        })
      );
    }
  };

  return (
    <Modal
      title={Translator.trans('places.add.title')}
      width="md"
      open={props.open}
      close={props.close}
    >
      <div className="col-12" style={{ padding: 0, overflow: 'hidden' }}>
        {currentProject.allowedLanguages.map((language) => (
          <AccordionTranslation key={language} language={language}>
            <PlacesCreationTranslations
              newPlace={props.newPlace}
              handleChangeTranslation={handleChangeTranslation}
              language={language}
            />
          </AccordionTranslation>
        ))}

        <ButtonsSaveModal
          onClose={props.close}
          onSubmit={handleSubmit}
          isLoading={loadingSend}
        />
      </div>
    </Modal>
  );
};

export default ModalPlacesCreation;
