import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Translator from '../../../services/translator';
import {
  postContentArticlesRequest,
  postContentArticleTranslationRequest,
} from '../../../Redux/actions';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { HeaderBanner } from '@emisys/audience-sdk-ui-react';
import { currentProject } from '../../../Global/currentProject';
import { pagesConstant } from '../../../Constants/Constant';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import { storeType } from '../../../index';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import ContentArticleTranslations from '../ArticleContent/ContentArticleTranslations';
import '../index.css';

const ArticleCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingSend, setLoadingSend] = useState(false);
  const translationContentArticles: any = useRef([]);

  const newContentArticle = useSelector(
    (state: storeType) => state.contentArticles.newContentArticle
  );

  useEffect(() => {
    if (currentProject.allowedLanguages) {
      currentProject.allowedLanguages.forEach((element) => {
        translationContentArticles.current.push({
          locale: element,
          title: '',
          teaser: '',
          content: '',
          externalLinkUrl: '',
          externalLinkLabel: '',
        });
      });
    }
  }, []);

  useEffect(() => {
    if (newContentArticle) {
      setLoadingSend(newContentArticle.loading);
      if (checkReduxResponse(newContentArticle, 'contentArticle')) {
        dispatch(
          postContentArticleTranslationRequest(
            newContentArticle.contentArticle.id,
            {
              translations: translationContentArticles.current,
            }
          )
        );
        navigate(pagesConstant.article.home);
      }
    }
  }, [dispatch, navigate, newContentArticle]);

  const handleChangeTranslation = (
    name: string,
    value: string,
    locale: string
  ) => {
    let objIndex = translationContentArticles.current.findIndex(
      (item: any) => item.locale === locale
    );
    if (objIndex > -1) {
      translationContentArticles.current[objIndex][name] = value;
    }
  };

  const handleSubmit = () => {
    translationContentArticles.current.forEach((element: any) => {
      if (element.locale === currentProject.language.locale) {
        if (element.title === '' || element.title === null) {
          NotificationManager.warning(
            Translator.trans('message.title.missing')
          );
        } else {
          dispatch(
            postContentArticlesRequest(currentProject.id, {
              isActive: true,
            })
          );
        }
      }
    });
  };

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('articles.add.title')}
          icon={<LibraryBooks />}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            {currentProject.allowedLanguages.map((language) => (
              <AccordionTranslation key={language} language={language}>
                <ContentArticleTranslations
                  handleChangeTranslation={handleChangeTranslation}
                  language={language}
                />
              </AccordionTranslation>
            ))}

            <ButtonsSaveModal
              onClose={() => navigate(pagesConstant.article.home)}
              onSubmit={handleSubmit}
              isLoading={loadingSend}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleCreation;
