import { programsConstants } from '../constants';
import ProgramModel from '../../Models/ProgramModel';

const initialState: any = {
  programsList: null,
  sessionsList: null,
  newSession: null,
};

let listPrograms: ProgramModel[] = [];
let listSessions: any[] = [];
let newSession: any = [];

const programs = (state = initialState, action: any) => {
  switch (action.type) {
    case programsConstants.GET_PROGRAMS_LIST_REQUEST: {
      return {
        ...state,
        programsList: { programs: null, error: false, loading: true },
      };
    }

    case programsConstants.GET_PROGRAMS_LIST_SUCCESS: {
      listPrograms = action.programs.data.map(
        (program: any) => new ProgramModel(program)
      );

      return {
        ...state,
        programsList: { programs: listPrograms, error: false, loading: false },
      };
    }

    case programsConstants.GET_PROGRAMS_LIST_FAILURE: {
      return {
        ...state,
        programsList: { programs: null, error: true, loading: false },
      };
    }

    case programsConstants.GET_PROGRAM_SESSIONS_REQUEST: {
      return {
        ...state,
        sessionsList: { sessions: listSessions, error: false, loading: true },
      };
    }

    case programsConstants.GET_PROGRAM_SESSIONS_SUCCESS: {
      listSessions = action.sessions.data;

      return {
        ...state,
        sessionsList: { sessions: listSessions, error: false, loading: false },
      };
    }

    case programsConstants.GET_PROGRAM_SESSIONS_FAILURE: {
      return {
        ...state,
        sessionsList: { sessions: null, error: true, loading: false },
      };
    }

    case programsConstants.POST_PROGRAM_SESSIONS_REQUEST: {
      return {
        ...state,
        newSession: { session: null, error: false, loading: true },
      };
    }

    case programsConstants.POST_PROGRAM_SESSIONS_SUCCESS: {
      newSession = action.newSession.data;

      return {
        ...state,
        newSession: { session: newSession, error: false, loading: false },
      };
    }

    case programsConstants.POST_PROGRAM_SESSIONS_FAILURE: {
      return {
        ...state,
        newSession: { session: null, error: true, loading: false },
      };
    }

    case programsConstants.CLEAR_PROGRAM: {
      return {
        ...state,
        newSession: null,
      };
    }

    default:
      return state;
  }
};

export default programs;
