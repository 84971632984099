import NewsModel from '../../Models/NewsModel';
import { contentNewsConstants } from '../constants';

const initialState: any = {
  contentNewsList: null,
  newContentNews: null,
  updatedContentNews: null,
  updatedContentNewsPosition: null,
  deletedContentNews: null,
  contentNewsDetails: null,
  translationContentNews: null,
  updatedTranslationContentNews: null,
};

let listContentNews: any[] = [];
const contentNews = (state = initialState, action: any) => {
  switch (action.type) {
    case contentNewsConstants.GET_CONTENT_NEWS_LIST_REQUEST: {
      return {
        ...state,
        contentNewsList: {
          contentNews: listContentNews,
          error: false,
          loading: true,
        },
      };
    }

    case contentNewsConstants.GET_CONTENT_NEWS_LIST_SUCCESS: {
      listContentNews = action.contentNews.data;

      return {
        ...state,
        contentNewsList: {
          contentNews: action.contentNews.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentNewsConstants.GET_CONTENT_NEWS_LIST_FAILURE: {
      return {
        ...state,
        contentNewsList: { contentNews: null, error: true, loading: false },
      };
    }

    case contentNewsConstants.POST_CONTENT_NEWS_REQUEST: {
      return {
        ...state,
        newContentNews: { contentNews: null, error: false, loading: true },
      };
    }

    case contentNewsConstants.POST_CONTENT_NEWS_SUCCESS: {
      return {
        ...state,
        newContentNews: {
          contentNews: action.newContentNews.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentNewsConstants.POST_CONTENT_NEWS_FAILURE: {
      return {
        ...state,
        newContentNews: { contentNews: null, error: true, loading: false },
      };
    }

    case contentNewsConstants.PUT_CONTENT_NEWS_REQUEST: {
      return {
        ...state,
        updatedContentNews: { contentNews: null, error: false, loading: true },
      };
    }

    case contentNewsConstants.PUT_CONTENT_NEWS_SUCCESS: {
      return {
        ...state,
        updatedContentNews: {
          contentNews: action.updatedContentNews.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentNewsConstants.PUT_CONTENT_NEWS_FAILURE: {
      return {
        ...state,
        updatedContentNews: { contentNews: null, error: true, loading: false },
      };
    }

    case contentNewsConstants.PUT_CONTENT_NEWS_POSITION_REQUEST: {
      return {
        ...state,
        updatedContentNewsPosition: {
          contentNews: null,
          error: false,
          loading: true,
        },
      };
    }

    case contentNewsConstants.PUT_CONTENT_NEWS_POSITION_SUCCESS: {
      return {
        ...state,
        updatedContentNewsPosition: {
          contentNews: action.updatedContentNewsPosition.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentNewsConstants.PUT_CONTENT_NEWS_POSITION_FAILURE: {
      return {
        ...state,
        updatedContentNewsPosition: {
          contentNews: null,
          error: true,
          loading: false,
        },
      };
    }

    case contentNewsConstants.GET_CONTENT_NEWS_REQUEST: {
      return {
        ...state,
        contentNewsDetails: { contentNews: null, error: false, loading: true },
      };
    }

    case contentNewsConstants.GET_CONTENT_NEWS_SUCCESS: {
      return {
        ...state,
        contentNewsDetails: {
          contentNews: new NewsModel(action.contentNewsDetails.data),
          error: false,
          loading: false,
        },
      };
    }

    case contentNewsConstants.GET_CONTENT_NEWS_FAILURE: {
      return {
        ...state,
        contentNewsDetails: { contentNews: null, error: true, loading: false },
      };
    }

    case contentNewsConstants.DELETE_CONTENT_NEWS_REQUEST: {
      return {
        ...state,
        deletedContentNews: { contentNews: null, error: false, loading: true },
      };
    }

    case contentNewsConstants.DELETE_CONTENT_NEWS_SUCCESS: {
      return {
        ...state,
        deletedContentNews: {
          contentNews: action.deletedContentNews.data
            ? action.deletedContentNews.data
            : 'deleted',
          error: false,
          loading: false,
        },
      };
    }

    case contentNewsConstants.DELETE_CONTENT_NEWS_FAILURE: {
      return {
        ...state,
        deletedContentNews: { contentNews: null, error: true, loading: false },
      };
    }

    case contentNewsConstants.GET_CONTENT_NEWS_TRANSLATION_REQUEST: {
      return {
        ...state,
        translationContentNewsList: {
          contentNewsList: null,
          error: false,
          loading: true,
        },
      };
    }

    case contentNewsConstants.GET_CONTENT_NEWS_TRANSLATION_SUCCESS: {
      return {
        ...state,
        translationContentNewsList: {
          contentNewsList: action.translationContentNewsList.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentNewsConstants.GET_CONTENT_NEWS_TRANSLATION_FAILURE: {
      return {
        ...state,
        translationContentNewsList: {
          contentNewsList: null,
          error: true,
          loading: false,
        },
      };
    }

    case contentNewsConstants.POST_CONTENT_NEWS_TRANSLATION_REQUEST: {
      return {
        ...state,
        translationContentNews: {
          contentNews: null,
          error: false,
          loading: true,
        },
      };
    }

    case contentNewsConstants.POST_CONTENT_NEWS_TRANSLATION_SUCCESS: {
      return {
        ...state,
        translationContentNews: {
          contentNews: action.translationContentNews.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentNewsConstants.POST_CONTENT_NEWS_TRANSLATION_FAILURE: {
      return {
        ...state,
        translationContentNews: {
          contentNews: null,
          error: true,
          loading: false,
        },
      };
    }

    case contentNewsConstants.PUT_CONTENT_NEWS_TRANSLATION_REQUEST: {
      return {
        ...state,
        updatedTranslationContentNews: {
          contentNews: null,
          error: false,
          loading: true,
        },
      };
    }

    case contentNewsConstants.PUT_CONTENT_NEWS_TRANSLATION_SUCCESS: {
      return {
        ...state,
        updatedTranslationContentNews: {
          contentNews: action.updatedTranslationContentNews.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentNewsConstants.PUT_CONTENT_NEWS_TRANSLATION_FAILURE: {
      return {
        ...state,
        updatedTranslationContentNews: {
          contentNews: null,
          error: true,
          loading: false,
        },
      };
    }

    case contentNewsConstants.CLEAR_CONTENT_NEWS: {
      return {
        ...state,
        newContentNews: null,
        updatedContentNews: null,
        updatedContentNewsPosition: null,
        deletedContentNews: null,
        contentNewsDetails: null,
        translationContentNews: null,
        updatedTranslationContentNews: null,
      };
    }

    default:
      return state;
  }
};

export default contentNews;
