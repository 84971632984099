import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { sessionsConstants } from '../constants';
import {
  getSessionsListSuccess,
  getSessionsListFailure,
  getSessionSuccess,
  getSessionFailure,
  putSessionSuccess,
  putSessionFailure,
  deleteSessionSuccess,
  deleteSessionFailure,
  postSessionPerformersSuccess,
  postSessionPerformersFailure,
  postSessionPlacesFailure,
  postSessionPlacesSuccess,
  putSessionPerformersSuccess,
  putSessionPerformersFailure,
  putSessionPlacesFailure,
  putSessionPlacesSuccess,
  getSessionTranslationSuccess,
  getSessionTranslationFailure,
  postSessionTranslationSuccess,
  postSessionTranslationFailure,
  putSessionTranslationSuccess,
  putSessionTranslationFailure,
} from '../actions';
import {
  getSessionsByProjectApi,
  getSessionSelfApi,
  deleteSessionSelfApi,
  putSessionSelfApi,
  postSessionPerformersApi,
  postSessionPlacesApi,
  putSessionPerformersApi,
  putSessionPlacesApi,
  getTranslationSessionApi,
  postTranslationSessionSelfApi,
  putTranslationSessionSelfApi,
} from '../api';

export function* fetchGetSessionsByProject(action: any): any {
  try {
    const sessions = yield call(getSessionsByProjectApi, {
      idProject: action.idProject,
      args: action.options,
    });
    yield put(getSessionsListSuccess(sessions));
  } catch (error) {
    yield put(getSessionsListFailure(error));
  }
}

export function* fetchGetSessionSelf(action: any): any {
  try {
    const session = yield call(getSessionSelfApi, { id: action.id });
    yield put(getSessionSuccess(session));
  } catch (error) {
    yield put(getSessionFailure(error));
  }
}

export function* fetchDeleteSessionSelf(action: any): any {
  try {
    const oldSession = yield call(deleteSessionSelfApi, action.id);
    yield put(deleteSessionSuccess(oldSession));
  } catch (error) {
    yield put(deleteSessionFailure(error));
  }
}

export function* fetchPutSessionSelf(action: any): any {
  try {
    const updatedSession = yield call(putSessionSelfApi, action.updatedSession);
    yield put(putSessionSuccess(updatedSession));
  } catch (error) {
    yield put(putSessionFailure(error));
  }
}

export function* fetchPostSessionPerformers(action: any): any {
  try {
    const sessionPerformers = yield call(postSessionPerformersApi, {
      performers: action.performers,
      idSession: action.idSession,
    });
    yield put(postSessionPerformersSuccess(sessionPerformers));
  } catch (error) {
    yield put(postSessionPerformersFailure(error));
  }
}

export function* fetchPostSessionPlaces(action: any): any {
  try {
    const sessionPlaces = yield call(postSessionPlacesApi, {
      places: action.places,
      idSession: action.idSession,
    });
    yield put(postSessionPlacesSuccess(sessionPlaces));
  } catch (error) {
    yield put(postSessionPlacesFailure(error));
  }
}

export function* fetchPutSessionPerformers(action: any): any {
  try {
    const updatedSessionPerformers = yield call(putSessionPerformersApi, {
      performers: action.performers,
      idSession: action.idSession,
    });
    yield put(putSessionPerformersSuccess(updatedSessionPerformers));
  } catch (error) {
    yield put(putSessionPerformersFailure(error));
  }
}

export function* fetchPutSessionPlaces(action: any): any {
  try {
    const updatedSessionPlaces = yield call(putSessionPlacesApi, {
      places: action.places,
      idSession: action.idSession,
    });
    yield put(putSessionPlacesSuccess(updatedSessionPlaces));
  } catch (error) {
    yield put(putSessionPlacesFailure(error));
  }
}

export function* fetchGetTranslationSessionSelf(action: any): any {
  try {
    const translationSessionList = yield call(getTranslationSessionApi, {
      id: action.idSession,
    });
    yield put(getSessionTranslationSuccess(translationSessionList));
  } catch (error) {
    yield put(getSessionTranslationFailure(error));
  }
}

export function* fetchPostTranslationSessionSelf(action: any): any {
  try {
    const translationSession = yield call(postTranslationSessionSelfApi, {
      id: action.sessionId,
      translationSession: action.translationSession,
    });
    yield put(postSessionTranslationSuccess(translationSession));
  } catch (error) {
    yield put(postSessionTranslationFailure(error));
  }
}

export function* fetchPutTranslationSessionSelf(action: any): any {
  try {
    const updatedTranslationSession = yield call(putTranslationSessionSelfApi, {
      id: action.sessionId,
      translations: action.translations,
    });
    yield put(putSessionTranslationSuccess(updatedTranslationSession));
  } catch (error) {
    yield put(putSessionTranslationFailure(error));
  }
}

export function* getSessionsByProject() {
  yield takeLatest(
    sessionsConstants.GET_SESSIONS_LIST_REQUEST,
    fetchGetSessionsByProject
  );
}

export function* getSessionSelf() {
  yield takeLatest(sessionsConstants.GET_SESSION_REQUEST, fetchGetSessionSelf);
}

export function* deleteSessionSelf() {
  yield takeLatest(
    sessionsConstants.DELETE_SESSION_REQUEST,
    fetchDeleteSessionSelf
  );
}

export function* putSessionSelf() {
  yield takeLatest(sessionsConstants.PUT_SESSION_REQUEST, fetchPutSessionSelf);
}

export function* postSessionPerformers() {
  yield takeLatest(
    sessionsConstants.POST_SESSION_PERFORMERS_REQUEST,
    fetchPostSessionPerformers
  );
}

export function* postSessionPlaces() {
  yield takeLatest(
    sessionsConstants.POST_SESSION_PLACES_REQUEST,
    fetchPostSessionPlaces
  );
}

export function* putSessionPerformers() {
  yield takeLatest(
    sessionsConstants.PUT_SESSION_PERFORMERS_REQUEST,
    fetchPutSessionPerformers
  );
}

export function* putSessionPlaces() {
  yield takeLatest(
    sessionsConstants.PUT_SESSION_PLACES_REQUEST,
    fetchPutSessionPlaces
  );
}

export function* getTranslationSessionSelf() {
  yield takeLatest(
    sessionsConstants.GET_SESSION_TRANSLATION_REQUEST,
    fetchGetTranslationSessionSelf
  );
}

export function* postTranslationSessionSelf() {
  yield takeLatest(
    sessionsConstants.POST_SESSION_TRANSLATION_REQUEST,
    fetchPostTranslationSessionSelf
  );
}

export function* putTranslationSessionSelf() {
  yield takeLatest(
    sessionsConstants.PUT_SESSION_TRANSLATION_REQUEST,
    fetchPutTranslationSessionSelf
  );
}

export default function* sessionTracksSaga() {
  yield all([
    fork(getSessionsByProject),
    fork(getSessionSelf),
    fork(deleteSessionSelf),
    fork(putSessionSelf),
    fork(postSessionPerformers),
    fork(postSessionPlaces),
    fork(putSessionPlaces),
    fork(putSessionPerformers),
    fork(getTranslationSessionSelf),
    fork(postTranslationSessionSelf),
    fork(putTranslationSessionSelf),
  ]);
}
