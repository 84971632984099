import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Translator from '../../../services/translator';
import PerformerCreationContent from './PerformerCreationContent';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { NavLink, useNavigate } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {
  postPerformerRequest,
  postProgramSessionsRequest,
  postSessionTranslationRequest,
  postSessionPlacesRequest,
  postSessionPerformersRequest,
  postPerformerTranslationsRequest,
  postUploadPictureRequest,
  clearPerformer,
  clearProgram,
  clearUploadPicture,
} from '../../../Redux/actions';
import SessionCreationContent from './SessionCreationContent';
import { currentProject } from '../../../Global/currentProject';
import { pagesConstant } from '../../../Constants/Constant';
import { HeaderBanner, MainButton } from '@emisys/audience-sdk-ui-react';
import GroupIcon from '@mui/icons-material/Group';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import '../index.css';
import { storeType } from '../../../index';

const PerformerCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const steps = useMemo(
    () => [
      Translator.trans('stepper.performer.step.one'),
      Translator.trans('stepper.performer.step.two'),
    ],
    []
  );
  const [activeStep, setActiveStep] = useState(0);
  const [loadingSend, setLoadingSend] = useState(false);

  const newPerformer = useSelector(
    (state: storeType) => state.performers.newPerformer
  );
  const newUploadPicture = useSelector(
    (state: storeType) => state.uploads.newUploadPicture
  );
  const newSession = useSelector(
    (state: storeType) => state.programs.newSession
  );

  const performer: any = useRef({
    name: '',
    website: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    youtubeUrl: '',
    spotifyUrl: '',
    headline: null,
  });

  const session: any = useRef({
    idProject: currentProject.id,
    title: null,
    description: '',
    locale: '',
    program: '',
    liveStartDateTimeUTC: null,
    liveEndDateTimeUTC: null,
    highlighted: true,
    signedLanguages: true,
    track: null,
    timeToBeConfirmed: false,
  });

  const translationSession: any = useRef([]);
  const sessionPerformers: any = useRef([]);
  const sessionPlaces: any = useRef([]);
  const translationPerformers: any = useRef([]);
  const program: any = useRef(null);
  const picture: any = useRef(null);

  useEffect(() => {
    currentProject.allowedLanguages.forEach((language) => {
      let performerTrans = translationPerformers.current.find(
        (item: any) => item.locale === language
      );

      let sessionTrans = translationSession.current.find(
        (item: any) => item.locale === language
      );

      if (!performerTrans) {
        translationPerformers.current.push({
          locale: language,
          biography: '',
        });
      }

      if (!sessionTrans) {
        translationSession.current.push({
          locale: language,
          description: '',
        });
      }
    });
  }, []);

  useEffect(() => {
    if (newUploadPicture) {
      if (checkReduxResponse(newUploadPicture, 'uploadPicture')) {
        performer.current.picture = newUploadPicture.uploadPicture.id;
        dispatch(postPerformerRequest(currentProject.id, performer.current));
        dispatch(clearUploadPicture());
      }
    }
  }, [dispatch, newUploadPicture]);

  useEffect(() => {
    if (newPerformer) {
      setLoadingSend(newPerformer.loading);
      if (checkReduxResponse(newPerformer, 'performer')) {
        performer.current = newPerformer.performer;
        sessionPerformers.current.push(performer.current.id);

        dispatch(
          postPerformerTranslationsRequest(performer.current.id, {
            translations: translationPerformers.current,
          })
        );

        if (
          session.current.title &&
          session.current.liveStartDateTimeUTC &&
          session.current.liveEndDateTimeUTC
        ) {
          dispatch(
            postProgramSessionsRequest(program.current, session.current)
          );
        } else {
          navigate(pagesConstant.performers.home);
          NotificationManager.success(
            Translator.trans('performers.message.success.added')
          );
          dispatch(clearPerformer());
        }
      }
    }
  }, [dispatch, navigate, newPerformer]);

  useEffect(() => {
    if (newSession) {
      if (checkReduxResponse(newSession, 'session')) {
        session.current = newSession.session;

        if (sessionPerformers.current.length !== 0) {
          dispatch(
            postSessionPerformersRequest(session.current.id, {
              performers: sessionPerformers.current,
            })
          );
        }
        if (sessionPlaces.current.length !== 0) {
          dispatch(
            postSessionPlacesRequest(session.current.id, {
              places: sessionPlaces.current,
            })
          );
        }
        dispatch(
          postSessionTranslationRequest(session.current.id, {
            translations: translationSession.current,
          })
        );

        NotificationManager.success(
          Translator.trans('performers.message.success.added')
        );

        navigate(pagesConstant.performers.home);
        dispatch(clearPerformer());
        dispatch(clearProgram());
      }
    }
  }, [dispatch, navigate, newSession]);

  const handleChangePicture = (value: string | null) =>
    (picture.current = value);

  const handleChangePerformer = (
    name: string,
    value: string | number | null
  ) => {
    if (name === 'name') {
      session.current.title = value;
    }
    performer.current[name] = value;
  };

  const handleChangeTranslationPerformer = (
    name: string,
    value: string,
    locale: string
  ) => {
    let objIndex = translationPerformers.current.findIndex(
      (item: any) => item.locale === locale
    );

    if (objIndex > -1) {
      translationPerformers.current[objIndex][name] = value;
    }
  };

  const handleChangeTranslationSession = (
    name: string,
    value: string,
    locale: string
  ) => {
    let objIndex = translationSession.current.findIndex(
      (item: any) => item.locale === locale
    );

    if (objIndex > -1) {
      translationSession.current[objIndex][name] = value;
    }
  };

  const handleChangeSession = (name: string, value: string) => {
    if (name === 'performers') {
      sessionPerformers.current = value;
    } else if (name === 'places') {
      sessionPlaces.current = value;
    } else if (name === 'program') {
      program.current = value;
    } else {
      session.current[name] = value;
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (performer.current.name) {
        setActiveStep(activeStep + 1);
      } else {
        NotificationManager.warning(Translator.trans('message.title.missing'));
      }
    } else if (activeStep === 1) {
      if (session.current.title === '' || session.current.title === null) {
        NotificationManager.warning(Translator.trans('message.title.missing'));
      } else {
        if (
          session.current.liveStartDateTimeUTC &&
          session.current.liveEndDateTimeUTC
        ) {
          handleSubmit();
        } else {
          NotificationManager.warning(
            Translator.trans('stepper.performer.session.error.date')
          );
        }
      }
    }
  };

  const handleSubmit = () => {
    if (performer.current.name) {
      if (picture.current) {
        dispatch(
          postUploadPictureRequest({
            type: 'PictureArtist',
            project_id: currentProject.id,
            data: picture.current,
          })
        );
      } else {
        dispatch(postPerformerRequest(currentProject.id, performer.current));
      }
    } else {
      NotificationManager.warning(Translator.trans('message.title.missing'));
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <PerformerCreationContent
            handleChangePerformer={handleChangePerformer}
            handleChangePicture={handleChangePicture}
            handleChangeTranslationPerformer={handleChangeTranslationPerformer}
            translations={translationPerformers.current}
          />
        );
      case 1:
        return (
          <SessionCreationContent
            session={session.current}
            handleChangeSession={handleChangeSession}
            handleChangeTranslationSession={handleChangeTranslationSession}
          />
        );
      default:
        return 'Uknown stepIndex';
    }
  };

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('performers.add.title')}
          icon={<GroupIcon />}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {getStepContent(activeStep)}

            <div className="buttonContainer">
              <NavLink
                className="btn-return-to-list"
                to={pagesConstant.performers.home}
              >
                <MainButton className="mr-5" buttonType={'outline'}>
                  {Translator.trans('stepper.button.back.to.list')}
                </MainButton>
              </NavLink>

              <ButtonsSaveModal
                isLoading={loadingSend}
                onClose={activeStep === 0 ? handleSubmit : handleBack}
                onSubmit={handleNext}
                cancelText={
                  activeStep === 0
                    ? Translator.trans('stepper.button.add.without.session')
                    : Translator.trans('appModule.back')
                }
                submitText={
                  activeStep !== steps.length - 1
                    ? Translator.trans('stepper.button.add.with.session')
                    : Translator.trans('appModule.save')
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformerCreation;
