import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getAppMobileInfoSuccess,
  getAppMobileInfoFailure,
  putAppMobileInfoSuccess,
  putAppMobileInfoFailure,
} from '../actions';
import { appMobileConstants } from '../constants';

import { getAppMobileByProjectApi, putAppMobileByProjectApi } from '../api';

export function* fetchGetAppMobileByProject(action: any): any {
  try {
    const appMobile = yield call(getAppMobileByProjectApi, {
      idProject: action.idProject,
    });
    yield put(getAppMobileInfoSuccess(appMobile));
  } catch (error) {
    yield put(getAppMobileInfoFailure(error));
  }
}

export function* fetchPutAppMobileByProject(action: any): any {
  try {
    const newAppMobile = yield call(putAppMobileByProjectApi, {
      idProject: action.idProject,
      newAppMobile: action.newAppMobile,
    });
    yield put(putAppMobileInfoSuccess(newAppMobile));
  } catch (error) {
    yield put(putAppMobileInfoFailure(error));
  }
}

export function* getAppMobileByProject() {
  yield takeLatest(
    appMobileConstants.GET_APP_MOBILE_INFO_REQUEST,
    fetchGetAppMobileByProject
  );
}

export function* putAppMobileByProject() {
  yield takeLatest(
    appMobileConstants.PUT_APP_MOBILE_INFO_REQUEST,
    fetchPutAppMobileByProject
  );
}

export default function* appMobileSaga() {
  yield all([fork(getAppMobileByProject), fork(putAppMobileByProject)]);
}
