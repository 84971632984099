import React from 'react';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import './SortLabel.css';

const SortLabel = (props: any) => (
  <>
    <span className={'sort-label-text'}>{props.children}</span>

    {!props.disabled && (
      <div className={'sort-label-arrow-container'}>
        <RiArrowUpSLine
          onClick={props.onSort}
          className={
            'sort-label-arrow ' + (props.direction === 'asc' && 'active')
          }
        />
        <RiArrowDownSLine
          onClick={props.onSort}
          className={
            'sort-label-arrow ' + (props.direction === 'desc' && 'active')
          }
        />
      </div>
    )}
  </>
);

export default SortLabel;
