import { columnNameAction, columnNameDrag } from '../Constants/Constant';
import Translator from '../../../services/translator';
import PlaceModel from '../../../Models/PlaceModel';
import PerformerModel from '../../../Models/PerformerModel';

export type columnType = {
  name: string;
  title: string;
  performers?: PerformerModel[];
  places?: PlaceModel[];
  programs?: any;
};

// -----------------Articles

export const columnsArticles: columnType[] = [
  { name: columnNameDrag, title: ' ' },
  {
    name: 'name',
    title: Translator.trans('cms.tables.title.name'),
  },
  {
    name: 'isActive',
    title: Translator.trans('news.news.edit.published'),
  },
  {
    name: columnNameAction,
    title: Translator.trans('cms.tables.title.actions'),
  },
];

export const tableColumnExtensionsArticles: any = [
  { columnName: columnNameDrag, width: 40 },
  { columnName: 'name' },
  { columnName: 'isActive' },
  { columnName: columnNameAction, align: 'right', width: 100 },
];

// -----------------News

export const columnsNews: columnType[] = [
  { name: columnNameDrag, title: ' ' },
  {
    name: 'name',
    title: Translator.trans('cms.tables.title.name'),
  },
  {
    name: 'publishDate',
    title: Translator.trans('news.news.edit.publishdate'),
  },
  {
    name: 'isActive',
    title: Translator.trans('news.news.edit.published'),
  },
  {
    name: columnNameAction,
    title: Translator.trans('cms.tables.title.actions'),
  },
];

export const tableColumnExtensionsNews: any = [
  { columnName: columnNameDrag, width: 40 },
  { columnName: 'name' },
  { columnName: 'publishDate' },
  { columnName: 'isActive' },
  { columnName: columnNameAction, align: 'right', width: 100 },
];

// -----------------Places

export const columnsPlaces: columnType[] = [
  { name: columnNameDrag, title: ' ' },
  {
    name: 'name',
    title: Translator.trans('cms.tables.title.name'),
  },
  {
    name: columnNameAction,
    title: Translator.trans('cms.tables.title.actions'),
  },
];

export const tableColumnExtensionsPlaces: any = [
  { columnName: columnNameDrag, width: 40 },
  { columnName: 'name' },
  { columnName: columnNameAction, align: 'right', width: 100 },
];

// -----------------Places

export const columnsSessionTracks: columnType[] = [
  { name: 'name', title: Translator.trans('cms.tables.title.name') },
  {
    name: columnNameAction,
    title: Translator.trans('cms.tables.title.actions'),
  },
];

export const tableColumnExtensionsSessionTracks: any = [
  { columnName: 'name', align: 'left' },
  { columnName: columnNameAction, align: 'right', width: 100 },
];

// -----------------Performers

export const columnsPerformers: columnType[] = [
  { name: 'name', title: Translator.trans('cms.tables.title.name') },
  {
    name: 'sessionPerformers',
    title: Translator.trans('sessions.title'),
  },
  {
    name: 'headline',
    title: Translator.trans('performers.title.headline'),
  },
  {
    name: columnNameAction,
    title: Translator.trans('cms.tables.title.actions'),
  },
];

export const tableColumnExtensionsPerformers: any = [
  { columnName: columnNameAction, align: 'right', width: 150 },
];

// -----------------Sessions

export const columnsSessions: columnType[] = [
  { name: 'title', title: Translator.trans('session.title') },
  {
    name: 'performer',
    title: Translator.trans('cms.tables.title.performers'),
    performers: [],
  },
  {
    name: 'place',
    title: Translator.trans('cms.tables.title.places'),
    places: [],
  },
  {
    name: 'liveStartDateTimeUTC',
    title: Translator.trans('cms.tables.title.start'),
  },
  {
    name: 'liveEndDateTimeUTC',
    title: Translator.trans('cms.tables.title.end'),
  },
  {
    name: columnNameAction,
    title: Translator.trans('cms.tables.title.actions'),
  },
];

export const tableColumnExtensionsSessions: any = [
  { columnName: 'title' },
  { columnName: 'performer' },
  { columnName: 'place' },
  { columnName: 'liveStartDateTimeUTC' },
  { columnName: 'liveEndDateTimeUTC' },
  { columnName: columnNameAction, align: 'right', width: 100 },
];
