import React, { useCallback, useMemo, useState } from 'react';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import {
  archiveProjectRequest,
  getProjectDetailsRequest,
} from '../../../../Redux/actions';
import { pagesConstant } from '../../../../Constants/Constant';
import { useNavigate } from 'react-router-dom';
import ActiveChip from '../../../../components/ActiveChip/ActiveChip';
import { currentProject } from '../../../../Global/currentProject';
import ProjectModel from '../../../../Models/ProjectModel';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Translator from '../../../../services/translator';
import { ActionButton } from '@emisys/audience-sdk-ui-react';
import './EventListItem.css';

interface EventListItemInterface {
  project: ProjectModel;
}
const EventListItem = (props: EventListItemInterface) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isDateStopOver = useMemo(
    () => new Date() < props.project.audienceDateStopUTC,
    [props.project.audienceDateStopUTC]
  );

  const getProjectDetails = useCallback(() => {
    dispatch(getProjectDetailsRequest(props.project.id));
  }, [dispatch, props.project.id]);

  const archiveProject = useCallback(() => {
    dispatch(archiveProjectRequest(props.project.id));
  }, [dispatch, props.project.id]);

  const handleSelectProject = () => {
    if (currentProject.id !== props.project.id) {
      getProjectDetails();
      localStorage.setItem('project_id', props.project.id.toString());
      navigate(pagesConstant.home);
      window.location.reload();
    } else {
      navigate(pagesConstant.events.current);
    }
  };

  const showMoreOptions = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMoreOptions = () => {
    setAnchorEl(null);
  };

  return (
    <div className="jr-card event-container">
      <div className={'event-picture-container'}>
        {props.project?.picture_logo?.thumb ? (
          <img
            className={'event-picture'}
            alt={'project logo'}
            src={props.project?.picture_logo?.thumb}
          />
        ) : (
          <div className={'event-picture empty'}>
            <ImageOutlinedIcon />
          </div>
        )}
      </div>

      <div className={'event-info-container'}>
        <h2 onClick={handleSelectProject}>{props.project.name}</h2>
        <div className={'event-info-dates-container'}>
          <DateRangeOutlinedIcon />
          <div className={'event-info-dates'}>
            <span>
              {dayjs(props.project.audienceDateStartUTC).format('LLLL')}
            </span>
            <span>
              {dayjs(props.project.audienceDateStopUTC).format('LLLL')}
            </span>
          </div>
        </div>
        <div className={'event-active-container'}>
          <ActiveChip isActive={props.project.isActive} />
        </div>
      </div>

      <div>
        {currentProject.id === props.project.id ? (
          <ActionButton icon={'edit'} onClick={handleSelectProject} />
        ) : (
          <>
            <IconButton
              aria-label="more"
              id="more-button"
              aria-controls={anchorEl ? 'more-menu' : undefined}
              aria-expanded={anchorEl ? 'true' : undefined}
              aria-haspopup="true"
              onClick={showMoreOptions}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="more-menu"
              MenuListProps={{
                'aria-labelledby': 'more-button',
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={closeMoreOptions}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={handleSelectProject}>
                {Translator.trans('appModule.select')}
              </MenuItem>
              {props.project.isActive && (
                <MenuItem
                  className="more-button-archive"
                  disabled={isDateStopOver}
                  onClick={archiveProject}
                >
                  {Translator.trans('appModule.archive')}
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </div>
    </div>
  );
};

export default EventListItem;
