import React, { useEffect, useState } from 'react';
import PlacesModalEdition from '../../app/Places/PlacesEdit/PlacesModalEdition';
import { useDispatch, useSelector } from 'react-redux';
import { deletePlacesRequest } from '../../Redux/actions';
import { ActionButton } from '@emisys/audience-sdk-ui-react';
import DeleteModal from '../DeleteModal/DeleteModal';
import PlaceModel from '../../Models/PlaceModel';
import { checkReduxResponse } from '../../services/checkReduxResponse';
import { storeType } from '../../index';

const ButtonsPlaces = (props: { place: PlaceModel }) => {
  const dispatch = useDispatch();
  const [openModalEditionPlace, setOpenModalEditionPlace] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const oldPlace = useSelector((state: storeType) => state.places.oldPlace);

  const handleRequestDelete = () => {
    dispatch(deletePlacesRequest(props.place.id));
  };

  useEffect(() => {
    if (oldPlace) {
      setIsLoading(oldPlace.loading);
      if (checkReduxResponse(oldPlace, 'place')) {
        setIsClosed(false);
        setIsClosed(false);
      }
    }
  }, [oldPlace]);

  return (
    <div className={'action-container-buttons'}>
      <ActionButton
        className={'editPlaces'}
        icon={'edit'}
        onClick={() => setOpenModalEditionPlace(true)}
      />

      <ActionButton
        className={'deletePlaces'}
        icon={'delete'}
        onClick={() => setIsClosed(true)}
      />

      {openModalEditionPlace && (
        <PlacesModalEdition
          place={props.place}
          open={openModalEditionPlace}
          close={() => setOpenModalEditionPlace(false)}
        />
      )}

      <DeleteModal
        isLoading={isLoading}
        isOpen={isClosed}
        onConfirm={handleRequestDelete}
        onCancel={() => setIsClosed(false)}
      />
    </div>
  );
};

export default ButtonsPlaces;
