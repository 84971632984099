import { brandingConstants } from '../constants';
import BrandingModel from '../../Models/BrandingModel';

const initialState: any = {
  brandingList: null,
  updatedBrandingList: null,
};

let listBranding: BrandingModel;
const branding = (state = initialState, action: any) => {
  switch (action.type) {
    case brandingConstants.GET_BRANDING_REQUEST: {
      return {
        ...state,
        brandingList: { branding: listBranding, error: false, loading: true },
      };
    }

    case brandingConstants.GET_BRANDING_SUCCESS: {
      listBranding = new BrandingModel(action.branding.data);

      return {
        ...state,
        brandingList: {
          branding: listBranding,
          error: false,
          loading: false,
        },
      };
    }

    case brandingConstants.GET_BRANDING_FAILURE: {
      return {
        ...state,
        brandingList: { branding: null, error: true, loading: false },
      };
    }

    case brandingConstants.PUT_BRANDING_REQUEST: {
      return {
        ...state,
        updatedBrandingList: {
          updatedBranding: null,
          error: false,
          loading: true,
        },
      };
    }

    case brandingConstants.PUT_BRANDING_SUCCESS: {
      listBranding = new BrandingModel(action.updatedBranding.data);

      return {
        ...state,
        updatedBrandingList: {
          updatedBranding: listBranding,
          error: false,
          loading: false,
        },
        brandingList: {
          branding: listBranding,
          error: false,
          loading: false,
        },
      };
    }

    case brandingConstants.PUT_BRANDING_FAILURE: {
      return {
        ...state,
        updatedBrandingList: {
          updatedBranding: null,
          error: true,
          loading: false,
        },
      };
    }

    case brandingConstants.CLEAR_BRANDING_PUT_BY_PROJECT: {
      return {
        ...state,
        updatedBrandingList: null,
      };
    }

    default:
      return state;
  }
};

export default branding;
