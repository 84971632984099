import React, { useEffect, useState } from 'react';
import Translator from '../../../services/translator';
import PerformerEditContent from './PerformerEditContent';
import { useParams } from 'react-router-dom';
import { HeaderBanner, Loading } from '@emisys/audience-sdk-ui-react';
import GroupIcon from '@mui/icons-material/Group';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPerformerRequest,
  getPerformerTranslationsRequest,
} from '../../../Redux/actions';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import { storeType } from '../../../index';
import PerformerModel from '../../../Models/PerformerModel';
import '../index.css';

const PerformerEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [currentPerformer, setCurrentPerformer] =
    useState<PerformerModel | null>(null);

  const performerDetails = useSelector(
    (state: storeType) => state.performers.performerDetails
  );

  useEffect(() => {
    if (id) {
      dispatch(getPerformerRequest(id));
      dispatch(getPerformerTranslationsRequest(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (performerDetails) {
      setLoading(performerDetails.loading);
      if (checkReduxResponse(performerDetails, 'performer')) {
        setCurrentPerformer(performerDetails.performer);
      }
    }
  }, [performerDetails]);

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('performer.title', {
            performer: currentPerformer?.name,
          })}
          icon={<GroupIcon />}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <PerformerEditContent performer={currentPerformer} />
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default PerformerEdit;
