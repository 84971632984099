import { getUrl, get, del, post, update } from '../../services';

export async function getSessionTracksByProjectApi(params: any) {
  return await get(getUrl('projects/' + params.idProject + '/sessiontracks'));
}

export async function postSessionTrackByProjectApi(params: any) {
  return await post(
    getUrl('projects/' + params.idProject + '/sessiontracks'),
    params.newSessionTrack
  );
}

export async function deleteSessionTrackSelfApi(id: any) {
  return await del(getUrl('sessiontracks/' + id));
}

export async function getTranslationSessionTrackApi(params: any) {
  return await get(getUrl('sessiontracks/' + params.id + '/translations'));
}

export async function postTranslationSessionTrackSelfApi(params: any) {
  return await post(
    getUrl('sessiontracks/' + params.id + '/translations'),
    params.translationSessionTrack
  );
}

export async function putTranslationSessionTrackSelfApi(params: any) {
  return await update(
    getUrl('sessiontracks/' + params.id + '/translations'),
    params.translations
  );
}
