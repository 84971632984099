import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import getStore from './Redux/store';
import App from './app';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import './app/global.css';
import './assets/vendors/style';

dayjs.extend(localizedFormat);
dayjs.extend(timezone);

const store = getStore();
export type storeType = ReturnType<typeof store.getState>;

const root = ReactDOM.createRoot(
  document.getElementById('app-site') as HTMLElement
);

root.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
);
