import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionRequest } from '../../../../Redux/actions';
import { Loading, Modal } from '@emisys/audience-sdk-ui-react';
import { FaUsers } from 'react-icons/fa';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { Place } from '@mui/icons-material';
import { Username } from '@emisys/audience-sdk-ui-react';
import { formatDateTime } from '../../../../services/formatDate';
import { storeType } from '../../../../index';
import { checkReduxResponse } from '../../../../services/checkReduxResponse';
import SessionModel from '../../../../Models/SessionModel';
import SessionPlaceModel from '../../../../Models/SessionPlaceModel';
import SessionPerformerModel from '../../../../Models/SessionPerformerModel';
import Translator from '../../../../services/translator';
import '../index.css';

const SessionsModal = (props: {
  close: () => void;
  open: boolean;
  idSession: number | string;
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState<SessionModel | null>(null);

  const getSessionSelf = useCallback(() => {
    if (props.idSession) {
      dispatch(getSessionRequest(props.idSession));
    }
  }, [dispatch, props.idSession]);

  const sessionSelf = useSelector(
    (state: storeType) => state.sessions.sessionSelf
  );

  useEffect(() => {
    getSessionSelf();
  }, [getSessionSelf]);

  useEffect(() => {
    if (sessionSelf) {
      setLoading(sessionSelf.loading);
      if (checkReduxResponse(sessionSelf, 'session')) {
        setSession(sessionSelf.session);
      }
    }
  }, [sessionSelf]);

  return (
    <Modal
      title={session?.title}
      open={props.open}
      close={props.close}
      width={'xs'}
    >
      <div className={'col-12'}>
        {session?.sessionPlaces && (
          <div className="marginBottom">
            <div className={'sessionContainer'}>
              <div className={'iconSessionContainer'}>
                <Place style={{ fontSize: 17 }} />
              </div>

              <div>
                {session.sessionPlaces.map(
                  (sessionPlace: SessionPlaceModel) => (
                    <p key={sessionPlace.id}>{sessionPlace.place.name}</p>
                  )
                )}
              </div>
            </div>
          </div>
        )}

        <div className="marginBottom">
          <div className={'sessionContainer'}>
            <div className={'iconSessionContainer'}>
              <AiOutlineFieldTime style={{ fontSize: 17 }} />
            </div>
            {session?.liveStartDateTimeUTC &&
              formatDateTime(session.liveStartDateTimeUTC)}
          </div>
        </div>

        {session?.sessionPerformers && (
          <div className="marginBottom">
            <div className={'sessionContainer'}>
              <div className={'iconSessionContainer'}>
                <FaUsers style={{ fontSize: 17 }} />
              </div>
              <div>
                {Translator.trans('cms.performers', {
                  qty: session.sessionPerformers.length,
                })}
              </div>
            </div>
            {session.sessionPerformers.map(
              (sessionPerformer: SessionPerformerModel) => (
                <div key={sessionPerformer.id} className="subContainer">
                  <Username name={sessionPerformer.performer?.name || ''} />
                </div>
              )
            )}
          </div>
        )}

        {loading && <Loading />}
      </div>
    </Modal>
  );
};

export default SessionsModal;
