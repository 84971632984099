import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearPerformer, deletePerformerRequest } from '../../Redux/actions';
import { ActionButton } from '@emisys/audience-sdk-ui-react';
import SessionsCreationModal from '../../app/Sessions/SessionsCreationModal/SessionsCreationModal';
import { NavLink } from 'react-router-dom';
import { pagesConstant } from '../../Constants/Constant';
import DeleteModal from '../DeleteModal/DeleteModal';
import PerformerModel from '../../Models/PerformerModel';
import { checkReduxResponse } from '../../services/checkReduxResponse';
import { storeType } from '../../index';

const ButtonsPerformers = (props: { performer: PerformerModel }) => {
  const dispatch = useDispatch();
  const performerId = props.performer.id;
  const [openModalNewSession, setOpenModalNewSession] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);

  const deletedPerformer = useSelector(
    (state: storeType) => state.performers.deletedPerformer
  );

  useEffect(() => {
    if (deletedPerformer) {
      setLoadingSend(deletedPerformer.loading);
      if (checkReduxResponse(deletedPerformer, 'performer')) {
        setIsClosed(false);
        dispatch(clearPerformer());
      }
    }
  }, [dispatch, deletedPerformer]);

  return (
    <div className={'action-container-buttons'}>
      <ActionButton
        className="addSessionPerformer"
        icon={'add'}
        onClick={() => setOpenModalNewSession(true)}
      />

      <NavLink to={pagesConstant.performers.edit + performerId}>
        <ActionButton icon={'edit'} className="editPerformer" />
      </NavLink>

      <ActionButton
        icon={'delete'}
        onClick={() => setIsClosed(true)}
        className="deletePerformer"
      />

      {openModalNewSession && props.performer && (
        <SessionsCreationModal
          key={props.performer.id}
          close={() => setOpenModalNewSession(false)}
          open={openModalNewSession}
          performer={props.performer}
        />
      )}
      <DeleteModal
        isLoading={loadingSend}
        isOpen={isClosed}
        onConfirm={() => {
          dispatch(deletePerformerRequest(performerId));
        }}
        onCancel={() => setIsClosed(false)}
      />
    </div>
  );
};

export default ButtonsPerformers;
