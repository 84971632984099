export const programsConstants = {
  GET_PROGRAMS_LIST_REQUEST: 'GET_PROGRAMS_LIST_REQUEST',
  GET_PROGRAMS_LIST_SUCCESS: 'GET_PROGRAMS_LIST_SUCCESS',
  GET_PROGRAMS_LIST_FAILURE: 'GET_PROGRAMS_LIST_FAILURE',

  GET_PROGRAM_SESSIONS_REQUEST: 'GET_PROGRAM_SESSIONS_REQUEST',
  GET_PROGRAM_SESSIONS_SUCCESS: 'GET_PROGRAM_SESSIONS_SUCCESS',
  GET_PROGRAM_SESSIONS_FAILURE: 'GET_PROGRAM_SESSIONS_FAILURE',

  POST_PROGRAM_SESSIONS_REQUEST: 'POST_PROGRAM_SESSIONS_REQUEST',
  POST_PROGRAM_SESSIONS_SUCCESS: 'POST_PROGRAM_SESSIONS_SUCCESS',
  POST_PROGRAM_SESSIONS_FAILURE: 'POST_PROGRAM_SESSIONS_FAILURE',

  CLEAR_PROGRAM: 'CLEAR_PROGRAM',
};
