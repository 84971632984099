import React, { useEffect, useRef, useState } from 'react';
import { Loading, Modal } from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';
import { currentProject } from '../../../Global/currentProject';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import SessionTrackEditionTranslations from './SessionTrackEditionTranslations';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import SessionTrackModel from '../../../Models/SessionTrackModel';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { useDispatch, useSelector } from 'react-redux';
import { storeType } from '../../../index';
import {
  getSessionTrackTranslationRequest,
  putSessionTrackTranslationRequest,
} from '../../../Redux/actions';

const SessionTrackModalEdition = (props: {
  open: boolean;
  close: () => void;
  sessionTrack: SessionTrackModel;
}) => {
  const dispatch = useDispatch();
  const [loadingSend, setLoadingSend] = useState(false);

  const translationSessionTrack: {
    current: any[];
  } = useRef([]);

  const translationSessionTrackList = useSelector(
    (state: storeType) => state.sessionTracks.translationSessionTrackList
  );

  const updatedTranslationSessionTrack = useSelector(
    (state: storeType) => state.sessionTracks.updatedTranslationSessionTrack
  );

  useEffect(() => {
    dispatch(getSessionTrackTranslationRequest(props.sessionTrack.id));
  }, [dispatch, props.sessionTrack.id]);

  useEffect(() => {
    if (translationSessionTrack.current.length === 0) {
      if (currentProject.allowedLanguages) {
        currentProject.allowedLanguages.forEach((element) => {
          let array = {
            locale: element,
            name: '',
          };
          translationSessionTrack.current.push(array);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (updatedTranslationSessionTrack) {
      setLoadingSend(updatedTranslationSessionTrack.loading);
      if (checkReduxResponse(updatedTranslationSessionTrack, 'sessionTrack')) {
        props.close();
      }

      if (updatedTranslationSessionTrack.error) {
        NotificationManager.success(
          updatedTranslationSessionTrack.error.message
        );
      }
    }
  }, [props, updatedTranslationSessionTrack]);

  useEffect(() => {
    if (translationSessionTrackList) {
      if (checkReduxResponse(translationSessionTrackList, 'sessionTrackList')) {
        translationSessionTrack.current.forEach(
          (element: any, indexT: number) => {
            translationSessionTrackList.sessionTrackList.forEach(
              (item: any, indexL: number) => {
                if (element.locale === item.locale) {
                  translationSessionTrack.current[indexT] =
                    translationSessionTrackList.sessionTrackList[indexL];
                }
              }
            );
          }
        );
      }
    }
  }, [translationSessionTrackList]);

  const handleChangeTranslation = (
    name: string,
    value: string,
    locale: string
  ) => {
    let objIndex = translationSessionTrack.current.findIndex(
      (item: any) => item.locale === locale
    );
    if (objIndex > -1) {
      translationSessionTrack.current[objIndex][name] = value;
    }
  };

  const handleSubmit = () => {
    translationSessionTrack.current.forEach((element: any) => {
      if (element.locale === currentProject.language.locale) {
        if (element.name === '') {
          NotificationManager.warning(
            Translator.trans('message.title.missing')
          );
        } else {
          dispatch(
            putSessionTrackTranslationRequest(props.sessionTrack.id, {
              translations: translationSessionTrack.current,
            })
          );
        }
      }
    });
  };

  return (
    <Modal
      title={Translator.trans('sessionTracks.edit.title')}
      width="md"
      open={props.open}
      close={props.close}
    >
      <div className="col-12" style={{ padding: 0, overflow: 'hidden' }}>
        {currentProject.allowedLanguages ? (
          currentProject.allowedLanguages.map((language) => {
            let translation = translationSessionTrack.current.find(
              (element: any) => element.locale === language
            );
            return (
              <AccordionTranslation key={language} language={language}>
                <SessionTrackEditionTranslations
                  translation={translation}
                  handleChangeTranslation={handleChangeTranslation}
                  language={language}
                />
              </AccordionTranslation>
            );
          })
        ) : (
          <Loading />
        )}

        <ButtonsSaveModal
          onClose={props.close}
          onSubmit={handleSubmit}
          isLoading={loadingSend}
        />
      </div>
    </Modal>
  );
};

export default SessionTrackModalEdition;
