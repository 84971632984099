import { userConstants } from '../constants';

export const getUserDetailsRequest = (idProject: number) => ({
  type: userConstants.GET_USER_DETAILS_REQUEST,
  idProject,
});

export const getUserDetailsSuccess = (selfDetails: any) => ({
  type: userConstants.GET_USER_DETAILS_SUCCESS,
  selfDetails,
});

export const getUserDetailsFailure = (error: any) => ({
  type: userConstants.GET_USER_DETAILS_FAILURE,
  error,
});

export const getUserProjectsListRequest = () => ({
  type: userConstants.GET_USER_PROJECTS_LIST_REQUEST,
  selfDetails: '',
});

export const getUserProjectsListSuccess = (projects: any[]) => ({
  type: userConstants.GET_USER_PROJECTS_LIST_SUCCESS,
  projects,
});

export const getUserProjectsListFailure = (error: any) => ({
  type: userConstants.GET_USER_PROJECTS_LIST_FAILURE,
  error,
});

export const getUserProjectRequest = (
  idProject: number | string,
  idUser: number
) => ({
  type: userConstants.GET_USER_PROJECT_REQUEST,
  idProject,
  idUser,
});

export const getUserProjectSuccess = (userProject: any) => ({
  type: userConstants.GET_USER_PROJECT_SUCCESS,
  userProject,
});

export const getUserProjectFailure = (error: any) => ({
  type: userConstants.GET_USER_PROJECT_FAILURE,
  error,
});

export const putUserRequest = (user: any) => ({
  type: userConstants.PUT_USER_REQUEST,
  user,
});

export const putUserSuccess = (userUpdate: any) => ({
  type: userConstants.PUT_USER_SUCCESS,
  userUpdate,
});

export const putUserFailure = (error: any) => ({
  type: userConstants.PUT_USER_FAILURE,
  error,
});
