import { getUrl, get, del, post, update } from '../../services';

export async function getPerformersByProjectApi(params: any) {
  return await get(
    getUrl('projects/' + params.idProject + '/performers', params.args)
  );
}

export async function postPerformersByProjectApi(params: any) {
  return await post(
    getUrl('projects/' + params.idProject + '/performers'),
    params.performer
  );
}

export async function getPerformerApi(params: any) {
  return await get(getUrl('performers/' + params.idPerformer));
}

export async function putPerformerApi(params: any) {
  return await update(
    getUrl('performers/' + params.idPerformer),
    params.performer
  );
}

export async function deletePerformerApi(idPerformer: any) {
  return await del(getUrl('performers/' + idPerformer));
}

export async function getTranslationPerformerApi(params: any) {
  return await get(getUrl('performers/' + params.id + '/translations'));
}

export async function postTranslationPerformerSelfApi(params: any) {
  return await post(
    getUrl('performers/' + params.id + '/translations'),
    params.translationPerformer
  );
}

export async function putTranslationPerformerSelfApi(params: any) {
  return await update(
    getUrl('performers/' + params.id + '/translations'),
    params.updatedTranslationPerformer
  );
}
