import { performersConstants } from '../constants';

export const getPerformersListRequest = (
  idProject: number | string,
  options: any
) => ({
  type: performersConstants.GET_PERFORMERS_LIST_REQUEST,
  idProject,
  options,
});

export const getPerformersListSuccess = (performers: any) => ({
  type: performersConstants.GET_PERFORMERS_LIST_SUCCESS,
  performers,
});

export const getPerformersListFailure = (error: any) => ({
  type: performersConstants.GET_PERFORMERS_LIST_FAILURE,
  error,
});

export const postPerformerRequest = (
  idProject: number | string,
  performer: any
) => ({
  type: performersConstants.POST_PERFORMER_REQUEST,
  idProject,
  performer,
});

export const postPerformerSuccess = (performer: any) => ({
  type: performersConstants.POST_PERFORMER_SUCCESS,
  performer,
});

export const postPerformerFailure = (error: any) => ({
  type: performersConstants.POST_PERFORMER_FAILURE,
  error,
});

export const getPerformerRequest = (id: number | string) => ({
  type: performersConstants.GET_PERFORMER_REQUEST,
  id,
});

export const getPerformerSuccess = (performerDetails: any) => ({
  type: performersConstants.GET_PERFORMER_SUCCESS,
  performerDetails,
});

export const getPerformerFailure = (error: any) => ({
  type: performersConstants.GET_PERFORMER_FAILURE,
  error,
});

export const putPerformerRequest = (
  performerId: number | string,
  updatedPerformer: any
) => ({
  type: performersConstants.PUT_PERFORMER_REQUEST,
  performerId,
  updatedPerformer,
});

export const putPerformerSuccess = (updatedPerformer: any) => ({
  type: performersConstants.PUT_PERFORMER_SUCCESS,
  updatedPerformer,
});

export const putPerformerFailure = (error: any) => ({
  type: performersConstants.PUT_PERFORMER_FAILURE,
  error,
});

export const deletePerformerRequest = (idPerformer: number | string) => ({
  type: performersConstants.DELETE_PERFORMER_REQUEST,
  idPerformer,
});

export const deletePerformerSuccess = (deletedPerformer: any) => ({
  type: performersConstants.DELETE_PERFORMER_SUCCESS,
  deletedPerformer,
});

export const deletePerformerFailure = (error: any) => ({
  type: performersConstants.DELETE_PERFORMER_FAILURE,
  error,
});

export const getPerformerTranslationsRequest = (
  idPerformer: number | string
) => ({
  type: performersConstants.GET_PERFORMER_TRANSLATIONS_REQUEST,
  idPerformer,
});

export const getPerformerTranslationsSuccess = (
  translationPerformerList: any
) => ({
  type: performersConstants.GET_PERFORMER_TRANSLATIONS_SUCCESS,
  translationPerformerList,
});

export const getPerformerTranslationsFailure = (error: any) => ({
  type: performersConstants.GET_PERFORMER_TRANSLATIONS_FAILURE,
  error,
});

export const postPerformerTranslationsRequest = (
  performerId: number | string,
  translationPerformer: any
) => ({
  type: performersConstants.POST_PERFORMER_TRANSLATIONS_REQUEST,
  performerId,
  translationPerformer,
});

export const postPerformerTranslationsSuccess = (
  translationPerformer: any
) => ({
  type: performersConstants.POST_PERFORMER_TRANSLATIONS_SUCCESS,
  translationPerformer,
});

export const postPerformerTranslationsFailure = (error: any) => ({
  type: performersConstants.POST_PERFORMER_TRANSLATIONS_FAILURE,
  error,
});

export const putPerformerTranslationsRequest = (
  performerId: number | string,
  updatedTranslationPerformer: any
) => ({
  type: performersConstants.PUT_PERFORMER_TRANSLATIONS_REQUEST,
  performerId,
  updatedTranslationPerformer,
});

export const putPerformerTranslationsSuccess = (
  updatedTranslationPerformer: any
) => ({
  type: performersConstants.PUT_PERFORMER_TRANSLATIONS_SUCCESS,
  updatedTranslationPerformer,
});

export const putPerformerTranslationsFailure = (error: any) => ({
  type: performersConstants.PUT_PERFORMER_TRANSLATIONS_FAILURE,
  error,
});

export const clearPerformer = () => ({
  type: performersConstants.CLEAR_PERFORMER,
});
