import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import {
  Scheduler,
  Resources,
  DayView,
  Appointments,
  CurrentTimeIndicator,
  GroupingPanel,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  ViewState,
  GroupingState,
  IntegratedGrouping,
} from '@devexpress/dx-react-scheduler';
import { currentProject } from '../../../Global/currentProject';
import PlaceModel from '../../../Models/PlaceModel';
import SessionModel from '../../../Models/SessionModel';

const LineUpVertical = (props: {
  chooseDate: Date;
  placesList: PlaceModel[];
  sessionsList: SessionModel[];
  setOpenModalSession: (arg: boolean) => void;
  setIdSession: (arg: string | number) => void;
}) => {
  const [places, setPlaces] = useState<any>(null);
  const [sessions, setSessions] = useState<any>(null);

  useEffect(() => {
    if (props.placesList) {
      let newPlaces = props.placesList.map((element: PlaceModel) => ({
        id: element.id,
        text: element.name,
      }));
      setPlaces(newPlaces);
    }
  }, [props.placesList]);

  useEffect(() => {
    if (props.sessionsList) {
      let arraySession: any[] = [];
      props.sessionsList.map((element: SessionModel) => {
        if (element.sessionPlaces && element.sessionPlaces.length !== 0) {
          arraySession.push({
            confirmed: element.timeToBeConfirmed,
            id: element.id,
            title: element.title,
            placeId: element.sessionPlaces[0].place.id,
            startDate: element.liveStartDateTimeUTC,
            endDate: element.liveEndDateTimeUTC,
          });
        }
      });

      setSessions(arraySession);
    }
  }, [props.sessionsList]);

  const Appointment = ({ children, style, data, ...restProps }: any) => (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        backgroundColor: data.confirmed ? '#999999' : '#e6007e',
      }}
      onClick={() => {
        props.setOpenModalSession(true);
        props.setIdSession(data.id);
      }}
    >
      {children}
    </Appointments.Appointment>
  );

  return (
    sessions !== null &&
    places !== null &&
    (places.length > 0 ? (
      <Paper>
        <Scheduler
          data={sessions}
          locale={currentProject.language.locale.replace('_', '-')}
        >
          <ViewState currentDate={props.chooseDate} />
          <DayView startDayHour={0} endDayHour={24} />

          <Appointments appointmentComponent={Appointment} />
          <CurrentTimeIndicator
            shadePreviousCells={true}
            shadePreviousAppointments={true}
            updateInterval={5000}
          />

          <GroupingState
            grouping={[{ resourceName: 'placeId' }]}
            groupByDate={() => true}
          />
          <Resources
            data={[
              {
                fieldName: 'placeId',
                title: 'Place',
                instances: places,
              },
            ]}
          />

          <IntegratedGrouping />
          <GroupingPanel />
        </Scheduler>
      </Paper>
    ) : (
      <Paper>
        <Scheduler
          data={sessions}
          locale={currentProject.language.locale.replace('_', '-')}
        >
          <ViewState currentDate={props.chooseDate} />
          <DayView startDayHour={0} endDayHour={24} />

          <Appointments appointmentComponent={Appointment} />
          <CurrentTimeIndicator
            shadePreviousCells={true}
            shadePreviousAppointments={true}
            updateInterval={5000}
          />
        </Scheduler>
      </Paper>
    ))
  );
};

export default LineUpVertical;
