import React from 'react';
import { PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import './Paging.css';

const PagingTable = (props: PagingPanel.ContainerProps) => (
  <>
    <PagingPanel.Container {...props} className={'table-paging paging-top'} />

    <PagingPanel.Container
      {...props}
      className={'table-paging paging-bottom'}
    />
  </>
);

export default PagingTable;
