import { placesConstants } from '../constants';
import PlaceModel from '../../Models/PlaceModel';

const initialState: any = {
  placesList: null,
  newPlace: null,
  updatedPlace: null,
  placeDetails: null,
  updatedPlacePosition: null,
  translationPlaceSelf: null,
  translationPlace: null,
  updatedTranslationPlace: null,
  oldPlace: null,
};

let listPlaces: PlaceModel[] = [];

const places = (state = initialState, action: any) => {
  switch (action.type) {
    case placesConstants.GET_PLACES_LIST_REQUEST: {
      return {
        ...state,
        placesList: { places: listPlaces, error: false, loading: true },
      };
    }

    case placesConstants.GET_PLACES_LIST_SUCCESS: {
      listPlaces = action.placesList.data.map(
        (place: any) => new PlaceModel(place)
      );

      return {
        ...state,
        placesList: { places: listPlaces, error: false, loading: false },
      };
    }

    case placesConstants.GET_PLACES_LIST_FAILURE: {
      return {
        ...state,
        placesList: { places: null, error: true, loading: false },
      };
    }

    case placesConstants.POST_PLACES_REQUEST: {
      return {
        ...state,
        newPlace: { place: null, error: false, loading: true },
      };
    }

    case placesConstants.POST_PLACES_SUCCESS: {
      return {
        ...state,
        newPlace: {
          place: new PlaceModel(action.place.data),
          error: false,
          loading: false,
        },
      };
    }

    case placesConstants.POST_PLACES_FAILURE: {
      return {
        ...state,
        newPlace: { place: null, error: true, loading: false },
      };
    }

    case placesConstants.PUT_PLACES_REQUEST: {
      return {
        ...state,
        updatedPlace: { place: null, error: false, loading: true },
      };
    }

    case placesConstants.PUT_PLACES_SUCCESS: {
      return {
        ...state,
        updatedPlace: {
          place: action.place.data,
          error: false,
          loading: false,
        },
      };
    }

    case placesConstants.PUT_PLACES_FAILURE: {
      return {
        ...state,
        updatedPlace: { place: null, error: true, loading: false },
      };
    }

    case placesConstants.GET_PLACE_REQUEST: {
      return {
        ...state,
        placeDetails: { place: null, error: false, loading: true },
      };
    }

    case placesConstants.GET_PLACE_SUCCESS: {
      return {
        ...state,
        placeDetails: {
          place: new PlaceModel(action.placeDetails.data),
          error: false,
          loading: false,
        },
      };
    }

    case placesConstants.GET_PLACE_FAILURE: {
      return {
        ...state,
        placeDetails: { place: null, error: true, loading: false },
      };
    }

    case placesConstants.PUT_PLACES_POSITION_REQUEST: {
      return {
        ...state,
        updatedPlacePosition: { place: null, error: false, loading: true },
      };
    }

    case placesConstants.PUT_PLACES_POSITION_SUCCESS: {
      return {
        ...state,
        updatedPlacePosition: {
          place: action.place.data,
          error: false,
          loading: false,
        },
      };
    }

    case placesConstants.PUT_PLACES_POSITION_FAILURE: {
      return {
        ...state,
        updatedPlacePosition: { place: null, error: true, loading: false },
      };
    }

    case placesConstants.GET_PLACES_TRANSLATION_REQUEST: {
      return {
        ...state,
        translationPlaceSelf: { place: null, error: false, loading: true },
      };
    }

    case placesConstants.GET_PLACES_TRANSLATION_SUCCESS: {
      return {
        ...state,
        translationPlaceSelf: {
          place: action.translationPlaceSelf.data,
          error: false,
          loading: false,
        },
      };
    }

    case placesConstants.GET_PLACES_TRANSLATION_FAILURE: {
      return {
        ...state,
        translationPlaceSelf: { place: null, error: true, loading: false },
      };
    }

    case placesConstants.POST_PLACES_TRANSLATION_REQUEST: {
      return {
        ...state,
        translationPlace: { place: null, error: false, loading: true },
      };
    }

    case placesConstants.POST_PLACES_TRANSLATION_SUCCESS: {
      return {
        ...state,
        translationPlace: {
          place: action.translationPlace.data,
          error: false,
          loading: false,
        },
      };
    }

    case placesConstants.POST_PLACES_TRANSLATION_FAILURE: {
      return {
        ...state,
        translationPlace: { place: null, error: true, loading: false },
      };
    }

    case placesConstants.PUT_PLACES_TRANSLATION_REQUEST: {
      return {
        ...state,
        updatedTranslationPlace: { place: null, error: false, loading: true },
      };
    }

    case placesConstants.PUT_PLACES_TRANSLATION_SUCCESS: {
      return {
        ...state,
        updatedTranslationPlace: {
          place: action.translationPlace.data,
          error: false,
          loading: false,
        },
      };
    }

    case placesConstants.PUT_PLACES_TRANSLATION_FAILURE: {
      return {
        ...state,
        updatedTranslationPlace: { place: null, error: true, loading: false },
      };
    }

    case placesConstants.DELETE_PLACES_REQUEST: {
      return {
        ...state,
        oldPlace: { place: null, error: false, loading: true },
      };
    }

    case placesConstants.DELETE_PLACES_SUCCESS: {
      return {
        ...state,
        oldPlace: { place: true, error: false, loading: false },
      };
    }

    case placesConstants.DELETE_PLACES_FAILURE: {
      return {
        ...state,
        oldPlace: { place: '', error: true, loading: false },
      };
    }

    case placesConstants.CLEAR_PLACES: {
      return {
        ...state,
        newPlace: null,
        updatedPlace: null,
        placeDetails: null,
        updatedPlacePosition: null,
        translationPlaceSelf: null,
        translationPlace: null,
        updatedTranslationPlace: null,
        oldPlace: null,
      };
    }

    default:
      return state;
  }
};

export default places;
