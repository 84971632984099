import { getUrl, get, del, post, patch, update } from '../../services';

export async function getPlacesByProjectApi(params: any) {
  return await get(
    getUrl('projects/' + params.idProject + '/places?order=position:ASC')
  );
}

export async function postPlacesByProjectApi(params: any) {
  return await post(
    getUrl('projects/' + params.idProject + '/places'),
    params.newPlace
  );
}

export async function putPlacesApi(params: any) {
  return await update(getUrl('places/' + params.id), params.updatedPlace);
}

export async function putPlacesPositionApi(params: any) {
  return await patch(
    getUrl('places/' + params.id + '/reorder/' + params.index)
  );
}

export async function getPlaceApi(params: any) {
  return await get(getUrl('places/' + params.idPlace));
}

export async function getTranslationPlacesSelfApi(params: any) {
  return await get(getUrl('places/' + params.id + '/translations'));
}

export async function postTranslationPlacesSelfApi(params: any) {
  return await post(
    getUrl('places/' + params.id + '/translations'),
    params.translationPlace
  );
}

export async function putTranslationPlacesSelfApi(params: any) {
  return await update(
    getUrl('places/' + params.id + '/translations'),
    params.updatedTranslationPlace
  );
}

export async function deletePlacesSelfApi(id: any) {
  return await del(getUrl('places/' + id));
}
