import { getUrl, get, del, post, patch, update } from '../../services';

export async function getContentNewsByProjectApi(params: any) {
  return await get(getUrl('projects/' + params.idProject + '/contentnews'));
}

export async function postContentNewsByProjectApi(params: any) {
  return await post(
    getUrl('projects/' + params.idProject + '/contentnews'),
    params.newContentNews
  );
}

export async function putContentNewsApi(params: any) {
  return await update(
    getUrl('contentnews/' + params.updatedContentNews.id),
    params.updatedContentNews
  );
}

export async function putContentNewsPositionApi(params: any) {
  return await patch(
    getUrl('contentnews/' + params.id + '/reorder/' + params.index)
  );
}

export async function getContentNewsApi(params: any) {
  return await get(getUrl('contentnews/' + params.idContentNews));
}

export async function deleteContentNewsApi(params: any) {
  return await del(getUrl('contentnews/' + params.idContentNews));
}

export async function getTranslationContentNewsApi(params: any) {
  return await get(getUrl('contentnews/' + params.id + '/translations'));
}

export async function postTranslationContentNewsSelfApi(params: any) {
  return await post(
    getUrl('contentnews/' + params.id + '/translations'),
    params.translationContentNews
  );
}

export async function putTranslationContentNewsSelfApi(params: any) {
  return await update(
    getUrl('contentnews/' + params.id + '/translations'),
    params.updatedTranslationContentNews
  );
}
