import React, { useState } from 'react';
import SessionTracksTable from './SessionTracksTable/SessionTracksTable';
import ModalSessionTrackCreation from './SessionTrackCreation/SessionTrackModalCreation';
import Translator from '../../services/translator';
import { CreateButton, HeaderBanner } from '@emisys/audience-sdk-ui-react';
import Bulb from '@mui/icons-material/EmojiObjects';

const SessionTracks = () => {
  const [openModalSessionTrack, setOpenModalSessionTrack] = useState(false);

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('sessionTracks.title')}
          icon={<Bulb />}
        />
        <CreateButton onClick={() => setOpenModalSessionTrack(true)}>
          {Translator.trans('sessionTracks.add.title')}
        </CreateButton>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <SessionTracksTable />
          </div>
        </div>
        <ModalSessionTrackCreation
          open={openModalSessionTrack}
          close={() => setOpenModalSessionTrack(false)}
        />
      </div>
    </div>
  );
};

export default SessionTracks;
