import ImageModel from './ImageModel';
import SessionPerformerModel from './SessionPerformerModel';

export default class PerformerModel {
  biography: string | null;
  external_id: null | number;
  headline: boolean;
  id: number;
  locale: string | null;
  name: string;
  picture: ImageModel | null;
  sessionPerformers: SessionPerformerModel[];
  website: string;
  facebookUrl: string;
  instagramUrl: string;
  twitterUrl: string;
  youtubeUrl: string;
  spotifyUrl: string;

  constructor(data: any) {
    this.biography = data.biography;
    this.external_id = data.external_id;
    this.headline = data.headline === 1;
    this.id = data.id;
    this.locale = data.locale;
    this.name = data.name ?? '';
    this.picture = data.picture ? new ImageModel(data.picture) : null;
    this.sessionPerformers = data.sessionPerformers
      ? data.sessionPerformers.map(
          (session: any) => new SessionPerformerModel(session)
        )
      : [];
    this.website = data.website ?? '';
    this.facebookUrl = data.facebookUrl ?? '';
    this.instagramUrl = data.instagramUrl ?? '';
    this.twitterUrl = data.twitterUrl ?? '';
    this.youtubeUrl = data.youtubeUrl ?? '';
    this.spotifyUrl = data.spotifyUrl ?? '';
  }
}
