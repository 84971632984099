import React, { useEffect } from 'react';
import {
  clearContentArticle,
  getContentArticlesListRequest,
} from '../../Redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { CreateButton, HeaderBanner } from '@emisys/audience-sdk-ui-react';
import { NotificationManager } from '../../components/Notification/NotificationManager';
import Translator from '../../services/translator';
import { checkReduxResponse } from '../../services/checkReduxResponse';
import { currentProject } from '../../Global/currentProject';
import { pagesConstant } from '../../Constants/Constant';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import { storeType } from '../..';
import ArticleTable from './ArticleTable/ArticleTable';

const ContentArticles = () => {
  const dispatch = useDispatch();

  const deletedContentArticle = useSelector(
    (state: storeType) => state.contentArticles.deletedContentArticle
  );
  const updatedContentArticlePosition = useSelector(
    (state: storeType) => state.contentArticles.updatedContentArticlePosition
  );
  const translationArticle = useSelector(
    (state: storeType) => state.contentArticles.translationArticle
  );
  const updatedTranslationArticle = useSelector(
    (state: storeType) => state.contentArticles.updatedTranslationArticle
  );

  useEffect(() => {
    dispatch(getContentArticlesListRequest(currentProject.id));
  }, [dispatch]);

  useEffect(() => {
    if (checkReduxResponse(translationArticle, 'contentArticle')) {
      NotificationManager.success(
        Translator.trans('articles.message.success.updated')
      );
      dispatch(clearContentArticle());
    }
  }, [dispatch, translationArticle]);

  useEffect(() => {
    if (checkReduxResponse(deletedContentArticle, 'contentArticle')) {
      NotificationManager.success(
        Translator.trans('articles.message.success.deleted')
      );
      dispatch(getContentArticlesListRequest(currentProject.id));
      dispatch(clearContentArticle());
    }
  }, [dispatch, deletedContentArticle]);

  useEffect(() => {
    if (updatedContentArticlePosition) {
      if (checkReduxResponse(updatedContentArticlePosition, 'contentArticle')) {
        NotificationManager.success(
          Translator.trans('cms.message.success.position.updated')
        );
      }
      dispatch(clearContentArticle());
    }
  }, [dispatch, updatedContentArticlePosition]);

  useEffect(() => {
    if (checkReduxResponse(updatedTranslationArticle, 'contentArticle')) {
      dispatch(clearContentArticle());
      NotificationManager.success(
        Translator.trans('articles.message.success.updated')
      );
    }
  }, [dispatch, updatedTranslationArticle]);

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('articles.title')}
          icon={<LibraryBooks />}
        />
        <NavLink to={pagesConstant.article.add}>
          <CreateButton>
            {Translator.trans('articles.button.add.title')}
          </CreateButton>
        </NavLink>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <ArticleTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentArticles;
