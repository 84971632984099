import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getNotificationsListSuccess,
  getNotificationsListFailure,
  postNotificationsSuccess,
  postNotificationsFailure,
} from '../actions';
import { notificationsConstants } from '../constants';
import {
  getNotificationsByProjectApi,
  postNotificationsByProjectApi,
} from '../api';

export function* fetchGetNotificationsByProject(action: any): any {
  try {
    const notifications = yield call(getNotificationsByProjectApi, {
      idProject: action.idProject,
    });
    yield put(getNotificationsListSuccess(notifications));
  } catch (error) {
    yield put(getNotificationsListFailure(error));
  }
}

export function* fetchPostNotificationsByProject(action: any): any {
  try {
    const newNotification = yield call(postNotificationsByProjectApi, {
      idProject: action.projectId,
      newNotification: action.newNotification,
    });
    yield put(postNotificationsSuccess(newNotification));
  } catch (error) {
    yield put(postNotificationsFailure(error));
  }
}

export function* getNotificationsByProject() {
  yield takeLatest(
    notificationsConstants.GET_NOTIFICATIONS_LIST_REQUEST,
    fetchGetNotificationsByProject
  );
}

export function* postNotificationsByProject() {
  yield takeLatest(
    notificationsConstants.POST_NOTIFICATIONS_REQUEST,
    fetchPostNotificationsByProject
  );
}

export default function* notificationsSaga() {
  yield all([
    fork(getNotificationsByProject),
    fork(postNotificationsByProject),
  ]);
}
