import React, { useEffect, useState } from 'react';
import { MainTextField } from '@emisys/audience-sdk-ui-react';
import { capitalizeFirstLetter } from '../../../services/capitalizeFirstLetter';

const SessionTranslations = (props: {
  translation?: any; // add type property
  language: string;
  handleChangeTranslation: (
    name: string,
    value: string,
    language: string
  ) => void;
}) => {
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (props.translation?.description) {
      setDescription(props.translation.description);
    }
  }, [props.translation?.description]);

  const handleChangeTranslation = (name: string) => (event: any) => {
    const newValue = capitalizeFirstLetter(event.target.value);
    setDescription(newValue);
    props.handleChangeTranslation(name, newValue, props.language);
  };

  return (
    <MainTextField
      className="sessionDesc"
      multiline
      minRows={4}
      value={description}
      onChange={handleChangeTranslation('description')}
      variant="outlined"
    />
  );
};

export default SessionTranslations;
