import React, { useCallback, useEffect, useState } from 'react';
import {
  EmptyText,
  HeaderBanner,
  Loading,
  MainButton,
  UnderlineTitle,
} from '@emisys/audience-sdk-ui-react';
import { Storefront } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearProjectRequest,
  getProjectsSelfRequest,
} from '../../../Redux/actions';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import EventListItem from './EventListItem/EventListItem';
import { currentProject } from '../../../Global/currentProject';
import Translator from '../../../services/translator';
import { storeType } from '../../../index';
import UserProjectModel from '../../../Models/UserProjectModel';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import './EventsList.css';

const ShowCurrentList = (props: { currentList: UserProjectModel[] }) => {
  return props.currentList.length > 0 ? (
    props.currentList.map((projectSelf: UserProjectModel) => (
      <EventListItem key={projectSelf.id} project={projectSelf.project} />
    ))
  ) : (
    <EmptyText>{Translator.trans('cms.no.events')}</EmptyText>
  );
};

const EventsList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [projectLength, setProjectLength] = useState(0);
  const [projectList, setProjectList] = useState<UserProjectModel[]>([]);
  const [archivedProjectList, setArchivedProjectList] = useState<
    UserProjectModel[]
  >([]);

  const [filteredProjectList, setFilteredProjectList] = useState<
    UserProjectModel[]
  >([]);
  const [filteredArchivedProjectList, setFilteredArchivedProjectList] =
    useState<UserProjectModel[]>([]);
  const [project, setProject] = useState<UserProjectModel>();
  const [showArchive, setShowArchive] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const projectArchived = useSelector(
    (state: storeType) => state.project.projectArchived
  );

  const projectsSelf = useSelector(
    (state: storeType) => state.project.projectsSelf
  );

  const getProjectsSelf = useCallback(() => {
    dispatch(getProjectsSelfRequest());
  }, [dispatch]);

  useEffect(() => {
    if (projectsSelf) {
      setLoading(projectsSelf.loading);
      if (checkReduxResponse(projectsSelf, 'projects')) {
        let newProject: UserProjectModel[] = [];
        let oldProject: UserProjectModel[] = [];
        projectsSelf.projects.forEach((projectSelf: UserProjectModel) => {
          if (projectSelf.project.id === currentProject.id) {
            setProject(projectSelf);
          } else {
            if (projectSelf.project.isActive) {
              newProject.push(projectSelf);
            } else {
              oldProject.push(projectSelf);
            }
          }
        });

        setProjectLength(projectsSelf.projects.length);

        setProjectList(newProject);
        setFilteredProjectList(newProject);

        setArchivedProjectList(oldProject);
        setFilteredArchivedProjectList(oldProject);
      }
    } else {
      getProjectsSelf();
    }
  }, [getProjectsSelf, projectsSelf]);

  useEffect(() => {
    if (projectArchived) {
      setLoading(projectArchived.loading);
      if (checkReduxResponse(projectArchived, 'project')) {
        getProjectsSelf();
        NotificationManager.success(
          Translator.trans('events.archive.success', {
            name: projectArchived.project.name,
          })
        );
        dispatch(clearProjectRequest());
      }
    }
  }, [dispatch, getProjectsSelf, projectArchived]);

  const handleSearchEvent = (value: string) => {
    setSearchValue(value);
    if (value) {
      if (showArchive) {
        const newList = archivedProjectList.filter((item: UserProjectModel) =>
          currentProject.stringMatcher.searchNameForMatch(
            item.project.name,
            value
          )
        );
        setFilteredArchivedProjectList(newList);
      } else {
        const newList = projectList.filter((item: UserProjectModel) =>
          currentProject.stringMatcher.searchNameForMatch(
            item.project.name,
            value
          )
        );
        setFilteredProjectList(newList);
      }
    } else {
      setFilteredProjectList(projectList);
      setFilteredArchivedProjectList(archivedProjectList);
    }
  };

  return (
    <div className="app-wrapper">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('sidenav.title.event')}
          icon={<Storefront />}
        />
      </div>
      <div className="row">
        <div className="col-12">
          {project && <EventListItem project={project.project} />}

          <UnderlineTitle
            title={Translator.trans('sidenav.title.others.events')}
          >
            <div className={'events-action-container'}>
              {projectLength > 4 && (
                <TextField
                  value={searchValue}
                  onChange={(event) => handleSearchEvent(event.target.value)}
                  InputProps={{
                    endAdornment: searchValue ? (
                      <CloseIcon
                        className={'search-clear-button'}
                        onClick={() => handleSearchEvent('')}
                      />
                    ) : (
                      <SearchIcon />
                    ),
                  }}
                  placeholder={Translator.trans('appModule.project.search')}
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={'search-event-input'}
                />
              )}
              {archivedProjectList.length > 0 && (
                <MainButton
                  className={'show-archive-btn'}
                  onClick={() => {
                    setShowArchive(!showArchive);
                    handleSearchEvent('');
                  }}
                >
                  {showArchive
                    ? Translator.trans('events.hide.archive')
                    : Translator.trans('events.show.archive', {
                        qty: archivedProjectList.length,
                      })}
                </MainButton>
              )}
            </div>
          </UnderlineTitle>

          {showArchive ? (
            <ShowCurrentList currentList={filteredArchivedProjectList} />
          ) : (
            <ShowCurrentList currentList={filteredProjectList} />
          )}
        </div>
        {loading && <Loading />}
      </div>
    </div>
  );
};

export default EventsList;
