import Translator from '../../services/translator';

export const genreList: { label: string; value: string }[] = [
  {
    value: 'M',
    label: Translator.trans('appModule.gender.man'),
  },
  {
    value: 'F',
    label: Translator.trans('appModule.gender.woman'),
  },
  {
    value: 'O',
    label: Translator.trans('appModule.gender.other'),
  },
  {
    value: 'NA',
    label: Translator.trans('appModule.gender.NA'),
  },
];

export const SelectedGenre = (genre: 'M' | 'F' | 'O' | null) => {
  if (genre === 'M') {
    return Translator.trans('appModule.gender.man');
  } else if (genre === 'F') {
    return Translator.trans('appModule.gender.woman');
  } else if (genre === 'O') {
    return Translator.trans('appModule.gender.other');
  } else {
    return Translator.trans('appModule.gender.NA');
  }
};
