import React, { useMemo } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Translator from './../../../services/translator';
import NotificationModel from '../../../Models/NotificationModel';
import { formatDateTime } from '../../../services/formatDate';
import '../Notifications.css';

const SubNotificationItem = (props: {
  open: boolean;
  notification: NotificationModel;
}) => {
  const rows: { name: string; infos: string }[] = useMemo(
    () => [
      {
        name: Translator.trans('notifications.table.subCard.title.id'),
        infos: props.notification.id.toString(),
      },
      {
        name: Translator.trans('notifications.table.subCard.title.externalId'),
        infos: props.notification.externalId,
      },
      {
        name: Translator.trans('notifications.table.subCard.title.recipients'),
        infos: props.notification.recipients.toString(),
      },
      {
        name: Translator.trans(
          'notifications.table.subCard.title.dateSendAfter'
        ),
        infos: formatDateTime(props.notification.dateSendAfter),
      },
      {
        name: Translator.trans('notifications.table.subCard.title.updatedAt'),
        infos: formatDateTime(props.notification.updatedAt),
      },
    ],
    [
      props.notification.dateSendAfter,
      props.notification.externalId,
      props.notification.id,
      props.notification.recipients,
      props.notification.updatedAt,
    ]
  );

  return (
    <Collapse in={props.open}>
      <div className="sub-notification-container">
        {rows.map((row, index) => (
          <div key={index} className={'sub-notification-item'}>
            <span>{row.name}</span>
            <span>{row.infos}</span>
          </div>
        ))}
      </div>
    </Collapse>
  );
};

export default SubNotificationItem;
