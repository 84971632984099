import { currentProject } from '../Global/currentProject';

export const formatDateTime = (
  date?: Date | string | null,
  locale?: string,
  timezone?: string
): string => {
  const currentTimezone = timezone || currentProject.timezone;
  const currentLocale = locale || currentProject.language.locale;
  if (currentTimezone && currentLocale && date) {
    return new Intl.DateTimeFormat(currentLocale.replace('_', '-'), {
      timeZone: currentTimezone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(new Date(date));
  }
  return '';
};

export const formatDate = (
  date?: Date | string | null,
  locale?: string,
  timezone?: string
): string => {
  const currentTimezone = timezone || currentProject.timezone;
  const currentLocale = locale || currentProject.language.locale;
  if (currentTimezone && currentLocale && date) {
    return new Intl.DateTimeFormat(currentLocale.replace('_', '-'), {
      timeZone: currentTimezone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(new Date(date));
  }
  return '';
};
