import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { columnNameAction, columnNameDrag } from '../../Constants/Constant';
import DragHandle from '../../Sortable/DragHandle/DragHandle';
import ButtonsPlaces from '../../../Buttons/ButtonsPlaces';

const PlacesCell = (props: Table.DataCellProps) => {
  let content = props.value;

  if (props.column.name === columnNameDrag) {
    return (
      <Table.Cell {...props} style={{ width: 20 }}>
        <DragHandle />
      </Table.Cell>
    );
  }

  if (props.column.name === columnNameAction) {
    content = <ButtonsPlaces place={props.row.place} />;
  }
  return <Table.Cell {...props}>{content}</Table.Cell>;
};

export default PlacesCell;
