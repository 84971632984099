import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Translator from '../../../services/translator';
import {
  getSessionsListRequest,
  getPlacesListRequest,
} from '../../../Redux/actions';
import 'react-calendar-timeline/lib/Timeline.css';
import dayjs, { OpUnitType } from 'dayjs';
import LineUpHorizontal from './LineUpHorizontal';
import LineUpVertical from './LineUpVertical';
import { RiArrowRightSLine, RiArrowLeftSLine } from 'react-icons/ri';
import { ButtonGroup, Button, IconButton } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { currentProject } from '../../../Global/currentProject';
import DatePickerComponent from '../../../components/DatePicker/DatePicker';
import { HeaderBanner, Loading } from '@emisys/audience-sdk-ui-react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { storeType } from '../../../index';
import SessionsModal from './Modal/SessionModal';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import SessionModel from '../../../Models/SessionModel';
import PlaceModel from '../../../Models/PlaceModel';

enum TypeEnum {
  Horizontal = 'hor',
  Vertical = 'vert',
}

type dashboardType = TypeEnum | null | undefined;

const LineUp = () => {
  const dispatch = useDispatch();
  const maxPlacesView = 3;
  const [showDashboard, setShowDashboard] = useState<dashboardType>(null);
  const [chooseDate, setChooseDate] = useState(new Date());
  const [minView, setMinView] = useState(0);
  const [maxView, setMaxView] = useState(0);
  const [classValue, setClassValue] = useState<OpUnitType>('day');
  const [openModalSession, setOpenModalSession] = useState(false);
  const [idSession, setIdSession] = useState<string | number>('');
  const [currentPlaces, setCurrentPlaces] = useState<PlaceModel[] | null>(null);
  const [currentSessions, setCurrentSessions] = useState<SessionModel[] | null>(
    null
  );

  const placesList = useSelector((state: storeType) => state.places.placesList);
  const sessionsList = useSelector(
    (state: storeType) => state.sessions.sessionsList
  );

  useEffect(() => {
    if (checkReduxResponse(placesList, 'places')) {
      setCurrentPlaces(placesList.places);
    }
  }, [placesList]);

  useEffect(() => {
    if (checkReduxResponse(sessionsList, 'sessions')) {
      setCurrentSessions(sessionsList.sessions);
    }
  }, [sessionsList]);

  const handleDashboardView = useCallback(() => {
    const dashboardView = localStorage.getItem('showDashboard');

    if (dashboardView) {
      if (
        dashboardView === TypeEnum.Horizontal ||
        dashboardView === TypeEnum.Vertical
      ) {
        setShowDashboard(dashboardView);
      } else {
        localStorage.setItem('showDashboard', TypeEnum.Horizontal);
        setShowDashboard(TypeEnum.Horizontal);
      }
    }
  }, []);

  useEffect(() => {
    handleDashboardView();
  }, [handleDashboardView]);

  const getSessions = useCallback(
    (date: Date, filter: OpUnitType) => {
      let sessionsParams: any = {
        accepted: 1,
      };

      if (showDashboard === TypeEnum.Horizontal) {
        handleChangeViewTimeline(date, filter);
      }

      if (showDashboard === TypeEnum.Vertical) {
        sessionsParams.liveStartDateTimeUTC = {
          operation: 'gte',
          value: new Date(
            date.setHours(0, 0, 0, 0) - date.getTimezoneOffset() * 60000
          ).toISOString(),
        };

        sessionsParams.liveEndDateTimeUTC = {
          operation: 'lte',
          value: new Date(
            date.setHours(23, 59, 59, 999) - date.getTimezoneOffset() * 60000
          ).toISOString(),
        };
      }

      dispatch(getSessionsListRequest(currentProject.id, sessionsParams));
    },
    [dispatch, showDashboard]
  );

  useEffect(() => {
    getSessions(chooseDate, classValue);
  }, [getSessions, chooseDate, classValue]);

  const handleChangeDate = useCallback(
    (date: Date | null, filter: OpUnitType) => {
      if (date) {
        setChooseDate(date);

        getSessions(date, filter);
      }
    },
    [getSessions]
  );

  useEffect(() => {
    dispatch(getPlacesListRequest(currentProject.id));
  }, [dispatch]);

  useEffect(() => {
    if (!localStorage.getItem('showDashboard') && placesList?.places) {
      if (placesList.places.length > maxPlacesView) {
        localStorage.setItem('showDashboard', TypeEnum.Horizontal);
      } else {
        localStorage.setItem('showDashboard', TypeEnum.Vertical);
      }
      handleDashboardView();
    }
  }, [placesList, handleDashboardView]);

  const handleChangeViewTimeline = (
    date: number | Date,
    filter: OpUnitType
  ) => {
    let minimumView = dayjs(date).startOf(filter).toDate();
    let maximumView = dayjs(date).endOf(filter).toDate();

    setMinView(minimumView.getTime());
    setMaxView(maximumView.getTime());
    setClassValue(filter);
  };

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('dashboard.title')}
          icon={<DashboardIcon />}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <div className={'filterContainer'}>
              <div>
                <IconButton
                  className="py-2"
                  onClick={() => {
                    let newDate = chooseDate;
                    let dayDiff = new Date(
                      newDate.setDate(newDate.getDate() - 1)
                    );
                    handleChangeDate(dayDiff, 'day');
                  }}
                >
                  <RiArrowLeftSLine />
                </IconButton>
                <DatePickerComponent
                  selected={chooseDate}
                  onChange={(date) => handleChangeDate(date, 'day')}
                />
                <IconButton
                  className="py-2"
                  onClick={() => {
                    let newDate = chooseDate;
                    let dayDiff = new Date(
                      newDate.setDate(newDate.getDate() + 1)
                    );
                    handleChangeDate(dayDiff, 'day');
                  }}
                >
                  <RiArrowRightSLine />
                </IconButton>

                {showDashboard === TypeEnum.Horizontal && (
                  <ButtonGroup
                    size="small"
                    variant="outlined"
                    aria-label="small outlined button group"
                    className={'buttonGroup'}
                  >
                    <Button
                      className={classValue === 'day' ? 'buttonClass' : ''}
                      onClick={() =>
                        handleChangeViewTimeline(chooseDate, 'day')
                      }
                    >
                      {Translator.trans('cms.day')}
                    </Button>
                    <Button
                      className={classValue === 'week' ? 'buttonClass' : ''}
                      onClick={() =>
                        handleChangeViewTimeline(chooseDate, 'week')
                      }
                    >
                      {Translator.trans('cms.week')}
                    </Button>
                    <Button
                      className={classValue === 'month' ? 'buttonClass' : ''}
                      onClick={() =>
                        handleChangeViewTimeline(chooseDate, 'month')
                      }
                    >
                      {Translator.trans('cms.month')}
                    </Button>
                    <Button
                      className={classValue === 'year' ? 'buttonClass' : ''}
                      onClick={() =>
                        handleChangeViewTimeline(chooseDate, 'year')
                      }
                    >
                      {Translator.trans('cms.year')}
                    </Button>
                  </ButtonGroup>
                )}
              </div>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={showDashboard}
                onChange={(e) => {
                  const newValue = e.target.value;

                  if (
                    newValue === TypeEnum.Horizontal ||
                    newValue === TypeEnum.Vertical
                  ) {
                    setShowDashboard(newValue);
                    localStorage.setItem('showDashboard', newValue);
                  }
                }}
              >
                <MenuItem value={TypeEnum.Horizontal}>
                  {Translator.trans('cms.horizontal')}
                </MenuItem>
                <MenuItem value={TypeEnum.Vertical}>
                  {Translator.trans('cms.vertical')}
                </MenuItem>
              </Select>
            </div>

            {currentPlaces !== null && currentSessions !== null ? (
              <>
                {showDashboard === TypeEnum.Horizontal && (
                  <LineUpHorizontal
                    chooseDate={chooseDate}
                    minView={minView}
                    maxView={maxView}
                    placesList={currentPlaces}
                    sessionsList={currentSessions}
                    setIdSession={setIdSession}
                    setOpenModalSession={setOpenModalSession}
                  />
                )}

                {showDashboard === TypeEnum.Vertical && (
                  <LineUpVertical
                    chooseDate={chooseDate}
                    placesList={currentPlaces}
                    sessionsList={currentSessions}
                    setIdSession={setIdSession}
                    setOpenModalSession={setOpenModalSession}
                  />
                )}
              </>
            ) : (
              <Loading />
            )}

            <div className="reference">
              <div className="cubeContainer">
                <div
                  className="cubeReference"
                  style={{ background: '#e6007e' }}
                />
                <p>{Translator.trans('sessions.title')}</p>
              </div>
              <div className="cubeContainer">
                <div
                  className="cubeReference"
                  style={{ background: '#999999' }}
                />
                <p>{Translator.trans('sessions.title.timeToBeConfirmed')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openModalSession && (
        <SessionsModal
          close={() => setOpenModalSession(false)}
          open={openModalSession}
          idSession={idSession}
        />
      )}
    </div>
  );
};

export default LineUp;
