import React from 'react';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import TextField from '@mui/material/TextField';
import FilterClear from '../FilterClear';
import Translator from '../../../../services/translator';

const style = {
  padding: 8,
  height: 65,
  width: '100%',
};

const FilterPlaceCell = ({ column, filter, onFilter, ...restProps }) => {
  const filterValue = filter && filter.value;
  const onChange = (e) =>
    onFilter({ columnName: column.name, value: e.target.value });

  const handleReset = () => {
    onFilter({ columnName: column.name, value: '' });
  };

  let content = <TableFilterRow.Cell />;

  if (column.name === 'name') {
    content = (
      <TableFilterRow.Cell {...restProps} style={style} column={column}>
        <TextField
          label={Translator.trans('appModule.filter')}
          value={filterValue ? filterValue : ''}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <FilterClear show={filterValue} handleReset={handleReset} />
            ),
          }}
          variant="standard"
        />
      </TableFilterRow.Cell>
    );
  }

  return content;
};

export default FilterPlaceCell;
