import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectHelper from '../../../components/SelectHelperSessions/index';
import DatePickerComponent from '../../../components/DatePicker/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from 'react-select';
import {
  getPlacesListRequest,
  getProgramsListRequest,
  postSessionPlacesRequest,
  postSessionPerformersRequest,
  getSessionTracksListRequest,
  clearProgram,
} from '../../../Redux/actions';
import Translator from '../../../services/translator';
import { currentProject } from '../../../Global/currentProject';
import { MainTextField } from '@emisys/audience-sdk-ui-react';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import { getNameLanguage } from '../../../services/GetNameLanguage';

class SessionCreationByPerformerContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      locale: '',
      liveStartDateTimeUTC: new Date(),
      liveEndDateTimeUTC: new Date(new Date().getTime() + 60 * 60 * 1000),
      confirmed: true,
      highlighted: true,
      signedLanguages: true,
      track: null,
      timeToBeConfirmed: false,
      duration: '',
    };
    this.performers = [];
    this.places = [];
  }

  componentDidMount() {
    this.props.getProgramsListRequest(currentProject.id);
    this.props.getPlacesListRequest(currentProject.id);
    this.props.getSessionTracksListRequest(currentProject.id);
    this.setState({
      title: this.props.performer ? this.props.performer.name : '',
    });

    this.props.handleChangeSession(
      'liveStartDateTimeUTC',
      this.state.liveStartDateTimeUTC
    );
    this.props.handleChangeSession(
      'liveEndDateTimeUTC',
      this.state.liveEndDateTimeUTC
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newSession !== this.props.newSession) {
      if (checkReduxResponse(this.props.newSession, 'session')) {
        this.performers.push(this.props.performer.id);
        this.props.postSessionPerformersRequest(
          this.props.newSession.session.id,
          {
            performers: this.performers,
          }
        );

        if (this.places.length > 0) {
          this.props.postSessionPlacesRequest(
            this.props.newSession.session.id,
            {
              places: this.places,
            }
          );
        }

        this.props.sendTranslations(this.props.newSession.session.id);
        this.props.onClose();
        this.props.clearProgram();
      }
    }
  }

  handleChange = (name) => (event) => {
    this.props.handleChangeSession(name, event.target.value);
    this.setState({ [name]: event.target.value });
  };

  handleChangeSelect = (name, selectedOption) => {
    if (selectedOption) {
      this.props.handleChangeSession(name, selectedOption.value);
      this.setState({ [name]: selectedOption });
    } else {
      this.props.handleChangeSession(name, '');
      this.setState({ [name]: '' });
    }
  };

  handleChangePerformer = (performerList) => {
    this.props.handleChangeSession('performers', performerList);
    this.performers = performerList;
  };

  handleChangePlace = (placeList) => {
    this.props.handleChangeSession('places', placeList);
    this.places = placeList;
  };

  handleChangeLiveStartDateTime = (date) => {
    if (this.state.timeToBeConfirmed) {
      const newStartDateTime = new Date(date.setHours(0, 0, 0, 0));
      this.setState({
        liveStartDateTimeUTC: newStartDateTime,
        liveEndDateTimeUTC: newStartDateTime,
      });
      this.props.handleChangeSession('liveStartDateTimeUTC', newStartDateTime);
      this.props.handleChangeSession('liveEndDateTimeUTC', newStartDateTime);
    } else {
      this.props.handleChangeSession('liveStartDateTimeUTC', date);
      this.setState({ liveStartDateTimeUTC: date });
    }
  };

  handleChangeLiveEndDateTime = (date) => {
    this.props.handleChangeSession('liveEndDateTimeUTC', date);
    this.setState({ liveEndDateTimeUTC: date });
  };

  handleChangeIsConfirmDate = (e) => {
    this.props.handleChangeSession('timeToBeConfirmed', e.target.checked);
    this.setState({ timeToBeConfirmed: e.target.checked });

    let newStartDate = this.state.liveStartDateTimeUTC;
    let newEndDate = this.state.liveStartDateTimeUTC;

    if (e.target.checked) {
      this.setState({
        liveStartDateTimeUTC: new Date(newStartDate.setHours(0, 0, 0, 0)),
        liveEndDateTimeUTC: new Date(newEndDate.setHours(0, 0, 0, 0)),
      });
      this.handleChangeLiveStartDateTime(newStartDate);
      this.handleChangeLiveEndDateTime(newEndDate);
    } else {
      this.setState({
        liveStartDateTimeUTC: newStartDate,
        liveEndDateTimeUTC: newEndDate,
      });
    }
  };

  render() {
    const {
      title,
      liveStartDateTimeUTC,
      liveEndDateTimeUTC,
      locale,
      track,
      timeToBeConfirmed,
    } = this.state;
    const { sessionTracks } = this.props;

    return (
      <div className="col-12">
        <div className="row">
          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('cms.title')}</h4>
            </div>
            <div className="col-12 col-md-6 p-0">
              <MainTextField
                className="perfomerSessionTitle"
                value={title}
                onChange={this.handleChange('title')}
              />
            </div>
          </div>

          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('sessions.title.start')}</h4>
            </div>
            <div className="col-12 col-md-6 p-0">
              <div className="row">
                <div className="col-12 col-md-7">
                  <DatePickerComponent
                    selected={
                      liveStartDateTimeUTC ? liveStartDateTimeUTC : null
                    }
                    onChange={this.handleChangeLiveStartDateTime}
                    showTimeSelect
                  />
                </div>
                <div className="col-12 col-md-5">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="ConfirmedPerformerSess"
                        checked={timeToBeConfirmed}
                        value="secondary"
                        color="primary"
                        onChange={this.handleChangeIsConfirmDate}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label={Translator.trans(
                      'sessions.title.hour.confirm.label'
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          {!timeToBeConfirmed && (
            <div className="col-12 information">
              <div className="col-12 col-md-4 p-0">
                <h4>{Translator.trans('sessions.title.end')}</h4>
              </div>
              <div className="col-12 col-md-6 p-0">
                <div className="row">
                  <div className="col-12 col-md-7">
                    <DatePickerComponent
                      selected={liveEndDateTimeUTC ? liveEndDateTimeUTC : null}
                      onChange={this.handleChangeLiveEndDateTime}
                      showTimeSelect
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('sessions.title.language')}</h4>
            </div>
            <div className="col-12 col-md-6 p-0">
              <div className="row">
                <div className="col-12">
                  <Select
                    id="session-perf-locale"
                    fullWidth
                    isClearable
                    isLoading={!currentProject.allowedLanguages}
                    labelId="demo-simple-select-label"
                    value={locale ? locale : ''}
                    onChange={(text) => this.handleChangeSelect('locale', text)}
                    options={currentProject.allowedLanguages.map((language) => {
                      return {
                        value: language,
                        label: getNameLanguage(
                          currentProject.language.locale,
                          language
                        ),
                      };
                    })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('sessions.title.theme')}</h4>
            </div>
            <div className="col-12 col-md-6 p-0">
              <div className="row">
                <div className="col-12">
                  <Select
                    id="session-perf-track"
                    fullWidth
                    isClearable
                    labelId="demo-simple-select-label"
                    value={track ? track : ''}
                    onChange={(text) => this.handleChangeSelect('track', text)}
                    options={
                      sessionTracks &&
                      sessionTracks.sessionTracks &&
                      sessionTracks.sessionTracks !== ''
                        ? sessionTracks.sessionTracks.map((data) => {
                            return {
                              value: data.id,
                              label: data.name,
                            };
                          })
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('stepper.performer.more.performers')}</h4>
            </div>
            <div className="col-12 col-md-6 p-0">
              <SelectHelper
                creatable={false}
                items={this.performers}
                listItems={this.props.performersList?.performers}
                hideItem={this.props.performer.id}
                updateItem={this.handleChangePerformer}
                labelSelect={'name'}
              />
            </div>
          </div>

          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('places.title')}</h4>
            </div>
            <div className="col-12 col-md-6 p-0">
              <SelectHelper
                id="session-perf-place"
                creatable={false}
                listItems={this.props.placesList?.places}
                updateItem={this.handleChangePlace}
                labelSelect={'name'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  placesList: state.places.placesList,
  performersList: state.performers.performersList,
  sessionTracks: state.sessionTracks.sessionTracksList,
  newSession: state.programs.newSession,
});

export default connect(mapStateToProps, {
  postSessionPerformersRequest,
  postSessionPlacesRequest,
  getPlacesListRequest,
  getSessionTracksListRequest,
  getProgramsListRequest,
  clearProgram,
})(SessionCreationByPerformerContent);
