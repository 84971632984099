import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Translator from '../../../services/translator';
import PerformersTranslations from '../PerformerContent/PerformersTranslations';
import { useNavigate } from 'react-router-dom';
import {
  UnderlineTitle,
  MainTextField,
  SwitchComponent,
} from '@emisys/audience-sdk-ui-react';
import {
  putPerformerRequest,
  putPerformerTranslationsRequest,
  postUploadPictureRequest,
  clearUploadPicture,
  clearPerformer,
} from '../../../Redux/actions';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { currentProject } from '../../../Global/currentProject';
import { pagesConstant } from '../../../Constants/Constant';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import PerformerSocials from '../PerformerContent/PerformerSocials';
import { storeType } from '../../../index';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import PerformerModel from '../../../Models/PerformerModel';
import '../index.css';

const PerformerEditContent = (props: { performer: PerformerModel | null }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [headline, setHeadline] = useState<1 | null>(null);
  const [loadingSend, setLoadingSend] = useState(false);
  const [translationPerformer, setTranslationPerformer] = useState<any[]>([]);

  const translationPerformerList = useSelector(
    (state: storeType) => state.performers.translationPerformerList
  );
  const newUploadPicture = useSelector(
    (state: storeType) => state.uploads.newUploadPicture
  );
  const updatedPerformer = useSelector(
    (state: storeType) => state.performers.updatedPerformer
  );

  const picture: any = useRef(undefined);
  const performer: any = useRef({});

  useEffect(() => {
    if (props.performer) {
      handleChangeName(props.performer.name);
      handleChangeHeadline(props.performer.headline);

      performer.current = props.performer;
    }
  }, [props.performer]);

  useEffect(() => {
    if (translationPerformerList) {
      if (checkReduxResponse(translationPerformerList, 'performerList')) {
        let getTranslations: any[] = [];
        currentProject.allowedLanguages.forEach((language) => {
          const currentTranslation =
            translationPerformerList.performerList.find(
              (trans: any) => trans.locale === language
            );

          if (currentTranslation) {
            getTranslations.push(currentTranslation);
          } else {
            getTranslations.push({
              locale: language,
              biography: '',
            });
          }
        });

        setTranslationPerformer(getTranslations);
      }
    }
  }, [translationPerformerList]);

  useEffect(() => {
    if (updatedPerformer) {
      setLoadingSend(updatedPerformer.loading);
      if (checkReduxResponse(updatedPerformer, 'performer')) {
        dispatch(
          putPerformerTranslationsRequest(updatedPerformer.performer.id, {
            translations: translationPerformer,
          })
        );
        NotificationManager.success(
          Translator.trans('performers.message.success.updated')
        );
        dispatch(clearPerformer());
        navigate(pagesConstant.performers.home);
      }
    }
  }, [dispatch, navigate, translationPerformer, updatedPerformer]);

  useEffect(() => {
    if (newUploadPicture) {
      if (checkReduxResponse(newUploadPicture, 'uploadPicture')) {
        performer.current.picture = newUploadPicture.uploadPicture.id;
        if (props.performer?.id) {
          dispatch(putPerformerRequest(props.performer.id, performer.current));
        }
        dispatch(clearUploadPicture());
      }
    }
  }, [dispatch, props.performer?.id, newUploadPicture]);

  const handleChangeName = (value: string) => {
    performer.current.name = value;
    setName(value);
  };

  const handleChangePerformer = (
    name: string,
    value: string | number | null
  ) => {
    performer.current[name] = value;
  };

  const handleChangeHeadline = (isChecked: boolean) => {
    if (isChecked) {
      performer.current.headline = 1;
      setHeadline(1);
    } else {
      performer.current.headline = null;
      setHeadline(null);
    }
  };

  const handleChangeTranslation = (
    name: string,
    value: string,
    locale: string
  ) => {
    const oldTranslation = [...translationPerformer];

    const objIndex = oldTranslation.findIndex(
      (item: any) => item.locale === locale
    );
    if (objIndex > -1) {
      oldTranslation[objIndex][name] = value;

      setTranslationPerformer(oldTranslation);
    }
  };

  const handleChangePicture = (value: string | null) => {
    picture.current = value;
  };

  const handleSubmit = () => {
    if (!name) {
      NotificationManager.warning(Translator.trans('message.title.missing'));
    } else {
      if (picture.current) {
        dispatch(
          postUploadPictureRequest({
            type: 'PictureArtist',
            project_id: currentProject.id,
            data: picture.current,
          })
        );
      } else {
        if (picture.current === null) {
          performer.current.picture = null;
        } else {
          delete performer.current.picture;
        }

        if (props.performer?.id) {
          dispatch(putPerformerRequest(props.performer.id, performer.current));
        }
      }
    }
  };

  return (
    <>
      <div className={'row mb-3'}>
        <div className="col-4">
          <PerformerSocials
            performer={props.performer}
            handleChangePicture={handleChangePicture}
            handleChangePerformer={handleChangePerformer}
          />
        </div>

        <div className="col-8">
          <UnderlineTitle title={Translator.trans('appModule.general.info')} />
          <div className="row">
            <div className="col-12 information">
              <div className="col-12 col-md-4 p-0">
                <h4>{Translator.trans('performers.title.name')}</h4>
              </div>
              <div className="col-12 col-md-5 p-0">
                <MainTextField
                  value={name}
                  onChange={(event) => handleChangeName(event.target.value)}
                />
              </div>
            </div>

            <div className="col-12 information">
              <div className="col-12 col-md-4 p-0">
                <h4>{Translator.trans('performers.title.headline')}</h4>
              </div>
              <div className="col-12 col-md-5 p-0">
                <SwitchComponent
                  onHandleChange={handleChangeHeadline}
                  checked={headline === 1}
                />
              </div>
            </div>

            <div className="col-12 mt-4">
              <UnderlineTitle
                title={Translator.trans('performers.title.biography')}
              />
              {currentProject.allowedLanguages.map((language) => {
                let translation = translationPerformer.find(
                  (element: any) => element.locale === language
                );
                return (
                  <AccordionTranslation key={language} language={language}>
                    <PerformersTranslations
                      translation={translation}
                      handleChangeTranslation={handleChangeTranslation}
                      language={language}
                    />
                  </AccordionTranslation>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <ButtonsSaveModal
        isLoading={loadingSend}
        onClose={() => navigate(pagesConstant.performers.home)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default PerformerEditContent;
