import React, { Component } from 'react';
import { arrayMoveImmutable } from 'array-move';
import { connect } from 'react-redux';
import {
  clearPlaces,
  getPlacesListRequest,
  putPlacesPositionRequest,
} from '../../../Redux/actions';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import Translator from '../../../services/translator';
import { Loading } from '@emisys/audience-sdk-ui-react';
import { currentProject } from '../../../Global/currentProject';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  hideSortingButtons,
  rowPerTablePage,
} from '../../../components/Table/Constants/Constant';
import SortableTable from '../../../components/Table/Sortable/SortableTable/SortableTable';
import SortableRow from '../../../components/Table/Sortable/SortableRow/SortableRow';
import TableContainer from '../../../components/Table/TableContainer/TableContainer';
import NoData from '../../../components/Table/NoData/NoData';
import PagingTable from '../../../components/Table/Paging/PagingTable';
import FilterPlaceCell from '../../../components/Table/Cells/Places/FilterPlaceCell';
import ColumnCell from '../../../components/Table/Columns/ColumnCell';
import SortLabel from '../../../components/Table/Sorting/SortLabel';
import { sortingColumnExtensions } from '../../../components/Table/Sorting/SortingExtensions';
import { filteringColumnExtensions } from '../../../components/Table/Filters/FilterExtensions';
import {
  columnsPlaces,
  tableColumnExtensionsPlaces,
} from '../../../components/Table/Columns/ShowColumns';
import PlacesCell from '../../../components/Table/Cells/Places/PlacesCell';

class PlacesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      filters: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.props.getPlacesListRequest(currentProject.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.placesList !== prevProps.placesList) {
      this.setState({ loading: this.props.placesList.loading });

      if (checkReduxResponse(this.props.placesList, 'places')) {
        let newRows = this.props.placesList.places.map((place) => ({
          id: place.id,
          name: place.name,
          place: place,
        }));
        this.setState({ rows: newRows });
      }
    }

    if (this.props.newPlace !== prevProps.newPlace) {
      if (checkReduxResponse(this.props.newPlace, 'place')) {
        NotificationManager.success(
          Translator.trans('places.message.success.added')
        );
        this.props.clearPlaces();
      }
    }

    if (this.props.oldPlace !== prevProps.oldPlace) {
      if (checkReduxResponse(this.props.oldPlace, 'place')) {
        this.props.getPlacesListRequest(currentProject.id);
        NotificationManager.success(
          Translator.trans('places.message.success.deleted')
        );
        this.props.clearPlaces();
      }
    }

    if (this.props.translationPlace !== prevProps.translationPlace) {
      if (checkReduxResponse(this.props.translationPlace, 'place')) {
        this.props.getPlacesListRequest(currentProject.id);
      }
    }

    if (
      this.props.updatedTranslationPlace !== prevProps.updatedTranslationPlace
    ) {
      if (checkReduxResponse(this.props.updatedTranslationPlace, 'place')) {
        this.props.getPlacesListRequest(currentProject.id);
        NotificationManager.success(
          Translator.trans('places.message.success.updated')
        );
        this.props.clearPlaces();
      }
    }

    if (this.props.updatedPlacePosition !== prevProps.updatedPlacePosition) {
      if (checkReduxResponse(this.props.updatedPlacePosition, 'place')) {
        this.props.getPlacesListRequest(currentProject.id);
        NotificationManager.success(
          Translator.trans('cms.message.success.position.updated')
        );
        this.props.clearPlaces();
      }
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let array = arrayMoveImmutable(this.state.rows, oldIndex, newIndex);
      this.setState({ rows: array }, () => {
        this.props.putPlacesPositionRequest({
          id: array[newIndex].id,
          position: newIndex,
        });
      });
    }
  };

  setCurrentFilters(filter) {
    let newFilter = filter.filter(
      (data) => !(data.value === '' || data.value === 'all')
    );
    this.setState({ filters: newFilter });
  }

  render() {
    const { rows, filters, loading } = this.state;
    return (
      <div>
        <Grid rows={rows} columns={columnsPlaces}>
          <PagingState />
          <FilteringState
            onFiltersChange={this.setCurrentFilters}
            filters={filters}
          />
          <SortingState columnExtensions={hideSortingButtons} />
          <IntegratedSorting columnExtensions={sortingColumnExtensions} />
          <IntegratedFiltering columnExtensions={filteringColumnExtensions} />
          <IntegratedPaging />
          <Table
            columnExtensions={tableColumnExtensionsPlaces}
            bodyComponent={(props) => (
              <SortableTable props={props} onSortEnd={this.onSortEnd} />
            )}
            rowComponent={(props) => <SortableRow props={props} rows={rows} />}
            cellComponent={PlacesCell}
            tableComponent={TableContainer}
            noDataCellComponent={NoData}
          />
          <TableHeaderRow
            showSortingControls
            sortLabelComponent={SortLabel}
            cellComponent={ColumnCell}
          />
          <TableFilterRow cellComponent={FilterPlaceCell} />
          <PagingPanel
            pageSizes={rowPerTablePage}
            containerComponent={PagingTable}
          />
        </Grid>
        {loading && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  placesList: state.places.placesList,
  oldPlace: state.places.oldPlace,
  translationPlace: state.places.translationPlace,
  updatedTranslationPlace: state.places.updatedTranslationPlace,
  updatedPlacePosition: state.places.updatedPlacePosition,
  newPlace: state.places.newPlace,
});

export default connect(mapStateToProps, {
  getPlacesListRequest,
  putPlacesPositionRequest,
  clearPlaces,
})(PlacesTable);
