const toLowerCase = (value: string) => String(value).toLowerCase();

const comparePriority = (a: string, b: string) => {
  const priorityA = toLowerCase(a);
  const priorityB = toLowerCase(b);
  if (priorityA === priorityB) {
    return 0;
  }
  return priorityA < priorityB ? -1 : 1;
};

export const sortingColumnExtensions = [
  { columnName: 'name', compare: comparePriority },
];
