import React from 'react';
import Translator from '../../services/translator';
import './EmptyTable.css';

const EmptyTable = () => (
  <div className={'empty-table-container'}>
    {Translator.trans('cms.no.data')}
  </div>
);

export default EmptyTable;
