import { notificationsConstants } from '../constants';
import NotificationModel from '../../Models/NotificationModel';
import ErrorModel from '../../Models/ErrorModel';

const initialState: any = {
  notificationsList: null,
  newNotification: null,
};

let listNotifications: NotificationModel[] = [];
const notifications = (state = initialState, action: any) => {
  switch (action.type) {
    case notificationsConstants.GET_NOTIFICATIONS_LIST_REQUEST: {
      return {
        ...state,
        notificationsList: { notifications: null, error: false, loading: true },
      };
    }

    case notificationsConstants.GET_NOTIFICATIONS_LIST_SUCCESS: {
      listNotifications = action.notifications.data.map(
        (notification: any) => new NotificationModel(notification)
      );

      return {
        ...state,
        notificationsList: {
          notifications: listNotifications,
          error: false,
          loading: false,
        },
      };
    }

    case notificationsConstants.GET_NOTIFICATIONS_LIST_FAILURE: {
      return {
        ...state,
        notificationsList: { notifications: null, error: true, loading: false },
      };
    }

    case notificationsConstants.POST_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        newNotification: { notification: null, error: false, loading: true },
      };
    }

    case notificationsConstants.POST_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        newNotification: {
          notification: action.newNotification.data,
          error: false,
          loading: false,
        },
      };
    }

    case notificationsConstants.POST_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        newNotification: {
          notification: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case notificationsConstants.CLEAR_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        newNotification: null,
      };
    }

    default:
      return state;
  }
};

export default notifications;
