import React from 'react';
import { currentProject } from '../../Global/currentProject';
import { getNameLanguage } from '../../services/GetNameLanguage';
import { AccordionContainer } from '@emisys/audience-sdk-ui-react';
import Translator from '../../services/translator';

interface AccordionTranslationProps {
  language: string;
  children: React.ReactNode;
}

const AccordionTranslation = (props: AccordionTranslationProps) => (
  <AccordionContainer
    isExpand={props.language === currentProject.defaultLanguage}
    title={getNameLanguage(currentProject.language.locale, props.language)}
    showOptionalOrNot
    showCollapse={currentProject.allowedLanguages.length > 1}
    optionalLabel={Translator.trans('languages.optional')}
    requiredLabel={Translator.trans('languages.compulsory')}
  >
    {props.children}
  </AccordionContainer>
);

export default AccordionTranslation;
