export const placesConstants = {
  GET_PLACES_LIST_REQUEST: 'GET_PLACES_LIST_REQUEST',
  GET_PLACES_LIST_SUCCESS: 'GET_PLACES_LIST_SUCCESS',
  GET_PLACES_LIST_FAILURE: 'GET_PLACES_LIST_FAILURE',

  POST_PLACES_REQUEST: 'POST_PLACES_REQUEST',
  POST_PLACES_SUCCESS: 'POST_PLACES_SUCCESS',
  POST_PLACES_FAILURE: 'POST_PLACES_FAILURE',

  PUT_PLACES_REQUEST: 'PUT_PLACES_REQUEST',
  PUT_PLACES_SUCCESS: 'PUT_PLACES_SUCCESS',
  PUT_PLACES_FAILURE: 'PUT_PLACES_FAILURE',

  GET_PLACE_REQUEST: 'GET_PLACE_REQUEST',
  GET_PLACE_SUCCESS: 'GET_PLACE_SUCCESS',
  GET_PLACE_FAILURE: 'GET_PLACE_FAILURE',

  PUT_PLACES_POSITION_REQUEST: 'PUT_PLACES_POSITION_REQUEST',
  PUT_PLACES_POSITION_SUCCESS: 'PUT_PLACES_POSITION_SUCCESS',
  PUT_PLACES_POSITION_FAILURE: 'PUT_PLACES_POSITION_FAILURE',

  GET_PLACES_TRANSLATION_REQUEST: 'GET_PLACES_TRANSLATION_REQUEST',
  GET_PLACES_TRANSLATION_SUCCESS: 'GET_PLACES_TRANSLATION_SUCCESS',
  GET_PLACES_TRANSLATION_FAILURE: 'GET_PLACES_TRANSLATION_FAILURE',

  POST_PLACES_TRANSLATION_REQUEST: 'POST_PLACES_TRANSLATION_REQUEST',
  POST_PLACES_TRANSLATION_SUCCESS: 'POST_PLACES_TRANSLATION_SUCCESS',
  POST_PLACES_TRANSLATION_FAILURE: 'POST_PLACES_TRANSLATION_FAILURE',

  PUT_PLACES_TRANSLATION_REQUEST: 'PUT_PLACES_TRANSLATION_REQUEST',
  PUT_PLACES_TRANSLATION_SUCCESS: 'PUT_PLACES_TRANSLATION_SUCCESS',
  PUT_PLACES_TRANSLATION_FAILURE: 'PUT_PLACES_TRANSLATION_FAILURE',

  DELETE_PLACES_REQUEST: 'DELETE_PLACES_REQUEST',
  DELETE_PLACES_SUCCESS: 'DELETE_PLACES_SUCCESS',
  DELETE_PLACES_FAILURE: 'DELETE_PLACES_FAILURE',

  CLEAR_PLACES: 'CLEAR_PLACES',
};
