import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Translator from '../../services/translator';
import { Chip } from '@mui/material';
import './ActiveChip.css';

interface ActiveChipInterface {
  isActive?: boolean;
}

const ActiveChip = (props: ActiveChipInterface) => (
  <Chip
    size="small"
    icon={
      <FiberManualRecordIcon
        className={`activeIcon ${props.isActive && 'active'}`}
      />
    }
    label={
      props.isActive
        ? Translator.trans('appModule.active')
        : Translator.trans('appModule.inactive')
    }
  />
);

export default ActiveChip;
