import React, { useEffect, useState } from 'react';
import { HTMLEditor } from '@emisys/audience-sdk-ui-react';

const PerformersTranslations = (props: {
  translation: any; // add type property
  handleChangeTranslation: (
    name: string,
    newValue: string,
    language: string
  ) => void;
  language: string;
}) => {
  const [biography, setBiography] = useState('');

  useEffect(() => {
    if (props.translation?.biography) {
      setBiography(props.translation.biography);
    }
  }, [props.translation?.biography]);

  const handleChangeBiography = (value: string) => {
    setBiography(value);
    props.handleChangeTranslation('biography', value, props.language);
  };

  return <HTMLEditor onChange={handleChangeBiography} value={biography} />;
};

export default PerformersTranslations;
