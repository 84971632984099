import React, { useState } from 'react';
import ModalNotificationCreation from './NotificationsModal/NotificationsModalCreation';
import NotificationsTable from './NotificationsTable/NotificationsTable';
import Translator from '../../services/translator';
import { CreateButton, HeaderBanner } from '@emisys/audience-sdk-ui-react';
import { NotificationsActive } from '@mui/icons-material';

const Notifications = () => {
  const [openModalNotifications, setOpenModalNotifications] = useState(false);

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('notifications.title')}
          icon={<NotificationsActive />}
        />
        <CreateButton onClick={() => setOpenModalNotifications(true)}>
          {Translator.trans('notifications.button.add.title')}
        </CreateButton>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <NotificationsTable />
          </div>
        </div>
        <ModalNotificationCreation
          open={openModalNotifications}
          close={() => setOpenModalNotifications(false)}
        />
      </div>
    </div>
  );
};

export default Notifications;
