import React, { Component } from 'react';
import AddSelect from './SelectHelperComponents/AddSelect';

class SelectHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
    };
  }

  componentDidMount() {
    if (this.props.items) {
      this.setState({ selectedItems: this.props.items });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.setState({ selectedItems: this.props.items });
    }
  }

  handleSelectedItems = (valueList) => {
    let array = [];

    valueList &&
      valueList.forEach((element) => {
        array.push(element.value);
      });

    this.setState({ selectedItems: array }, () => {
      this.handleChange(this.state.selectedItems);
    });
  };

  handleChange = (selectedItems) => {
    this.props.updateItem(selectedItems);
  };

  render() {
    return (
      <AddSelect
        id={this.props.id}
        creatable={this.props.creatable}
        onClickAction={(text) => this.props.onClickAction(text)}
        listItemsSelected={this.state.selectedItems}
        handleSelectedItems={this.handleSelectedItems}
        listItems={this.props.listItems ? this.props.listItems : []}
        hideItem={this.props.hideItem}
        labelSelect={this.props.labelSelect}
        isLoading={this.props.isLoading}
      />
    );
  }
}

export default SelectHelper;
