import ImageModel, { createImageModel } from './ImageModel';

export default class AppMobileModel {
  id: number;
  appMobileFilterDays: boolean;
  appMobileFilterStage: boolean;

  appMobileUrlScheme: string;
  appMobileUrlTicketing: string;
  appMobileUrlWebapp: string;

  appMobileShowArtist: boolean;
  appMobileShowCashless: boolean;
  appMobileShowCountdown: boolean;
  appMobileShowETicket: boolean;
  appMobileShowInfo: boolean;
  appMobileShowLineup: boolean;
  appMobileShowNews: boolean;
  appMobileShowTicketing: boolean;
  appMobileShowPos: boolean;

  countdownDatetime: Date | null;
  emergencyLabel: string;
  emergencyPhonenumber: string;

  colorBackground: string;
  colorBackgroundLight: string;
  colorPrimary: string;
  colorPrimaryDark: string;
  colorSecondary: string;

  pictureBackground: ImageModel | null;
  pictureLogo: ImageModel | null;
  pictureMap: ImageModel | null;
  picturePartners: ImageModel | null;

  constructor(data: any) {
    this.id = data.id;
    this.appMobileFilterDays = data.appMobileFilterDays;
    this.appMobileFilterStage = data.appMobileFilterStage;
    this.appMobileShowArtist = data.appMobileShowArtist;
    this.appMobileShowCashless = data.appMobileShowCashless;
    this.appMobileShowCountdown = data.appMobileShowCountdown;
    this.appMobileShowETicket = data.appMobileShowETicket;
    this.appMobileShowInfo = data.appMobileShowInfo;
    this.appMobileShowLineup = data.appMobileShowLineup;
    this.appMobileShowNews = data.appMobileShowNews;
    this.appMobileShowTicketing = data.appMobileShowTicketing;
    this.appMobileShowPos = data.appMobileShowPos;
    this.appMobileUrlScheme = data.appMobileUrlScheme || '';
    this.appMobileUrlTicketing = data.appMobileUrlTicketing || '';
    this.appMobileUrlWebapp = data.appMobileUrlWebapp || '';
    this.countdownDatetime = data.countdownDatetimeUTC
      ? new Date(data.countdownDatetimeUTC)
      : null;
    this.emergencyLabel = data.emergencyLabel || '';
    this.emergencyPhonenumber = data.emergencyPhonenumber || '';
    this.colorBackground = data.colorBackground;
    this.colorBackgroundLight = data.colorBackgroundLight;
    this.colorPrimary = data.colorPrimary;
    this.colorPrimaryDark = data.colorPrimaryDark;
    this.colorSecondary = data.colorSecondary;

    this.pictureBackground = data.pictureBackground
      ? createImageModel(data.pictureBackground)
      : null;
    this.pictureLogo = data.pictureLogo
      ? createImageModel(data.pictureLogo)
      : null;
    this.pictureMap = data.pictureMap
      ? createImageModel(data.pictureMap)
      : null;
    this.picturePartners = data.picturePartners
      ? createImageModel(data.picturePartners)
      : null;
  }
}
