import { uploadsConstants } from '../constants';

export const postUploadPictureRequest = (picture: any) => ({
  type: uploadsConstants.POST_UPLOAD_PICTURE_REQUEST,
  picture,
});

export const postUploadPictureSuccess = (newUploadPicture: any) => ({
  type: uploadsConstants.POST_UPLOAD_PICTURE_SUCCESS,
  newUploadPicture,
});

export const postUploadPictureFailure = (error: any) => ({
  type: uploadsConstants.POST_UPLOAD_PICTURE_FAILURE,
  error,
});

export const clearUploadPicture = () => ({
  type: uploadsConstants.CLEAR_PICTURE,
});
