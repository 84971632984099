import SessionModel from './SessionModel';
import PerformerModel from './PerformerModel';

export default class SessionPerformerModel {
  id: number;
  session: SessionModel | null;
  performer: PerformerModel | null;

  constructor(data: any) {
    this.id = data.id;
    this.session = data.session ? new SessionModel(data.session) : null;
    this.performer = data.performer ? new PerformerModel(data.performer) : null;
  }
}
