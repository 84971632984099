export default class SessionTrackModel {
  id: number;
  color: null | string;
  external_id: null | string;
  name: string;

  constructor(data: any) {
    this.id = data.id;
    this.color = data.color;
    this.external_id = data.external_id;
    this.name = data.name;
  }
}
