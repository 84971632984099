export default class ArticleModel {
  id: number;
  content: string;
  externalLinkLabel: string;
  externalLinkUrl: string;
  external_id: null | string;
  isActive: boolean;
  isIconMode: boolean;
  locale: string;
  position: number;
  publishDate: Date | null;
  startDate: Date | null;
  stopDate: Date | null;
  teaser: string;
  title: string;

  constructor(data: any) {
    this.id = data.id;
    this.content = data.content;
    this.externalLinkLabel = data.externalLinkLabel;
    this.externalLinkUrl = data.externalLinkUrl;
    this.external_id = data.external_id;
    this.isActive = data.isActive;
    this.isIconMode = data.isIconMode;
    this.locale = data.locale;
    this.position = data.position;
    this.publishDate = data.publishDate ? new Date(data.publishDate) : null;
    this.startDate = data.startDate ? new Date(data.startDate) : null;
    this.stopDate = data.stopDate ? new Date(data.stopDate) : null;
    this.teaser = data.teaser;
    this.title = data.title;
  }
}
