export const sessionTracksConstants = {
  GET_SESSION_TRACKS_REQUEST: 'GET_SESSION_TRACKS_REQUEST',
  GET_SESSION_TRACKS_SUCCESS: 'GET_SESSION_TRACKS_SUCCESS',
  GET_SESSION_TRACKS_FAILURE: 'GET_SESSION_TRACKS_FAILURE',

  POST_SESSION_TRACKS_REQUEST: 'POST_SESSION_TRACKS_REQUEST',
  POST_SESSION_TRACKS_SUCCESS: 'POST_SESSION_TRACKS_SUCCESS',
  POST_SESSION_TRACKS_FAILURE: 'POST_SESSION_TRACKS_FAILURE',

  DELETE_SESSION_TRACKS_REQUEST: 'DELETE_SESSION_TRACKS_REQUEST',
  DELETE_SESSION_TRACKS_SUCCESS: 'DELETE_SESSION_TRACKS_SUCCESS',
  DELETE_SESSION_TRACKS_FAILURE: 'DELETE_SESSION_TRACKS_FAILURE',

  GET_SESSION_TRACKS_TRANSLATION_REQUEST:
    'GET_SESSION_TRACKS_TRANSLATION_REQUEST',
  GET_SESSION_TRACKS_TRANSLATION_SUCCESS:
    'GET_SESSION_TRACKS_TRANSLATION_SUCCESS',
  GET_SESSION_TRACKS_TRANSLATION_FAILURE:
    'GET_SESSION_TRACKS_TRANSLATION_FAILURE',

  POST_SESSION_TRACKS_TRANSLATION_REQUEST:
    'POST_SESSION_TRACKS_TRANSLATION_REQUEST',
  POST_SESSION_TRACKS_TRANSLATION_SUCCESS:
    'POST_SESSION_TRACKS_TRANSLATION_SUCCESS',
  POST_SESSION_TRACKS_TRANSLATION_FAILURE:
    'POST_SESSION_TRACKS_TRANSLATION_FAILURE',

  PUT_SESSION_TRACKS_TRANSLATION_REQUEST:
    'PUT_SESSION_TRACKS_TRANSLATION_REQUEST',
  PUT_SESSION_TRACKS_TRANSLATION_SUCCESS:
    'PUT_SESSION_TRACKS_TRANSLATION_SUCCESS',
  PUT_SESSION_TRACKS_TRANSLATION_FAILURE:
    'PUT_SESSION_TRACKS_TRANSLATION_FAILURE',

  CLEAR_SESSION_TRACK: 'CLEAR_SESSION_TRACK',
};
