import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Translator from './../../services/translator';
import { Modal, ActionButton } from '@emisys/audience-sdk-ui-react';
import ContentNewsModalVisualize from '../../app/News/NewsModal/ContentNewsModalVisualize';
import { deleteContentNewsRequest } from '../../Redux/actions';
import { currentProject } from '../../Global/currentProject';
import { pagesConstant } from '../../Constants/Constant';
import DeleteModal from '../DeleteModal/DeleteModal';
import NewsModel from '../../Models/NewsModel';

const ButtonsNews = (props: { news: NewsModel }) => {
  const dispatch = useDispatch();
  const [openModalVisualize, setOpenModalVisualize] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const handleRequestDelete = () => {
    setShowModalDelete(false);
    dispatch(deleteContentNewsRequest(props.news.id));
  };

  return (
    <div className={'action-container-buttons'}>
      <ActionButton
        className={'viewNews'}
        icon={'view'}
        onClick={() => setOpenModalVisualize(true)}
      />

      <NavLink to={pagesConstant.news.edit + props.news.id}>
        <ActionButton icon={'edit'} className={'editNews'} />
      </NavLink>

      <ActionButton
        icon={'delete'}
        onClick={() => setShowModalDelete(true)}
        className={'deleteNews'}
      />

      <Modal
        open={openModalVisualize}
        title={Translator.trans('news.visualize.title')}
        close={() => setOpenModalVisualize(false)}
        width={'md'}
      >
        <ContentNewsModalVisualize
          news={props.news}
          locale={currentProject.language.locale}
          timezone={currentProject.timezone}
        />
      </Modal>

      <DeleteModal
        isOpen={showModalDelete}
        onConfirm={handleRequestDelete}
        onCancel={() => {
          setShowModalDelete(false);
        }}
      />
    </div>
  );
};

export default ButtonsNews;
