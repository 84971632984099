import React, { useState } from 'react';
import PlacesTable from '../Places/PlacesTable/PlacesTable';
import ModalPlacesCreation from './PlacesCreation/PlacesModalCreation';
import Translator from '../../services/translator';
import { CreateButton, HeaderBanner } from '@emisys/audience-sdk-ui-react';
import PlaceIcon from '@mui/icons-material/Place';

const Places = () => {
  const [openModalPlaces, setOpenModalPlaces] = useState(false);

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('places.title')}
          icon={<PlaceIcon />}
        />
        <CreateButton onClick={() => setOpenModalPlaces(true)}>
          {Translator.trans('places.add.title')}
        </CreateButton>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <PlacesTable />
          </div>
        </div>
      </div>

      <ModalPlacesCreation
        open={openModalPlaces}
        close={() => setOpenModalPlaces(false)}
      />
    </div>
  );
};

export default Places;
