import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import audience from '../../assets/images/audience/Audience.png';
import Translator from '../../services/translator';
import { pagesConstant } from '../../Constants/Constant';
import './SidenavContent.css';

const SidenavContent = () => {
  const location = useLocation();

  useEffect(() => {
    const menuLi: any = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function () {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = closest(this, 'li');
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains('open'))
          ) {
            menuLi[j].classList.remove('open');
            menuLi[j].classList.remove('closed');
          }
        }
      };
    }
  }, []);

  useEffect(() => {
    const pathname = location.pathname; // get current path
    const activeLi = document.querySelector(`a[href="${pathname}"]`); // select current a element

    try {
      const activeNav = closest(activeLi, 'ul'); // select closest ul
      if (activeNav) {
        if (activeNav.classList.contains('sub-menu')) {
          closest(activeNav, 'li').classList.add('open');
        } else {
          closest(activeLi, 'li').classList.add('open');
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [location.pathname]);

  function closest(el: any, selector: any) {
    try {
      let matchesFn: any;
      // find vendor prefix
      [
        'matches',
        'webkitMatchesSelector',
        'mozMatchesSelector',
        'msMatchesSelector',
        'oMatchesSelector',
      ].some((fn: any) => {
        // @ts-ignore
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  const clickSideBar = (event: any) => {
    const element = event.target.closest('.menu');
    if (element.classList.contains('open')) {
      if (element.classList.contains('closed')) {
        element.classList.remove('closed');
      } else {
        element.classList.add('closed');
      }
    } else {
      element.classList.add('open');
    }
  };

  return (
    <div className={'nav-menu-container'}>
      <ul className="nav-menu">
        <div className={'menu-list'}>
          {/* DASHBOARD */}
          <li className="menu">
            <Button className="title-sidebar" onClick={clickSideBar}>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
                {Translator.trans('sidenav.title.dashboard')}
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to={pagesConstant.lineUp}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.dashboard.lineup')}
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* CONTENT */}
          <li className="menu">
            <Button className="title-sidebar" onClick={clickSideBar}>
              <i className="zmdi zmdi-assignment zmdi-hc-fw" />
              <span className="nav-text">
                {Translator.trans('sidenav.title.content')}
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to={pagesConstant.news.home}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.news')}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to={pagesConstant.article.home}
                >
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.articles')}
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* LINE UPS */}
          <li className="menu">
            <Button className="title-sidebar" onClick={clickSideBar}>
              <i className="zmdi zmdi-time zmdi-hc-fw" />
              <span className="nav-text">
                {Translator.trans('sidenav.title.lineup')}
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  to={pagesConstant.performers.home}
                >
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.performers')}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to={pagesConstant.sessions.home}
                >
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.sessions')}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to={pagesConstant.places}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.places')}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to={pagesConstant.sessiontracks}
                >
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.sessiontracks')}
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* NOTIFICATION */}
          <li className="menu">
            <Button className="title-sidebar" onClick={clickSideBar}>
              <i className="zmdi zmdi-notifications-active zmdi-hc-fw" />
              <span className="nav-text">
                {Translator.trans('sidenav.title.notifications')}
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  to={pagesConstant.notifications}
                >
                  <span className="nav-text">
                    {Translator.trans('sidenav.subtitle.notifications')}
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* CONFIG */}
          <li className="menu">
            <Button className="title-sidebar" onClick={clickSideBar}>
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">
                {Translator.trans('sidenav.title.config')}
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink to={pagesConstant.events.current}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.event')}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to={pagesConstant.config}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.mobile.app')}
                  </span>
                </NavLink>
              </li>
              {/*<li>*/}
              {/*  <NavLink className="prepend-icon" to={pagesConstant.branding}>*/}
              {/*    <span className="nav-text">*/}
              {/*      {Translator.trans('sidenav.title.branding')}*/}
              {/*    </span>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
            </ul>
          </li>

          {/* ORGANIZATION */}
          <li className="menu">
            <Button className="title-sidebar" onClick={clickSideBar}>
              <i className="zmdi zmdi-store"></i>
              <span className="nav-text">
                {Translator.trans('sidenav.title.organization')}
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink to={pagesConstant.events.list}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.all.events')}
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>
        </div>

        <div className="footer-power-by">
          <span>powered by</span>
          <img src={audience} alt={'audience'} />
        </div>
      </ul>
    </div>
  );
};

export default SidenavContent;
