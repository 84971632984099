import React, { useEffect, useState } from 'react';
import Translator from '../../../services/translator';
import { MainTextField } from '@emisys/audience-sdk-ui-react';
import { capitalizeFirstLetter } from '../../../services/capitalizeFirstLetter';

const SessionTrackEditionTranslations = (props: {
  translation: any; // add type property
  language: string;
  handleChangeTranslation: (
    name: string,
    value: string,
    language: string
  ) => void;
}) => {
  const [name, setName] = useState('');

  useEffect(() => {
    if (props.translation?.name) {
      setName(props.translation.name);
    }
  }, [props.translation.name]);

  const handleChangeTranslation = (name: string) => (event: any) => {
    const newValue = capitalizeFirstLetter(event.target.value);
    setName(newValue);
    props.handleChangeTranslation(name, newValue, props.language);
  };

  return (
    <div>
      <div className="row mt-2">
        <div className="col-12 col-md-4 textFieldLabel mt-3">
          <h4>{Translator.trans('cms.title')}</h4>
        </div>
        <div className="col-12 col-md-8 mt-4">
          <div className="form-group text-field-stepper">
            <MainTextField
              className="TitleSessionTracksEdit"
              value={name}
              onChange={handleChangeTranslation('name')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionTrackEditionTranslations;
