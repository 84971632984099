import React from 'react';
import { MainButton } from '@emisys/audience-sdk-ui-react';
import Translator from '../../services/translator';

interface ButtonsSaveModalInterface {
  onClose?: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  cancelText?: string;
  submitText?: string;
  hideCancel?: boolean;
}

const ButtonsSaveModal = (props: ButtonsSaveModalInterface) => (
  <div className="d-flex justify-content-end mt-3">
    {!props.hideCancel && (
      <MainButton
        onClick={props.onClose}
        className="modal-cancel-button mr-2"
        buttonType={'outline'}
      >
        {props.cancelText
          ? props.cancelText
          : Translator.trans('appModule.cancel')}
      </MainButton>
    )}
    <MainButton
      onClick={props.onSubmit}
      buttonType={'contain'}
      disabled={props.isDisabled}
      className={'modal-save-button'}
      loading={props.isLoading}
    >
      {props.submitText ? props.submitText : Translator.trans('appModule.save')}
    </MainButton>
  </div>
);

export default ButtonsSaveModal;
