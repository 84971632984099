import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { userConstants } from '../constants';
import {
  getUserDetailsSuccess,
  getUserDetailsFailure,
  getUserProjectsListSuccess,
  getUserProjectsListFailure,
  getUserProjectSuccess,
  getUserProjectFailure,
  putUserSuccess,
  putUserFailure,
} from '../actions';
import {
  getUserSelfDetailsApi,
  getUserSelfProjectsApi,
  getUserProjectByProjectApi,
  putUserApi,
} from '../api';

export function* fetchUserSelfDetails(action: any): any {
  try {
    const userSelfDetails = yield call(getUserSelfDetailsApi, action.idProject);
    yield put(getUserDetailsSuccess(userSelfDetails));
  } catch (error) {
    yield put(getUserDetailsFailure(error));
  }
}

export function* fetchUserSelfProjects(): any {
  try {
    const userSelfProjects = yield call(getUserSelfProjectsApi);
    yield put(getUserProjectsListSuccess(userSelfProjects));
  } catch (error) {
    yield put(getUserProjectsListFailure(error));
  }
}

export function* fetchGetUserProjectByProject(action: any): any {
  try {
    const userProject = yield call(getUserProjectByProjectApi, {
      idProject: action.idProject,
      idUser: action.idUser,
    });
    yield put(getUserProjectSuccess(userProject));
  } catch (error) {
    yield put(getUserProjectFailure(error));
  }
}

export function* fetchPutUser(action: any): any {
  try {
    const userUpdate = yield call(putUserApi, action.user);
    yield put(putUserSuccess(userUpdate));
  } catch (error) {
    yield put(putUserFailure(error));
  }
}

export function* getUserSelfDetails() {
  yield takeLatest(
    userConstants.GET_USER_DETAILS_REQUEST,
    fetchUserSelfDetails
  );
}

export function* getUserSelfProjects() {
  yield takeLatest(
    userConstants.GET_USER_PROJECTS_LIST_REQUEST,
    fetchUserSelfProjects
  );
}

export function* getUserProjectByProject() {
  yield takeLatest(
    userConstants.GET_USER_PROJECT_REQUEST,
    fetchGetUserProjectByProject
  );
}

export function* putUser() {
  yield takeLatest(userConstants.PUT_USER_REQUEST, fetchPutUser);
}

export default function* userSaga() {
  yield all([
    fork(getUserSelfDetails),
    fork(getUserSelfProjects),
    fork(getUserProjectByProject),
    fork(putUser),
  ]);
}
