export const projectConstants = {
  GET_PROJECT_DETAILS_REQUEST: 'GET_PROJECT_DETAILS_REQUEST',
  GET_PROJECT_DETAILS_SUCCESS: 'GET_PROJECT_DETAILS_SUCCESS',
  GET_PROJECT_DETAILS_FAILURE: 'GET_PROJECT_DETAILS_FAILURE',

  GET_PROJECT_REQUEST: 'GET_PROJECT_REQUEST',
  GET_PROJECT_SUCCESS: 'GET_PROJECT_SUCCESS',
  GET_PROJECT_FAILURE: 'GET_PROJECT_FAILURE',

  PUT_PROJECT_DETAILS_REQUEST: 'PUT_PROJECT_DETAILS_REQUEST',
  PUT_PROJECT_DETAILS_SUCCESS: 'PUT_PROJECT_DETAILS_SUCCESS',
  PUT_PROJECT_DETAILS_FAILURE: 'PUT_PROJECT_DETAILS_FAILURE',

  GET_PROJECT_COST_REQUEST: 'GET_PROJECT_COST_REQUEST',
  GET_PROJECT_COST_SUCCESS: 'GET_PROJECT_COST_SUCCESS',
  GET_PROJECT_COST_FAILURE: 'GET_PROJECT_COST_FAILURE',

  ARCHIVE_PROJECT_REQUEST: 'ARCHIVE_PROJECT_REQUEST',
  ARCHIVE_PROJECT_SUCCESS: 'ARCHIVE_PROJECT_SUCCESS',
  ARCHIVE_PROJECT_FAILURE: 'ARCHIVE_PROJECT_FAILURE',

  CLEAR_PROJECT: 'CLEAR_PROJECT',
};
