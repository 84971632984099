export const performersConstants = {
  GET_PERFORMERS_LIST_REQUEST: 'GET_PERFORMERS_LIST_REQUEST',
  GET_PERFORMERS_LIST_SUCCESS: 'GET_PERFORMERS_LIST_SUCCESS',
  GET_PERFORMERS_LIST_FAILURE: 'GET_PERFORMERS_LIST_FAILURE',

  GET_PERFORMER_REQUEST: 'GET_PERFORMER_REQUEST',
  GET_PERFORMER_SUCCESS: 'GET_PERFORMER_SUCCESS',
  GET_PERFORMER_FAILURE: 'GET_PERFORMER_FAILURE',

  POST_PERFORMER_REQUEST: 'POST_PERFORMER_REQUEST',
  POST_PERFORMER_SUCCESS: 'POST_PERFORMER_SUCCESS',
  POST_PERFORMER_FAILURE: 'POST_PERFORMER_FAILURE',

  PUT_PERFORMER_REQUEST: 'PUT_PERFORMER_REQUEST',
  PUT_PERFORMER_SUCCESS: 'PUT_PERFORMER_SUCCESS',
  PUT_PERFORMER_FAILURE: 'PUT_PERFORMER_FAILURE',

  DELETE_PERFORMER_REQUEST: 'DELETE_PERFORMER_REQUEST',
  DELETE_PERFORMER_SUCCESS: 'DELETE_PERFORMER_SUCCESS',
  DELETE_PERFORMER_FAILURE: 'DELETE_PERFORMER_FAILURE',

  GET_PERFORMER_TRANSLATIONS_REQUEST: 'GET_PERFORMER_TRANSLATIONS_REQUEST',
  GET_PERFORMER_TRANSLATIONS_SUCCESS: 'GET_PERFORMER_TRANSLATIONS_SUCCESS',
  GET_PERFORMER_TRANSLATIONS_FAILURE: 'GET_PERFORMER_TRANSLATIONS_FAILURE',

  POST_PERFORMER_TRANSLATIONS_REQUEST: 'POST_PERFORMER_TRANSLATIONS_REQUEST',
  POST_PERFORMER_TRANSLATIONS_SUCCESS: 'POST_PERFORMER_TRANSLATIONS_SUCCESS',
  POST_PERFORMER_TRANSLATIONS_FAILURE: 'POST_PERFORMER_TRANSLATIONS_FAILURE',

  PUT_PERFORMER_TRANSLATIONS_REQUEST: 'PUT_PERFORMER_TRANSLATIONS_REQUEST',
  PUT_PERFORMER_TRANSLATIONS_SUCCESS: 'PUT_PERFORMER_TRANSLATIONS_SUCCESS',
  PUT_PERFORMER_TRANSLATIONS_FAILURE: 'PUT_PERFORMER_TRANSLATIONS_FAILURE',

  CLEAR_PERFORMER: 'CLEAR_PERFORMER',
};
