import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getContentArticlesListSuccess,
  getContentArticlesListFailure,
  postContentArticlesFailure,
  postContentArticlesSuccess,
  putContentArticlesSuccess,
  putContentArticlesFailure,
  putContentArticlesPositionSuccess,
  putContentArticlesPositionFailure,
  deleteContentArticlesSuccess,
  deleteContentArticlesFailure,
  getContentArticleSuccess,
  getContentArticleFailure,
  getContentArticleTranslationSuccess,
  getContentArticleTranslationFailure,
  postContentArticleTranslationSuccess,
  postContentArticleTranslationFailure,
  putContentArticleTranslationSuccess,
  putContentArticleTranslationFailure,
} from '../actions';
import { contentArticlesConstants } from '../constants';
import {
  getContentArticlesByProjectApi,
  postContentArticlesByProjectApi,
  putContentArticleApi,
  putContentArticlePositionApi,
  deleteContentArticleApi,
  getContentArticleApi,
  getTranslationContentArticleApi,
  postTranslationContentArticleSelfApi,
  putTranslationContentArticleSelfApi,
} from '../api';

export function* fetchGetContentArticlesByProject(action: any): any {
  try {
    const contentArticles = yield call(getContentArticlesByProjectApi, {
      idProject: action.idProject,
    });
    yield put(getContentArticlesListSuccess(contentArticles));
  } catch (error) {
    yield put(getContentArticlesListFailure(error));
  }
}

export function* fetchPostContentArticlesByProject(action: any): any {
  try {
    const newContentArticle = yield call(postContentArticlesByProjectApi, {
      idProject: action.idProject,
      newContentArticle: action.newContentArticle,
    });
    yield put(postContentArticlesSuccess(newContentArticle));
  } catch (error) {
    yield put(postContentArticlesFailure(error));
  }
}

export function* fetchPutContentArticle(action: any): any {
  try {
    const updatedContentArticle = yield call(putContentArticleApi, {
      idProject: action.idProject,
      updatedContentArticle: action.updatedContentArticle,
    });
    yield put(putContentArticlesSuccess(updatedContentArticle));
  } catch (error) {
    yield put(putContentArticlesFailure(error));
  }
}

export function* fetchPutContentArticlePosition(action: any): any {
  try {
    const updatedContentArticlePosition = yield call(
      putContentArticlePositionApi,
      {
        id: action.updatedContentArticlePosition.id,
        index: action.updatedContentArticlePosition.position,
      }
    );
    yield put(putContentArticlesPositionSuccess(updatedContentArticlePosition));
  } catch (error) {
    yield put(putContentArticlesPositionFailure(error));
  }
}

export function* fetchDeleteContentArticle(action: any): any {
  try {
    const deletedContentArticle = yield call(deleteContentArticleApi, {
      id: action.contentArticleId,
    });
    yield put(deleteContentArticlesSuccess(deletedContentArticle));
  } catch (error) {
    yield put(deleteContentArticlesFailure(error));
  }
}

export function* fetchGetContentArticle(action: any): any {
  try {
    const contentArticle = yield call(getContentArticleApi, {
      idContentArticle: action.idContentArticle,
    });
    yield put(getContentArticleSuccess(contentArticle));
  } catch (error) {
    yield put(getContentArticleFailure(error));
  }
}

export function* fetchGetTranslationContentArticle(action: any): any {
  try {
    const translationContentArticleList = yield call(
      getTranslationContentArticleApi,
      { id: action.idContentArticle }
    );
    yield put(
      getContentArticleTranslationSuccess(translationContentArticleList)
    );
  } catch (error) {
    yield put(getContentArticleTranslationFailure(error));
  }
}

export function* fetchPostTranslationContentArticleSelf(action: any): any {
  try {
    const translationContentArticle = yield call(
      postTranslationContentArticleSelfApi,
      {
        id: action.articleId,
        translationContentArticle: action.translationContentArticle,
      }
    );
    yield put(postContentArticleTranslationSuccess(translationContentArticle));
  } catch (error) {
    yield put(postContentArticleTranslationFailure(error));
  }
}

export function* fetchPutTranslationContentArticleSelf(action: any): any {
  try {
    const updatedTranslationContentArticle = yield call(
      putTranslationContentArticleSelfApi,
      {
        id: action.articleId,
        updatedTranslationContentArticle:
          action.updatedTranslationContentArticle,
      }
    );
    yield put(
      putContentArticleTranslationSuccess(updatedTranslationContentArticle)
    );
  } catch (error) {
    yield put(putContentArticleTranslationFailure(error));
  }
}

export function* getContentArticlesByProject() {
  yield takeLatest(
    contentArticlesConstants.GET_CONTENT_ARTICLES_REQUEST,
    fetchGetContentArticlesByProject
  );
}

export function* postContentArticlesByProject() {
  yield takeLatest(
    contentArticlesConstants.POST_CONTENT_ARTICLES_REQUEST,
    fetchPostContentArticlesByProject
  );
}

export function* putContentArticles() {
  yield takeLatest(
    contentArticlesConstants.PUT_CONTENT_ARTICLES_REQUEST,
    fetchPutContentArticle
  );
}

export function* putContentArticlesPosition() {
  yield takeLatest(
    contentArticlesConstants.PUT_CONTENT_ARTICLES_POSITION_REQUEST,
    fetchPutContentArticlePosition
  );
}

export function* deleteContentArticles() {
  yield takeLatest(
    contentArticlesConstants.DELETE_CONTENT_ARTICLES_REQUEST,
    fetchDeleteContentArticle
  );
}

export function* getContentArticle() {
  yield takeLatest(
    contentArticlesConstants.GET_CONTENT_ARTICLE_REQUEST,
    fetchGetContentArticle
  );
}

export function* getTranslationContentArticle() {
  yield takeLatest(
    contentArticlesConstants.GET_CONTENT_ARTICLE_TRANSLATION_REQUEST,
    fetchGetTranslationContentArticle
  );
}

export function* postTranslationContentArticleSelf() {
  yield takeLatest(
    contentArticlesConstants.POST_CONTENT_ARTICLE_TRANSLATION_REQUEST,
    fetchPostTranslationContentArticleSelf
  );
}

export function* putTranslationContentArticleSelf() {
  yield takeLatest(
    contentArticlesConstants.PUT_CONTENT_ARTICLE_TRANSLATION_REQUEST,
    fetchPutTranslationContentArticleSelf
  );
}

export default function* contentArticlesSaga() {
  yield all([
    fork(getContentArticlesByProject),
    fork(postContentArticlesByProject),
    fork(putContentArticles),
    fork(putContentArticlesPosition),
    fork(deleteContentArticles),
    fork(getContentArticle),
    fork(getTranslationContentArticle),
    fork(postTranslationContentArticleSelf),
    fork(putTranslationContentArticleSelf),
  ]);
}
