import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from 'react-select';
import Translator from '../../../services/translator';
import {
  getPerformersListRequest,
  getPlacesListRequest,
  getSessionTracksListRequest,
} from '../../../Redux/actions';
import { capitalizeFirstLetter } from '../../../services/capitalizeFirstLetter';
import { MainTextField, UnderlineTitle } from '@emisys/audience-sdk-ui-react';
import { currentProject } from '../../../Global/currentProject';
import DatePickerComponent from '../../../components/DatePicker/DatePicker';
import { getNameLanguage } from '../../../services/GetNameLanguage';
import SelectHelper from '../../../components/SelectHelperSessions';
import PerformerModalCreation from '../../Performers/PerformerModal/PerformerModalCreation';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import ModalPlacesCreation from '../../Places/PlacesCreation/PlacesModalCreation';
import { storeType } from '../../../index';
import SessionTrackModel from '../../../Models/SessionTrackModel';
import PlaceModel from '../../../Models/PlaceModel';
import PerformerModel from '../../../Models/PerformerModel';
import { selectItemsInterface } from '../../../Constants/Constant';
import SessionModel from '../../../Models/SessionModel';

interface SessionContentInterface {
  session?: SessionModel | null;
  handleChangeSession: (kay: string, value: any) => void;
  defaultPlaces?: number[];
  defaultPerformers?: number[];
}

const SessionContent = (props: SessionContentInterface) => {
  const dispatch = useDispatch();
  const today = useMemo(() => new Date(), []);
  const tomorrow = useMemo(
    () => new Date(today.getTime() + 60 * 60 * 1000),
    [today]
  );
  const [title, setTitle] = useState('');
  const [locale, setLocale] = useState<selectItemsInterface | null>(null);
  const [liveStartDateTimeUTC, setLiveStartDateTimeUTC] = useState<Date>(today);
  const [liveEndDateTimeUTC, setLiveEndDateTimeUTC] = useState<Date>(tomorrow);
  const [track, setTrack] = useState<selectItemsInterface | null>(null);
  const [selectedPlaces, setSelectedPlaces] = useState<number[]>([]);
  const [selectedPerformers, setSelectedPerformers] = useState<number[]>([]);
  const [openModalPerformer, setOpenModalPerformer] = useState(false);
  const [newPerformerStr, setNewPerformerStr] = useState('');
  const [currentPerformers, setCurrentPerformers] = useState<PerformerModel[]>(
    []
  );
  const [currentSessionTracks, setCurrentSessionTracks] = useState<
    SessionTrackModel[]
  >([]);
  const [openModalPlaces, setOpenModalPlaces] = useState(false);
  const [newPlaceStr, setNewPlaceStr] = useState('');
  const [currentPlaces, setCurrentPlaces] = useState<PlaceModel[]>([]);
  const [timeToBeConfirmed, setTimeToBeConfirmed] = useState(false);

  const placesList = useSelector((state: storeType) => state.places.placesList);

  const sessionTracksList = useSelector(
    (state: storeType) => state.sessionTracks.sessionTracksList
  );
  const performersList = useSelector(
    (state: storeType) => state.performers.performersList
  );

  useEffect(() => {
    dispatch(getPlacesListRequest(currentProject.id));
    dispatch(getSessionTracksListRequest(currentProject.id));
    dispatch(
      getPerformersListRequest(currentProject.id, { order: 'name:ASC' })
    );
  }, [dispatch]);

  useEffect(() => {
    if (checkReduxResponse(placesList, 'places')) {
      setCurrentPlaces(placesList.places);
    }
  }, [placesList]);

  const handleChangeTrack = useCallback(
    (selectedOption: selectItemsInterface | null) => {
      props.handleChangeSession('track', selectedOption?.value || null);
      setTrack(selectedOption);
    },
    [props]
  );

  const handleChangePerformer = useCallback(
    (performerList: number[]) => {
      props.handleChangeSession('performers', performerList);
      setSelectedPerformers(performerList);
    },
    [props]
  );

  const handleChangePlace = useCallback(
    (placeList: number[]) => {
      setSelectedPlaces(placeList);
      props.handleChangeSession('places', placeList);
    },
    [props]
  );

  useEffect(() => {
    if (checkReduxResponse(performersList, 'performers')) {
      setCurrentPerformers(performersList.performers);
    }
  }, [performersList]);

  useEffect(() => {
    if (props.defaultPlaces) {
      handleChangePlace(props.defaultPlaces);
    }
  }, [handleChangePlace, props.defaultPlaces]);

  useEffect(() => {
    if (props.defaultPerformers) {
      handleChangePerformer(props.defaultPerformers);
    }
  }, [handleChangePerformer, props.defaultPerformers]);

  useEffect(() => {
    if (checkReduxResponse(sessionTracksList, 'sessionTracks')) {
      setCurrentSessionTracks(sessionTracksList.sessionTracks);
      if (props.session?.track) {
        handleChangeTrack({
          value: props.session.track.id,
          label: props.session.track.name,
        });
      }
    }
  }, [handleChangeTrack, props.session?.track, sessionTracksList]);

  const handleChangeTitle = useCallback(
    (value: string) => {
      const newValue = capitalizeFirstLetter(value);
      setTitle(newValue);
      props.handleChangeSession('title', newValue);
    },
    [props]
  );

  const handleChangeLiveStartDateTime = useCallback(
    (date: Date | null, isConfirmed?: boolean) => {
      if (date) {
        if (isConfirmed) {
          const newLiveStartDateTime = new Date(date.setHours(0, 0, 0, 0));
          setLiveStartDateTimeUTC(newLiveStartDateTime);
          setLiveEndDateTimeUTC(newLiveStartDateTime);
          props.handleChangeSession(
            'liveStartDateTimeUTC',
            newLiveStartDateTime
          );
          props.handleChangeSession('liveEndDateTimeUTC', newLiveStartDateTime);
        } else {
          props.handleChangeSession('liveStartDateTimeUTC', date);
          setLiveStartDateTimeUTC(date);

          const newEndDateTime = new Date(date.getTime() + 60 * 60 * 1000);
          props.handleChangeSession('liveEndDateTimeUTC', newEndDateTime);
          setLiveEndDateTimeUTC(newEndDateTime);
        }
      }
    },
    [props]
  );

  const handleChangeLiveEndDateTime = useCallback(
    (date: Date) => {
      props.handleChangeSession('liveEndDateTimeUTC', date);
      setLiveEndDateTimeUTC(date);
    },
    [props]
  );

  const handleChangeIsConfirmDate = useCallback(
    (isConfirmed: boolean) => {
      setTimeToBeConfirmed(isConfirmed);
      props.handleChangeSession('timeToBeConfirmed', isConfirmed);
      handleChangeLiveStartDateTime(liveStartDateTimeUTC, isConfirmed);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChangeLiveStartDateTime, props]
  );

  const handleChangeLocale = useCallback(
    (selectedOption: selectItemsInterface | null) => {
      props.handleChangeSession('locale', selectedOption?.value || null);
      setLocale(selectedOption);
    },
    [props]
  );

  useEffect(() => {
    if (props.session) {
      handleChangeTitle(props.session.title);
      handleChangeIsConfirmDate(props.session.timeToBeConfirmed);
      if (props.session.liveStartDateTimeUTC) {
        handleChangeLiveStartDateTime(
          props.session.liveStartDateTimeUTC,
          props.session.timeToBeConfirmed
        );
      }
      if (props.session.liveEndDateTimeUTC) {
        handleChangeLiveEndDateTime(props.session.liveEndDateTimeUTC);
      }
      if (props.session.locale) {
        handleChangeLocale(props.session.locale);
      }
    }
  }, [
    handleChangeIsConfirmDate,
    handleChangeLiveEndDateTime,
    handleChangeLiveStartDateTime,
    handleChangeLocale,
    handleChangeTitle,
    props.session,
  ]);

  return (
    <>
      <UnderlineTitle title={Translator.trans('appModule.general.info')} />
      <div className="row">
        <div className="col-12 information">
          <div className="col-12 col-md-4 p-0">
            <h4>{Translator.trans('cms.title')}</h4>
          </div>
          <div className="col-12 col-md-5 p-0">
            <MainTextField
              value={title}
              onChange={(event) => handleChangeTitle(event.target.value)}
            />
          </div>
        </div>

        <div className="col-12 information">
          <div className="col-12 col-md-4 p-0">
            <h4>{Translator.trans('sessions.title.start')}</h4>
          </div>
          <div className="col-12 col-md-5 p-0">
            <div className="row">
              <div className="col-7">
                <DatePickerComponent
                  selected={liveStartDateTimeUTC}
                  onChange={(newDate) =>
                    handleChangeLiveStartDateTime(newDate, timeToBeConfirmed)
                  }
                  showTimeSelect
                />
              </div>
              <div className="col-5">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="sessionToConfirme"
                      checked={timeToBeConfirmed}
                      value="secondary"
                      color="primary"
                      onChange={(event) =>
                        handleChangeIsConfirmDate(event.target.checked)
                      }
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  }
                  label={Translator.trans('sessions.title.hour.confirm.label')}
                />
              </div>
            </div>
          </div>
        </div>

        {!timeToBeConfirmed && (
          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('sessions.title.end')}</h4>
            </div>
            <div className="col-12 col-md-5 p-0">
              <div className="row">
                <div className="col-7">
                  <DatePickerComponent
                    selected={liveEndDateTimeUTC}
                    minDate={liveEndDateTimeUTC}
                    onChange={handleChangeLiveEndDateTime}
                    showTimeSelect
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="col-12 information">
          <div className="col-12 col-md-4 p-0">
            <h4>{Translator.trans('sessions.title.language')}</h4>
          </div>
          <div className="col-12 col-md-5 p-0">
            <div className="row">
              <div className="col-12">
                <Select
                  isClearable
                  isLoading={!currentProject.allowedLanguages}
                  value={locale}
                  onChange={handleChangeLocale}
                  options={
                    currentProject.allowedLanguages &&
                    currentProject.allowedLanguages.map((language) => ({
                      value: language,
                      label: getNameLanguage(
                        currentProject.language.locale,
                        language
                      ),
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 information">
          <div className="col-12 col-md-4 p-0">
            <h4>{Translator.trans('sessions.title.theme')}</h4>
          </div>
          <div className="col-12 col-md-5 p-0">
            <div className="row">
              <div className="col-12">
                <Select
                  isClearable
                  value={track}
                  onChange={handleChangeTrack}
                  options={currentSessionTracks.map(
                    (data: SessionTrackModel) => ({
                      value: data.id,
                      label: data.name,
                    })
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 information">
          <div className="col-12 col-md-4 p-0">
            <h4>{Translator.trans('sessions.title.performers')}</h4>
          </div>
          <div className="col-12 col-md-5 p-0">
            <SelectHelper
              creatable={true}
              onClickAction={(text: string) => {
                setOpenModalPerformer(true);
                setNewPerformerStr(text);
              }}
              items={selectedPerformers}
              listItems={currentPerformers}
              updateItem={handleChangePerformer}
              labelSelect={'name'}
            />
          </div>
          <PerformerModalCreation
            open={openModalPerformer}
            newPerformerStr={newPerformerStr}
            close={() => setOpenModalPerformer(false)}
          />
        </div>

        <div className="col-12 information">
          <div className="col-12 col-md-4 p-0">
            <h4>{Translator.trans('places.title')}</h4>
          </div>
          <div className="col-12 col-md-5 p-0">
            <SelectHelper
              creatable={true}
              onClickAction={(text: string) => {
                setOpenModalPlaces(true);
                setNewPlaceStr(text);
              }}
              items={selectedPlaces}
              listItems={currentPlaces}
              updateItem={handleChangePlace}
              labelSelect={'name'}
            />
          </div>
          <ModalPlacesCreation
            open={openModalPlaces}
            newPlace={newPlaceStr}
            close={() => setOpenModalPlaces(false)}
          />
        </div>
      </div>
    </>
  );
};

export default SessionContent;
