import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import ButtonsPerformers from '../../../../components/Buttons/ButtonsPerformers';
import {
  EmptyText,
  SwitchComponent,
  Username,
} from '@emisys/audience-sdk-ui-react';
import LinkToSession from '../../../Buttons/LinkToSession';
import Translator from '../../../../services/translator';
import SessionPerformerModel from '../../../../Models/SessionPerformerModel';
import { columnNameAction } from '../../Constants/Constant';
import { putPerformerRequest } from '../../../../Redux/actions';
import { useDispatch } from 'react-redux';

const PerformersCell = (cell: Table.DataCellProps) => {
  const dispatch = useDispatch();
  let content = cell.value;

  const handleChangeIsActive = (isChecked: boolean) => {
    dispatch(
      putPerformerRequest(cell.row.id, {
        headline: isChecked,
      })
    );
  };

  if (cell.column.name === 'name') {
    content = <Username name={cell.row.name} />;
  }

  if (cell.column.name === 'sessionPerformers') {
    if (cell.row.sessionPerformers.length !== 0) {
      content = (
        <ul style={{ padding: 0, margin: 0 }}>
          {cell.row.sessionPerformers.map(
            (data: SessionPerformerModel) =>
              data.session && (
                <li key={data.id}>
                  <LinkToSession session={data.session} />
                </li>
              )
          )}
        </ul>
      );
    } else {
      content = <EmptyText>{Translator.trans('cms.no.session')}</EmptyText>;
    }
  }

  if (cell.column.name === 'headline') {
    content = (
      <SwitchComponent
        onHandleChange={handleChangeIsActive}
        checked={cell.row.headline}
      />
    );
  }

  if (cell.column.name === columnNameAction) {
    content = <ButtonsPerformers performer={cell.row.performer} />;
  }

  return <Table.Cell {...cell}>{content}</Table.Cell>;
};

export default PerformersCell;
