import React, { useEffect, useState } from 'react';
import { Loading, Modal } from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';
import PlaceModel from '../../../Models/PlaceModel';
import { currentProject } from '../../../Global/currentProject';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import PlacesEditionTranslations from './PlacesEditionTranslations';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import { useDispatch, useSelector } from 'react-redux';
import { storeType } from '../../../index';
import {
  getPlacesTranslationRequest,
  putPlacesRequest,
  putPlacesTranslationRequest,
} from '../../../Redux/actions';

const PlacesModalEdition = (props: {
  place: PlaceModel;
  open: boolean;
  close: () => void;
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [translationPlace, setTranslationPlace] = useState<any[]>([]);

  const translationPlaceSelf = useSelector(
    (state: storeType) => state.places.translationPlaceSelf
  );

  const updatedPlace = useSelector(
    (state: storeType) => state.places.updatedPlace
  );

  useEffect(() => {
    dispatch(getPlacesTranslationRequest(props.place.id));

    return () => {
      setTranslationPlace([]);
    };
  }, [props.place, dispatch]);

  useEffect(() => {
    if (updatedPlace) {
      setLoadingSend(updatedPlace.loading);
      if (checkReduxResponse(updatedPlace, 'place')) {
        dispatch(
          putPlacesTranslationRequest(updatedPlace.place.id, {
            translations: translationPlace,
          })
        );
        props.close();
      }
    }
  }, [dispatch, props, translationPlace, updatedPlace]);

  useEffect(() => {
    if (translationPlaceSelf) {
      setLoading(translationPlaceSelf.loading);
      if (checkReduxResponse(translationPlaceSelf, 'place')) {
        if (currentProject.allowedLanguages) {
          let translations: any[] = [];

          currentProject.allowedLanguages.forEach((language) => {
            const current = translationPlaceSelf.place.find(
              (item: any) => item.locale === language
            );

            if (current) {
              translations.push(current);
            } else {
              translations.push({
                locale: language,
                name: '',
              });
            }
          });
          setTranslationPlace(translations);
        }
      }
    }
  }, [translationPlaceSelf]);

  const handleChangeTranslation = (
    name: string,
    value: string,
    locale: string
  ) => {
    let newTranslations: any[] = [...translationPlace];
    if (currentProject.defaultLanguage === locale) {
      setName(value);
    }
    let objIndex = newTranslations.findIndex((item) => item.locale === locale);
    if (objIndex > -1) {
      newTranslations[objIndex][name] = value;
    }

    setTranslationPlace(newTranslations);
  };

  const handleSubmit = () => {
    if (!name) {
      NotificationManager.warning(Translator.trans('message.title.missing'));
    } else {
      const payload = { ...props.place };
      payload.name = name;
      dispatch(putPlacesRequest(props.place.id, payload));
    }
  };

  return (
    <Modal
      title={Translator.trans('places.edit.title')}
      width="md"
      open={props.open}
      close={props.close}
    >
      <div className="col-12" style={{ padding: 0, overflow: 'hidden' }}>
        {currentProject.allowedLanguages ? (
          currentProject.allowedLanguages.map((language) => {
            let translation = translationPlace.find(
              (element) => element.locale === language
            );
            return (
              <AccordionTranslation key={language} language={language}>
                <PlacesEditionTranslations
                  translation={translation}
                  handleChangeTranslation={handleChangeTranslation}
                  language={language}
                />
              </AccordionTranslation>
            );
          })
        ) : (
          <Loading />
        )}

        <ButtonsSaveModal
          onClose={props.close}
          onSubmit={handleSubmit}
          isLoading={loadingSend}
        />
      </div>
      {loading && <Loading />}
    </Modal>
  );
};

export default PlacesModalEdition;
