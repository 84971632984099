import { appMobileConstants } from '../constants';
import AppMobileModel from '../../Models/AppMobileModel';
import ErrorModel from '../../Models/ErrorModel';

const initialState: any = {
  appMobileList: null,
  newAppMobileList: null,
};

let currentAppMobile: AppMobileModel | null = null;
const appMobile = (state = initialState, action: any) => {
  switch (action.type) {
    case appMobileConstants.GET_APP_MOBILE_INFO_REQUEST: {
      return {
        ...state,
        appMobileList: {
          appMobile: currentAppMobile,
          error: false,
          loading: true,
        },
      };
    }

    case appMobileConstants.GET_APP_MOBILE_INFO_SUCCESS: {
      currentAppMobile = new AppMobileModel(action.appMobile.data);

      return {
        ...state,
        appMobileList: {
          appMobile: currentAppMobile,
          error: false,
          loading: false,
        },
      };
    }

    case appMobileConstants.GET_APP_MOBILE_INFO_FAILURE: {
      return {
        ...state,
        appMobileList: { appMobile: null, error: true, loading: false },
      };
    }

    case appMobileConstants.PUT_APP_MOBILE_INFO_REQUEST: {
      return {
        ...state,
        newAppMobileList: {
          appMobile: null,
          error: false,
          loading: true,
        },
      };
    }

    case appMobileConstants.PUT_APP_MOBILE_INFO_SUCCESS: {
      currentAppMobile = new AppMobileModel(action.newAppMobile.data);
      return {
        ...state,
        newAppMobileList: {
          appMobile: currentAppMobile,
          error: false,
          loading: false,
        },
      };
    }

    case appMobileConstants.PUT_APP_MOBILE_INFO_FAILURE: {
      return {
        ...state,
        newAppMobileList: {
          appMobile: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case appMobileConstants.CLEAR_APP_MOBILE_REQUEST: {
      return {
        ...state,
        newAppMobileList: null,
      };
    }

    default:
      return state;
  }
};

export default appMobile;
