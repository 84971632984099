import React, { useEffect, useRef, useState } from 'react';
import { Loading, Modal } from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';
import { currentProject } from '../../../Global/currentProject';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import SessionTrackCreationTranslations from './SessionTrackCreationTranslations';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { useDispatch, useSelector } from 'react-redux';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import { storeType } from '../../../index';
import {
  clearSessionTrack,
  getSessionTracksListRequest,
  postSessionTrackRequest,
  postSessionTrackTranslationRequest,
} from '../../../Redux/actions';

const ModalSessionTrackCreation = (props: {
  open: boolean;
  close: () => void;
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [loadingSend, setLoadingSend] = useState(false);

  const newSessionTrack = useSelector(
    (state: storeType) => state.sessionTracks.newSessionTrack
  );
  const translationSessionTrack = useSelector(
    (state: storeType) => state.sessionTracks.translationSessionTrack
  );

  const newTranslationSessionTrack: {
    current: any[];
  } = useRef([]);

  useEffect(() => {
    if (currentProject.allowedLanguages) {
      currentProject.allowedLanguages.forEach((element) => {
        let array = {
          locale: element,
          name: '',
        };
        newTranslationSessionTrack.current.push(array);
      });
    }
  }, []);

  useEffect(() => {
    if (newSessionTrack) {
      setLoadingSend(newSessionTrack.loading);
      if (checkReduxResponse(newSessionTrack, 'sessionTrack')) {
        dispatch(
          postSessionTrackTranslationRequest(newSessionTrack.sessionTrack.id, {
            translations: newTranslationSessionTrack.current,
          })
        );
      }

      if (newSessionTrack.error) {
        NotificationManager.error(newSessionTrack.error.message);
      }
    }
  }, [dispatch, newSessionTrack]);

  useEffect(() => {
    if (translationSessionTrack) {
      setLoadingSend(translationSessionTrack.loading);
      if (checkReduxResponse(translationSessionTrack, 'sessionTrack')) {
        dispatch(getSessionTracksListRequest(currentProject.id));
        NotificationManager.success(
          Translator.trans('sessionTracks.message.success.added')
        );
        dispatch(clearSessionTrack());
        props.close();
      }

      if (translationSessionTrack.error) {
        NotificationManager.error(translationSessionTrack.error.message);
        dispatch(clearSessionTrack());
      }
    }
  }, [dispatch, newSessionTrack, props, translationSessionTrack]);

  const handleChangeName = (name: string, value: string, locale: string) => {
    setName(value);

    let objIndex = newTranslationSessionTrack.current.findIndex(
      (item: any) => item.locale === locale
    );
    if (objIndex > -1) {
      newTranslationSessionTrack.current[objIndex][name] = value;
    }
  };

  const handleSubmit = () => {
    newTranslationSessionTrack.current.forEach((element: any) => {
      if (element.locale === currentProject.language.locale) {
        if (element.name === '') {
          NotificationManager.warning(
            Translator.trans('message.title.missing')
          );
        } else {
          dispatch(
            postSessionTrackRequest({
              idProject: currentProject.id,
              name: name,
            })
          );
        }
      }
    });
  };

  return (
    <Modal
      title={Translator.trans('sessionTracks.add.title')}
      width="md"
      open={props.open}
      close={props.close}
    >
      <div className="col-12" style={{ padding: 0, overflow: 'hidden' }}>
        {currentProject.allowedLanguages ? (
          currentProject.allowedLanguages.map((language) => (
            <AccordionTranslation key={language} language={language}>
              <SessionTrackCreationTranslations
                handleChangeTranslation={handleChangeName}
                language={language}
              />
            </AccordionTranslation>
          ))
        ) : (
          <Loading />
        )}

        <ButtonsSaveModal
          onClose={props.close}
          onSubmit={handleSubmit}
          isLoading={loadingSend}
        />
      </div>
    </Modal>
  );
};

export default ModalSessionTrackCreation;
