export const sessionsConstants = {
  GET_SESSIONS_LIST_REQUEST: 'GET_SESSIONS_LIST_REQUEST',
  GET_SESSIONS_LIST_SUCCESS: 'GET_SESSIONS_LIST_SUCCESS',
  GET_SESSIONS_LIST_FAILURE: 'GET_SESSIONS_LIST_FAILURE',

  GET_SESSION_REQUEST: 'GET_SESSION_REQUEST',
  GET_SESSION_SUCCESS: 'GET_SESSION_SUCCESS',
  GET_SESSION_FAILURE: 'GET_SESSION_FAILURE',

  PUT_SESSION_REQUEST: 'PUT_SESSION_REQUEST',
  PUT_SESSION_SUCCESS: 'PUT_SESSION_SUCCESS',
  PUT_SESSION_FAILURE: 'PUT_SESSION_FAILURE',

  DELETE_SESSION_REQUEST: 'DELETE_SESSION_REQUEST',
  DELETE_SESSION_SUCCESS: 'DELETE_SESSION_SUCCESS',
  DELETE_SESSION_FAILURE: 'DELETE_SESSION_FAILURE',

  POST_SESSION_PERFORMERS_REQUEST: 'POST_SESSION_PERFORMERS_REQUEST',
  POST_SESSION_PERFORMERS_SUCCESS: 'POST_SESSION_PERFORMERS_SUCCESS',
  POST_SESSION_PERFORMERS_FAILURE: 'POST_SESSION_PERFORMERS_FAILURE',

  POST_SESSION_PLACES_REQUEST: 'POST_SESSION_PLACES_REQUEST',
  POST_SESSION_PLACES_SUCCESS: 'POST_SESSION_PLACES_SUCCESS',
  POST_SESSION_PLACES_FAILURE: 'POST_SESSION_PLACES_FAILURE',

  PUT_SESSION_PERFORMERS_REQUEST: 'PUT_SESSION_PERFORMERS_REQUEST',
  PUT_SESSION_PERFORMERS_SUCCESS: 'PUT_SESSION_PERFORMERS_SUCCESS',
  PUT_SESSION_PERFORMERS_FAILURE: 'PUT_SESSION_PERFORMERS_FAILURE',

  PUT_SESSION_PLACES_REQUEST: 'PUT_SESSION_PLACES_REQUEST',
  PUT_SESSION_PLACES_SUCCESS: 'PUT_SESSION_PLACES_SUCCESS',
  PUT_SESSION_PLACES_FAILURE: 'PUT_SESSION_PLACES_FAILURE',

  GET_SESSION_TRANSLATION_REQUEST: 'GET_SESSION_TRANSLATION_REQUEST',
  GET_SESSION_TRANSLATION_SUCCESS: 'GET_SESSION_TRANSLATION_SUCCESS',
  GET_SESSION_TRANSLATION_FAILURE: 'GET_SESSION_TRANSLATION_FAILURE',

  POST_SESSION_TRANSLATION_REQUEST: 'POST_SESSION_TRANSLATION_REQUEST',
  POST_SESSION_TRANSLATION_SUCCESS: 'POST_SESSION_TRANSLATION_SUCCESS',
  POST_SESSION_TRANSLATION_FAILURE: 'POST_SESSION_TRANSLATION_FAILURE',

  PUT_SESSION_TRANSLATION_REQUEST: 'PUT_SESSION_TRANSLATION_REQUEST',
  PUT_SESSION_TRANSLATION_SUCCESS: 'PUT_SESSION_TRANSLATION_SUCCESS',
  PUT_SESSION_TRANSLATION_FAILURE: 'PUT_SESSION_TRANSLATION_FAILURE',

  CLEAR_SESSION: 'CLEAR_SESSION',
};
