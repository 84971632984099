import { all } from 'redux-saga/effects';
import userSaga from './User';
import sessionsSaga from './Sessions';
import programsSaga from './Programs';
import placesSaga from './Places';
import brandingSaga from './Branding';
import performersSaga from './Performers';
import uploadsSaga from './Uploads';
import contentArticlesSaga from './ContentArticles';
import contentNewsSaga from './ContentNews';
import sessionTracksSaga from './SessionTracks';
import projectSaga from './Project';
import notificationsSaga from './Notifications';
import appMobileSaga from './AppMobile';
import addressesSaga from './Addresses';

export default function* appSaga() {
  yield all([
    appMobileSaga(),
    brandingSaga(),
    contentArticlesSaga(),
    contentNewsSaga(),
    notificationsSaga(),
    performersSaga(),
    placesSaga(),
    programsSaga(),
    projectSaga(),
    sessionsSaga(),
    sessionTracksSaga(),
    uploadsSaga(),
    userSaga(),
    addressesSaga(),
  ]);
}
