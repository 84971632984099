import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { programsConstants } from '../constants';
import {
  getProgramsListSuccess,
  getProgramsListFailure,
  getProgramSessionsSuccess,
  getProgramSessionsFailure,
  postProgramSessionsSuccess,
  postProgramSessionsFailure,
} from '../actions';
import {
  getProgramsByProjectApi,
  getProgramSessionsApi,
  postProgramSessionsApi,
} from '../api';

export function* fetchGetProgramsByProject(action: any): any {
  try {
    const programs = yield call(getProgramsByProjectApi, action.idProject);
    yield put(getProgramsListSuccess(programs));
  } catch (error) {
    yield put(getProgramsListFailure(error));
  }
}

export function* fetchGetProgramSessions(action: any): any {
  try {
    const sessions = yield call(getProgramSessionsApi, {
      id: action.id,
      args: { limit: 1000 },
    });
    yield put(getProgramSessionsSuccess(sessions));
  } catch (error) {
    yield put(getProgramSessionsFailure(error));
  }
}

export function* fetchPostProgramSessions(action: any): any {
  try {
    const session = yield call(postProgramSessionsApi, {
      idProgram: action.idProgram,
      newSession: action.newSession,
    });
    yield put(postProgramSessionsSuccess(session));
  } catch (error) {
    yield put(postProgramSessionsFailure(error));
  }
}

export function* getProgramsByProject() {
  yield takeLatest(
    programsConstants.GET_PROGRAMS_LIST_REQUEST,
    fetchGetProgramsByProject
  );
}

export function* getProgramSessions() {
  yield takeLatest(
    programsConstants.GET_PROGRAM_SESSIONS_REQUEST,
    fetchGetProgramSessions
  );
}

export function* postProgramSession() {
  yield takeLatest(
    programsConstants.POST_PROGRAM_SESSIONS_REQUEST,
    fetchPostProgramSessions
  );
}

export default function* programsSaga() {
  yield all([
    fork(getProgramsByProject),
    fork(getProgramSessions),
    fork(postProgramSession),
  ]);
}
