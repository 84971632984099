import React from 'react';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import Translator from '../../../../services/translator';
import TextField from '@mui/material/TextField';
import FilterClear from '../FilterClear';

const style = {
  paddingBottom: 15,
  paddingRight: 15,
  height: 65,
  width: '100%',
  paddingLeft: 0,
};

const FilterCellPerformer = ({ column, filter, onFilter, ...restProps }) => {
  const filterValue = filter && filter.value;
  const onChange = (e) =>
    onFilter({ columnName: column.name, value: e.target.value });

  const handleReset = () => {
    onFilter({ columnName: column.name, value: '' });
  };

  let content = <TableFilterRow.Cell />;

  if (column.name === 'name') {
    const defaultValue = JSON.parse(localStorage.getItem('paramsPerformer'))
      ? JSON.parse(localStorage.getItem('paramsPerformer'))[column.name]
        ? JSON.parse(localStorage.getItem('paramsPerformer'))[column.name].value
        : ''
      : '';

    content = (
      <TableFilterRow.Cell {...restProps} style={style} column={column}>
        <TextField
          label={Translator.trans('appModule.filter')}
          value={defaultValue}
          onChange={onChange}
          InputProps={{
            endAdornment: (filterValue || defaultValue) && (
              <FilterClear show={filterValue} handleReset={handleReset} />
            ),
          }}
          variant="standard"
        />
      </TableFilterRow.Cell>
    );
  }

  return content;
};

export default FilterCellPerformer;
