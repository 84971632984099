import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const TableContainer: React.FunctionComponent = (props: any) => (
  <Table.Table ref={props.tableRef} style={props.style} className={'my-5'}>
    {props.children}
  </Table.Table>
);

export default TableContainer;
