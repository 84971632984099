import React, { useEffect, useState } from 'react';
import { MainTextField, Modal } from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';
import { capitalizeFirstLetter } from '../../../services/capitalizeFirstLetter';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { currentProject } from '../../../Global/currentProject';
import { useDispatch, useSelector } from 'react-redux';
import { postPerformerRequest } from '../../../Redux/actions';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import { storeType } from '../../../index';
import { checkReduxResponse } from '../../../services/checkReduxResponse';

const PerformerModalCreation = (props: {
  newPerformerStr: string;
  open: boolean;
  close: () => void;
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [loadingSend, setLoadingSend] = useState(false);

  const newPerformer = useSelector(
    (state: storeType) => state.performers.newPerformer
  );

  useEffect(() => {
    if (props.newPerformerStr) {
      handleChange(props.newPerformerStr);
    }
  }, [props.newPerformerStr]);

  useEffect(() => {
    if (newPerformer) {
      setLoadingSend(newPerformer.loading);
      if (checkReduxResponse(newPerformer, 'performer')) {
        props.close();
      }
    }
  }, [props, newPerformer]);

  const handleChange = (value: string) => {
    setName(capitalizeFirstLetter(value));
  };

  const handleSubmit = () => {
    if (!name) {
      NotificationManager.warning(Translator.trans('message.title.missing'));
    } else {
      dispatch(
        postPerformerRequest(currentProject.id, {
          name: name,
        })
      );
    }
  };

  return (
    <Modal
      title={Translator.trans('performers.add.title')}
      width="md"
      open={props.open}
      close={props.close}
    >
      <div className="row mt-4">
        <div className="col-4">
          <h4>{Translator.trans('performers.title.name')}</h4>
        </div>
        <div className="col-8">
          <MainTextField
            className="m-0"
            value={name}
            onChange={(event) => handleChange(event.target.value)}
          />
        </div>
      </div>
      <ButtonsSaveModal
        isLoading={loadingSend}
        onClose={props.close}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default PerformerModalCreation;
