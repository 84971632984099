import React from 'react';
import SessionsTable from './SessionsTable/SessionsTable';
import Translator from '../../services/translator';
import { CreateButton, HeaderBanner } from '@emisys/audience-sdk-ui-react';
import { NavLink } from 'react-router-dom';
import { pagesConstant } from '../../Constants/Constant';
import QueryBuilder from '@mui/icons-material/QueryBuilder';

const Sessions = () => (
  <div className="app-wrapper div-wrapper-title">
    <div className="headerAlign">
      <HeaderBanner
        title={Translator.trans('sessions.title')}
        icon={<QueryBuilder />}
      />
      <NavLink to={pagesConstant.sessions.add}>
        <CreateButton>{Translator.trans('sessions.title.add')}</CreateButton>
      </NavLink>
    </div>

    <div className="row">
      <div className="col-12">
        <div className="jr-card">
          <SessionsTable />
        </div>
      </div>
    </div>
  </div>
);

export default Sessions;
