import { contentArticlesConstants } from '../constants';

export const getContentArticlesListRequest = (idProject: string | number) => ({
  type: contentArticlesConstants.GET_CONTENT_ARTICLES_REQUEST,
  idProject,
});

export const getContentArticlesListSuccess = (contentArticles: any) => ({
  type: contentArticlesConstants.GET_CONTENT_ARTICLES_SUCCESS,
  contentArticles,
});

export const getContentArticlesListFailure = (error: any) => ({
  type: contentArticlesConstants.GET_CONTENT_ARTICLES_FAILURE,
  error,
});

export const postContentArticlesRequest = (
  idProject: string | number,
  newContentArticle: any
) => ({
  type: contentArticlesConstants.POST_CONTENT_ARTICLES_REQUEST,
  idProject,
  newContentArticle,
});

export const postContentArticlesSuccess = (newContentArticle: any) => ({
  type: contentArticlesConstants.POST_CONTENT_ARTICLES_SUCCESS,
  newContentArticle,
});

export const postContentArticlesFailure = (error: any) => ({
  type: contentArticlesConstants.POST_CONTENT_ARTICLES_FAILURE,
  error,
});

export const putContentArticlesRequest = (updatedContentArticle: any) => ({
  type: contentArticlesConstants.PUT_CONTENT_ARTICLES_REQUEST,
  updatedContentArticle,
});

export const putContentArticlesSuccess = (updatedContentArticle: any) => ({
  type: contentArticlesConstants.PUT_CONTENT_ARTICLES_SUCCESS,
  updatedContentArticle,
});

export const putContentArticlesFailure = (error: any) => ({
  type: contentArticlesConstants.PUT_CONTENT_ARTICLES_FAILURE,
  error,
});

export const putContentArticlesPositionRequest = (
  updatedContentArticlePosition: any
) => ({
  type: contentArticlesConstants.PUT_CONTENT_ARTICLES_POSITION_REQUEST,
  updatedContentArticlePosition,
});

export const putContentArticlesPositionSuccess = (
  updatedContentArticlePosition: any
) => ({
  type: contentArticlesConstants.PUT_CONTENT_ARTICLES_POSITION_SUCCESS,
  updatedContentArticlePosition,
});

export const putContentArticlesPositionFailure = (error: any) => ({
  type: contentArticlesConstants.PUT_CONTENT_ARTICLES_POSITION_FAILURE,
  error,
});

export const deleteContentArticlesRequest = (
  contentArticleId: number | string
) => ({
  type: contentArticlesConstants.DELETE_CONTENT_ARTICLES_REQUEST,
  contentArticleId,
});

export const deleteContentArticlesSuccess = (deletedContentArticle: any) => ({
  type: contentArticlesConstants.DELETE_CONTENT_ARTICLES_SUCCESS,
  deletedContentArticle,
});

export const deleteContentArticlesFailure = (error: any) => ({
  type: contentArticlesConstants.DELETE_CONTENT_ARTICLES_FAILURE,
  error,
});

export const getContentArticleRequest = (
  idContentArticle: number | string
) => ({
  type: contentArticlesConstants.GET_CONTENT_ARTICLE_REQUEST,
  idContentArticle,
});

export const getContentArticleSuccess = (contentArticle: any) => ({
  type: contentArticlesConstants.GET_CONTENT_ARTICLE_SUCCESS,
  contentArticle,
});

export const getContentArticleFailure = (error: any) => ({
  type: contentArticlesConstants.GET_CONTENT_ARTICLE_FAILURE,
  error,
});

export const getContentArticleTranslationRequest = (
  idContentArticle: number | string
) => ({
  type: contentArticlesConstants.GET_CONTENT_ARTICLE_TRANSLATION_REQUEST,
  idContentArticle,
});

export const getContentArticleTranslationSuccess = (
  translationContentArticleList: any
) => ({
  type: contentArticlesConstants.GET_CONTENT_ARTICLE_TRANSLATION_SUCCESS,
  translationContentArticleList,
});

export const getContentArticleTranslationFailure = (error: any) => ({
  type: contentArticlesConstants.GET_CONTENT_ARTICLE_TRANSLATION_FAILURE,
  error,
});

export const postContentArticleTranslationRequest = (
  articleId: string | number,
  translationContentArticle: any
) => ({
  type: contentArticlesConstants.POST_CONTENT_ARTICLE_TRANSLATION_REQUEST,
  articleId,
  translationContentArticle,
});

export const postContentArticleTranslationSuccess = (
  translationContentArticle: any
) => ({
  type: contentArticlesConstants.POST_CONTENT_ARTICLE_TRANSLATION_SUCCESS,
  translationContentArticle,
});

export const postContentArticleTranslationFailure = (error: any) => ({
  type: contentArticlesConstants.POST_CONTENT_ARTICLE_TRANSLATION_FAILURE,
  error,
});

export const putContentArticleTranslationRequest = (
  articleId: number | string,
  updatedTranslationContentArticle: any
) => ({
  type: contentArticlesConstants.PUT_CONTENT_ARTICLE_TRANSLATION_REQUEST,
  articleId,
  updatedTranslationContentArticle,
});

export const putContentArticleTranslationSuccess = (
  updatedTranslationContentArticle: any
) => ({
  type: contentArticlesConstants.PUT_CONTENT_ARTICLE_TRANSLATION_SUCCESS,
  updatedTranslationContentArticle,
});

export const putContentArticleTranslationFailure = (error: any) => ({
  type: contentArticlesConstants.PUT_CONTENT_ARTICLE_TRANSLATION_FAILURE,
  error,
});

export const clearContentArticle = () => ({
  type: contentArticlesConstants.CLEAR_CONTENT_ARTICLES,
});
