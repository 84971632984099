import PlaceModel from './PlaceModel';

export default class SessionPlaceModel {
  id: number;
  place: PlaceModel;
  session: { id: number };

  constructor(data: any) {
    this.id = data.id;
    this.place = new PlaceModel(data.place);
    this.session = data.session;
  }
}
