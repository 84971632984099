export const notificationsConstants = {
  GET_NOTIFICATIONS_LIST_REQUEST: 'GET_NOTIFICATIONS_LIST_REQUEST',
  GET_NOTIFICATIONS_LIST_SUCCESS: 'GET_NOTIFICATIONS_LIST_SUCCESS',
  GET_NOTIFICATIONS_LIST_FAILURE: 'GET_NOTIFICATIONS_LIST_FAILURE',

  POST_NOTIFICATIONS_REQUEST: 'POST_NOTIFICATIONS_REQUEST',
  POST_NOTIFICATIONS_SUCCESS: 'POST_NOTIFICATIONS_SUCCESS',
  POST_NOTIFICATIONS_FAILURE: 'POST_NOTIFICATIONS_FAILURE',

  CLEAR_NOTIFICATIONS_REQUEST: 'CLEAR_NOTIFICATIONS_REQUEST',
};
