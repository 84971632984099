import React, { useState } from 'react';
import { formatDateTime } from '../../../services/formatDate';
import Translator from '../../../services/translator';
import SubNotificationItem from './SubNotificationItem';
import NotificationModel, {
  NotificationStatus,
} from '../../../Models/NotificationModel';
import { StatusComponent } from '@emisys/audience-sdk-ui-react';
import { getStatusName } from '../../../services/getStatusName';
import '../Notifications.css';

const NotificationTableItem = (props: { notification: NotificationModel }) => {
  const [openSubNotificationCard, setOpenSubNotificationCard] = useState(false);

  const handleClick = () => {
    setOpenSubNotificationCard(!openSubNotificationCard);
  };

  return (
    <div className="notification-item" onClick={handleClick}>
      <div className="notification-item-info">
        <div className={'col-10'}>
          <div className="notification-item-title">
            {props.notification.name}
          </div>
          <div>
            {props.notification.createdAt &&
              Translator.trans('notifications.table.createdAt', {
                date: formatDateTime(props.notification.createdAt),
              })}
          </div>
        </div>
        <div className={'col-2'}>
          <StatusComponent
            status={
              props.notification.status === NotificationStatus.delivery
                ? 'accepted'
                : 'denied'
            }
            label={getStatusName(props.notification.status)}
          />
        </div>
      </div>

      <SubNotificationItem
        open={openSubNotificationCard}
        notification={props.notification}
      />
    </div>
  );
};

export default NotificationTableItem;
