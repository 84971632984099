import React, { Component } from 'react';
import { connect } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from 'react-select';
import Translator from '../../../services/translator';
import SelectHelper from '../../../components/SelectHelperSessions/index';
import {
  getPerformersListRequest,
  getProgramsListRequest,
  getPlacesListRequest,
  getSessionTracksListRequest,
  clearPlaces,
} from '../../../Redux/actions';
import { UnderlineTitle, MainTextField } from '@emisys/audience-sdk-ui-react';
import ModalPlacesCreation from '../../Places/PlacesCreation/PlacesModalCreation';
import { currentProject } from '../../../Global/currentProject';
import DatePickerComponent from '../../../components/DatePicker/DatePicker';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import { getNameLanguage } from '../../../services/GetNameLanguage';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import SessionTranslations from '../../Sessions/SessionContent/SessionTranslations';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import '../index.css';

class SessionCreationContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      locale: '',
      liveStartDateTimeUTC: new Date(),
      liveEndDateTimeUTC: new Date(new Date().getTime() + 60 * 60 * 1000),
      timeToBeConfirmed: false,
      highlighted: true,
      signedLanguages: true,
      track: null,
      openModalPlaces: false,
      newPlaceStr: null,
      loadingSelect: false,
      currentTracks: [],
      sessionPerformers: [],
      sessionPlaces: [],
    };
  }

  componentDidMount() {
    this.props.getSessionTracksListRequest(currentProject.id);
    this.props.getPlacesListRequest(currentProject.id);
    this.props.getPerformersListRequest(currentProject.id, {
      order: 'name:ASC',
    });
    this.props.getProgramsListRequest(currentProject.id);

    if (
      !this.props.session.liveStartDateTimeUTC &&
      !this.props.session.liveEndDateTimeUTC
    ) {
      this.props.handleChangeSession(
        'liveStartDateTimeUTC',
        this.state.liveStartDateTimeUTC
      );
      this.props.handleChangeSession(
        'liveEndDateTimeUTC',
        this.state.liveEndDateTimeUTC
      );
    } else {
      this.setState({
        liveStartDateTimeUTC: this.props.session.liveStartDateTimeUTC,
        liveEndDateTimeUTC: this.props.session.liveEndDateTimeUTC,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.translationPlace !== prevProps.translationPlace) {
      if (checkReduxResponse(this.props.translationPlace, 'place')) {
        this.props.getPlacesListRequest(currentProject.id);
      }
    }

    if (this.props.programsList !== prevProps.programsList) {
      if (checkReduxResponse(this.props.programsList, 'programs')) {
        this.props.handleChangeSession(
          'program',
          this.props.programsList.programs[0].id
        );
      }
    }

    if (this.props.sessionTracks !== prevProps.sessionTracks) {
      if (checkReduxResponse(this.props.sessionTracks, 'sessionTracks')) {
        this.setState({
          currentTracks: this.props.sessionTracks.sessionTracks,
        });
      }
    }

    if (this.props.newPlace !== prevProps.newPlace) {
      if (checkReduxResponse(this.props.newPlace, 'place')) {
        const oldPlaces = [...this.state.sessionPlaces];
        oldPlaces.push(this.props.newPlace.place.id);
        this.handleChangePlace(oldPlaces);
        NotificationManager.success(
          Translator.trans('places.message.success.added')
        );
        this.props.clearPlaces();
      }
    }
  }

  handleChangeIsConfirmDate = (e) => {
    this.props.handleChangeSession('timeToBeConfirmed', e.target.checked);

    this.setState({ timeToBeConfirmed: e.target.checked });

    if (e.target.checked) {
      let newStartDate = new Date(this.state.liveStartDateTimeUTC);
      let newEndDate = new Date(this.state.liveStartDateTimeUTC);
      this.setState({
        liveStartDateTimeUTC: new Date(newStartDate.setHours(0, 0, 0, 0)),
        liveEndDateTimeUTC: new Date(newEndDate.setHours(23, 59, 59, 999)),
      });
      this.handleChangeLiveStartDateTime(newStartDate);
      this.handleChangeLiveEndDateTime(newEndDate);
    } else {
      this.setState({
        liveStartDateTimeUTC: this.state.liveStartDateTimeUTC,
        liveEndDateTimeUTC: this.state.liveEndDateTimeUTC,
      });
    }
  };

  handleChangeLiveStartDateTime = (date) => {
    if (this.state.timeToBeConfirmed) {
      const liveStartNewDate = new Date(date.setHours(0, 0, 0, 0));
      const liveEndNewDate = new Date(date.setHours(23, 59, 59, 999));
      this.setState({
        liveStartDateTimeUTC: liveStartNewDate,
        liveEndDateTimeUTC: liveEndNewDate,
      });
      this.props.handleChangeSession('liveStartDateTimeUTC', liveStartNewDate);
      this.props.handleChangeSession('liveEndDateTimeUTC', liveEndNewDate);
    } else {
      this.props.handleChangeSession('liveStartDateTimeUTC', date);
      this.setState({ liveStartDateTimeUTC: date });
    }
  };

  handleChangeLiveEndDateTime = (date) => {
    this.props.handleChangeSession('liveEndDateTimeUTC', date);
    this.setState({ liveEndDateTimeUTC: date });
  };

  handleChangeTitle = (event) => {
    this.props.handleChangeSession('title', event.target.value);
    this.setState({ title: event.target.value });
  };

  handleChangeSelectLocale = (selectedOption) => {
    this.props.handleChangeSession('locale', selectedOption.value);
    this.setState({ locale: selectedOption });
  };

  handleChangeSelectTrack = (selectedOption) => {
    this.props.handleChangeSession('track', selectedOption.value);
    this.setState({ track: selectedOption });
  };

  handleChangePerformer = (performerList) => {
    this.props.handleChangeSession('performers', performerList);
    this.setState({ sessionPerformers: performerList });
  };

  handleChangePlace = (placeList) => {
    this.props.handleChangeSession('places', placeList);
    this.setState({ sessionPlaces: placeList });
  };

  render() {
    const {
      locale,
      track,
      openModalPlaces,
      newPlaceStr,
      currentTracks,
      sessionPerformers,
      sessionPlaces,
      timeToBeConfirmed,
      liveStartDateTimeUTC,
      liveEndDateTimeUTC,
    } = this.state;

    return (
      <div className="mt-4">
        <UnderlineTitle title={Translator.trans('sessions.add.subtitle')} />
        <div className="row">
          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('cms.title')}</h4>
            </div>
            <div className="col-12 col-md-5 p-0">
              <MainTextField
                value={this.props.session.title}
                onChange={this.handleChangeTitle}
              />
            </div>
          </div>
          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('sessions.title.start')}</h4>
            </div>
            <div className="col-12 col-md-5 p-0">
              <div className="row">
                <div className="col-7">
                  <div className="form-group text-field">
                    <DatePickerComponent
                      selected={liveStartDateTimeUTC}
                      onChange={this.handleChangeLiveStartDateTime}
                      showTimeSelect
                    />
                  </div>
                </div>
                <div className="col-5">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="toConfirmedSession"
                        checked={timeToBeConfirmed}
                        value="secondary"
                        color="primary"
                        onChange={this.handleChangeIsConfirmDate}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label={Translator.trans(
                      'sessions.title.hour.confirm.label'
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          {!timeToBeConfirmed && (
            <div className="col-12 information">
              <div className="col-12 col-md-4 p-0">
                <h4>{Translator.trans('sessions.title.end')}</h4>
              </div>
              <div className="col-12 col-md-5 p-0">
                <div className="row">
                  <div className="col-7">
                    <div className="form-group text-field">
                      <DatePickerComponent
                        selected={liveEndDateTimeUTC}
                        minDate={liveEndDateTimeUTC}
                        onChange={this.handleChangeLiveEndDateTime}
                        showTimeSelect
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('sessions.title.language')}</h4>
            </div>
            <div className="col-12 col-md-5 p-0">
              <div className="row">
                <div className="col-12">
                  <Select
                    fullWidth
                    isClearable
                    isLoading={!currentProject.allowedLanguages}
                    labelId="demo-simple-select-label"
                    id="select-locale"
                    value={locale}
                    onChange={this.handleChangeSelectLocale}
                    options={currentProject.allowedLanguages.map(
                      (language) => ({
                        value: language,
                        label: getNameLanguage(
                          currentProject.language.locale,
                          language
                        ),
                      })
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('sessions.title.theme')}</h4>
            </div>
            <div className="col-12 col-md-5 p-0">
              <div className="row">
                <div className="col-12">
                  <Select
                    fullWidth
                    isClearable
                    labelId="demo-simple-select-label"
                    id="select-track"
                    value={track}
                    onChange={this.handleChangeSelectTrack}
                    options={currentTracks.map((data) => ({
                      value: data.id,
                      label: data.name,
                    }))}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('stepper.performer.more.performers')}</h4>
            </div>
            <div className="col-12 col-md-5 p-0">
              <SelectHelper
                creatable={false}
                isLoading={this.props.performersList?.loading}
                listItems={this.props.performersList?.performers}
                items={sessionPerformers}
                updateItem={this.handleChangePerformer}
                labelSelect={'name'}
              />
            </div>
          </div>
          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('places.title')}</h4>
            </div>
            <div className="col-12 col-md-5 p-0">
              <SelectHelper
                id="select-place"
                creatable={true}
                isLoading={this.props.placesList?.loading}
                items={sessionPlaces}
                onClickAction={(text) => {
                  this.setState({
                    openModalPlaces: true,
                    newPlaceStr: text,
                  });
                }}
                listItems={this.props.placesList?.places}
                updateItem={this.handleChangePlace}
                labelSelect={'name'}
              />
            </div>
          </div>

          <div className="col-12 mt-4">
            <UnderlineTitle
              title={Translator.trans('sessions.title.description')}
            />
            {currentProject.allowedLanguages.map((language) => (
              <AccordionTranslation key={language} language={language}>
                <SessionTranslations
                  handleChangeTranslation={
                    this.props.handleChangeTranslationSession
                  }
                  language={language}
                />
              </AccordionTranslation>
            ))}
          </div>
        </div>
        <ModalPlacesCreation
          open={openModalPlaces}
          newPlace={newPlaceStr}
          close={() => this.setState({ openModalPlaces: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionTracks: state.sessionTracks.sessionTracksList,
  performersList: state.performers.performersList,
  placesList: state.places.placesList,
  programsList: state.programs.programsList,
  translationPlace: state.places.translationPlace,
  newPlace: state.places.newPlace,
});

export default connect(mapStateToProps, {
  getSessionTracksListRequest,
  getPlacesListRequest,
  getPerformersListRequest,
  getProgramsListRequest,
  clearPlaces,
})(SessionCreationContent);
