import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Login } from '@emisys/audience-sdk-ui-react';
import { getUserProjectsListRequest } from '../../Redux/actions';
import Translator from '../../services/translator';
import { getUrl, post } from '../../Redux/services';
import { addTokenToStorage } from '../../services/handleToken';
import { Alert } from '@mui/material';

const SignIn = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [networkError, setNetworkError] = useState('');
  const [loading, setLoading] = useState(false);

  const getUserSelfProjects = useCallback(() => {
    dispatch(getUserProjectsListRequest());
  }, [dispatch]);

  const handleSignIn = () => {
    setLoading(true);
    post(getUrl('login', null, ''), {
      username: email,
      password: password,
    })
      .then((data) => {
        addTokenToStorage(data);
        getUserSelfProjects();
      })
      .catch((error) => {
        setNetworkError(
          error?.body?.message ?? Translator.trans('appModule.message.error')
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setNetworkError('');
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setNetworkError('');
  };

  const handleSubmitForgotPassword = () => {
    alert('TODO : Send request forgot password');
  };

  return (
    <Login
      title={Translator.trans('signIn.identify')}
      emailLabel={Translator.trans('appModule.email')}
      email={email}
      handleEmail={handleChangeEmail}
      passwordLabel={Translator.trans('appModule.password')}
      password={password}
      handlePassword={handleChangePassword}
      errorMessage={
        networkError && <Alert severity="error">{networkError}</Alert>
      }
      connexionLabel={Translator.trans('appModule.connection')}
      loader={loading}
      disabled={email === ''}
      handleConfirm={handleSignIn}
      forgotPasswordLabel={Translator.trans('appModule.forgotPassword')}
      forgotPasswordLabelButton={Translator.trans('appModule.reset')}
      handleSubmitForgotPassword={handleSubmitForgotPassword}
      returnToLoginlabel={Translator.trans('appModule.back')}
      forgotPasswordLabelDesc={Translator.trans(
        'appModule.forgotPassword.desc'
      )}
    />
  );
};

export default SignIn;
