import React from 'react';
import { formatDateTime } from '../../../services/formatDate';
import NewsModel from '../../../Models/NewsModel';
import './index.css';

interface ContentNewsModalVisualizeProps {
  news: NewsModel;
  locale: string;
  timezone: string;
}

const ContentNewsModalVisualize = (props: ContentNewsModalVisualizeProps) => {
  const { news, locale, timezone } = props;
  let date: string | null = '';
  if (news && news.publishDate) {
    date = formatDateTime(news.publishDate, locale, timezone);
  }
  return (
    <div>
      <div className="row">
        {news.picture && (
          <div className={'img-news-container'}>
            <img src={news.picture.original} alt="News" />
          </div>
        )}
        <div className="col-10">
          <h2>{news.title}</h2>
        </div>
      </div>
      <p>{date}</p>
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: news.content }}
      />
      {news.externalLinkUrl && (
        <div>
          <a
            href={news.externalLinkUrl}
            target="_BLANK"
            rel="noopener noreferrer"
          >
            {news.externalLinkLabel
              ? news.externalLinkLabel
              : news.externalLinkUrl}
          </a>
        </div>
      )}
    </div>
  );
};

export default ContentNewsModalVisualize;
