import React, { Component } from 'react';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedFiltering,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { connect } from 'react-redux';
import {
  clearSessionTrack,
  getSessionTracksListRequest,
} from '../../../Redux/actions';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import Translator from '../../../services/translator';
import ColumnCell from '../../../components/Table/Columns/ColumnCell';
import SessionsTracksCell from '../../../components/Table/Cells/SessionTracks/SessionTracksCell';
import { Loading } from '@emisys/audience-sdk-ui-react';
import NoData from '../../../components/Table/NoData/NoData';
import { currentProject } from '../../../Global/currentProject';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import TableContainer from '../../../components/Table/TableContainer/TableContainer';
import SortLabel from '../../../components/Table/Sorting/SortLabel';
import { filteringColumnExtensions } from '../../../components/Table/Filters/FilterExtensions';
import { sortingColumnExtensions } from '../../../components/Table/Sorting/SortingExtensions';
import {
  hideSortingButtons,
  rowPerTablePage,
} from '../../../components/Table/Constants/Constant';
import PagingTable from '../../../components/Table/Paging/PagingTable';
import {
  columnsSessionTracks,
  tableColumnExtensionsSessionTracks,
} from '../../../components/Table/Columns/ShowColumns';

class SessionTracksTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
    };
  }

  componentDidMount() {
    this.props.getSessionTracksListRequest(currentProject.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sessionTracksList !== this.props.sessionTracksList) {
      this.setState({ loading: this.props.sessionTracksList.loading });

      if (checkReduxResponse(this.props.sessionTracksList, 'sessionTracks')) {
        this.setState({ rows: this.props.sessionTracksList.sessionTracks });
      }
    }

    if (
      this.props.updatedTranslationSessionTrack !==
      prevProps.updatedTranslationSessionTrack
    ) {
      if (
        checkReduxResponse(
          this.props.updatedTranslationSessionTrack,
          'sessionTrack'
        )
      ) {
        NotificationManager.success(
          Translator.trans('sessionTracks.message.success.updated')
        );
        this.props.getSessionTracksListRequest(currentProject.id);
      }
    }

    if (this.props.oldSessionTrackSelf !== prevProps.oldSessionTrackSelf) {
      if (checkReduxResponse(this.props.oldSessionTrackSelf, 'sessionTrack')) {
        this.props.getSessionTracksListRequest(currentProject.id);
        NotificationManager.success(
          Translator.trans('sessionTracks.message.success.deleted')
        );
      }
      if (this.props.oldSessionTrackSelf?.error) {
        NotificationManager.error(this.props.oldSessionTrackSelf.error.message);
      }
    }
  }

  render() {
    const { loading, rows } = this.state;

    return (
      <div>
        <Grid rows={rows} columns={columnsSessionTracks}>
          <PagingState />
          <SortingState columnExtensions={hideSortingButtons} />
          <IntegratedSorting columnExtensions={sortingColumnExtensions} />
          <IntegratedFiltering columnExtensions={filteringColumnExtensions} />
          <IntegratedPaging />
          <Table
            columnExtensions={tableColumnExtensionsSessionTracks}
            cellComponent={SessionsTracksCell}
            tableComponent={TableContainer}
            noDataCellComponent={NoData}
          />

          <TableHeaderRow
            showSortingControls
            sortLabelComponent={SortLabel}
            cellComponent={ColumnCell}
          />
          <PagingPanel
            pageSizes={rowPerTablePage}
            containerComponent={PagingTable}
          />
        </Grid>
        {loading && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionTracksList: state.sessionTracks.sessionTracksList,
  oldSessionTrackSelf: state.sessionTracks.oldSessionTrackSelf,
  updatedTranslationSessionTrack:
    state.sessionTracks.updatedTranslationSessionTrack,
});

export default connect(mapStateToProps, {
  getSessionTracksListRequest,
  clearSessionTrack,
})(SessionTracksTable);
