import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationTableItem from './NotificationTableItem';
import { getNotificationsListRequest } from '../../../Redux/actions';
import {
  EmptyText,
  Loading,
  UnderlineTitle,
} from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';
import { currentProject } from '../../../Global/currentProject';
import NotificationModel from '../../../Models/NotificationModel';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import { storeType } from '../../../index';

const NotificationsTable = () => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);
  const [loading, setLoading] = useState(false);

  const notificationsList = useSelector(
    (state: storeType) => state.notifications.notificationsList
  );

  useEffect(() => {
    dispatch(getNotificationsListRequest(currentProject.id));
  }, [dispatch]);

  useEffect(() => {
    if (notificationsList) {
      setLoading(notificationsList.loading);

      if (checkReduxResponse(notificationsList, 'notifications')) {
        setNotifications(notificationsList.notifications);
      }
    }
  }, [notificationsList]);

  return (
    <div>
      <UnderlineTitle title={Translator.trans('notifications.table.title')} />

      {notifications.length > 0 ? (
        notifications.map((notification: NotificationModel) => (
          <NotificationTableItem
            key={notification.id}
            notification={notification}
          />
        ))
      ) : (
        <EmptyText>{Translator.trans('notifications.table.no.data')}</EmptyText>
      )}

      {loading && <Loading />}
    </div>
  );
};

export default NotificationsTable;
