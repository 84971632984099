import React, { ReactNode } from 'react';
import { currentProject } from '../../Global/currentProject';
import './FooterSave.css';

interface FooterSaveProps {
  children: ReactNode;
  type?: 'start' | 'between' | 'end';
}

const FooterSave = (props: FooterSaveProps) => {
  const { children, type = 'end' } = props;

  return (
    currentProject.isActive && (
      <div className={`footer-save-container ${type}`}>{children}</div>
    )
  );
};

export default FooterSave;
