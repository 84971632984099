import { contentNewsConstants } from '../constants';

export const getContentNewsListRequest = (idProject: string | number) => ({
  type: contentNewsConstants.GET_CONTENT_NEWS_LIST_REQUEST,
  idProject,
});

export const getContentNewsListSuccess = (contentNews: any) => ({
  type: contentNewsConstants.GET_CONTENT_NEWS_LIST_SUCCESS,
  contentNews,
});

export const getContentNewsListFailure = (error: any) => ({
  type: contentNewsConstants.GET_CONTENT_NEWS_LIST_FAILURE,
  error,
});

export const postContentNewsRequest = (
  idProject: string | number,
  newContentNews: any
) => ({
  type: contentNewsConstants.POST_CONTENT_NEWS_REQUEST,
  idProject,
  newContentNews,
});

export const postContentNewsSuccess = (newContentNews: any) => ({
  type: contentNewsConstants.POST_CONTENT_NEWS_SUCCESS,
  newContentNews,
});

export const postContentNewsFailure = (error: any) => ({
  type: contentNewsConstants.POST_CONTENT_NEWS_FAILURE,
  error,
});

export const putContentNewsRequest = (updatedContentNews: any) => ({
  type: contentNewsConstants.PUT_CONTENT_NEWS_REQUEST,
  updatedContentNews,
});

export const putContentNewsSuccess = (updatedContentNews: any) => ({
  type: contentNewsConstants.PUT_CONTENT_NEWS_SUCCESS,
  updatedContentNews,
});

export const putContentNewsFailure = (error: any) => ({
  type: contentNewsConstants.PUT_CONTENT_NEWS_FAILURE,
  error,
});

export const putContentNewsPositionRequest = (
  updatedContentNewsPosition: any
) => ({
  type: contentNewsConstants.PUT_CONTENT_NEWS_POSITION_REQUEST,
  updatedContentNewsPosition,
});

export const putContentNewsPositionSuccess = (
  updatedContentNewsPosition: any
) => ({
  type: contentNewsConstants.PUT_CONTENT_NEWS_POSITION_SUCCESS,
  updatedContentNewsPosition,
});

export const putContentNewsPositionFailure = (error: any) => ({
  type: contentNewsConstants.PUT_CONTENT_NEWS_POSITION_FAILURE,
  error,
});

export const getContentNewsRequest = (idContentNews: string | number) => ({
  type: contentNewsConstants.GET_CONTENT_NEWS_REQUEST,
  idContentNews,
});

export const getContentNewsSuccess = (contentNewsDetails: any) => ({
  type: contentNewsConstants.GET_CONTENT_NEWS_SUCCESS,
  contentNewsDetails,
});

export const getContentNewsFailure = (error: any) => ({
  type: contentNewsConstants.GET_CONTENT_NEWS_FAILURE,
  error,
});

export const deleteContentNewsRequest = (idContentNews: string | number) => ({
  type: contentNewsConstants.DELETE_CONTENT_NEWS_REQUEST,
  idContentNews,
});

export const deleteContentNewsSuccess = (deletedContentNews: any) => ({
  type: contentNewsConstants.DELETE_CONTENT_NEWS_SUCCESS,
  deletedContentNews,
});

export const deleteContentNewsFailure = (error: any) => ({
  type: contentNewsConstants.DELETE_CONTENT_NEWS_FAILURE,
  error,
});

export const getContentNewsTranslationRequest = (
  idContentNews: string | number
) => ({
  type: contentNewsConstants.GET_CONTENT_NEWS_TRANSLATION_REQUEST,
  idContentNews,
});

export const getContentNewsTranslationSuccess = (
  translationContentNewsList: any
) => ({
  type: contentNewsConstants.GET_CONTENT_NEWS_TRANSLATION_SUCCESS,
  translationContentNewsList,
});

export const getContentNewsTranslationFailure = (error: any) => ({
  type: contentNewsConstants.GET_CONTENT_NEWS_TRANSLATION_FAILURE,
  error,
});

export const postContentNewsTranslationRequest = (
  newsId: string | number,
  translationContentNews: any
) => ({
  type: contentNewsConstants.POST_CONTENT_NEWS_TRANSLATION_REQUEST,
  newsId,
  translationContentNews,
});

export const postContentNewsTranslationSuccess = (
  translationContentNews: any
) => ({
  type: contentNewsConstants.POST_CONTENT_NEWS_TRANSLATION_SUCCESS,
  translationContentNews,
});

export const postContentNewsTranslationFailure = (error: any) => ({
  type: contentNewsConstants.POST_CONTENT_NEWS_TRANSLATION_FAILURE,
  error,
});

export const putContentNewsTranslationRequest = (
  newsId: string | number,
  updatedTranslationContentNews: any
) => ({
  type: contentNewsConstants.PUT_CONTENT_NEWS_TRANSLATION_REQUEST,
  updatedTranslationContentNews,
  newsId,
});

export const putContentNewsTranslationSuccess = (
  updatedTranslationContentNews: any
) => ({
  type: contentNewsConstants.PUT_CONTENT_NEWS_TRANSLATION_SUCCESS,
  updatedTranslationContentNews,
});

export const putContentNewsTranslationFailure = (error: any) => ({
  type: contentNewsConstants.PUT_CONTENT_NEWS_TRANSLATION_FAILURE,
  error,
});

export const clearPutContentNews = () => ({
  type: contentNewsConstants.CLEAR_CONTENT_NEWS,
});
