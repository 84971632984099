import React, { useEffect, useState } from 'react';
import { HTMLEditor, MainTextField } from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';

const NewsTranslations = (props: {
  translation?: any;
  handleChangeTranslation: (
    name: string,
    value: string,
    locale: string
  ) => void;
  language: string;
}) => {
  const [newsTitle, setNewsTitle] = useState('');
  const [newsContent, setNewsContent] = useState('');

  const onChangeContent = (value: string) => {
    props.handleChangeTranslation('content', value, props.language);
  };

  useEffect(() => {
    if (props.translation) {
      setNewsTitle(props.translation.title || '');
      setNewsContent(props.translation.content || '');
    }
  }, [props.translation]);

  const handleChangeTitle = (value: string) => {
    setNewsTitle(value);
    props.handleChangeTranslation('title', value, props.language);
  };

  return (
    <div className="row mt-3">
      <div className="col-12 col-md-4 textFieldLabel mt-2">
        <h4>{Translator.trans('cms.title')}</h4>
      </div>
      <div className="col-12 col-md-8 mt-4">
        <MainTextField
          value={newsTitle}
          onChange={(event) => handleChangeTitle(event.target.value)}
        />
      </div>
      <div className="col-12 col-md-4 textFieldLabel mt-4">
        <h4>{Translator.trans('cms.content')}</h4>
      </div>
      <div className="col-12 col-md-8 mt-4 mb-4">
        <HTMLEditor onChange={onChangeContent} value={newsContent} />
      </div>
    </div>
  );
};

export default NewsTranslations;
