import { getUrl, get, post } from '../../services';

export async function getNotificationsByProjectApi(params: any) {
  return await get(getUrl('projects/' + params.idProject + '/notifications'));
}

export async function postNotificationsByProjectApi(params: any) {
  return await post(
    getUrl('projects/' + params.idProject + '/notifications'),
    params.newNotification
  );
}
