import ProgramModel from './ProgramModel';
import SessionPlaceModel from './SessionPlaceModel';
import SessionTrackModel from './SessionTrackModel';
import { selectItemsInterface } from '../Constants/Constant';
import SessionPerformerModel from './SessionPerformerModel';
import { getNameLanguage } from '../services/GetNameLanguage';
import { currentProject } from '../Global/currentProject';

export default class SessionModel {
  id: number;
  confirmed: boolean;
  description: string;
  external_id: null | number;
  highlighted: boolean;
  liveEndDateTimeUTC: Date | null;
  liveStartDateTimeUTC: Date | null;
  locale: selectItemsInterface | null;
  program: ProgramModel | null;
  sessionPlaces: SessionPlaceModel[];
  sessionPerformers: SessionPerformerModel[];
  signedLanguages: boolean;
  timeToBeConfirmed: boolean;
  title: string;
  track: SessionTrackModel | null;

  constructor(data: any) {
    this.id = data.id;
    this.confirmed = data.confirmed;
    this.description = data.description;
    this.external_id = data.external_id;
    this.highlighted = data.highlighted;
    this.liveEndDateTimeUTC = data.liveEndDateTimeUTC
      ? new Date(data.liveEndDateTimeUTC)
      : null;
    this.liveStartDateTimeUTC = data.liveStartDateTimeUTC
      ? new Date(data.liveStartDateTimeUTC)
      : null;
    this.program = data.program ? new ProgramModel(data.program) : null;
    this.sessionPlaces = data.sessionPlaces
      ? data.sessionPlaces.map((place: any) => new SessionPlaceModel(place))
      : [];
    this.signedLanguages = data.signedLanguages;
    this.timeToBeConfirmed = data.timeToBeConfirmed;
    this.title = data.title ? data.title : '';
    this.track = data.track ? new SessionTrackModel(data.track) : null;
    this.locale = data.locale ? createLocale(data.locale) : null;
    this.sessionPerformers = data.sessionPerformers
      ? data.sessionPerformers.map(
          (performer: any) => new SessionPerformerModel(performer)
        )
      : [];
  }
}

const createLocale = (language: string): selectItemsInterface => ({
  value: language,
  label: getNameLanguage(currentProject.language.locale, language),
});
