import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { checkReduxResponse } from '../../services/checkReduxResponse';
import { storeType } from '../../index';
import UserSelfModel from '../../Models/UserSelfModel';
import './index.css';

const PersonalContentSide = () => {
  const [currentUser, setCurrentUser] = useState<UserSelfModel | null>(null);
  const userSelfDetails = useSelector(
    (state: storeType) => state.user.userSelfDetails
  );

  useEffect(() => {
    if (checkReduxResponse(userSelfDetails, 'details')) {
      setCurrentUser(userSelfDetails.details);
    }
  }, [userSelfDetails]);

  return (
    <aside className="col-12 asideProfile">
      <Grid container justifyContent="center" alignItems="center">
        <Avatar
          alt="image speaker"
          src={
            currentUser?.pictureFullWebPath
              ? currentUser.pictureFullWebPath
              : ''
          }
          className="avatarProfile"
        />
      </Grid>
      <h2>{currentUser?.fullName}</h2>
    </aside>
  );
};

export default PersonalContentSide;
