import ImageModel, { createImageModel } from './ImageModel';

export default class BrandingModel {
  id: number;
  brandingName: string;
  colorBackground: string;
  colorPrimary: string;
  colorSecondary: string;
  facebookPixel: string;
  facebookUrl: string;
  googleAdwordConversionId: null | number;
  googleAdwordConversionLabel: string;
  googleAnalyticsCode: string;
  googleTagManagerId: string;
  instagramUrl: string;
  pictureBanner: ImageModel | null;
  pictureLogo: ImageModel | null;
  pictureMap: ImageModel | null;
  printAtHomeTranslations: { id: number }[];
  qrCodePictureLogo: ImageModel | null;
  shopBackground: ImageModel | null;
  twitterUrl: string;
  websiteUrl: string;

  constructor(data: any) {
    this.id = data.id;
    this.brandingName = data.brandingName;
    this.colorBackground = data.colorBackground ?? '';
    this.colorPrimary = data.colorPrimary ?? '';
    this.colorSecondary = data.colorSecondary ?? '';
    this.facebookPixel = data.facebookPixel ?? '';
    this.facebookUrl = data.facebookUrl ?? '';
    this.googleAdwordConversionId = data.googleAdwordConversionId;
    this.googleAdwordConversionLabel = data.googleAdwordConversionLabel ?? '';
    this.googleAnalyticsCode = data.googleAnalyticsCode ?? '';
    this.googleTagManagerId = data.googleTagManagerId ?? '';
    this.instagramUrl = data.instagramUrl ?? '';
    this.pictureBanner = data.pictureBanner
      ? createImageModel(data.pictureBanner)
      : null;
    this.pictureLogo = data.pictureLogo
      ? createImageModel(data.pictureLogo)
      : null;
    this.pictureMap = data.pictureMap
      ? createImageModel(data.pictureMap)
      : null;
    this.printAtHomeTranslations = data.printAtHomeTranslations;
    this.qrCodePictureLogo = data.qrCodePictureLogo
      ? createImageModel(data.qrCodePictureLogo)
      : null;
    this.shopBackground = data.shopBackground
      ? createImageModel(data.shopBackground)
      : null;
    this.twitterUrl = data.twitterUrl ?? '';
    this.websiteUrl = data.websiteUrl ?? '';
  }
}
