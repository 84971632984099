import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';
import NotificationsCreationModalContent from './NotificationsCreationModalContent';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import { currentProject } from '../../../Global/currentProject';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { storeType } from '../../../index';
import {
  clearNotifications,
  getNotificationsListRequest,
  postNotificationsRequest,
} from '../../../Redux/actions';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';

const ModalNotificationCreation = (props: {
  open: boolean;
  close: () => void;
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [dateSendAfter, setDateSendAfter] = useState<Date | null>(null);
  const [currentName, setCurrentName] = useState('');
  const [errors, setErrors] = useState(null);
  const translationNotification: {
    current: any[];
  } = useRef([]);

  const newNotification = useSelector(
    (state: storeType) => state.notifications.newNotification
  );

  useEffect(() => {
    if (newNotification) {
      setIsLoading(newNotification.loading);
      if (checkReduxResponse(newNotification, 'notification')) {
        dispatch(getNotificationsListRequest(currentProject.id));
        NotificationManager.success(
          Translator.trans('notifications.messages.success.add')
        );
        dispatch(clearNotifications());
        props.close();
      }

      if (newNotification.error) {
        NotificationManager.error(
          Translator.trans(newNotification.error.message)
        );
        if (newNotification.error.formErrors) {
          setErrors(newNotification.error.formErrors);
        }
        dispatch(clearNotifications());
      }
    }
  }, [dispatch, newNotification, props]);

  const handleChangeTranslation = (
    name: string,
    value: string,
    locale: string
  ) => {
    if (locale === currentProject.defaultLanguage && name === 'heading') {
      setCurrentName(value);
    }

    let objIndex = translationNotification.current.findIndex(
      (item: any) => item.locale === locale
    );
    if (objIndex > -1) {
      translationNotification.current[objIndex][name] = value;
    } else {
      translationNotification.current.push({
        locale: locale,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    setErrors(null);
    dispatch(
      postNotificationsRequest(currentProject.id, {
        name: currentName,
        dateSendAfter: dateSendAfter,
        translations: translationNotification.current,
      })
    );
  };

  return (
    <Modal
      title={Translator.trans('notifications.add.title')}
      width="md"
      open={props.open}
      close={props.close}
    >
      <div className="col-12">
        <NotificationsCreationModalContent
          handleChangeTranslation={handleChangeTranslation}
          handleChangeDate={setDateSendAfter}
          errors={errors}
        />
        <ButtonsSaveModal
          onClose={props.close}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          isDisabled={!dateSendAfter || !currentName}
        />
      </div>
    </Modal>
  );
};

export default ModalNotificationCreation;
