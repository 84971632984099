import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearPutContentNews,
  getContentNewsListRequest,
} from '../../Redux/actions';
import { NavLink } from 'react-router-dom';
import { HeaderBanner, CreateButton } from '@emisys/audience-sdk-ui-react';
import { NotificationManager } from '../../components/Notification/NotificationManager';
import { currentProject } from '../../Global/currentProject';
import Translator from '../../services/translator';
import { pagesConstant } from '../../Constants/Constant';
import { MenuBook } from '@mui/icons-material';
import { checkReduxResponse } from '../../services/checkReduxResponse';
import { storeType } from '../../index';
import NewsTable from './NewsTable/NewsTable';

export const ContentNews = () => {
  const dispatch = useDispatch();

  const updatedContentNews = useSelector(
    (state: storeType) => state.contentNews.updatedContentNews
  );
  const deletedContentNews = useSelector(
    (state: storeType) => state.contentNews.deletedContentNews
  );
  const updatedContentNewsPosition = useSelector(
    (state: storeType) => state.contentNews.updatedContentNewsPosition
  );

  useEffect(() => {
    dispatch(getContentNewsListRequest(currentProject.id));
  }, [dispatch]);

  useEffect(() => {
    if (checkReduxResponse(updatedContentNews, 'contentNews')) {
      NotificationManager.success(
        Translator.trans('news.message.success.updated')
      );
      dispatch(clearPutContentNews());
    }
  }, [dispatch, updatedContentNews]);

  useEffect(() => {
    if (checkReduxResponse(deletedContentNews, 'contentNews')) {
      NotificationManager.success(
        Translator.trans('news.message.success.deleted')
      );
      dispatch(getContentNewsListRequest(currentProject.id));
      dispatch(clearPutContentNews());
    }
  }, [dispatch, deletedContentNews]);

  useEffect(() => {
    if (updatedContentNewsPosition) {
      if (checkReduxResponse(updatedContentNewsPosition, 'contentNews')) {
        NotificationManager.success(
          Translator.trans('cms.message.success.position.updated')
        );
      }
      dispatch(clearPutContentNews());
    }
  }, [dispatch, updatedContentNewsPosition]);

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('news.title')}
          icon={<MenuBook />}
        />
        <NavLink to={pagesConstant.news.add}>
          <CreateButton>
            {Translator.trans('news.button.add.new.title')}
          </CreateButton>
        </NavLink>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <NewsTable />
          </div>
        </div>
      </div>
    </div>
  );
};
