export const contentNewsConstants = {
  GET_CONTENT_NEWS_LIST_REQUEST: 'GET_CONTENT_NEWS_LIST_REQUEST',
  GET_CONTENT_NEWS_LIST_SUCCESS: 'GET_CONTENT_NEWS_LIST_SUCCESS',
  GET_CONTENT_NEWS_LIST_FAILURE: 'GET_CONTENT_NEWS_LIST_FAILURE',

  POST_CONTENT_NEWS_REQUEST: 'POST_CONTENT_NEWS_REQUEST',
  POST_CONTENT_NEWS_SUCCESS: 'POST_CONTENT_NEWS_SUCCESS',
  POST_CONTENT_NEWS_FAILURE: 'POST_CONTENT_NEWS_FAILURE',

  GET_CONTENT_NEWS_REQUEST: 'GET_CONTENT_NEWS_REQUEST',
  GET_CONTENT_NEWS_SUCCESS: 'GET_CONTENT_NEWS_SUCCESS',
  GET_CONTENT_NEWS_FAILURE: 'GET_CONTENT_NEWS_FAILURE',

  PUT_CONTENT_NEWS_REQUEST: 'PUT_CONTENT_NEWS_REQUEST',
  PUT_CONTENT_NEWS_SUCCESS: 'PUT_CONTENT_NEWS_SUCCESS',
  PUT_CONTENT_NEWS_FAILURE: 'PUT_CONTENT_NEWS_FAILURE',

  PUT_CONTENT_NEWS_POSITION_REQUEST: 'PUT_CONTENT_NEWS_POSITION_REQUEST',
  PUT_CONTENT_NEWS_POSITION_SUCCESS: 'PUT_CONTENT_NEWS_POSITION_SUCCESS',
  PUT_CONTENT_NEWS_POSITION_FAILURE: 'PUT_CONTENT_NEWS_POSITION_FAILURE',

  DELETE_CONTENT_NEWS_REQUEST: 'DELETE_CONTENT_NEWS_REQUEST',
  DELETE_CONTENT_NEWS_SUCCESS: 'DELETE_CONTENT_NEWS_SUCCESS',
  DELETE_CONTENT_NEWS_FAILURE: 'DELETE_CONTENT_NEWS_FAILURE',

  GET_CONTENT_NEWS_TRANSLATION_REQUEST: 'GET_CONTENT_NEWS_TRANSLATION_REQUEST',
  GET_CONTENT_NEWS_TRANSLATION_SUCCESS: 'GET_CONTENT_NEWS_TRANSLATION_SUCCESS',
  GET_CONTENT_NEWS_TRANSLATION_FAILURE: 'GET_CONTENT_NEWS_TRANSLATION_FAILURE',

  POST_CONTENT_NEWS_TRANSLATION_REQUEST:
    'POST_CONTENT_NEWS_TRANSLATION_REQUEST',
  POST_CONTENT_NEWS_TRANSLATION_SUCCESS:
    'POST_CONTENT_NEWS_TRANSLATION_SUCCESS',
  POST_CONTENT_NEWS_TRANSLATION_FAILURE:
    'POST_CONTENT_NEWS_TRANSLATION_FAILURE',

  PUT_CONTENT_NEWS_TRANSLATION_REQUEST: 'PUT_CONTENT_NEWS_TRANSLATION_REQUEST',
  PUT_CONTENT_NEWS_TRANSLATION_SUCCESS: 'PUT_CONTENT_NEWS_TRANSLATION_SUCCESS',
  PUT_CONTENT_NEWS_TRANSLATION_FAILURE: 'PUT_CONTENT_NEWS_TRANSLATION_FAILURE',

  CLEAR_CONTENT_NEWS: 'CLEAR_CONTENT_NEWS',
};
