export const userConstants = {
  GET_USER_DETAILS_REQUEST: 'GET_USER_DETAILS_REQUEST',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',

  GET_USER_PROJECTS_LIST_REQUEST: 'GET_USER_PROJECTS_LIST_REQUEST',
  GET_USER_PROJECTS_LIST_SUCCESS: 'GET_USER_PROJECTS_LIST_SUCCESS',
  GET_USER_PROJECTS_LIST_FAILURE: 'GET_USER_PROJECTS_LIST_FAILURE',

  GET_USER_PROJECT_REQUEST: 'GET_USER_PROJECT_REQUEST',
  GET_USER_PROJECT_SUCCESS: 'GET_USER_PROJECT_SUCCESS',
  GET_USER_PROJECT_FAILURE: 'GET_USER_PROJECT_FAILURE',

  PUT_USER_REQUEST: 'PUT_USER_REQUEST',
  PUT_USER_SUCCESS: 'PUT_USER_SUCCESS',
  PUT_USER_FAILURE: 'PUT_USER_FAILURE',
};
