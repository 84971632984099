import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getBrandingSuccess,
  getBrandingFailure,
  putBrandingSuccess,
  putBrandingFailure,
} from '../actions';
import { brandingConstants } from '../constants';
import { getBrandingByProjectApi, putBrandingByProjectApi } from '../api';

export function* fetchGetBrandingByProject(action: any): any {
  try {
    const branding = yield call(getBrandingByProjectApi, action.idProject);
    yield put(getBrandingSuccess(branding));
  } catch (error) {
    yield put(getBrandingFailure(error));
  }
}

export function* fetchPutBrandingByProject(action: any): any {
  try {
    const updatedBranding = yield call(putBrandingByProjectApi, {
      idProject: action.idProject,
      updatedBranding: action.updatedBranding,
    });
    yield put(putBrandingSuccess(updatedBranding));
  } catch (error) {
    yield put(putBrandingFailure(error));
  }
}

export function* getBrandingByProject() {
  yield takeLatest(
    brandingConstants.GET_BRANDING_REQUEST,
    fetchGetBrandingByProject
  );
}

export function* putBrandingByProject() {
  yield takeLatest(
    brandingConstants.PUT_BRANDING_REQUEST,
    fetchPutBrandingByProject
  );
}

export default function* brandingSaga() {
  yield all([fork(getBrandingByProject), fork(putBrandingByProject)]);
}
