import { sessionTracksConstants } from '../constants';

export const getSessionTracksListRequest = (idProject: number | string) => ({
  type: sessionTracksConstants.GET_SESSION_TRACKS_REQUEST,
  idProject,
});

export const getSessionTracksListSuccess = (sessionTracks: any) => ({
  type: sessionTracksConstants.GET_SESSION_TRACKS_SUCCESS,
  sessionTracks,
});

export const getSessionTracksListFailure = (error: any) => ({
  type: sessionTracksConstants.GET_SESSION_TRACKS_FAILURE,
  error,
});

export const postSessionTrackRequest = (sessionTrack: any) => ({
  type: sessionTracksConstants.POST_SESSION_TRACKS_REQUEST,
  sessionTrack,
});

export const postSessionTrackSuccess = (sessionTrack: any) => ({
  type: sessionTracksConstants.POST_SESSION_TRACKS_SUCCESS,
  sessionTrack,
});

export const postSessionTrackFailure = (error: any) => ({
  type: sessionTracksConstants.POST_SESSION_TRACKS_FAILURE,
  error,
});

export const deleteSessionTrackRequest = (id: number | string) => ({
  type: sessionTracksConstants.DELETE_SESSION_TRACKS_REQUEST,
  id,
});

export const deleteSessionTrackSuccess = (oldSessionTrack: any) => ({
  type: sessionTracksConstants.DELETE_SESSION_TRACKS_SUCCESS,
  oldSessionTrack,
});

export const deleteSessionTrackFailure = (error: any) => ({
  type: sessionTracksConstants.DELETE_SESSION_TRACKS_FAILURE,
  error,
});

export const getSessionTrackTranslationRequest = (
  idSessionTrack: number | string
) => ({
  type: sessionTracksConstants.GET_SESSION_TRACKS_TRANSLATION_REQUEST,
  idSessionTrack,
});

export const getSessionTrackTranslationSuccess = (translations: any) => ({
  type: sessionTracksConstants.GET_SESSION_TRACKS_TRANSLATION_SUCCESS,
  translations,
});

export const getSessionTrackTranslationFailure = (error: any) => ({
  type: sessionTracksConstants.GET_SESSION_TRACKS_TRANSLATION_FAILURE,
  error,
});

export const postSessionTrackTranslationRequest = (
  sessionTrackId: number | string,
  translationSessionTrack: any
) => ({
  type: sessionTracksConstants.POST_SESSION_TRACKS_TRANSLATION_REQUEST,
  sessionTrackId,
  translationSessionTrack,
});

export const postSessionTrackTranslationSuccess = (translations: any) => ({
  type: sessionTracksConstants.POST_SESSION_TRACKS_TRANSLATION_SUCCESS,
  translations,
});

export const postSessionTrackTranslationFailure = (error: any) => ({
  type: sessionTracksConstants.POST_SESSION_TRACKS_TRANSLATION_FAILURE,
  error,
});

export const putSessionTrackTranslationRequest = (
  sessionTrackId: number | string,
  translations: any
) => ({
  type: sessionTracksConstants.PUT_SESSION_TRACKS_TRANSLATION_REQUEST,
  sessionTrackId,
  translations,
});

export const putSessionTrackTranslationSuccess = (translations: any) => ({
  type: sessionTracksConstants.PUT_SESSION_TRACKS_TRANSLATION_SUCCESS,
  translations,
});

export const putSessionTrackTranslationFailure = (error: any) => ({
  type: sessionTracksConstants.PUT_SESSION_TRACKS_TRANSLATION_FAILURE,
  error,
});

export const clearSessionTrack = () => ({
  type: sessionTracksConstants.CLEAR_SESSION_TRACK,
});
