import { contentArticlesConstants } from '../constants';
import ArticleModel from '../../Models/ArticleModel';

const initialState: any = {
  contentArticlesList: null,
  newContentArticle: null,
  updatedContentArticle: null,
  updatedContentArticlePosition: null,
  deletedContentArticle: null,
  contentArticle: null,
  translationArticle: null,
  updatedTranslationArticle: null,
  contentArticleList: null,
};

let listContentArticles: ArticleModel[] = [];
const contentArticles = (state = initialState, action: any) => {
  switch (action.type) {
    case contentArticlesConstants.GET_CONTENT_ARTICLES_REQUEST: {
      return {
        ...state,
        contentArticlesList: {
          contentArticles: listContentArticles,
          error: false,
          loading: true,
        },
      };
    }

    case contentArticlesConstants.GET_CONTENT_ARTICLES_SUCCESS: {
      listContentArticles = action.contentArticles.data.map(
        (article: any) => new ArticleModel(article)
      );

      return {
        ...state,
        contentArticlesList: {
          contentArticles: action.contentArticles.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.GET_CONTENT_ARTICLES_FAILURE: {
      return {
        ...state,
        contentArticlesList: {
          contentArticles: null,
          error: true,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.POST_CONTENT_ARTICLES_REQUEST: {
      return {
        ...state,
        newContentArticle: {
          contentArticle: null,
          error: false,
          loading: true,
        },
      };
    }

    case contentArticlesConstants.POST_CONTENT_ARTICLES_SUCCESS: {
      return {
        ...state,
        newContentArticle: {
          contentArticle: action.newContentArticle.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.POST_CONTENT_ARTICLES_FAILURE: {
      return {
        ...state,
        newContentArticle: {
          contentArticles: null,
          error: true,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.PUT_CONTENT_ARTICLES_REQUEST: {
      return {
        ...state,
        updatedContentArticle: {
          contentArticle: null,
          error: false,
          loading: true,
        },
      };
    }

    case contentArticlesConstants.PUT_CONTENT_ARTICLES_SUCCESS: {
      return {
        ...state,
        updatedContentArticle: {
          contentArticle: action.updatedContentArticle.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.PUT_CONTENT_ARTICLES_FAILURE: {
      return {
        ...state,
        updatedContentArticle: {
          contentArticles: null,
          error: true,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.PUT_CONTENT_ARTICLES_POSITION_REQUEST: {
      return {
        ...state,
        updatedContentArticlePosition: {
          contentArticle: null,
          error: false,
          loading: true,
        },
      };
    }

    case contentArticlesConstants.PUT_CONTENT_ARTICLES_POSITION_SUCCESS: {
      return {
        ...state,
        updatedContentArticlePosition: {
          contentArticle: action.updatedContentArticlePosition.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.PUT_CONTENT_ARTICLES_POSITION_FAILURE: {
      return {
        ...state,
        updatedContentArticlePosition: {
          contentArticles: null,
          error: true,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.DELETE_CONTENT_ARTICLES_REQUEST: {
      return {
        ...state,
        deletedContentArticle: {
          contentArticle: null,
          error: false,
          loading: true,
        },
      };
    }

    case contentArticlesConstants.DELETE_CONTENT_ARTICLES_SUCCESS: {
      return {
        ...state,
        deletedContentArticle: {
          contentArticle: action.deletedContentArticle.data
            ? action.deletedContentArticle.data
            : 'deleted',
          error: false,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.DELETE_CONTENT_ARTICLES_FAILURE: {
      return {
        ...state,
        deletedContentArticle: {
          contentArticles: null,
          error: true,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.GET_CONTENT_ARTICLE_REQUEST: {
      return {
        ...state,
        contentArticle: { contentArticle: null, error: false, loading: true },
      };
    }

    case contentArticlesConstants.GET_CONTENT_ARTICLE_SUCCESS: {
      return {
        ...state,
        contentArticle: {
          contentArticle: action.contentArticle.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.GET_CONTENT_ARTICLE_FAILURE: {
      return {
        ...state,
        contentArticle: { contentArticles: null, error: true, loading: false },
      };
    }

    case contentArticlesConstants.GET_CONTENT_ARTICLE_TRANSLATION_REQUEST: {
      return {
        ...state,
        translationContentArticleList: {
          contentArticleList: null,
          error: false,
          loading: true,
        },
      };
    }

    case contentArticlesConstants.GET_CONTENT_ARTICLE_TRANSLATION_SUCCESS: {
      return {
        ...state,
        translationContentArticleList: {
          contentArticleList: action.translationContentArticleList.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.GET_CONTENT_ARTICLE_TRANSLATION_FAILURE: {
      return {
        ...state,
        translationContentArticleList: {
          contentArticleList: null,
          error: true,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.POST_CONTENT_ARTICLE_TRANSLATION_REQUEST: {
      return {
        ...state,
        translationArticle: {
          contentArticle: null,
          error: false,
          loading: true,
        },
      };
    }

    case contentArticlesConstants.POST_CONTENT_ARTICLE_TRANSLATION_SUCCESS: {
      return {
        ...state,
        translationArticle: {
          contentArticle: action.translationContentArticle.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.POST_CONTENT_ARTICLE_TRANSLATION_FAILURE: {
      return {
        ...state,
        translationArticle: {
          contentArticle: null,
          error: true,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.PUT_CONTENT_ARTICLE_TRANSLATION_REQUEST: {
      return {
        ...state,
        updatedTranslationArticle: {
          contentArticle: null,
          error: false,
          loading: true,
        },
      };
    }

    case contentArticlesConstants.PUT_CONTENT_ARTICLE_TRANSLATION_SUCCESS: {
      return {
        ...state,
        updatedTranslationArticle: {
          contentArticle: action.updatedTranslationContentArticle.data,
          error: false,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.PUT_CONTENT_ARTICLE_TRANSLATION_FAILURE: {
      return {
        ...state,
        updatedTranslationArticle: {
          contentArticle: null,
          error: true,
          loading: false,
        },
      };
    }

    case contentArticlesConstants.CLEAR_CONTENT_ARTICLES: {
      return {
        ...state,
        newContentArticle: null,
        updatedContentArticle: null,
        updatedContentArticlePosition: null,
        deletedContentArticle: null,
        contentArticle: null,
        translationArticle: null,
        updatedTranslationArticle: null,
        contentArticlesList: null,
        contentArticleList: null,
      };
    }

    default:
      return state;
  }
};

export default contentArticles;
