import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getPerformersListSuccess,
  getPerformersListFailure,
  postPerformerSuccess,
  postPerformerFailure,
  getPerformerSuccess,
  getPerformerFailure,
  putPerformerSuccess,
  putPerformerFailure,
  deletePerformerSuccess,
  deletePerformerFailure,
  getPerformerTranslationsSuccess,
  getPerformerTranslationsFailure,
  postPerformerTranslationsSuccess,
  postPerformerTranslationsFailure,
  putPerformerTranslationsSuccess,
  putPerformerTranslationsFailure,
} from '../actions';
import { performersConstants } from '../constants';
import {
  getPerformersByProjectApi,
  postPerformersByProjectApi,
  getPerformerApi,
  putPerformerApi,
  deletePerformerApi,
  getTranslationPerformerApi,
  postTranslationPerformerSelfApi,
  putTranslationPerformerSelfApi,
} from '../api';

export function* fetchGetPerformersByProject(action: any): any {
  try {
    const performers = yield call(getPerformersByProjectApi, {
      idProject: action.idProject,
      args: action.options,
    });
    yield put(getPerformersListSuccess(performers));
  } catch (error) {
    yield put(getPerformersListFailure(error));
  }
}

export function* fetchPostPerformersByProject(action: any): any {
  try {
    const performer = yield call(postPerformersByProjectApi, {
      idProject: action.idProject,
      performer: action.performer,
    });
    yield put(postPerformerSuccess(performer));
  } catch (error) {
    yield put(postPerformerFailure(error));
  }
}

export function* fetchGetPerformer(action: any): any {
  try {
    const performerDetails = yield call(getPerformerApi, {
      idPerformer: action.id,
    });
    yield put(getPerformerSuccess(performerDetails));
  } catch (error) {
    yield put(getPerformerFailure(error));
  }
}

export function* fetchPutPerformer(action: any): any {
  try {
    const updatedPerformer = yield call(putPerformerApi, {
      idPerformer: action.performerId,
      performer: action.updatedPerformer,
    });
    yield put(putPerformerSuccess(updatedPerformer));
  } catch (error) {
    yield put(putPerformerFailure(error));
  }
}

export function* fetchDeletePerformer(action: any): any {
  try {
    const deletedPerformer = yield call(deletePerformerApi, action.idPerformer);
    yield put(deletePerformerSuccess(deletedPerformer));
  } catch (error) {
    yield put(deletePerformerFailure(error));
  }
}

export function* fetchGetTranslationPerformerSelf(action: any): any {
  try {
    const translationPerformerList = yield call(getTranslationPerformerApi, {
      id: action.idPerformer,
    });
    yield put(getPerformerTranslationsSuccess(translationPerformerList));
  } catch (error) {
    yield put(getPerformerTranslationsFailure(error));
  }
}

export function* fetchPostTranslationPerformerSelf(action: any): any {
  try {
    const translationPerformer = yield call(postTranslationPerformerSelfApi, {
      id: action.performerId,
      translationPerformer: action.translationPerformer,
    });
    yield put(postPerformerTranslationsSuccess(translationPerformer));
  } catch (error) {
    yield put(postPerformerTranslationsFailure(error));
  }
}

export function* fetchPutTranslationPerformerSelf(action: any): any {
  try {
    const updatedTranslationPerformer = yield call(
      putTranslationPerformerSelfApi,
      {
        id: action.performerId,
        updatedTranslationPerformer: action.updatedTranslationPerformer,
      }
    );
    yield put(putPerformerTranslationsSuccess(updatedTranslationPerformer));
  } catch (error) {
    yield put(putPerformerTranslationsFailure(error));
  }
}

export function* getPerformersByProject() {
  yield takeLatest(
    performersConstants.GET_PERFORMERS_LIST_REQUEST,
    fetchGetPerformersByProject
  );
}

export function* postPerformersByProject() {
  yield takeLatest(
    performersConstants.POST_PERFORMER_REQUEST,
    fetchPostPerformersByProject
  );
}

export function* getPerformer() {
  yield takeLatest(
    performersConstants.GET_PERFORMER_REQUEST,
    fetchGetPerformer
  );
}

export function* putPerformer() {
  yield takeLatest(
    performersConstants.PUT_PERFORMER_REQUEST,
    fetchPutPerformer
  );
}

export function* deletePerformer() {
  yield takeLatest(
    performersConstants.DELETE_PERFORMER_REQUEST,
    fetchDeletePerformer
  );
}

export function* getTranslationPerformerSelf() {
  yield takeLatest(
    performersConstants.GET_PERFORMER_TRANSLATIONS_REQUEST,
    fetchGetTranslationPerformerSelf
  );
}

export function* postTranslationPerformerSelf() {
  yield takeLatest(
    performersConstants.POST_PERFORMER_TRANSLATIONS_REQUEST,
    fetchPostTranslationPerformerSelf
  );
}

export function* putTranslationPerformerSelf() {
  yield takeLatest(
    performersConstants.PUT_PERFORMER_TRANSLATIONS_REQUEST,
    fetchPutTranslationPerformerSelf
  );
}

export default function* performersSaga() {
  yield all([
    fork(getPerformersByProject),
    fork(postPerformersByProject),
    fork(getPerformer),
    fork(putPerformer),
    fork(deletePerformer),
    fork(getTranslationPerformerSelf),
    fork(postTranslationPerformerSelf),
    fork(putTranslationPerformerSelf),
  ]);
}
