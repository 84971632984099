import React, { useState } from 'react';
import Translator from '../../../services/translator';
import { capitalizeFirstLetter } from '../../../services/capitalizeFirstLetter';
import { MainTextField } from '@emisys/audience-sdk-ui-react';

const SessionTrackCreationTranslations = (props: {
  handleChangeTranslation: (
    name: string,
    newValue: string,
    language: string
  ) => void;
  language: string;
}) => {
  const [name, setName] = useState('');

  const handleChangeTranslation = (name: string) => (event: any) => {
    const newValue = capitalizeFirstLetter(event.target.value);
    setName(newValue);
    props.handleChangeTranslation(name, newValue, props.language);
  };

  return (
    <div className="row">
      <div className="col-12 col-md-4 mt-3">
        <h4>{Translator.trans('cms.title')}</h4>
      </div>
      <div className="col-12 col-md-8 mt-3">
        <MainTextField
          className="TitleSessionTracks"
          value={name}
          onChange={handleChangeTranslation('name')}
        />
      </div>
    </div>
  );
};

export default SessionTrackCreationTranslations;
