import React, { useMemo } from 'react';
import reactCSS from 'reactcss';
import { BlockPicker } from 'react-color';

const ColorPicker = (props: {
  label?: string;
  color: string;
  changeColor: (hex: string) => void;
}) => {
  const styles: any = reactCSS({
    default: {
      swatch: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
      },
    },
  });

  const colorsPickers = useMemo(
    () => [
      '#1abc9c',
      '#2ecc71',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#f39c12',
      '#d35400',
      '#c0392b',
      '#bdc3c7',
      '#7f8c8d',
    ],
    []
  );

  return (
    <div style={styles.swatch}>
      {props.label && <span>{props.label}</span>}
      <BlockPicker
        color={props.color}
        onChange={(colorPicker) => props.changeColor(colorPicker.hex)}
        colors={colorsPickers}
      />
    </div>
  );
};

export default ColorPicker;
