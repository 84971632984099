import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SortingState,
  PagingState,
  CustomPaging,
  FilteringState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  clearPerformer,
  clearSession,
  getPerformersListRequest,
} from '../../../Redux/actions';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import Translator from '../../../services/translator';
import ColumnCell from '../../../components/Table/Columns/ColumnCell';
import PerformersCell from '../../../components/Table/Cells/Performers/PerformersCell';
import { Loading } from '@emisys/audience-sdk-ui-react';
import NoData from '../../../components/Table/NoData/NoData';
import FilterCellPerformer from '../../../components/Table/Cells/Performers/FiltersCell';
import { currentProject } from '../../../Global/currentProject';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import {
  hideSortingButtons,
  rowPerTablePage,
} from '../../../components/Table/Constants/Constant';
import TableContainer from '../../../components/Table/TableContainer/TableContainer';
import SortLabel from '../../../components/Table/Sorting/SortLabel';
import PagingTable from '../../../components/Table/Paging/PagingTable';
import {
  columnsPerformers,
  tableColumnExtensionsPerformers,
} from '../../../components/Table/Columns/ShowColumns';
import { storeType } from '../../../index';
import PerformerModel from '../../../Models/PerformerModel';

const PerformersTable = () => {
  const dispatch = useDispatch();
  const localStoragePerformer = localStorage.getItem('paramsPerformer');
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [filters, setFilters] = useState<any[]>([]);
  const [objectCount, setObjectCount] = useState(0);

  const timeout: any = useRef(0);
  let params: any = useMemo(
    () => ({
      limit: currentPageSize,
      page: currentPage + 1,
      order: 'id:DESC',
    }),
    [currentPage, currentPageSize]
  );

  const performersList = useSelector(
    (state: storeType) => state.performers.performersList
  );
  const deletedPerformer = useSelector(
    (state: storeType) => state.performers.deletedPerformer
  );
  const newSessionPerformers = useSelector(
    (state: storeType) => state.sessions.newSessionPerformers
  );
  const updatedPerformer = useSelector(
    (state: storeType) => state.performers.updatedPerformer
  );

  const getPerformersList = useCallback(
    (params: any) => {
      dispatch(getPerformersListRequest(currentProject.id, params));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!localStoragePerformer) {
      getPerformersList(params);
      localStorage.setItem('paramsPerformer', JSON.stringify(params));
    }
  }, [getPerformersList, localStoragePerformer, params]);

  useEffect(() => {
    if (localStoragePerformer) {
      const newParams = JSON.parse(localStoragePerformer);

      setCurrentPage(newParams.page - 1);
      setCurrentPageSize(newParams.limit);
      getPerformersList(newParams);
    }
  }, [getPerformersList, localStoragePerformer]);

  useEffect(() => {
    if (performersList) {
      setLoading(performersList.loading);
      if (checkReduxResponse(performersList, 'performers')) {
        const newRows = performersList.performers.map(
          (performer: PerformerModel) => ({
            id: performer.id,
            name: performer.name,
            sessionPerformers: performer.sessionPerformers,
            headline: performer.headline,
            performer: performer,
          })
        );

        setRows(newRows);
        setObjectCount(performersList.meta.object_count);
      }
    }
  }, [performersList]);

  useEffect(() => {
    if (deletedPerformer) {
      if (checkReduxResponse(deletedPerformer, 'performer')) {
        getPerformersList(params);
        NotificationManager.success(
          Translator.trans('performers.message.success.deleted')
        );
        dispatch(clearPerformer());
      }
    }
  }, [dispatch, deletedPerformer, getPerformersList, params]);

  useEffect(() => {
    if (newSessionPerformers) {
      if (checkReduxResponse(newSessionPerformers, 'sessionPerformers')) {
        getPerformersList(params);
        NotificationManager.success(
          Translator.trans('sessions.message.success.added')
        );
        dispatch(clearSession());
      }
    }
  }, [dispatch, getPerformersList, newSessionPerformers, params]);

  useEffect(() => {
    if (updatedPerformer) {
      if (checkReduxResponse(updatedPerformer, 'performer')) {
        getPerformersList(params);
        NotificationManager.success(
          Translator.trans('performers.message.success.updated')
        );

        dispatch(clearPerformer());
      }
    }
  }, [dispatch, getPerformersList, params, updatedPerformer]);

  function handleCurrentPage(page: number) {
    setCurrentPage(page);
    params.page = page + 1;
    localStorage.setItem('paramsPerformer', JSON.stringify(params));
  }

  function setPageSize(pageSize: number) {
    setCurrentPageSize(pageSize);
    params.limit = pageSize;
    params.page = 1;
    localStorage.setItem('paramsPerformer', JSON.stringify(params));
  }

  const onFilter = (filter: any[]) => {
    setFilters(filter);
    filter.forEach((data) => {
      if (data.value && data.value !== '' && data.value !== 'all') {
        params[data.columnName] = {};
        params[data.columnName].operation = 'contain';
        params[data.columnName].value = data.value;
      } else {
        delete params[data.columnName];
      }
    });

    localStorage.setItem('paramsPerformer', JSON.stringify(params));
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      getPerformersList(params);
    }, 200);
  };

  const setSorting = (sort: any) => {
    sort.forEach((data: any) => {
      params.limit = currentPageSize;
      params.page = 1;
      params.order = data.columnName + ':' + data.direction.toUpperCase();
    });

    handleCurrentPage(0);

    getPerformersList(params);

    localStorage.setItem(
      'paramsPerformer',
      JSON.stringify({
        limit: currentPageSize,
        page: 1,
        order: 'id:DESC',
      })
    );
  };

  return (
    <div>
      <Grid rows={rows} columns={columnsPerformers}>
        <PagingState
          pageSize={currentPageSize}
          currentPage={currentPage}
          onCurrentPageChange={handleCurrentPage}
          onPageSizeChange={setPageSize}
        />
        <SortingState
          onSortingChange={setSorting}
          columnExtensions={hideSortingButtons}
        />
        <FilteringState onFiltersChange={onFilter} filters={filters} />
        <Table
          columnExtensions={tableColumnExtensionsPerformers}
          cellComponent={PerformersCell}
          tableComponent={TableContainer}
          noDataCellComponent={NoData}
        />
        <CustomPaging totalCount={objectCount} />
        <TableHeaderRow
          showSortingControls
          sortLabelComponent={SortLabel}
          cellComponent={ColumnCell}
        />
        <TableFilterRow cellComponent={FilterCellPerformer} />
        <PagingPanel
          pageSizes={rowPerTablePage}
          containerComponent={PagingTable}
        />
      </Grid>
      {loading && <Loading />}
    </div>
  );
};

export default PerformersTable;
