import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  columnsArticles,
  tableColumnExtensionsArticles,
} from '../../../components/Table/Columns/ShowColumns';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  hideSortingButtons,
  rowPerTablePage,
} from '../../../components/Table/Constants/Constant';
import { sortingColumnExtensions } from '../../../components/Table/Sorting/SortingExtensions';
import { filteringColumnExtensions } from '../../../components/Table/Filters/FilterExtensions';
import SortableTable from '../../../components/Table/Sortable/SortableTable/SortableTable';
import SortableRow from '../../../components/Table/Sortable/SortableRow/SortableRow';
import TableContainer from '../../../components/Table/TableContainer/TableContainer';
import NoData from '../../../components/Table/NoData/NoData';
import SortLabel from '../../../components/Table/Sorting/SortLabel';
import ColumnCell from '../../../components/Table/Columns/ColumnCell';
import FilterPlaceCell from '../../../components/Table/Cells/Places/FilterPlaceCell';
import PagingTable from '../../../components/Table/Paging/PagingTable';
import { Loading } from '@emisys/audience-sdk-ui-react';
import React, { useEffect, useState } from 'react';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import { useDispatch, useSelector } from 'react-redux';
import { storeType } from '../../../index';
import { putContentArticlesPositionRequest } from '../../../Redux/actions';
import { arrayMoveImmutable } from 'array-move';
import ArticleCell from '../../../components/Table/Cells/Article/ArticleCell';
import ArticleModel from '../../../Models/ArticleModel';

const ArticleTable = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState<any[]>([]);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);

  const contentArticlesList = useSelector(
    (state: storeType) => state.contentArticles.contentArticlesList
  );

  useEffect(() => {
    if (contentArticlesList) {
      setLoading(contentArticlesList.loading);
      if (checkReduxResponse(contentArticlesList, 'contentArticles')) {
        let newRows = contentArticlesList.contentArticles.map(
          (article: ArticleModel) => ({
            id: article.id,
            name: article.title,
            isActive: article.isActive,
            article: article,
          })
        );
        setRows(newRows);
      }
    }
  }, [contentArticlesList]);

  const setCurrentFilters = (filter: any) => {
    let newFilter = filter.filter(
      (data: any) => !(data.value === '' || data.value === 'all')
    );
    setFilters(newFilter);
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (newIndex !== oldIndex) {
      let array: any[] = arrayMoveImmutable(rows, oldIndex, newIndex);
      setRows(array);
      dispatch(
        putContentArticlesPositionRequest({
          id: array[newIndex].id,
          position: newIndex,
        })
      );
    }
  };

  return (
    <div>
      <Grid rows={rows} columns={columnsArticles}>
        <PagingState />
        <FilteringState onFiltersChange={setCurrentFilters} filters={filters} />
        <SortingState columnExtensions={hideSortingButtons} />
        <IntegratedSorting columnExtensions={sortingColumnExtensions} />
        <IntegratedFiltering columnExtensions={filteringColumnExtensions} />
        <IntegratedPaging />
        <Table
          columnExtensions={tableColumnExtensionsArticles}
          bodyComponent={(props) => (
            <SortableTable props={props} onSortEnd={onSortEnd} />
          )}
          rowComponent={(props) => <SortableRow props={props} rows={rows} />}
          cellComponent={ArticleCell}
          tableComponent={TableContainer}
          noDataCellComponent={NoData}
        />
        <TableHeaderRow
          showSortingControls
          sortLabelComponent={SortLabel}
          cellComponent={ColumnCell}
        />
        <TableFilterRow cellComponent={FilterPlaceCell} />
        <PagingPanel
          pageSizes={rowPerTablePage}
          containerComponent={PagingTable}
        />
      </Grid>
      {loading && <Loading />}
    </div>
  );
};

export default ArticleTable;
