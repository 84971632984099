import React from 'react';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Translator from '../../../../services/translator';
import DatePickerComponent from '../../../DatePicker/DatePicker';
import TextField from '@mui/material/TextField';
import FilterClear from '../FilterClear';

const style = {
  paddingBottom: 15,
  paddingRight: 15,
  height: 65,
  width: '100%',
  paddingLeft: 0,
};

const FilterSessionCell = ({ column, filter, onFilter, ...restProps }) => {
  const filterValue = filter && filter.value;
  const onChange = (e) =>
    onFilter({ columnName: column.name, value: e.target.value });

  const handleReset = () => {
    onFilter({ columnName: column.name, value: '' });
  };

  let content = <TableFilterRow.Cell />;

  const onChangeDate = (e) => {
    if (e === null) {
      onFilter({ columnName: column.name, value: null });
    } else {
      let dateStart = new Date(e);
      dateStart = new Date(
        dateStart.getFullYear(),
        dateStart.getMonth(),
        dateStart.getDate(),
        0,
        0,
        0
      );
      let dateEnd = new Date(e);
      dateEnd = new Date(
        dateEnd.getFullYear(),
        dateEnd.getMonth(),
        dateEnd.getDate(),
        23,
        59,
        59
      );
      const dateFormatted = new Date(e);
      onFilter({
        columnName: column.name,
        value: dateFormatted,
        values: {
          dateStart: dateStart,
          dateEnd: dateEnd,
        },
      });
    }
  };

  if (column.name === 'program') {
    content = (
      <TableFilterRow.Cell {...restProps} style={style} column={column}>
        <FormControl className="w-100 mt-3">
          <Select
            value={filterValue ? filterValue : 'all'}
            fullWidth
            onChange={onChange}
            variant="standard"
          >
            <MenuItem key={0} value="all">
              {Translator.trans('appModule.all')}
            </MenuItem>
            {column.programs &&
              column.programs.map((element) => (
                <MenuItem key={element.id} value={element.name}>
                  {element.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </TableFilterRow.Cell>
    );
  }

  if (
    column.name === 'liveStartDateTimeUTC' ||
    column.name === 'liveEndDateTimeUTC'
  ) {
    content = (
      <TableFilterRow.Cell {...restProps} style={style} column={column}>
        <DatePickerComponent
          customInput={
            <TextField
              label={Translator.trans('appModule.filter')}
              InputProps={{
                endAdornment: (
                  <FilterClear show={filterValue} handleReset={handleReset} />
                ),
              }}
              variant="standard"
            />
          }
          selected={filterValue ? filterValue : ''}
          onChange={onChangeDate}
          noBorder
        />
      </TableFilterRow.Cell>
    );
  }

  if (column.name === 'title') {
    content = (
      <TableFilterRow.Cell {...restProps} style={style} column={column}>
        <TextField
          label={Translator.trans('appModule.filter')}
          value={filterValue ? filterValue : ''}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <FilterClear show={filterValue} handleReset={handleReset} />
            ),
          }}
          variant="standard"
        />
      </TableFilterRow.Cell>
    );
  }

  if (column.name === 'performer') {
    let performers = column.performers;
    content = (
      <TableFilterRow.Cell {...restProps} style={style} column={column}>
        <FormControl className="w-100 mt-3">
          <Select
            value={filterValue ? filterValue : 'all'}
            fullWidth
            onChange={onChange}
            variant="standard"
          >
            <MenuItem key={0} value="all">
              {Translator.trans('appModule.all')}
            </MenuItem>
            {performers &&
              performers.map((element) => (
                <MenuItem key={element.id} value={element.name}>
                  {element.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </TableFilterRow.Cell>
    );
  }

  if (column.name === 'place') {
    let places = column.places;
    content = (
      <TableFilterRow.Cell {...restProps} style={style} column={column}>
        <FormControl className="w-100 mt-3">
          <Select
            value={filterValue ? filterValue : 'all'}
            fullWidth
            onChange={onChange}
            variant="standard"
          >
            <MenuItem key={0} value="all">
              {Translator.trans('appModule.all')}
            </MenuItem>
            {places &&
              places.map((element) => (
                <MenuItem key={element.id} value={element.name}>
                  {element.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </TableFilterRow.Cell>
    );
  }

  return content;
};

export default FilterSessionCell;
