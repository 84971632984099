import { appMobileConstants } from '../constants';

export const getAppMobileInfoRequest = (idProject: string | number) => ({
  type: appMobileConstants.GET_APP_MOBILE_INFO_REQUEST,
  idProject,
});

export const getAppMobileInfoSuccess = (appMobile: any) => ({
  type: appMobileConstants.GET_APP_MOBILE_INFO_SUCCESS,
  appMobile,
});

export const getAppMobileInfoFailure = (error: any) => ({
  type: appMobileConstants.GET_APP_MOBILE_INFO_FAILURE,
  error,
});

export const putAppMobileInfoRequest = (
  idProject: string | number,
  newAppMobile: any
) => ({
  type: appMobileConstants.PUT_APP_MOBILE_INFO_REQUEST,
  idProject,
  newAppMobile,
});

export const putAppMobileInfoSuccess = (newAppMobile: any) => ({
  type: appMobileConstants.PUT_APP_MOBILE_INFO_SUCCESS,
  newAppMobile,
});

export const putAppMobileInfoFailure = (error: any) => ({
  type: appMobileConstants.PUT_APP_MOBILE_INFO_FAILURE,
  error,
});

export const clearAppMobile = () => ({
  type: appMobileConstants.CLEAR_APP_MOBILE_REQUEST,
});
