export const validURL = (str: string) => {
  let pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator

  if (str === '') {
    return true;
  } else {
    return pattern.test(str);
  }
};

export const isEmailValid = (str: string) => {
  let pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  if (str === '') {
    return true;
  } else {
    return pattern.test(str);
  }
};

export const isVatValid = (str: string) => {
  let pattern = new RegExp(
    '^((AL)[JKL]{1}[0-9]{8}[A-Z]{1}|' + // Albania
      '(AR)[0-9]{11}|' + // Argentina
      '(AU)[0-9]{11}|' + // Australia
      '(AT)U[0-9]{8}|' + // Austria
      '(BY)[0-9]{9}|' + // Belarus
      '(BE)0[0-9]{9}|' + // Belgium
      '(BO)[0-9]{7}|' + // Bolivia
      '(BG)[0-9]{9,10}|' + // Bulgaria
      '(CA)[0-9]{9}|' + // Canada
      '(HR)[0-9]{11}|' + // Croatia
      '(CY)[0-9]{8}L|' + // Cyprus
      '(CZ)[0-9]{8,10}|' + // Czech Republic
      '(DK)[0-9]{8}|' + // Denmark
      '(EC)[0-9]{13}|' + // Ecuador
      '(EE)[0-9]{9}|' + // Estonia
      '(FI)[0-9]{8}|' + // Finland
      '(FR)[0-9A-Z]{2}[0-9]{9}|' + // France
      '(DE)[0-9]{9}|' + // Germany
      '(EL|GR)[0-9]{9}|' + // Greece
      '(HU)[0-9]{8}|' + // Hungary
      '(IS)[0-9]{5,6}|' + // Iceland
      '(IN)[0-9]{11}[CV]|' + // India
      '(ID)[0-9w.-]{20}|(ID)[0-9]{15}|' + // Indonesia
      '(IE)[0-9]S[0-9]{5}L|' + // Ireland
      '(IT)[0-9]{11}|' + // Italy
      '(LV)[0-9]{11}|' + // Latvia
      '(LT)([0-9]{9}|[0-9]{12})|' + // Lithuania
      '(LU)[0-9]{8}|' + // Luxembourg
      '(MT)[0-9]{8}|' + // Malta
      '(NL)[0-9]{9}B[0-9]{2}|' + // Netherlands
      '(NO)[0-9]{9}MVA|' + // Norway
      '(PE)[0-9]{11}|' + // Peru
      '(PH)[0-9]{12}|' + // Philippines
      '(PL)[0-9]{10}|' + // Poland
      '(PT)[0-9]{9}|' + // Portugal
      '(RO)[0-9]{2,10}|' + // Romania
      '(RU)[0-9]{10}|(RU)[0-9]{12}|' + // Russia
      '(SM)[0-9]{5}|' + // San marino
      '(RS)[0-9]{9}|' + // Serbia
      '(SK)[0-9]{10}|' + // Slovakia
      '(SI)[0-9]{8}|' + // Slovenia
      '(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|' + // Spain
      '(SE)[0-9]{12}|' + // Sweden
      '(CH)E[0-9]{9}(TVA|MWST|IVA)|(CH)E[0-9w.-]{12} (TVA|MWST|IVA)|' + // Switzerland
      '(TR)[0-9]{10}|' + // Turkey
      '(UA)[0-9]{12}|' + // Ukraine
      '(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3}))$' // United Kingdom
  );

  if (str === '') {
    return true;
  } else {
    return pattern.test(str);
  }
};
