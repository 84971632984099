import React from 'react';
import { Modal, MainButton } from '@emisys/audience-sdk-ui-react';
import Translator from '../../services/translator';
import './DeleteModal.css';

interface DeleteModalProps {
  title?: string | null;
  isOpen: boolean;
  onCancel: () => void;
  isLoading?: boolean;
  onConfirm: () => void;
}

const DeleteModal = (props: DeleteModalProps) => (
  <Modal
    className={'delete-modal-component'}
    title={props.title}
    width="sm"
    open={props.isOpen}
    close={props.onCancel}
  >
    <div className={'delete-modal-body'}>
      <h1>{Translator.trans('appModule.modal.delete.confirm.message')}</h1>
    </div>

    <div className={'delete-modal-footer'}>
      <MainButton
        className={'modal-delete-cancel-btn'}
        buttonType={'outline'}
        onClick={props.onCancel}
      >
        {Translator.trans('appModule.cancel')}
      </MainButton>

      <MainButton
        className={'modal-delete-btn'}
        cancel
        loading={props.isLoading}
        onClick={props.onConfirm}
        buttonType={'contain'}
      >
        {Translator.trans('appModule.delete')}
      </MainButton>
    </div>
  </Modal>
);

export default DeleteModal;
