import { getUrl, get, post } from '../../services';

export async function getProgramsByProjectApi(idProject: any) {
  return await get(getUrl('projects/' + idProject + '/programs'));
}

export async function getProgramSessionsApi(params: any) {
  return await get(getUrl('programs/' + params.id + '/sessions', params.args));
}

export async function postProgramSessionsApi(params: any) {
  return await post(
    getUrl('programs/' + params.idProgram + '/sessions'),
    params.newSession
  );
}
