import Translator from './translator';

export const getStatusName = (status: string): string => {
  switch (status) {
    case 'error':
      return Translator.trans('status.error');
    case 'delivery':
      return Translator.trans('status.delivery');
    default:
      return Translator.trans('status.unknown');
  }
};
