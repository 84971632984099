import { sessionsConstants } from '../constants';

export const getSessionsListRequest = (
  idProject: number | string,
  options: any
) => ({
  type: sessionsConstants.GET_SESSIONS_LIST_REQUEST,
  idProject,
  options,
});

export const getSessionsListSuccess = (sessions: any) => ({
  type: sessionsConstants.GET_SESSIONS_LIST_SUCCESS,
  sessions,
});

export const getSessionsListFailure = (error: any) => ({
  type: sessionsConstants.GET_SESSIONS_LIST_FAILURE,
  error,
});

export const getSessionRequest = (id: number | string) => ({
  type: sessionsConstants.GET_SESSION_REQUEST,
  id,
});

export const getSessionSuccess = (session: any) => ({
  type: sessionsConstants.GET_SESSION_SUCCESS,
  session,
});

export const getSessionFailure = (error: any) => ({
  type: sessionsConstants.GET_SESSION_FAILURE,
  error,
});

export const putSessionRequest = (updatedSession: any) => ({
  type: sessionsConstants.PUT_SESSION_REQUEST,
  updatedSession,
});

export const putSessionSuccess = (updatedSession: any) => ({
  type: sessionsConstants.PUT_SESSION_SUCCESS,
  updatedSession,
});

export const putSessionFailure = (error: any) => ({
  type: sessionsConstants.PUT_SESSION_FAILURE,
  error,
});

export const deleteSessionRequest = (id: number | string) => ({
  type: sessionsConstants.DELETE_SESSION_REQUEST,
  id,
});

export const deleteSessionSuccess = (oldSession: any) => ({
  type: sessionsConstants.DELETE_SESSION_SUCCESS,
  oldSession,
});

export const deleteSessionFailure = (error: any) => ({
  type: sessionsConstants.DELETE_SESSION_FAILURE,
  error,
});

export const postSessionPerformersRequest = (
  idSession: number | string,
  performers: any
) => ({
  type: sessionsConstants.POST_SESSION_PERFORMERS_REQUEST,
  idSession,
  performers,
});

export const postSessionPerformersSuccess = (sessionPerformers: any) => ({
  type: sessionsConstants.POST_SESSION_PERFORMERS_SUCCESS,
  sessionPerformers,
});

export const postSessionPerformersFailure = (error: any) => ({
  type: sessionsConstants.POST_SESSION_PERFORMERS_FAILURE,
  error,
});

export const putSessionPerformersRequest = (
  idSession: number | string,
  performers: any
) => ({
  type: sessionsConstants.PUT_SESSION_PERFORMERS_REQUEST,
  idSession,
  performers,
});

export const putSessionPerformersSuccess = (updatedSessionPerformers: any) => ({
  type: sessionsConstants.PUT_SESSION_PERFORMERS_SUCCESS,
  updatedSessionPerformers,
});

export const putSessionPerformersFailure = (error: any) => ({
  type: sessionsConstants.PUT_SESSION_PERFORMERS_FAILURE,
  error,
});

export const postSessionPlacesRequest = (
  idSession: number | string,
  places: any
) => ({
  type: sessionsConstants.POST_SESSION_PLACES_REQUEST,
  places,
  idSession,
});

export const postSessionPlacesSuccess = (sessionPlaces: any) => ({
  type: sessionsConstants.POST_SESSION_PLACES_SUCCESS,
  sessionPlaces,
});

export const postSessionPlacesFailure = (error: any) => ({
  type: sessionsConstants.POST_SESSION_PLACES_FAILURE,
  error,
});

export const putSessionPlacesRequest = (
  idSession: number | string,
  places: any
) => ({
  type: sessionsConstants.PUT_SESSION_PLACES_REQUEST,
  places,
  idSession,
});

export const putSessionPlacesSuccess = (updatedSessionPlaces: any) => ({
  type: sessionsConstants.PUT_SESSION_PLACES_SUCCESS,
  updatedSessionPlaces,
});

export const putSessionPlacesFailure = (error: any) => ({
  type: sessionsConstants.PUT_SESSION_PLACES_FAILURE,
  error,
});

export const getSessionTranslationRequest = (idSession: number | string) => ({
  type: sessionsConstants.GET_SESSION_TRANSLATION_REQUEST,
  idSession,
});

export const getSessionTranslationSuccess = (translationSessionList: any) => ({
  type: sessionsConstants.GET_SESSION_TRANSLATION_SUCCESS,
  translationSessionList,
});

export const getSessionTranslationFailure = (error: any) => ({
  type: sessionsConstants.GET_SESSION_TRANSLATION_FAILURE,
  error,
});

export const postSessionTranslationRequest = (
  sessionId: number | string,
  translationSession: any
) => ({
  type: sessionsConstants.POST_SESSION_TRANSLATION_REQUEST,
  sessionId,
  translationSession,
});

export const postSessionTranslationSuccess = (translationSession: any) => ({
  type: sessionsConstants.POST_SESSION_TRANSLATION_SUCCESS,
  translationSession,
});

export const postSessionTranslationFailure = (error: any) => ({
  type: sessionsConstants.POST_SESSION_TRANSLATION_FAILURE,
  error,
});

export const putSessionTranslationRequest = (
  sessionId: number | string,
  translations: any
) => ({
  type: sessionsConstants.PUT_SESSION_TRANSLATION_REQUEST,
  sessionId,
  translations,
});

export const putSessionTranslationSuccess = (
  updatedTranslationSession: any
) => ({
  type: sessionsConstants.PUT_SESSION_TRANSLATION_SUCCESS,
  updatedTranslationSession,
});

export const putSessionTranslationFailure = (error: any) => ({
  type: sessionsConstants.PUT_SESSION_TRANSLATION_FAILURE,
  error,
});

export const clearSession = () => ({
  type: sessionsConstants.CLEAR_SESSION,
});
