import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContentArticleTranslationRequest,
  putContentArticleTranslationRequest,
} from '../../../Redux/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { HeaderBanner, UnderlineTitle } from '@emisys/audience-sdk-ui-react';
import { LibraryBooks } from '@mui/icons-material';
import Translator from '../../../services/translator';
import { currentProject } from '../../../Global/currentProject';
import { pagesConstant } from '../../../Constants/Constant';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import { storeType } from '../../..';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import ContentArticleTranslations from '../ArticleContent/ContentArticleTranslations';
import '../index.css';

const ArticleEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingSend, setLoadingSend] = useState(false);
  const [currentTranslations, setCurrentTranslations] = useState<any[]>([]);

  const translationArticle = useRef<any[]>([]);

  const translationContentArticleList = useSelector(
    (state: storeType) => state.contentArticles.translationContentArticleList
  );

  const updatedTranslationArticle = useSelector(
    (state: storeType) => state.contentArticles.updatedTranslationArticle
  );

  useEffect(() => {
    if (id) {
      dispatch(getContentArticleTranslationRequest(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (
      checkReduxResponse(translationContentArticleList, 'contentArticleList')
    ) {
      translationArticle.current = [];

      currentProject.allowedLanguages.forEach((language) => {
        const currentTrad =
          translationContentArticleList.contentArticleList.find(
            (item: any) => language === item.locale
          );

        if (currentTrad) {
          translationArticle.current.push(currentTrad);
        } else {
          translationArticle.current.push({
            locale: language,
            title: '',
            teaser: '',
            content: '',
            externalLinkUrl: '',
            externalLinkLabel: '',
          });
        }
      });

      setCurrentTranslations(translationArticle.current);
    }
  }, [translationContentArticleList]);

  useEffect(() => {
    if (updatedTranslationArticle) {
      setLoadingSend(updatedTranslationArticle.loading);
      if (checkReduxResponse(updatedTranslationArticle, 'contentArticle')) {
        navigate(pagesConstant.article.home);
      }
    }
  }, [dispatch, navigate, updatedTranslationArticle]);

  const handleChangeTranslation = (
    name: string,
    value: string,
    locale: string
  ) => {
    let objIndex = translationArticle.current.findIndex(
      (item: any) => item.locale === locale
    );
    if (objIndex > -1) {
      translationArticle.current[objIndex][name] = value;
    }
  };

  const handleSubmit = () => {
    const currentTrad = translationArticle.current.find(
      (element: any) => element.locale === currentProject.language.locale
    );

    if (currentTrad && id) {
      if (!currentTrad.title) {
        NotificationManager.warning(Translator.trans('message.title.missing'));
      } else {
        dispatch(
          putContentArticleTranslationRequest(id, {
            translations: translationArticle.current,
          })
        );
      }
    }
  };

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('articles.edit.title')}
          icon={<LibraryBooks />}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <UnderlineTitle
              title={Translator.trans('appModule.general.info')}
            />
            {currentProject.allowedLanguages.map((language) => {
              let translation = currentTranslations.find(
                (element: any) => element.locale === language
              );
              return (
                <AccordionTranslation key={language} language={language}>
                  <ContentArticleTranslations
                    translation={translation}
                    handleChangeTranslation={handleChangeTranslation}
                    language={language}
                  />
                </AccordionTranslation>
              );
            })}
            <ButtonsSaveModal
              onClose={() => navigate(pagesConstant.article.home)}
              onSubmit={handleSubmit}
              isLoading={loadingSend}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleEdit;
