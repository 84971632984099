import React, { useCallback, useEffect, useState } from 'react';
import { MainTextField, HTMLEditor } from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';

const ContentArticleTranslations = (props: {
  translation?: any;
  handleChangeTranslation: (
    name: string,
    value: string,
    locale: string
  ) => void;
  language: string;
}) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [externalLinkUrl, setExternalLinkUrl] = useState('');
  const [externalLinkLabel, setExternalLinkLabel] = useState('');

  const handleChangeTitle = useCallback(
    (value: string) => {
      setTitle(value);
      props.handleChangeTranslation('title', value, props.language);
    },
    [props]
  );

  const onChangeContent = useCallback(
    (value: string) => {
      setContent(value);
      props.handleChangeTranslation('content', value, props.language);
    },
    [props]
  );

  const handleChangeExternalLink = useCallback(
    (value: string) => {
      setExternalLinkUrl(value);
      props.handleChangeTranslation('externalLinkUrl', value, props.language);
    },
    [props]
  );

  const handleChangeExternalLabel = useCallback(
    (value: string) => {
      setExternalLinkLabel(value);
      props.handleChangeTranslation('externalLinkLabel', value, props.language);
    },
    [props]
  );

  useEffect(() => {
    if (props.translation) {
      handleChangeTitle(props.translation.title || '');
      onChangeContent(props.translation.content || '');
      handleChangeExternalLink(props.translation.externalLinkUrl || '');
      handleChangeExternalLabel(props.translation.externalLinkLabel || '');
    }
  }, [
    handleChangeExternalLabel,
    handleChangeExternalLink,
    handleChangeTitle,
    onChangeContent,
    props.translation,
  ]);

  return (
    <div className="mt-4">
      <div className="information">
        <div className="col-12 col-md-4">
          <h4>{Translator.trans('cms.title')}</h4>
        </div>
        <div className="col-12 col-md-8">
          <MainTextField
            value={title}
            onChange={(event) => handleChangeTitle(event.target.value)}
          />
        </div>
      </div>
      <div className="information">
        <div className="col-12 col-md-4">
          <h4>{Translator.trans('cms.content')}</h4>
        </div>
        <div className="col-12 col-md-8">
          <HTMLEditor onChange={onChangeContent} value={content} />
        </div>
      </div>
      <div className="information">
        <div className="col-12 col-md-4">
          <h4>{Translator.trans('articles.title.link.url')}</h4>
        </div>
        <div className="col-12 col-md-8">
          <MainTextField
            placeholder={Translator.trans(
              'articles.title.link.url.placeholder'
            )}
            value={externalLinkUrl}
            onChange={(event) => handleChangeExternalLink(event.target.value)}
          />
        </div>
      </div>

      {externalLinkUrl && (
        <div className="information">
          <div className="col-12 col-md-4">
            <h4>{Translator.trans('articles.title.link.name')}</h4>
          </div>
          <div className="col-12 col-md-8">
            <MainTextField
              placeholder={Translator.trans(
                'articles.title.link.name.placeholder'
              )}
              value={externalLinkLabel}
              onChange={(event) =>
                handleChangeExternalLabel(event.target.value)
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentArticleTranslations;
