import React from 'react';
import EmptyTable from '../../EmptyTable/EmptyTable';

const NoData = (props: any) => (
  <td colSpan={props.colSpan}>
    <EmptyTable />
  </td>
);

export default NoData;
