import { placesConstants } from '../constants';

export const getPlacesListRequest = (idProject: number | string) => ({
  type: placesConstants.GET_PLACES_LIST_REQUEST,
  idProject,
});

export const getPlacesListSuccess = (placesList: any) => ({
  type: placesConstants.GET_PLACES_LIST_SUCCESS,
  placesList,
});

export const getPlacesListFailure = (error: any) => ({
  type: placesConstants.GET_PLACES_LIST_FAILURE,
  error,
});

export const postPlacesRequest = (idProject: number | string, place: any) => ({
  type: placesConstants.POST_PLACES_REQUEST,
  idProject,
  place,
});

export const postPlacesSuccess = (place: any) => ({
  type: placesConstants.POST_PLACES_SUCCESS,
  place,
});

export const postPlacesFailure = (error: any) => ({
  type: placesConstants.POST_PLACES_FAILURE,
  error,
});

export const putPlacesRequest = (placeId: number | string, place: any) => ({
  type: placesConstants.PUT_PLACES_REQUEST,
  placeId,
  place,
});

export const putPlacesSuccess = (place: any) => ({
  type: placesConstants.PUT_PLACES_SUCCESS,
  place,
});

export const putPlacesFailure = (error: any) => ({
  type: placesConstants.PUT_PLACES_FAILURE,
  error,
});

export const getPlaceRequest = (id: number | string) => ({
  type: placesConstants.GET_PLACE_REQUEST,
  id,
});

export const getPlaceSuccess = (placeDetails: any) => ({
  type: placesConstants.GET_PLACE_SUCCESS,
  placeDetails,
});

export const getPlaceFailure = (error: any) => ({
  type: placesConstants.GET_PLACE_FAILURE,
  error,
});

export const putPlacesPositionRequest = (place: any) => ({
  type: placesConstants.PUT_PLACES_POSITION_REQUEST,
  place,
});

export const putPlacesPositionSuccess = (place: any) => ({
  type: placesConstants.PUT_PLACES_POSITION_SUCCESS,
  place,
});

export const putPlacesPositionFailure = (error: any) => ({
  type: placesConstants.PUT_PLACES_POSITION_FAILURE,
  error,
});

export const getPlacesTranslationRequest = (id: number | string) => ({
  type: placesConstants.GET_PLACES_TRANSLATION_REQUEST,
  id,
});

export const getPlacesTranslationSuccess = (translationPlaceSelf: any) => ({
  type: placesConstants.GET_PLACES_TRANSLATION_SUCCESS,
  translationPlaceSelf,
});

export const getPlacesTranslationFailure = (error: any) => ({
  type: placesConstants.GET_PLACES_TRANSLATION_FAILURE,
  error,
});

export const postPlacesTranslationRequest = (
  placeId: number | string,
  translationPlace: any
) => ({
  type: placesConstants.POST_PLACES_TRANSLATION_REQUEST,
  placeId,
  translationPlace,
});

export const postPlacesTranslationSuccess = (translationPlace: any) => ({
  type: placesConstants.POST_PLACES_TRANSLATION_SUCCESS,
  translationPlace,
});

export const postPlacesTranslationFailure = (error: any) => ({
  type: placesConstants.POST_PLACES_TRANSLATION_FAILURE,
  error,
});

export const putPlacesTranslationRequest = (
  placeId: number | string,
  translationPlace: any
) => ({
  type: placesConstants.PUT_PLACES_TRANSLATION_REQUEST,
  placeId,
  translationPlace,
});

export const putPlacesTranslationSuccess = (translationPlace: any) => ({
  type: placesConstants.PUT_PLACES_TRANSLATION_SUCCESS,
  translationPlace,
});

export const putPlacesTranslationFailure = (error: any) => ({
  type: placesConstants.PUT_PLACES_TRANSLATION_FAILURE,
  error,
});

export const deletePlacesRequest = (id: number | string) => ({
  type: placesConstants.DELETE_PLACES_REQUEST,
  id,
});

export const deletePlacesSuccess = (oldPlace: any) => ({
  type: placesConstants.DELETE_PLACES_SUCCESS,
  oldPlace,
});

export const deletePlacesFailure = (error: any) => ({
  type: placesConstants.DELETE_PLACES_FAILURE,
  error,
});

export const clearPlaces = () => ({
  type: placesConstants.CLEAR_PLACES,
});
