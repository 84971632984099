import React, { useEffect, useState } from 'react';
import {
  clearSessionTrack,
  deleteSessionTrackRequest,
} from '../../Redux/actions';
import { ActionButton } from '@emisys/audience-sdk-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import SessionTrackModalEdition from '../../app/SessionTracks/SessionTrackEdit/SessionTrackModalEdition';
import DeleteModal from '../DeleteModal/DeleteModal';
import { checkReduxResponse } from '../../services/checkReduxResponse';
import { storeType } from '../../index';
import SessionTrackModel from '../../Models/SessionTrackModel';

const ButtonsSessionTracks = (props: { sessionTrack: SessionTrackModel }) => {
  const dispatch = useDispatch();
  const [openModalEditionSessionTrack, setOpenModalEditionSessionTrack] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const oldSessionTrackSelf = useSelector(
    (state: storeType) => state.sessionTracks.oldSessionTrackSelf
  );

  const handleRequestDelete = () => {
    dispatch(deleteSessionTrackRequest(props.sessionTrack.id));
  };

  useEffect(() => {
    if (oldSessionTrackSelf) {
      setIsLoading(oldSessionTrackSelf.loading);
      if (checkReduxResponse(oldSessionTrackSelf, 'sessionTrack')) {
        setIsClosed(false);
        dispatch(clearSessionTrack());
      }
    }
  }, [dispatch, oldSessionTrackSelf]);

  return (
    <div className={'action-container-buttons'}>
      <ActionButton
        className="editSessionTrack"
        icon={'edit'}
        onClick={() => setOpenModalEditionSessionTrack(true)}
      />
      <ActionButton
        className="deleteSessionTrack"
        icon={'delete'}
        onClick={() => setIsClosed(true)}
      />
      <SessionTrackModalEdition
        sessionTrack={props.sessionTrack}
        open={openModalEditionSessionTrack}
        close={() => setOpenModalEditionSessionTrack(false)}
      />
      <DeleteModal
        isLoading={isLoading}
        isOpen={isClosed}
        onConfirm={handleRequestDelete}
        onCancel={() => setIsClosed(false)}
      />
    </div>
  );
};

export default ButtonsSessionTracks;
