import React from 'react';
import Select from 'react-select';
import countries from 'i18n-iso-countries';
import { currentProject } from '../../Global/currentProject';
import Translator from '../../services/translator';

countries.registerLocale(
  require(`i18n-iso-countries/langs/${
    currentProject.language.locale === 'fr_CA'
      ? 'fr'
      : currentProject.language.locale
  }.json`)
);

let allCountry = countries.getNames(
  currentProject.language.locale === 'fr_CA'
    ? 'fr'
    : currentProject.language.locale
);

interface CountrySelectInterface {
  value: string;
  onChange: (countryCode: string) => void;
  required?: boolean;
}

const CountrySelect = (props: CountrySelectInterface) => {
  function handleChangeCountry(e: any) {
    let countryCode = countries.getAlpha2Code(
      e.value,
      currentProject.language.locale
    );
    if (countryCode) {
      props.onChange(countryCode);
    }
  }

  return (
    <>
      <div className={`mb-1 ${props.required && 'required'}`}>
        {Translator.trans('appModule.country')}
      </div>
      <Select
        className="w-100"
        placeholder={Translator.trans('appModule.country')}
        value={
          countries.getName(props.value, currentProject.language.locale)
            ? {
                value: countries.getName(
                  props.value,
                  currentProject.language.locale
                ),
                label: countries.getName(
                  props.value,
                  currentProject.language.locale
                ),
              }
            : ''
        }
        onChange={handleChangeCountry}
        options={
          allCountry &&
          Object.keys(allCountry).map((data) => {
            return {
              value: allCountry[data],
              label: allCountry[data],
            };
          })
        }
      />
    </>
  );
};

export default CountrySelect;
