import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => (
  <div className="draggable-icon">
    <i className="zmdi zmdi-more-vert mr-1" style={{ fontSize: 25 }} />
    <i className="zmdi zmdi-more-vert" style={{ fontSize: 25 }} />
  </div>
));

export default DragHandle;
