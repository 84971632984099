import React, { useEffect, useState } from 'react';
import {
  UnderlineTitle,
  MainTextField,
  ImageCropper,
} from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';
import DatePickerComponent from '../../../components/DatePicker/DatePicker';
import NewsModel from '../../../Models/NewsModel';
import DeleteBadge from '../../../components/DeleteBadge/DeleteBadge';

interface NewsEditContentProps {
  news?: NewsModel;
  handleChangeNews: (name: string, value: string | null | Date) => void;
  handleChangePicture: (picture: string | null) => void;
}

const NewsContent = (props: NewsEditContentProps) => {
  const [publishDate, setPublishDate] = useState<Date | null>(null);
  const [externalLinkUrl, setExternalLinkUrl] = useState('');
  const [externalLinkLabel, setExternalLinkLabel] = useState('');
  const [picture, setPicture] = useState<string | null>(null);

  useEffect(() => {
    if (props.news) {
      setPublishDate(props.news.publishDate);
      setExternalLinkUrl(props.news.externalLinkUrl || '');
      setExternalLinkLabel(props.news.externalLinkLabel || '');

      if (props.news.picture) {
        setPicture(props.news.picture?.original);
      }
    }
  }, [props.news]);

  const handleChangePublishDate = (date: Date) => {
    setPublishDate(date);
    props.handleChangeNews('publishDate', date);
  };

  const handleChangeLinkLabel = (value: string) => {
    setExternalLinkLabel(value);
    props.handleChangeNews('externalLinkLabel', value);
  };

  const handleChangeLinkUrl = (value: string) => {
    setExternalLinkUrl(value);
    props.handleChangeNews('externalLinkUrl', value);
  };

  const handleImageBs64 = (picture: string | null) => {
    props.handleChangePicture(picture);
  };

  const removeImage = () => {
    props.handleChangePicture(null);
  };

  return (
    <div className="mt-4">
      <UnderlineTitle title={Translator.trans('appModule.additional.info')} />
      <div className="information">
        <div className="col-12 col-md-4 p-0">
          <h4>{Translator.trans('news.news.edit.publishdate')}</h4>
        </div>
        <div className="col-12 col-md-5 p-0">
          <DatePickerComponent
            selected={publishDate}
            onChange={handleChangePublishDate}
            showTimeSelect
          />
        </div>
      </div>
      <div className="information">
        <div className="col-12 col-md-4 p-0">
          <h4>{Translator.trans('articles.title.link.url')}</h4>
        </div>
        <div className="col-12 col-md-5 p-0">
          <MainTextField
            placeholder={Translator.trans(
              'articles.title.link.url.placeholder'
            )}
            id="linkUrl"
            value={externalLinkUrl}
            onChange={(event) => handleChangeLinkUrl(event.target.value)}
          />
        </div>
      </div>
      {externalLinkUrl && (
        <div className="information">
          <div className="col-12 col-md-4 p-0">
            <h4>{Translator.trans('articles.title.link.name')}</h4>
          </div>
          <div className="col-12 col-md-5 p-0">
            <MainTextField
              id="linkName"
              placeholder={Translator.trans(
                'articles.title.link.name.placeholder'
              )}
              value={externalLinkLabel}
              onChange={(event) => handleChangeLinkLabel(event.target.value)}
            />
          </div>
        </div>
      )}

      <div className="information">
        <div className="col-12 col-md-4 p-0">
          <h4>{Translator.trans('news.news.title.picture')}</h4>
        </div>
        <div className="col-12 col-md-5 p-0">
          {picture ? (
            <div className={'containerImg'}>
              <DeleteBadge onClick={removeImage}>
                <img src={picture} alt="artisteImage" />
              </DeleteBadge>
            </div>
          ) : (
            <ImageCropper
              labelText={Translator.trans('appModule.drag.and.drop')}
              handlePicture={handleImageBs64}
              aspect="landscape"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsContent;
