import React, { useEffect, useState } from 'react';
import Translator from '../../../services/translator';
import { MainTextField } from '@emisys/audience-sdk-ui-react';
import { capitalizeFirstLetter } from '../../../services/capitalizeFirstLetter';

const PlacesEditionTranslations = (props: {
  translation: any; // add type property
  language: string;
  handleChangeTranslation: (
    name: string,
    value: string,
    language: string
  ) => void;
}) => {
  const [name, setName] = useState('');

  useEffect(() => {
    if (props.translation?.name) {
      setName(props.translation.name);
    }
  }, [props.translation?.name]);

  const handleChangeTranslation = (name: string) => (event: any) => {
    const newValue = capitalizeFirstLetter(event.target.value);
    setName(newValue);
    props.handleChangeTranslation(name, newValue, props.language);
  };

  return (
    <div className="row">
      <div className="col-12 col-md-4 textFieldLabel mt-3">
        <h4>{Translator.trans('sessions.title.place')}</h4>
      </div>
      <div className="col-12 col-md-8 mt-4">
        <MainTextField
          className="TitlePlacesEdit"
          value={name ? name : ''}
          onChange={handleChangeTranslation('name')}
        />
      </div>
    </div>
  );
};

export default PlacesEditionTranslations;
