import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import ButtonsSessions from '../../../../components/Buttons/ButtonsSessions';
import Translator from '../../../../services/translator';
import { MdDateRange } from 'react-icons/md';
import { Place } from '@mui/icons-material';
import { EmptyText, Username } from '@emisys/audience-sdk-ui-react';
import { formatDateTime } from '../../../../services/formatDate';
import '../cell.css';
import { currentProject } from '../../../../Global/currentProject';

const SessionsCell = (cell) => {
  let content = cell.value;

  if (cell.column.name === 'title') {
    content = cell.row.title ? (
      <span>{cell.row.title}</span>
    ) : (
      <EmptyText>{Translator.trans('cms.no.title')}</EmptyText>
    );
  }

  if (cell.column.name === 'performer') {
    if (cell.row.performer && cell.row.performer.length !== 0) {
      content = cell.row.performer.map((data, i) => {
        return (
          <div className="mb-2" key={i}>
            <Username
              name={data.performer.name}
              picture={data.performer.picture?.thumb}
            />
          </div>
        );
      });
    } else {
      content = <EmptyText>{Translator.trans('cms.no.artist')}</EmptyText>;
    }
  }

  if (cell.column.name === 'place') {
    if (cell.row.place.length !== 0) {
      content = cell.row.place.map((data, i) => {
        return (
          <div className="containerIcon mb-2" key={i}>
            <Place className="icons" />
            <span>{data.place.name}</span>
          </div>
        );
      });
    } else {
      content = <EmptyText>{Translator.trans('cms.no.place')}</EmptyText>;
    }
  }

  if (cell.column.name === 'liveStartDateTimeUTC') {
    content = (
      <div className="containerIcon">
        <MdDateRange className="icons" />
        <span>
          {formatDateTime(
            cell.row.liveStartDateTimeUTC,
            currentProject.language.locale,
            currentProject.timezone
          )}
        </span>
      </div>
    );
  }

  if (cell.column.name === 'liveEndDateTimeUTC') {
    content = cell.row.timeToBeConfirmed ? (
      <EmptyText>
        {Translator.trans('sessions.title.timeToBeConfirmed')}
      </EmptyText>
    ) : (
      <div className="containerIcon">
        <MdDateRange className="icons" />
        <span>
          {formatDateTime(
            cell.row.liveEndDateTimeUTC,
            currentProject.language.locale,
            currentProject.timezone
          )}
        </span>
      </div>
    );
  }

  if (cell.column.name === 'buttons') {
    content = <ButtonsSessions session={cell.row.session} />;
  }

  return <Table.Cell style={cell.style}>{content}</Table.Cell>;
};

export default SessionsCell;
