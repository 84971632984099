import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import ButtonsSessionTracks from '../../../../components/Buttons/ButtonsSessionTracks';
import { columnNameAction } from '../../Constants/Constant';

const SessionsTracksCell = (cell: any) => {
  let content = cell.value;
  if (cell.column.name === columnNameAction) {
    content = (
      <div style={{ textAlign: 'right' }}>
        <ButtonsSessionTracks sessionTrack={cell.row} />
      </div>
    );
  }
  if (cell.column.name === 'name') {
    content = (
      <div style={{ textAlign: 'left' }} className="listTitle">
        <span>{cell.row.name}</span>
      </div>
    );
  }
  return (
    <Table.Cell style={cell.style} {...cell}>
      {content}
    </Table.Cell>
  );
};

export default SessionsTracksCell;
