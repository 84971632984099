export const appMobileConstants = {
  GET_APP_MOBILE_INFO_REQUEST: 'GET_APP_MOBILE_INFO_REQUEST',
  GET_APP_MOBILE_INFO_SUCCESS: 'GET_APP_MOBILE_INFO_SUCCESS',
  GET_APP_MOBILE_INFO_FAILURE: 'GET_APP_MOBILE_INFO_FAILURE',

  PUT_APP_MOBILE_INFO_REQUEST: 'PUT_APP_MOBILE_INFO_REQUEST',
  PUT_APP_MOBILE_INFO_SUCCESS: 'PUT_APP_MOBILE_INFO_SUCCESS',
  PUT_APP_MOBILE_INFO_FAILURE: 'PUT_APP_MOBILE_INFO_FAILURE',

  CLEAR_APP_MOBILE_REQUEST: 'CLEAR_APP_MOBILE_REQUEST',
};
