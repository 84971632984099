import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const SortableTable = (props: any) => {
  const TableBody = SortableContainer(Table.TableBody);
  return (
    <TableBody
      lockAxis="y"
      onSortEnd={props.onSortEnd}
      useDragHandle
      helperClass={'sortable-table-container'}
      {...props.props}
    />
  );
};

export default SortableTable;
