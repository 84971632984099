import React from 'react';
import { useDispatch } from 'react-redux';
import { putContentNewsRequest } from '../../../../Redux/actions';
import { formatDateTime } from '../../../../services/formatDate';
import ButtonsNews from '../../../Buttons/ButtonsNews';
import { currentProject } from '../../../../Global/currentProject';
import { SwitchComponent } from '@emisys/audience-sdk-ui-react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { columnNameAction, columnNameDrag } from '../../Constants/Constant';
import DragHandle from '../../Sortable/DragHandle/DragHandle';

const NewsCell = (props: Table.DataCellProps) => {
  const dispatch = useDispatch();
  let content = props.value;

  const handleChangeIsActive = (isActive: boolean) => {
    dispatch(
      putContentNewsRequest({ id: props.row.news.id, isActive: isActive })
    );
  };

  if (props.column.name === columnNameDrag) {
    return (
      <Table.Cell {...props} style={{ width: 20 }}>
        <DragHandle />
      </Table.Cell>
    );
  }

  if (props.column.name === 'publishDate') {
    content = formatDateTime(
      props.row.publishDate,
      currentProject.language.locale,
      currentProject.timezone
    );
  }

  if (props.column.name === 'isActive') {
    content = (
      <SwitchComponent
        onHandleChange={handleChangeIsActive}
        checked={props.row.isActive}
      />
    );
  }

  if (props.column.name === columnNameAction) {
    content = <ButtonsNews news={props.row.news} />;
  }

  return <Table.Cell {...props}>{content}</Table.Cell>;
};
export default NewsCell;
