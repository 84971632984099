import React, { useState } from 'react';
import Translator from '../../../services/translator';
import {
  UnderlineTitle,
  MainTextField,
  SwitchComponent,
} from '@emisys/audience-sdk-ui-react';
import PerformerSocials from '../PerformerContent/PerformerSocials';
import { currentProject } from '../../../Global/currentProject';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import PerformersTranslations from '../PerformerContent/PerformersTranslations';
import '../index.css';

const PerformerCreationContent = (props: {
  handleChangePerformer: (name: string, value: string | number | null) => void;
  handleChangePicture: (picture: string | null) => void;
  handleChangeTranslationPerformer: (
    name: string,
    newValue: string,
    language: string
  ) => void;
  translations: any;
}) => {
  const [name, setName] = useState('');
  const [headline, setHeadline] = useState<1 | null>(null);

  const handleChangeHeadline = (isChecked: boolean) => {
    const newValue = isChecked ? 1 : null;
    props.handleChangePerformer('headline', newValue);
    setHeadline(newValue);
  };

  const handleChangeName = (event: any) => {
    props.handleChangePerformer('name', event.target.value);
    setName(event.target.value);
  };

  return (
    <div className="row my-4">
      <div className="col-4">
        <PerformerSocials
          handleChangePerformer={props.handleChangePerformer}
          handleChangePicture={props.handleChangePicture}
        />
      </div>

      <div className="col-8">
        <UnderlineTitle title={Translator.trans('appModule.general.info')} />
        <div className="row">
          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('performers.title.name')}</h4>
            </div>
            <div className="col-12 col-md-5 p-0">
              <MainTextField
                className="m-0"
                value={name}
                onChange={handleChangeName}
              />
            </div>
          </div>

          <div className="col-12 information">
            <div className="col-12 col-md-4 p-0">
              <h4>{Translator.trans('performers.title.headline')}</h4>
            </div>
            <div className="col-12 col-md-5 p-0">
              <SwitchComponent
                onHandleChange={handleChangeHeadline}
                checked={!!headline}
              />
            </div>
          </div>

          <div className="col-12 mt-4">
            <UnderlineTitle
              title={Translator.trans('performers.title.biography')}
            />
            {currentProject.allowedLanguages.map((language) => {
              let translation = props.translations.find(
                (element: any) => element.locale === language
              );
              return (
                <AccordionTranslation key={language} language={language}>
                  <PerformersTranslations
                    translation={translation}
                    handleChangeTranslation={
                      props.handleChangeTranslationPerformer
                    }
                    language={language}
                  />
                </AccordionTranslation>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformerCreationContent;
