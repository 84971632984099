export enum NotificationStatus {
  delivery = 'delivery',
  error = 'error',
}

export default class NotificationModel {
  id: number;
  name: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  dateSendAfter: Date | null;
  externalId: string;
  recipients: number;
  status: NotificationStatus;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.createdAt = data.createdAtUTC ? new Date(data.createdAtUTC) : null;
    this.updatedAt = data.updatedAtUTC ? new Date(data.updatedAtUTC) : null;
    this.dateSendAfter = data.dateSendAfter
      ? new Date(data.dateSendAfter)
      : null;
    this.externalId = data.externalId;
    this.recipients = data.recipients;
    this.status = data.status;
  }
}
