export const brandingConstants = {
  GET_BRANDING_REQUEST: 'GET_BRANDING_REQUEST',
  GET_BRANDING_SUCCESS: 'GET_BRANDING_SUCCESS',
  GET_BRANDING_FAILURE: 'GET_BRANDING_FAILURE',

  PUT_BRANDING_REQUEST: 'PUT_BRANDING_REQUEST',
  PUT_BRANDING_SUCCESS: 'PUT_BRANDING_SUCCESS',
  PUT_BRANDING_FAILURE: 'PUT_BRANDING_FAILURE',

  CLEAR_BRANDING_PUT_BY_PROJECT: 'CLEAR_BRANDING_PUT_BY_PROJECT',
};
