import { sessionTracksConstants } from '../constants';
import SessionTrackModel from '../../Models/SessionTrackModel';
import ErrorModel from '../../Models/ErrorModel';

const initialState: any = {
  sessionTracksList: null,
  newSessionTrack: null,
  oldSessionTrackSelf: null,
  translationSessionTrackList: null,
  translationSessionTrack: null,
  updatedTranslationSessionTrack: null,
};

let listSessionTracks: SessionTrackModel[] = [];

const sessionTracks = (state = initialState, action: any) => {
  switch (action.type) {
    case sessionTracksConstants.GET_SESSION_TRACKS_REQUEST: {
      return {
        ...state,
        sessionTracksList: {
          sessionTracks: listSessionTracks,
          error: false,
          loading: true,
        },
      };
    }

    case sessionTracksConstants.GET_SESSION_TRACKS_SUCCESS: {
      listSessionTracks = action.sessionTracks.data.map(
        (track: any) => new SessionTrackModel(track)
      );

      return {
        ...state,
        sessionTracksList: {
          sessionTracks: listSessionTracks,
          error: false,
          loading: false,
        },
      };
    }

    case sessionTracksConstants.GET_SESSION_TRACKS_FAILURE: {
      return {
        ...state,
        sessionTracksList: {
          sessionTracks: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case sessionTracksConstants.POST_SESSION_TRACKS_REQUEST: {
      return {
        ...state,
        newSessionTrack: { sessionTrack: null, error: false, loading: true },
      };
    }

    case sessionTracksConstants.POST_SESSION_TRACKS_SUCCESS: {
      return {
        ...state,
        newSessionTrack: {
          sessionTrack: action.sessionTrack.data,
          error: false,
          loading: false,
        },
      };
    }

    case sessionTracksConstants.POST_SESSION_TRACKS_FAILURE: {
      return {
        ...state,
        newSessionTrack: {
          sessionTrack: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case sessionTracksConstants.DELETE_SESSION_TRACKS_REQUEST: {
      return {
        ...state,
        oldSessionTrackSelf: {
          sessionTrack: null,
          error: false,
          loading: true,
        },
      };
    }

    case sessionTracksConstants.DELETE_SESSION_TRACKS_SUCCESS: {
      return {
        ...state,
        oldSessionTrackSelf: {
          sessionTrack: true,
          error: false,
          loading: false,
        },
      };
    }

    case sessionTracksConstants.DELETE_SESSION_TRACKS_FAILURE: {
      return {
        ...state,
        oldSessionTrackSelf: {
          sessionTrack: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case sessionTracksConstants.GET_SESSION_TRACKS_TRANSLATION_REQUEST: {
      return {
        ...state,
        translationSessionTrackList: {
          sessionTrackList: null,
          error: false,
          loading: true,
        },
      };
    }

    case sessionTracksConstants.GET_SESSION_TRACKS_TRANSLATION_SUCCESS: {
      return {
        ...state,
        translationSessionTrackList: {
          sessionTrackList: action.translations.data,
          error: false,
          loading: false,
        },
      };
    }

    case sessionTracksConstants.GET_SESSION_TRACKS_TRANSLATION_FAILURE: {
      return {
        ...state,
        translationSessionTrackList: {
          sessionTrackList: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case sessionTracksConstants.POST_SESSION_TRACKS_TRANSLATION_REQUEST: {
      return {
        ...state,
        translationSessionTrack: {
          sessionTrack: null,
          error: false,
          loading: true,
        },
      };
    }

    case sessionTracksConstants.POST_SESSION_TRACKS_TRANSLATION_SUCCESS: {
      return {
        ...state,
        translationSessionTrack: {
          sessionTrack: action.translations.data,
          error: false,
          loading: false,
        },
      };
    }

    case sessionTracksConstants.POST_SESSION_TRACKS_TRANSLATION_FAILURE: {
      return {
        ...state,
        translationSessionTrack: {
          sessionTrack: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case sessionTracksConstants.PUT_SESSION_TRACKS_TRANSLATION_REQUEST: {
      return {
        ...state,
        updatedTranslationSessionTrack: {
          sessionTrack: null,
          error: false,
          loading: true,
        },
      };
    }

    case sessionTracksConstants.PUT_SESSION_TRACKS_TRANSLATION_SUCCESS: {
      return {
        ...state,
        updatedTranslationSessionTrack: {
          sessionTrack: action.translations.data,
          error: false,
          loading: false,
        },
      };
    }

    case sessionTracksConstants.PUT_SESSION_TRACKS_TRANSLATION_FAILURE: {
      return {
        ...state,
        updatedTranslationSessionTrack: {
          sessionTrack: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case sessionTracksConstants.CLEAR_SESSION_TRACK: {
      return {
        ...state,
        newSessionTrack: null,
        oldSessionTrackSelf: null,
        translationSessionTrackList: null,
        translationSessionTrack: null,
        updatedTranslationSessionTrack: null,
      };
    }

    default:
      return state;
  }
};

export default sessionTracks;
