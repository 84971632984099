import { userConstants } from '../constants';
import UserSelfProjectModel from '../../Models/UserSelfProjectModel';
import UserSelfModel from '../../Models/UserSelfModel';
import ErrorModel from '../../Models/ErrorModel';

const initialState: any = {
  userSelfDetails: null,
  userSelfProjects: null,
  userProjectByProject: null,
  userUpdate: null,
};

let selfProjectList: UserSelfProjectModel[] = [];

const user = (state = initialState, action: any) => {
  switch (action.type) {
    case userConstants.GET_USER_DETAILS_REQUEST: {
      return {
        ...state,
        userSelfDetails: { details: null, error: false, loading: true },
      };
    }

    case userConstants.GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userSelfDetails: {
          details: new UserSelfModel(action.selfDetails.data),
          error: false,
          loading: false,
        },
      };
    }

    case userConstants.GET_USER_DETAILS_FAILURE: {
      return {
        ...state,
        userSelfDetails: { details: null, error: true, loading: false },
      };
    }

    case userConstants.GET_USER_PROJECTS_LIST_REQUEST: {
      return {
        ...state,
        userSelfProjects: {
          projects: selfProjectList,
          error: false,
          loading: true,
        },
      };
    }

    case userConstants.GET_USER_PROJECTS_LIST_SUCCESS: {
      selfProjectList = action.projects.data.map(
        (project: any) => new UserSelfProjectModel(project)
      );

      return {
        ...state,
        userSelfProjects: {
          projects: selfProjectList,
          error: false,
          loading: false,
        },
      };
    }

    case userConstants.GET_USER_PROJECTS_LIST_FAILURE: {
      return {
        ...state,
        userSelfProjects: { projects: null, error: true, loading: false },
      };
    }

    case userConstants.GET_USER_PROJECT_REQUEST: {
      return {
        ...state,
        userProjectByProject: {
          userProject: null,
          error: false,
          loading: true,
        },
      };
    }

    case userConstants.GET_USER_PROJECT_SUCCESS: {
      return {
        ...state,
        userProjectByProject: {
          userProject: action.userProject.data,
          error: false,
          loading: false,
        },
      };
    }

    case userConstants.GET_USER_PROJECT_FAILURE: {
      return {
        ...state,
        userProjectByProject: {
          userProject: null,
          error: true,
          loading: false,
        },
      };
    }

    case userConstants.PUT_USER_REQUEST: {
      return {
        ...state,
        userUpdate: { user: null, error: false, loading: true },
      };
    }

    case userConstants.PUT_USER_SUCCESS: {
      return {
        ...state,
        userUpdate: {
          user: action.userUpdate.data,
          error: false,
          loading: false,
        },
      };
    }

    case userConstants.PUT_USER_FAILURE: {
      return {
        ...state,
        errorUser: action.error,
        userUpdate: {
          user: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default user;
