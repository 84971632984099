import React, { useEffect, useRef, useState } from 'react';
import { Modal, UnderlineTitle } from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';
import PerformerModel from '../../../Models/PerformerModel';
import SessionCreationByPerformerContent from './SessionCreationByPerformerContent';
import { currentProject } from '../../../Global/currentProject';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import SessionTranslations from '../SessionContent/SessionTranslations';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { useDispatch, useSelector } from 'react-redux';
import { storeType } from '../../../index';
import {
  postProgramSessionsRequest,
  postSessionTranslationRequest,
} from '../../../Redux/actions';

const SessionsCreationModal = (props: {
  performer: PerformerModel;
  open: boolean;
  close: () => void;
}) => {
  const dispatch = useDispatch();
  const [loadingSend, setLoadingSend] = useState(false);

  const programsList = useSelector(
    (state: storeType) => state.programs.programsList
  );

  const performers: any = useRef(null);
  const places: any = useRef(null);

  const sessionPayload: any = useRef({
    idProject: 0,
    title: '',
    description: '',
    locale: '',
    liveStartDateTimeUTC: '',
    liveEndDateTimeUTC: '',
    confirmed: false,
    highlighted: false,
    signedLanguages: false,
    track: null,
    timeToBeConfirmed: false,
    duration: null,
    display: false,
    displayDateStartMessage: false,
    displayDateEndMessage: false,
  });

  const translationSessions: {
    current: any[];
  } = useRef([]);

  useEffect(() => {
    if (currentProject.allowedLanguages) {
      currentProject.allowedLanguages.forEach((element) => {
        let array = {
          locale: element,
          description: '',
        };
        translationSessions.current.push(array);
      });
    }
  }, []);

  useEffect(() => {
    sessionPayload.current.idProject = currentProject.id;
    sessionPayload.current.title = props.performer?.name || '';
  }, [props.performer?.name]);

  const handleChangeTranslation = (
    name: string,
    value: string,
    locale: string
  ) => {
    let objIndex = translationSessions.current.findIndex(
      (item) => item.locale === locale
    );
    if (objIndex > -1) {
      translationSessions.current[objIndex][name] = value;
    }
  };

  const handleChangeSession = (name: string, value: string) => {
    if (name === 'performers') {
      performers.current = { performers: value };
    } else if (name === 'places') {
      places.current = { places: value };
    } else {
      sessionPayload.current[name] = value;
    }
  };

  const handleSubmit = () => {
    if (!sessionPayload.current.title) {
      NotificationManager.warning(Translator.trans('message.title.missing'));
    } else {
      setLoadingSend(true);
      dispatch(
        postProgramSessionsRequest(
          programsList.programs[0].id,
          sessionPayload.current
        )
      );
    }
  };

  const sendTranslations = (sessionId: number) => {
    dispatch(
      postSessionTranslationRequest(sessionId, {
        translations: translationSessions.current,
      })
    );
  };

  return (
    <Modal
      title={Translator.trans('sessions.title.performer.add', {
        performer: props.performer?.name,
      })}
      width="md"
      open={props.open}
      close={props.close}
    >
      <div className="col-12" style={{ padding: 0, overflow: 'hidden' }}>
        <SessionCreationByPerformerContent
          handleChangeSession={handleChangeSession}
          sendTranslations={sendTranslations}
          performer={props.performer}
          onClose={props.close}
        />

        <div className="col-12 mt-4">
          <UnderlineTitle
            title={Translator.trans('sessions.title.description')}
          />
          {currentProject.allowedLanguages.map((language) => (
            <AccordionTranslation key={language} language={language}>
              <SessionTranslations
                handleChangeTranslation={handleChangeTranslation}
                language={language}
              />
            </AccordionTranslation>
          ))}
        </div>

        <ButtonsSaveModal
          onClose={props.close}
          onSubmit={handleSubmit}
          isLoading={loadingSend}
        />
      </div>
    </Modal>
  );
};

export default SessionsCreationModal;
