import { brandingConstants } from '../constants';

export const getBrandingRequest = (idProject: number | string) => ({
  type: brandingConstants.GET_BRANDING_REQUEST,
  idProject,
});

export const getBrandingSuccess = (branding: any) => ({
  type: brandingConstants.GET_BRANDING_SUCCESS,
  branding,
});

export const getBrandingFailure = (error: any) => ({
  type: brandingConstants.GET_BRANDING_FAILURE,
  error,
});

export const putBrandingRequest = (
  idProject: number | string,
  updatedBranding: any
) => ({
  type: brandingConstants.PUT_BRANDING_REQUEST,
  idProject,
  updatedBranding,
});

export const putBrandingSuccess = (updatedBranding: any) => ({
  type: brandingConstants.PUT_BRANDING_SUCCESS,
  updatedBranding,
});

export const putBrandingFailure = (error: any) => ({
  type: brandingConstants.PUT_BRANDING_FAILURE,
  error,
});

export const clearBrandingByProject = () => ({
  type: brandingConstants.CLEAR_BRANDING_PUT_BY_PROJECT,
});
