import React, { useCallback, useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from '@mui/material';
import { currentProject } from '../../Global/currentProject';
import AppLanguages, { languageDataInterface } from '../../lngProvider';
import initTranslations from '../../services/initTranslations';
import LanguageItem from '../LanguageSwitcher/LanguageItem';
import './index.css';

interface HeaderInterface {
  currentLocale: string;
  setCurrentLocale: (locale: string) => void;
  onToggleCollapsedNav: () => void;
}

const Header = (props: HeaderInterface) => {
  const [anchorLang, setAnchorLang] = useState<null | HTMLElement>(null);

  const nameProject = currentProject?.name;

  const handleSwitchLanguage = useCallback(
    (lang: languageDataInterface) => {
      initTranslations(lang.locale);
      props.setCurrentLocale(lang.locale);
      handleClose();
    },
    [props]
  );

  const onLangSwitcherSelect = (event: any) => {
    setAnchorLang(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorLang(null);
  };

  return (
    <AppBar className={'app-main-header'}>
      <Toolbar className="app-toolbar" disableGutters={false}>
        <IconButton
          className={`jr-menu-icon mr-3 d-block d-md-none`}
          aria-label="Menu"
          onClick={props.onToggleCollapsedNav}
        >
          <span className="menu-icon color-menu" />
        </IconButton>

        <h1>{nameProject}</h1>

        <ul className="header-notifications list-inline ml-auto full-height">
          <li className="list-inline-item languages pointer">
            <div
              className="d-flex align-items-center pt-1"
              aria-controls="language-menu"
              aria-haspopup="true"
              onClick={onLangSwitcherSelect}
            >
              <i
                className={`flag flag-24 flag-${currentProject.language.icon}`}
              />
            </div>
            <Menu
              id="language-menu"
              anchorEl={anchorLang}
              open={Boolean(anchorLang)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {AppLanguages.map((language: languageDataInterface) => (
                <MenuItem
                  key={language.locale}
                  onClick={() => handleSwitchLanguage(language)}
                >
                  <LanguageItem language={language} />
                </MenuItem>
              ))}
            </Menu>
          </li>
        </ul>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
