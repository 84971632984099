import React from 'react';
import PerformersTable from '../Performers/PerformersTable/PerformersTable';
import Translator from '../../services/translator';
import { CreateButton, HeaderBanner } from '@emisys/audience-sdk-ui-react';
import { NavLink } from 'react-router-dom';
import { pagesConstant } from '../../Constants/Constant';
import GroupIcon from '@mui/icons-material/Group';

const Performers = () => (
  <div className="app-wrapper div-wrapper-title">
    <div className="headerAlign">
      <HeaderBanner
        title={Translator.trans('performers.title')}
        icon={<GroupIcon />}
      />
      <NavLink to={pagesConstant.performers.add}>
        <CreateButton>
          {Translator.trans('performers.button.add.title')}
        </CreateButton>
      </NavLink>
    </div>

    <div className="row">
      <div className="col-12">
        <div className="jr-card">
          <PerformersTable />
        </div>
      </div>
    </div>
  </div>
);

export default Performers;
