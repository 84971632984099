import { getUrl, get, del, post, patch, update } from '../../services';

export async function getContentArticlesByProjectApi(params: any) {
  return await get(getUrl('projects/' + params.idProject + '/contentarticles'));
}

export async function postContentArticlesByProjectApi(params: any) {
  return await post(
    getUrl('projects/' + params.idProject + '/contentarticles'),
    params.newContentArticle
  );
}

export async function putContentArticleApi(params: any) {
  return await update(
    getUrl('contentarticles/' + params.updatedContentArticle.id),
    params.updatedContentArticle
  );
}

export async function putContentArticlePositionApi(params: any) {
  return await patch(
    getUrl('contentarticles/' + params.id + '/reorder/' + params.index)
  );
}

export async function deleteContentArticleApi(params: any) {
  return await del(getUrl('contentarticles/' + params.id));
}

export async function getContentArticleApi(params: any) {
  return await get(getUrl('contentarticles/' + params.idContentArticle));
}

export async function getTranslationContentArticleApi(params: any) {
  return await get(getUrl('contentarticles/' + params.id + '/translations'));
}

export async function postTranslationContentArticleSelfApi(params: any) {
  return await post(
    getUrl('contentarticles/' + params.id + '/translations'),
    params.translationContentArticle
  );
}

export async function putTranslationContentArticleSelfApi(params: any) {
  return await update(
    getUrl('contentarticles/' + params.id + '/translations'),
    params.updatedTranslationContentArticle
  );
}
