import React, { useEffect, useState } from 'react';
import { deleteSessionRequest } from '../../Redux/actions';
import { ActionButton } from '@emisys/audience-sdk-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { pagesConstant } from '../../Constants/Constant';
import DeleteModal from '../DeleteModal/DeleteModal';
import SessionModel from '../../Models/SessionModel';
import { storeType } from '../../index';
import { checkReduxResponse } from '../../services/checkReduxResponse';

const ButtonsSessions = (props: { session: SessionModel }) => {
  const dispatch = useDispatch();
  const [isClosed, setIsClosed] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);

  const oldSessionSelf = useSelector(
    (state: storeType) => state.sessions.oldSessionSelf
  );

  useEffect(() => {
    if (oldSessionSelf) {
      setLoadingSend(oldSessionSelf.loading);
      if (checkReduxResponse(oldSessionSelf, 'session')) {
        setIsClosed(false);
      }
    }
  }, [dispatch, oldSessionSelf]);
  const handleRequestDelete = () => {
    dispatch(deleteSessionRequest(props.session.id));
  };

  return (
    <div className={'action-container-buttons'}>
      <NavLink
        to={{
          pathname: pagesConstant.sessions.edit + props.session.id,
        }}
      >
        <ActionButton icon={'edit'} className="editSession" />
      </NavLink>

      <ActionButton
        icon={'delete'}
        className="deleteSession"
        onClick={() => setIsClosed(true)}
      />

      <DeleteModal
        isLoading={loadingSend}
        isOpen={isClosed}
        onConfirm={handleRequestDelete}
        onCancel={() => setIsClosed(false)}
      />
    </div>
  );
};

export default ButtonsSessions;
