export const rowPerTablePage = [5, 10, 20, 50, 100];

export const columnNameAction = 'buttons';
export const columnNameDrag = 'drag';

export const hideSortingButtons = [
  { columnName: 'sessionPerformers', sortingEnabled: false },
  { columnName: 'performer', sortingEnabled: false },
  { columnName: 'place', sortingEnabled: false },
  { columnName: columnNameAction, sortingEnabled: false },
  { columnName: columnNameDrag, sortingEnabled: false },
];
