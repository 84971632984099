import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  clearContentArticle,
  deleteContentArticlesRequest,
} from '../../Redux/actions';
import { ActionButton } from '@emisys/audience-sdk-ui-react';
import { pagesConstant } from '../../Constants/Constant';
import DeleteModal from '../DeleteModal/DeleteModal';
import { checkReduxResponse } from '../../services/checkReduxResponse';
import { storeType } from '../../index';

const ButtonsArticles = (props: { id: number }) => {
  const dispatch = useDispatch();
  const [loadingSend, setLoadingSend] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const deletedContentArticle = useSelector(
    (state: storeType) => state.contentArticles.deletedContentArticle
  );

  useEffect(() => {
    if (deletedContentArticle) {
      setLoadingSend(deletedContentArticle.loading);
      if (checkReduxResponse(deletedContentArticle, 'contentArticle')) {
        setShowModalDelete(false);
        dispatch(clearContentArticle());
      }
    }
  }, [deletedContentArticle, dispatch]);

  return (
    <div className={'action-container-buttons'}>
      <NavLink to={pagesConstant.article.edit + props.id}>
        <ActionButton icon={'edit'} className={'editArticle'} />
      </NavLink>

      <ActionButton
        className={'deleteArticle'}
        icon={'delete'}
        onClick={() => setShowModalDelete(true)}
      />

      <DeleteModal
        isLoading={loadingSend}
        isOpen={showModalDelete}
        onConfirm={() => {
          dispatch(deleteContentArticlesRequest(props.id));
        }}
        onCancel={() => {
          setShowModalDelete(false);
        }}
      />
    </div>
  );
};

export default ButtonsArticles;
