import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Translator from '../../../services/translator';
import {
  putSessionTranslationRequest,
  getSessionTranslationRequest,
  getSessionRequest,
  putSessionRequest,
  putSessionPerformersRequest,
  putSessionPlacesRequest,
  clearSession,
  getPerformersListRequest,
} from '../../../Redux/actions';
import {
  HeaderBanner,
  Loading,
  UnderlineTitle,
} from '@emisys/audience-sdk-ui-react';
import { NotificationManager } from '../../../components/Notification/NotificationManager';
import { currentProject } from '../../../Global/currentProject';
import { pagesConstant } from '../../../Constants/Constant';
import QueryBuilder from '@mui/icons-material/QueryBuilder';
import AccordionTranslation from '../../../components/Accordion/AccordionTranslation';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import ButtonsSaveModal from '../../../components/Buttons/ButtonsSaveModal';
import { storeType } from '../../../index';
import { useNavigate, useParams } from 'react-router-dom';
import SessionContent from '../SessionContent/SessionContent';
import SessionTranslations from '../SessionContent/SessionTranslations';
import SessionModel from '../../../Models/SessionModel';
import SessionPlaceModel from '../../../Models/SessionPlaceModel';
import { compareArrays } from '../../../services/compareArrays';
import SessionPerformerModel from '../../../Models/SessionPerformerModel';

const SessionEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [currentSession, setCurrentSession] = useState<SessionModel | null>(
    null
  );

  const [defaultPlaces, setDefaultPlaces] = useState<number[]>([]);
  const [defaultPerformers, setDefaultPerformers] = useState<number[]>([]);

  const translationSessionList = useSelector(
    (state: storeType) => state.sessions.translationSessionList
  );

  const sessionSelf = useSelector(
    (state: storeType) => state.sessions.sessionSelf
  );

  const updatedSession = useSelector(
    (state: storeType) => state.sessions.updatedSession
  );

  const newPerformer = useSelector(
    (state: storeType) => state.performers.newPerformer
  );

  const newPlace = useSelector((state: storeType) => state.places.newPlace);

  const performers: any = useRef([]);
  const places: any = useRef([]);

  const session: any = useRef({
    title: '',
    description: '',
    locale: '',
    liveStartDateTimeUTC: '',
    liveEndDateTimeUTC: '',
    confirmed: false,
    highlighted: false,
    signedLanguages: false,
    track: null,
    duration: null,
  });
  const translationSession: any = useRef([]);

  useEffect(() => {
    if (id) {
      dispatch(getSessionRequest(id));
      dispatch(getSessionTranslationRequest(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (checkReduxResponse(translationSessionList, 'sessionList')) {
      currentProject.allowedLanguages.forEach((language) => {
        const currentTranslation = translationSessionList.sessionList.find(
          (element: any) => element.locale === language
        );

        if (currentTranslation) {
          if (
            !translationSession.current.some(
              (element: any) => element.id === currentTranslation.id
            )
          ) {
            translationSession.current.push(currentTranslation);
          }
        } else {
          translationSession.current.push({
            locale: language,
            description: '',
          });
        }
      });
    }
  }, [translationSessionList]);

  useEffect(() => {
    if (sessionSelf) {
      setLoading(sessionSelf.loading);
      if (checkReduxResponse(sessionSelf, 'session')) {
        session.current.id = sessionSelf.session.id;
        setCurrentSession(sessionSelf.session);

        if (sessionSelf.session?.sessionPlaces) {
          const selectedPlaces = sessionSelf.session.sessionPlaces.map(
            (sessionPlace: SessionPlaceModel) => sessionPlace.place.id
          );
          setDefaultPlaces(selectedPlaces);
        }

        if (sessionSelf.session?.sessionPerformers) {
          const selectedPerformers: number[] =
            sessionSelf.session.sessionPerformers.map(
              (sessionPerformer: SessionPerformerModel) =>
                sessionPerformer.performer!.id
            );
          setDefaultPerformers(selectedPerformers);
        }
      }
    }
  }, [sessionSelf]);

  useEffect(() => {
    if (updatedSession) {
      setLoadingSend(updatedSession.loading);
      if (checkReduxResponse(updatedSession, 'session')) {
        NotificationManager.success(
          Translator.trans('sessions.message.success.updated')
        );
        navigate(pagesConstant.sessions.home);
      }

      dispatch(clearSession());
    }
  }, [navigate, dispatch, updatedSession]);

  useEffect(() => {
    if (newPlace) {
      setLoadingSend(newPlace.loading);
      if (checkReduxResponse(newPlace, 'place')) {
        places.current.push(newPlace.place.id);
        NotificationManager.success(
          Translator.trans('places.message.success.added')
        );
      }
    }
  }, [newPlace]);

  useEffect(() => {
    if (newPerformer) {
      setLoadingSend(newPerformer.loading);
      if (checkReduxResponse(newPerformer, 'performer')) {
        NotificationManager.success(
          Translator.trans('performers.message.success.added')
        );
        dispatch(
          getPerformersListRequest(currentProject.id, {
            order: 'name:ASC',
          })
        );
        performers.current.push(newPerformer.performer.id);
      }
    }
  }, [dispatch, newPerformer]);

  const handleChangeTranslation = (
    name: string,
    value: string,
    locale: string
  ) => {
    let objIndex = translationSession.current.findIndex(
      (item: any) => item.locale === locale
    );

    if (objIndex > -1) {
      translationSession.current[objIndex][name] = value;
    }
  };

  const handleChangeSession = (name: string, data: any) => {
    if (name === 'performers') {
      performers.current = data;
    } else if (name === 'places') {
      places.current = data;
    } else {
      session.current[name] = data;
    }
  };

  const handleSubmit = () => {
    if (session.current.title === '' || session.current.title === null) {
      NotificationManager.warning(Translator.trans('message.title.missing'));
    } else {
      dispatch(putSessionRequest(session.current));

      if (currentSession) {
        dispatch(
          putSessionTranslationRequest(currentSession.id, {
            translations: translationSession.current,
          })
        );


        if (!compareArrays(performers.current, defaultPerformers)) {
          console.log("=>(SessionEdit.tsx:222) performers.current", performers.current);
          dispatch(
            putSessionPerformersRequest(currentSession.id, {
              performers: performers.current,
            })
          );
        }


        if (!compareArrays(places.current, defaultPlaces)) {
          console.log("=>(SessionEdit.tsx:231) places.current", places.current);
          dispatch(
            putSessionPlacesRequest(currentSession.id, {
              places: places.current,
            })
          );
        }
      }
    }
  };

  return (
    <div className="app-wrapper div-wrapper-title">
      <div className="headerAlign">
        <HeaderBanner
          title={Translator.trans('sessions.edit.news.title')}
          icon={<QueryBuilder />}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="jr-card">
            <SessionContent
              session={currentSession}
              handleChangeSession={handleChangeSession}
              defaultPlaces={defaultPlaces}
              defaultPerformers={defaultPerformers}
            />

            <div className="mt-4">
              <UnderlineTitle
                title={Translator.trans('sessions.title.description')}
              />

              {currentProject.allowedLanguages.map((language) => {
                let translation = translationSession.current.find(
                  (element: any) => element.locale === language
                );
                return (
                  <AccordionTranslation key={language} language={language}>
                    <SessionTranslations
                      translation={translation}
                      handleChangeTranslation={handleChangeTranslation}
                      language={language}
                    />
                  </AccordionTranslation>
                );
              })}
            </div>

            <ButtonsSaveModal
              onClose={() => navigate(pagesConstant.sessions.home)}
              onSubmit={handleSubmit}
              isLoading={loadingSend}
            />
            {loading && <Loading />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionEdit;
