import React, { useEffect, useState } from 'react';
import Translator from '../../services/translator';
import AddressModel from '../../Models/AddressModel';
import CountrySelect from '../../components/CountrySelect/CountrySelect';
import {
  Loading,
  MainTextField,
  InputPhone,
} from '@emisys/audience-sdk-ui-react';
import { currentProject } from '../../Global/currentProject';
import FormHelperText from '@mui/material/FormHelperText';

interface EventAddressProps {
  address: AddressModel | null;
  handleChange: (address: AddressModel) => void;
  errors: { [key: string]: string } | null;
}

export const AddressComponent = (props: EventAddressProps) => {
  const [currentAddress, setCurrentAddress] = useState<AddressModel>(
    new AddressModel({})
  );

  useEffect(() => {
    if (props.address) {
      setCurrentAddress(props.address);
    }
  }, [props.address]);

  const updateAddress = (key: string, newValue: string) => {
    let newAddress: any = { ...props.address };
    newAddress[key] = newValue;
    props.handleChange(newAddress);
  };

  const updatePhoneNumber = (newValue: string) => {
    let newAddress: any = { ...props.address };
    if (newValue) {
      newAddress.phoneNumber = '+' + newValue;
    } else {
      newAddress.phoneNumber = '';
    }
    props.handleChange(newAddress);
  };

  return (
    <div className="position-relative">
      <div className="row">
        <div className="col-lg-12 mb-3">
          <MainTextField
            name={'name'}
            labelText={Translator.trans('appModule.address.name')}
            value={currentAddress.name}
            onChange={(e: any) => updateAddress('name', e.target.value)}
            helperText={props.errors?.name}
            error={props.errors?.hasOwnProperty('name')}
          />
        </div>
        <div className="col-lg-8">
          <MainTextField
            required
            name={'street'}
            labelText={Translator.trans('appModule.address')}
            placeholder={Translator.trans('appModule.address')}
            value={currentAddress.street}
            onChange={(e: any) => updateAddress('street', e.target.value)}
            helperText={props.errors?.street}
            error={props.errors?.hasOwnProperty('street')}
          />
        </div>
        <div className="col-lg-2">
          <MainTextField
            name={'streetNumber'}
            labelText={Translator.trans('appModule.houseNumber')}
            placeholder={Translator.trans('appModule.houseNumber')}
            value={currentAddress.streetNumber}
            onChange={(e: any) => updateAddress('streetNumber', e.target.value)}
            helperText={props.errors?.streetNumber}
            error={props.errors?.hasOwnProperty('streetNumber')}
          />
        </div>
        <div className="col-lg-2">
          <MainTextField
            name={'boxNumber'}
            labelText={Translator.trans('project.label.house.box')}
            placeholder={Translator.trans('project.label.house.box')}
            value={currentAddress.boxNumber}
            onChange={(e: any) => updateAddress('boxNumber', e.target.value)}
            helperText={props.errors?.boxNumber}
            error={props.errors?.hasOwnProperty('boxNumber')}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-6">
          <MainTextField
            name={'city'}
            labelText={Translator.trans('appModule.city')}
            placeholder={Translator.trans('appModule.city')}
            value={currentAddress.city}
            onChange={(e: any) => updateAddress('city', e.target.value)}
            helperText={props.errors?.city}
            error={props.errors?.hasOwnProperty('city')}
          />
        </div>
        <div className="col-lg-3">
          <MainTextField
            name={'zipCode'}
            labelText={Translator.trans('appModule.zipCode')}
            placeholder={Translator.trans('appModule.zipCode')}
            value={currentAddress.zipCode}
            onChange={(e: any) => updateAddress('zipCode', e.target.value)}
            helperText={props.errors?.zipCode}
            error={props.errors?.hasOwnProperty('zipCode')}
          />
        </div>
        <div className="col-lg-3">
          <MainTextField
            name={'areaLevel1'}
            labelText={Translator.trans('project.label.district')}
            placeholder={Translator.trans('project.label.district')}
            value={currentAddress.areaLevel1}
            onChange={(e: any) => updateAddress('areaLevel1', e.target.value)}
            helperText={props.errors?.areaLevel1}
            error={props.errors?.hasOwnProperty('areaLevel1')}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-3">
          <CountrySelect
            value={currentAddress.country}
            onChange={(value) => updateAddress('country', value)}
          />
        </div>
        <div className="col-lg-4">
          <div className="mb-1">{Translator.trans('appModule.phone')}</div>
          <InputPhone
            country={currentProject.defaultCountry.toLowerCase()}
            value={currentAddress.phoneNumber}
            onChange={updatePhoneNumber}
          />
          <FormHelperText error>{props.errors?.phoneNumber}</FormHelperText>
        </div>
      </div>

      {!props.address && <Loading />}
    </div>
  );
};

export default AddressComponent;
