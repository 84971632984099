import { getUrl, get, del, post, update } from '../../services';

export async function getSessionsByProjectApi(params: any) {
  return await get(
    getUrl('projects/' + params.idProject + '/sessions', params.args)
  );
}

export async function getSessionSelfApi(params: any) {
  return await get(getUrl('sessions/' + params.id));
}

export async function deleteSessionSelfApi(id: any) {
  return await del(getUrl('sessions/' + id));
}

export async function putSessionSelfApi(session: any) {
  return await update(getUrl('sessions/' + session.id), session);
}

export async function postSessionPerformersApi(params: any) {
  return await post(
    getUrl('sessions/' + params.idSession + '/performers'),
    params.performers
  );
}

export async function postSessionPlacesApi(params: any) {
  return await post(
    getUrl('sessions/' + params.idSession + '/places'),
    params.places
  );
}

export async function putSessionPerformersApi(params: any) {
  return await update(
    getUrl('sessions/' + params.idSession + '/performers'),
    params.performers
  );
}

export async function putSessionPlacesApi(params: any) {
  return await update(
    getUrl('sessions/' + params.idSession + '/places'),
    params.places
  );
}

export async function getTranslationSessionApi(params: any) {
  return await get(getUrl('sessions/' + params.id + '/translations'));
}

export async function postTranslationSessionSelfApi(params: any) {
  return await post(
    getUrl('sessions/' + params.id + '/translations'),
    params.translationSession
  );
}

export async function putTranslationSessionSelfApi(params: any) {
  return await update(
    getUrl('sessions/' + params.id + '/translations'),
    params.translations
  );
}
