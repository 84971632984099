import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { sessionTracksConstants } from '../constants';
import {
  getSessionTracksListSuccess,
  getSessionTracksListFailure,
  postSessionTrackSuccess,
  postSessionTrackFailure,
  deleteSessionTrackSuccess,
  deleteSessionTrackFailure,
  getSessionTrackTranslationSuccess,
  getSessionTrackTranslationFailure,
  postSessionTrackTranslationSuccess,
  postSessionTrackTranslationFailure,
  putSessionTrackTranslationSuccess,
  putSessionTrackTranslationFailure,
} from '../actions';
import {
  getSessionTracksByProjectApi,
  postSessionTrackByProjectApi,
  deleteSessionTrackSelfApi,
  getTranslationSessionTrackApi,
  postTranslationSessionTrackSelfApi,
  putTranslationSessionTrackSelfApi,
} from '../api';

export function* fetchGetSessionTracksByProject(action: any): any {
  try {
    const sessionTracks = yield call(getSessionTracksByProjectApi, {
      idProject: action.idProject,
    });
    yield put(getSessionTracksListSuccess(sessionTracks));
  } catch (error) {
    yield put(getSessionTracksListFailure(error));
  }
}

export function* fetchPostSessionTrackByProject(action: any): any {
  try {
    const sessionTrack = yield call(postSessionTrackByProjectApi, {
      idProject: action.sessionTrack.idProject,
      newSessionTrack: action.sessionTrack,
    });
    yield put(postSessionTrackSuccess(sessionTrack));
  } catch (error) {
    yield put(postSessionTrackFailure(error));
  }
}

export function* fetchDeleteSessionTrackSelf(action: any): any {
  try {
    const sessionTrack = yield call(deleteSessionTrackSelfApi, action.id);
    yield put(deleteSessionTrackSuccess(sessionTrack));
  } catch (error) {
    yield put(deleteSessionTrackFailure(error));
  }
}

export function* fetchGetTranslationSessionTrackSelf(action: any): any {
  try {
    const translationSessionTrackList = yield call(
      getTranslationSessionTrackApi,
      { id: action.idSessionTrack }
    );
    yield put(getSessionTrackTranslationSuccess(translationSessionTrackList));
  } catch (error) {
    yield put(getSessionTrackTranslationFailure(error));
  }
}

export function* fetchPostTranslationSessionTrackSelf(action: any): any {
  try {
    const translationSessionTrack = yield call(
      postTranslationSessionTrackSelfApi,
      {
        id: action.sessionTrackId,
        translationSessionTrack: action.translationSessionTrack,
      }
    );
    yield put(postSessionTrackTranslationSuccess(translationSessionTrack));
  } catch (error) {
    yield put(postSessionTrackTranslationFailure(error));
  }
}

export function* fetchPutTranslationSessionTrackSelf(action: any): any {
  try {
    const updatedTranslationSessionTrack = yield call(
      putTranslationSessionTrackSelfApi,
      {
        id: action.sessionTrackId,
        translations: action.translations,
      }
    );
    yield put(
      putSessionTrackTranslationSuccess(updatedTranslationSessionTrack)
    );
  } catch (error) {
    yield put(putSessionTrackTranslationFailure(error));
  }
}

export function* getSessionTracksByProject() {
  yield takeLatest(
    sessionTracksConstants.GET_SESSION_TRACKS_REQUEST,
    fetchGetSessionTracksByProject
  );
}

export function* postSessionTrackByProject() {
  yield takeLatest(
    sessionTracksConstants.POST_SESSION_TRACKS_REQUEST,
    fetchPostSessionTrackByProject
  );
}

export function* deleteSessionTrackSelf() {
  yield takeLatest(
    sessionTracksConstants.DELETE_SESSION_TRACKS_REQUEST,
    fetchDeleteSessionTrackSelf
  );
}

export function* getTranslationSessionTrackSelf() {
  yield takeLatest(
    sessionTracksConstants.GET_SESSION_TRACKS_TRANSLATION_REQUEST,
    fetchGetTranslationSessionTrackSelf
  );
}

export function* postTranslationSessionTrackSelf() {
  yield takeLatest(
    sessionTracksConstants.POST_SESSION_TRACKS_TRANSLATION_REQUEST,
    fetchPostTranslationSessionTrackSelf
  );
}

export function* putTranslationSessionTrackSelf() {
  yield takeLatest(
    sessionTracksConstants.PUT_SESSION_TRACKS_TRANSLATION_REQUEST,
    fetchPutTranslationSessionTrackSelf
  );
}

export default function* sessionTracksSaga() {
  yield all([
    fork(getSessionTracksByProject),
    fork(postSessionTrackByProject),
    fork(deleteSessionTrackSelf),
    fork(getTranslationSessionTrackSelf),
    fork(postTranslationSessionTrackSelf),
    fork(putTranslationSessionTrackSelf),
  ]);
}
