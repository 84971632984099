import { notificationsConstants } from '../constants';

export const getNotificationsListRequest = (idProject: number | string) => ({
  type: notificationsConstants.GET_NOTIFICATIONS_LIST_REQUEST,
  idProject,
});

export const getNotificationsListSuccess = (notifications: any) => ({
  type: notificationsConstants.GET_NOTIFICATIONS_LIST_SUCCESS,
  notifications,
});

export const getNotificationsListFailure = (error: any) => ({
  type: notificationsConstants.GET_NOTIFICATIONS_LIST_FAILURE,
  error,
});

export const postNotificationsRequest = (
  projectId: number | string,
  newNotification: any
) => ({
  type: notificationsConstants.POST_NOTIFICATIONS_REQUEST,
  projectId,
  newNotification,
});

export const postNotificationsSuccess = (newNotification: any) => ({
  type: notificationsConstants.POST_NOTIFICATIONS_SUCCESS,
  newNotification,
});

export const postNotificationsFailure = (error: any) => ({
  type: notificationsConstants.POST_NOTIFICATIONS_FAILURE,
  error,
});

export const clearNotifications = () => ({
  type: notificationsConstants.CLEAR_NOTIFICATIONS_REQUEST,
});
