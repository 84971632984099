import { sessionsConstants } from '../constants';
import SessionModel from '../../Models/SessionModel';

const initialState: any = {
  sessionsList: null,
  sessionSelf: null,
  updatedSession: null,
  oldSessionSelf: null,
  newSessionPerformers: null,
  newSessionPlaces: null,
  updatedSessionPerformers: null,
  updatedSessionPlaces: null,
  translationSessionList: null,
  translationSession: null,
  updatedTranslationSession: null,
};

let sessionList: SessionModel[] = [];
const sessions = (state = initialState, action: any) => {
  switch (action.type) {
    case sessionsConstants.GET_SESSIONS_LIST_REQUEST: {
      return {
        ...state,
        sessionsList: { sessions: sessionList, error: false, loading: true },
      };
    }

    case sessionsConstants.GET_SESSIONS_LIST_SUCCESS: {
      sessionList = action.sessions.data.map(
        (session: any) => new SessionModel(session)
      );
      return {
        ...state,
        sessionsList: {
          sessions: sessionList,
          meta: action.sessions.meta,
          error: false,
          loading: false,
        },
      };
    }

    case sessionsConstants.GET_SESSIONS_LIST_FAILURE: {
      return {
        ...state,
        sessionsList: { sessions: null, error: true, loading: false },
      };
    }

    case sessionsConstants.GET_SESSION_REQUEST: {
      return {
        ...state,
        sessionSelf: { session: null, error: false, loading: true },
      };
    }

    case sessionsConstants.GET_SESSION_SUCCESS: {
      return {
        ...state,
        sessionSelf: {
          session: new SessionModel(action.session.data),
          error: false,
          loading: false,
        },
      };
    }

    case sessionsConstants.GET_SESSION_FAILURE: {
      return {
        ...state,
        sessionSelf: { session: null, error: true, loading: false },
      };
    }

    case sessionsConstants.PUT_SESSION_REQUEST: {
      return {
        ...state,
        updatedSession: { session: null, error: false, loading: true },
      };
    }

    case sessionsConstants.PUT_SESSION_SUCCESS: {
      return {
        ...state,
        updatedSession: {
          session: action.updatedSession.data,
          error: false,
          loading: false,
        },
      };
    }

    case sessionsConstants.PUT_SESSION_FAILURE: {
      return {
        ...state,
        updatedSession: { session: null, error: true, loading: false },
      };
    }

    case sessionsConstants.DELETE_SESSION_REQUEST: {
      return {
        ...state,
        oldSessionSelf: { session: null, error: false, loading: true },
      };
    }

    case sessionsConstants.DELETE_SESSION_SUCCESS: {
      return {
        ...state,
        oldSessionSelf: { session: true, error: false, loading: false },
      };
    }

    case sessionsConstants.DELETE_SESSION_FAILURE: {
      return {
        ...state,
        oldSessionSelf: { session: null, error: true, loading: false },
      };
    }

    case sessionsConstants.POST_SESSION_PERFORMERS_REQUEST: {
      return {
        ...state,
        newSessionPerformers: {
          sessionPerformers: null,
          error: false,
          loading: true,
        },
      };
    }

    case sessionsConstants.POST_SESSION_PERFORMERS_SUCCESS: {
      return {
        ...state,
        newSessionPerformers: {
          sessionPerformers: action.sessionPerformers.data,
          error: false,
          loading: false,
        },
      };
    }

    case sessionsConstants.POST_SESSION_PERFORMERS_FAILURE: {
      return {
        ...state,
        newSessionPerformers: {
          sessionPerformers: null,
          error: true,
          loading: false,
        },
      };
    }

    case sessionsConstants.POST_SESSION_PLACES_REQUEST: {
      return {
        ...state,
        newSessionPlaces: { sessionPlaces: null, error: false, loading: true },
      };
    }

    case sessionsConstants.POST_SESSION_PLACES_SUCCESS: {
      return {
        ...state,
        newSessionPlaces: {
          sessionPlaces: action.sessionPlaces.data,
          error: false,
          loading: false,
        },
      };
    }

    case sessionsConstants.POST_SESSION_PLACES_FAILURE: {
      return {
        ...state,
        newSessionPlaces: { sessionPlaces: null, error: true, loading: false },
      };
    }

    case sessionsConstants.PUT_SESSION_PERFORMERS_REQUEST: {
      return {
        ...state,
        updatedSessionPerformers: {
          sessionPerformers: null,
          error: false,
          loading: true,
        },
      };
    }

    case sessionsConstants.PUT_SESSION_PERFORMERS_SUCCESS: {
      return {
        ...state,
        updatedSessionPerformers: {
          sessionPerformers: action.updatedSessionPerformers.data,
          error: false,
          loading: false,
        },
      };
    }

    case sessionsConstants.PUT_SESSION_PERFORMERS_FAILURE: {
      return {
        ...state,
        updatedSessionPerformers: {
          sessionPerformers: null,
          error: true,
          loading: false,
        },
      };
    }

    case sessionsConstants.PUT_SESSION_PLACES_REQUEST: {
      return {
        ...state,
        updatedSessionPlaces: {
          sessionPlaces: null,
          error: false,
          loading: true,
        },
      };
    }

    case sessionsConstants.PUT_SESSION_PLACES_SUCCESS: {
      return {
        ...state,
        updatedSessionPlaces: {
          sessionPlaces: action.updatedSessionPlaces.data,
          error: false,
          loading: false,
        },
      };
    }

    case sessionsConstants.PUT_SESSION_PLACES_FAILURE: {
      return {
        ...state,
        updatedSessionPlaces: {
          sessionPlaces: null,
          error: true,
          loading: false,
        },
      };
    }

    case sessionsConstants.GET_SESSION_TRANSLATION_REQUEST: {
      return {
        ...state,
        translationSessionList: {
          sessionList: '',
          error: false,
          loading: true,
        },
      };
    }

    case sessionsConstants.GET_SESSION_TRANSLATION_SUCCESS: {
      return {
        ...state,
        translationSessionList: {
          sessionList: action.translationSessionList.data,
          error: false,
          loading: false,
        },
      };
    }

    case sessionsConstants.GET_SESSION_TRANSLATION_FAILURE: {
      return {
        ...state,
        translationSessionList: {
          sessionList: '',
          error: true,
          loading: false,
        },
      };
    }

    case sessionsConstants.POST_SESSION_TRANSLATION_REQUEST: {
      return {
        ...state,
        translationSession: { session: '', error: false, loading: true },
      };
    }

    case sessionsConstants.POST_SESSION_TRANSLATION_SUCCESS: {
      return {
        ...state,
        translationSession: {
          session: action.translationSession.data,
          error: false,
          loading: false,
        },
      };
    }

    case sessionsConstants.POST_SESSION_TRANSLATION_FAILURE: {
      return {
        ...state,
        translationSession: { session: '', error: true, loading: false },
      };
    }

    case sessionsConstants.PUT_SESSION_TRANSLATION_REQUEST: {
      return {
        ...state,
        updatedTranslationSession: { session: '', error: false, loading: true },
      };
    }

    case sessionsConstants.PUT_SESSION_TRANSLATION_SUCCESS: {
      return {
        ...state,
        updatedTranslationSession: {
          session: action.updatedTranslationSession.data,
          error: false,
          loading: false,
        },
      };
    }

    case sessionsConstants.PUT_SESSION_TRANSLATION_FAILURE: {
      return {
        ...state,
        updatedTranslationSession: { session: '', error: true, loading: false },
      };
    }

    case sessionsConstants.CLEAR_SESSION: {
      return {
        ...state,
        sessionSelf: null,
        updatedSession: null,
        oldSessionSelf: null,
        newSessionPerformers: null,
        newSessionPlaces: null,
        updatedSessionPerformers: null,
        updatedSessionPlaces: null,
        translationSessionList: null,
        translationSession: null,
        updatedTranslationSession: null,
      };
    }

    default:
      return state;
  }
};

export default sessions;
