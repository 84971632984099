import React, { useEffect, useState } from 'react';
import { ImageCropper, MainTextField } from '@emisys/audience-sdk-ui-react';
import Translator from '../../../services/translator';
import {
  FaFacebookF,
  FaInstagram,
  FaLink,
  FaSpotify,
  FaYoutube,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import PerformerModel from '../../../Models/PerformerModel';
import DeleteBadge from '../../../components/DeleteBadge/DeleteBadge';
import '../index.css';

const PerformerSocials = (props: {
  performer?: PerformerModel | null;
  handleChangePicture: (picture: string | null) => void;
  handleChangePerformer: (name: string, value: string | number | null) => void;
}) => {
  const [currentImg, setCurrentImg] = useState('');
  const [website, setWebsite] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [spotifyUrl, setSpotifyUrl] = useState('');

  useEffect(() => {
    if (props.performer) {
      setWebsite(props.performer.website);
      setFacebookUrl(props.performer.facebookUrl);
      setInstagramUrl(props.performer.instagramUrl);
      setTwitterUrl(props.performer.twitterUrl);
      setYoutubeUrl(props.performer.youtubeUrl);
      setSpotifyUrl(props.performer.spotifyUrl);

      if (props.performer.picture?.original) {
        setCurrentImg(props.performer.picture.original);
      }
    }
  }, [props.performer]);

  const removeImage = () => {
    props.handleChangePicture(null);
    setCurrentImg('');
  };

  const addImage = (image: string) => {
    props.handleChangePicture(image);
  };

  const handleChangeWebsite = (value: string) => {
    props.handleChangePerformer('website', value);
    setWebsite(value);
  };

  const handleChangeFacebook = (value: string) => {
    props.handleChangePerformer('facebookUrl', value);
    setFacebookUrl(value);
  };

  const handleChangeInstagram = (value: string) => {
    props.handleChangePerformer('instagramUrl', value);
    setInstagramUrl(value);
  };

  const handleChangeTwitter = (value: string) => {
    props.handleChangePerformer('twitterUrl', value);
    setTwitterUrl(value);
  };

  const handleChangeYoutube = (value: string) => {
    props.handleChangePerformer('youtubeUrl', value);
    setYoutubeUrl(value);
  };

  const handleChangeSpotify = (value: string) => {
    props.handleChangePerformer('spotifyUrl', value);
    setSpotifyUrl(value);
  };

  return (
    <aside className={'performer-aside-socials'}>
      <div className="mb-3">
        {currentImg ? (
          <DeleteBadge onClick={removeImage}>
            <img src={currentImg} alt={'perfomer image'} />
          </DeleteBadge>
        ) : (
          <ImageCropper
            labelText={Translator.trans('appModule.drag.and.drop')}
            handlePicture={addImage}
          />
        )}
      </div>
      <hr />
      <div>
        <div className="mb-2 socialMediaLink">
          <FaLink className="mr-2" />
          <MainTextField
            value={website}
            placeholder="www.personallink.com"
            onChange={(event) => handleChangeWebsite(event.target.value)}
          />
        </div>
        <div className="mb-2 socialMediaLink">
          <FaFacebookF className="mr-2" color={'#4267B2'} />
          <MainTextField
            value={facebookUrl}
            placeholder="www.facebook.com"
            onChange={(event) => handleChangeFacebook(event.target.value)}
          />
        </div>
        <div className="mb-2 socialMediaLink">
          <FaInstagram className="mr-2" color={'#E1306C'} />
          <MainTextField
            value={instagramUrl}
            placeholder="www.instagram.com"
            onChange={(event) => handleChangeInstagram(event.target.value)}
          />
        </div>
        <div className="mb-2 socialMediaLink">
          <FaXTwitter className="mr-2" />
          <MainTextField
            value={twitterUrl}
            placeholder="www.twitter.com"
            onChange={(event) => handleChangeTwitter(event.target.value)}
          />
        </div>
        <div className="mb-2 socialMediaLink">
          <FaYoutube className="mr-2" color={'#FF0000'} />
          <MainTextField
            value={youtubeUrl}
            placeholder="www.youtube.com"
            onChange={(event) => handleChangeYoutube(event.target.value)}
          />
        </div>
        <div className="mb-2 socialMediaLink">
          <FaSpotify className="mr-2" color={'#1DB954'} />
          <MainTextField
            value={spotifyUrl}
            placeholder="www.spotify.com"
            onChange={(event) => handleChangeSpotify(event.target.value)}
          />
        </div>
      </div>
    </aside>
  );
};

export default PerformerSocials;
