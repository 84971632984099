import { uploadsConstants } from '../constants';
import { NotificationManager } from '../../components/Notification/NotificationManager';
import { createImageModel } from '../../Models/ImageModel';

const initialState: any = {
  newUploadPicture: null,
};

const uploads = (state = initialState, action: any) => {
  switch (action.type) {
    case uploadsConstants.POST_UPLOAD_PICTURE_REQUEST: {
      return {
        ...state,
        newUploadPicture: { uploadPicture: null, error: false, loading: true },
      };
    }

    case uploadsConstants.POST_UPLOAD_PICTURE_SUCCESS: {
      return {
        ...state,
        newUploadPicture: {
          uploadPicture: createImageModel(action.newUploadPicture.data),
          error: false,
          loading: false,
        },
      };
    }

    case uploadsConstants.POST_UPLOAD_PICTURE_FAILURE: {
      if (action.error?.body?.data?.message) {
        NotificationManager.error(action.error.body.data.message);
      }
      return {
        ...state,
        newUploadPicture: { uploadPicture: null, error: true, loading: false },
      };
    }

    case uploadsConstants.CLEAR_PICTURE: {
      return {
        ...state,
        newUploadPicture: null,
      };
    }

    default:
      return state;
  }
};

export default uploads;
